import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { UIHelpers } from "@vas-common/ui-kit";

import FlagIcon from "../../flag/FlagIcon";
import { setLogFilter } from "store/actions";
import { TFraudType, TGateway, TNetwork, TTrafficList } from "store/mobx/types";
import { tickFormatter } from "utils/formatNumber";
import { URLS } from "constants/Urls";

import css from "./TableRow.module.scss";

type Props = {
  data: TTrafficList;
  idFraud: number;
  fraudType: string;
  networks: TNetwork[];
  gateways: TGateway[];
  fraudTypes: TFraudType[];
  from: number;
  to: number | undefined;
};

const TableRow = ({
  data,
  idFraud,
  fraudType,
  networks,
  gateways,
  from,
  to,
}: Props) => {
  const history = useHistory();

  const getDynamicData = () => {
    const findElemNetwork = networks.find((el) => el.id === data.network_id);
    const findElemGateway = gateways.find((el) => el.id === data.gateway_id);
    if (findElemNetwork) {
      return (
        <div className={css.countryColumn}>
          <FlagIcon
            className={css.flagIcon}
            country={findElemNetwork.country_iso}
          />
          <div>{findElemNetwork.name}</div>
        </div>
      );
    }
    if (findElemGateway) {
      return <div>{findElemGateway.name}</div>;
    }
    return <div className={css.rowTableSkeleton} />;
  };

  const goToFraud = () => {
    const findElemGateway =
      gateways.find((el) => el.id === data.gateway_id)?.id || undefined;
    const country = data.country;
    let searchParams = `?direction=${fraudType}`;
    if (findElemGateway)
      searchParams = searchParams + `&gateway_ids=${findElemGateway}`;
    if (idFraud) searchParams = searchParams + `&fraud_types=${idFraud}`;
    if (country) searchParams = searchParams + `&countries=${country}`;
    if (from) searchParams = searchParams + `&from_ts=${from}`;
    if (to) searchParams = searchParams + `&till_ts=${to}`;
    history.push(`${URLS.fraudcalls}${searchParams}`);
  };

  const getStyle = (record: number, str: string | number) => {
    const color = record > 0 ? "#000" : "rgba(63, 69, 87, .5)";
    return <div style={{ color: color }}>{str}</div>;
  };

  const duration =
    data.fraudDuration < 60
      ? `${data.fraudDuration} sec`
      : `${Math.floor(data.fraudDuration / 60).toLocaleString()} min`;

  return (
    <>
      <tr
        className={`${css.row} ${data.fraudCalls > 0 ? css.active : ""}`}
        onClick={data.fraudCalls > 0 ? goToFraud : undefined}
      >
        <Severity value={data.severity} />

        <td className={css.defaultCell}>
          {data.percent && data.percent.toFixed(3)}%
        </td>

        <td className={css.originatorCell}>
          {data.country ? (
            <div className={css.countryColumn}>
              <FlagIcon className={css.flagIcon} country={data.country} />
              <div>{`${UIHelpers.countries.getCountryName(
                String(data.country).toUpperCase()
              )} (${String(data?.country || "").toUpperCase()})`}</div>
            </div>
          ) : (
            <div style={{ height: "100%" }}>{getDynamicData()}</div>
          )}
        </td>

        <td className={`${css.defaultCell} ${css.totalCountCol}`}>
          {getStyle(data.totalCalls, tickFormatter(data.totalCalls))}
        </td>

        <td className={css.defaultCell}>
          {getStyle(data.validCalls, tickFormatter(data.validCalls))}
        </td>

        <td className={css.defaultCell}>
          {getStyle(data.fraudCalls, tickFormatter(data.fraudCalls))}
        </td>

        <td className={css.defaultCell}>{duration}</td>
      </tr>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logFilters: state.filters.logFilters,
    intervalFilter: state.stats.filter,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLogFilters: (filters: any) => dispatch(setLogFilter(filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);

const Severity = (props: any) => {
  const { value } = props;
  return (
    <td className={css.severyCell} style={{ color: `var(--${value})` }}>
      <div className={css.circle} style={{ background: `var(--${value})` }} />
      {value}
    </td>
  );
};
