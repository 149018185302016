import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Spin, Checkbox, Form, Input } from "antd";
import isEqual from "lodash/isEqual";
import { Select } from "@vas-common/ui-kit";

import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";
import { useNotifications } from "ui/Notifications";
import {
  NotificationChannelTag,
  SipCodeTag,
} from "ui/FormControls/Select/tags";
import { RULE_ACTION_TAGS_MAP } from "ui/maps";
import { arrayToRuleAction, ruleActionToArray } from "./helpers";
import dictionary from "assets/dictionary.json";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import { ReactComponent as IconSettings } from "assets/svg/settings-gear.svg";
import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";

import type { PredefinedRuleUpdateBody } from "store/mobx/types";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import s from "./index.module.scss";

const LocationFalsification = observer(() => {
  const history = useHistory();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isNotificationChannelsDisabled, setIsNotificationChannelsDisabled] =
    useState(false);

  const [isSipRejectCodesShown, setIsSipRejectCodesShown] = useState(true);

  const [form] = Form.useForm();

  const { resetNotification, handleError } = useNotifications();

  const {
    referenceStore: { notificationChannels },
    firewallStore: {
      settings: firewallSettings,
      getLocationFalsificationRuleSettings,
      updateLocationFalsificationRuleSettings,
      getSipRejectCodes,
      sipRejectCodes,
    },
  } = useStores();

  const {
    locationFalsification: {
      isLoading: isLoadingLocationFalsification,
      settings,
    },
  } = firewallSettings;

  const isLoading =
    notificationChannels.isLoading ||
    isLoadingLocationFalsification ||
    sipRejectCodes.isLoading;

  useEffect(() => {
    getLocationFalsificationRuleSettings();
    getSipRejectCodes();
  }, [getLocationFalsificationRuleSettings, getSipRejectCodes]);

  useEffect(() => {
    // sync up settings and form. In case of the network error we need to roll back state
    const { action, notification_channel_ids, sip_reject_codes } =
      settings || {};

    form.setFieldsValue({
      action: ruleActionToArray(action),
      notification_channel_ids: notification_channel_ids || [],
      sip_reject_codes: sip_reject_codes || [],
    });
  }, [settings, form]);

  useEffect(() => {
    // initially define state
    if (settings?.action) {
      const value = settings.action;
      setIsNotificationChannelsDisabled(!value.includes("alert"));
      setIsSipRejectCodesShown(value.includes("block"));
    }
  }, [settings]);

  const initialValues = {
    name: "Location Falsification",
    action: ruleActionToArray(settings?.action),
    notification_channel_ids: settings?.notification_channel_ids || [],
    enabled: settings?.enabled,
    ...(settings?.show_sip_reject_codes &&
      settings.action.includes("block") && {
        sip_reject_codes: settings?.sip_reject_codes,
      }),
  };

  const isEmptyNotificationChannels = notificationChannels.data.length === 0;

  const handleIsNotificationChannelsDisabled = (e: CheckboxChangeEvent) => {
    setIsNotificationChannelsDisabled(!e.target.checked);
  };

  const handleSipRejectCodesShown = (e: CheckboxChangeEvent) => {
    setIsSipRejectCodesShown(e.target.checked);
  };

  return isLoading ? (
    <Spin className={s.spin} spinning />
  ) : (
    <Form
      form={form}
      className={`${s.container} ${s.form}`}
      initialValues={initialValues}
      layout="vertical"
      onFinish={(values) => {
        setIsUpdating(true);
        resetNotification();

        const action = arrayToRuleAction(values.action);

        const data: PredefinedRuleUpdateBody = {
          ...values,
          action: action,
        };

        return updateLocationFalsificationRuleSettings(data)
          .then(() => history.push(URLS.predefinedRules))
          .catch((err) => {
            setIsUpdating(false);
            handleError(err);
          });
      }}
    >
      <div className={s.head}>
        <h1 className={`${s.fontMainTitle} ${s.mb24} ${s.titleBackBtn}`}>
          Edit Rule
          <Button className={`${s.btnFlat} ${s.mb8}`} onClick={history.goBack}>
            <IconArrowBack width="2rem" />
          </Button>
        </h1>

        <div className={s.row}>
          <Form.Item label="Rule Name" name="name" style={{ width: "100%" }}>
            <Input className={s.input} disabled />
          </Form.Item>

          <Form.Item
            className={s.activeCheckbox}
            name="enabled"
            valuePropName="checked"
          >
            <Checkbox className={s.checkbox}>
              <div className={s.fontBody}>Enabled</div>
            </Checkbox>
          </Form.Item>
        </div>
      </div>

      <div className={s.section}>
        <h2 className={`${s.fontTitle} ${s.mb16}`}>Actions</h2>

        <div className={s.ruleActionFields}>
          <Form.Item
            name="action"
            className={s.checkboxGroup}
            required={false}
            rules={[{ required: true, message: "Rule Action is required" }]}
            normalize={(value) => {
              value.sort();
              return value;
            }}
          >
            <Checkbox.Group>
              <Checkbox
                className={s.checkbox}
                value="alert"
                onChange={handleIsNotificationChannelsDisabled}
                disabled={isEmptyNotificationChannels}
              >
                {RULE_ACTION_TAGS_MAP["alert"]}
              </Checkbox>
              <Checkbox
                onChange={handleSipRejectCodesShown}
                className={s.checkbox}
                value="block"
              >
                {RULE_ACTION_TAGS_MAP["block"]}
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>

          {/* Notification Channels */}
          {!isEmptyNotificationChannels && (
            <Form.Item
              label="Notification Channels"
              name="notification_channel_ids"
              dependencies={["action"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isAlertOn = Boolean(
                      getFieldValue("action")?.includes("alert")
                    );
                    const isEmptyChannels = !value || value.length === 0;

                    if (isAlertOn && isEmptyChannels) {
                      return Promise.reject(
                        new Error(
                          "At least one notification channel should be selected"
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                mode="multiple"
                tagRender={NotificationChannelTag}
                options={notificationChannels.data.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={isNotificationChannelsDisabled}
              />
            </Form.Item>
          )}

          {/* Sip Reject Codes */}
          {Boolean(
            settings?.show_sip_reject_codes && isSipRejectCodesShown
          ) && (
            <Form.Item
              label="Sip Reject Codes"
              name="sip_reject_codes"
              dependencies={["action"]}
              tooltip={{
                title: dictionary.tooltips.sipRejectCodes,
                icon: <IconHelp width="1.6rem" />,
              }}
            >
              <Select
                mode="multiple"
                tagRender={SipCodeTag}
                options={sipRejectCodes.data.map(({ code, label }) => ({
                  value: code,
                  label: label,
                }))}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}

          {/* TODO there is no such a case in design */}
          {isEmptyNotificationChannels && (
            <div className={s.fontBody}>
              No notification channel has been created yet
              <Link to={URLS.channels} style={{ width: "min-content" }}>
                <Button className={s.btnSecondary}>
                  <IconSettings width="2rem" />
                  Configure Channels
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className={s.buttons}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue, getFieldsError }) => {
            const isValid = getFieldsError().every(
              (field) => field.errors.length === 0
            );
            const currentValues = getFieldsValue();
            const isChanged = !isEqual(currentValues, initialValues);
            return (
              <Button
                className={s.btnPrimary}
                htmlType="submit"
                disabled={!isChanged || !isValid}
                loading={isUpdating}
              >
                Save
              </Button>
            );
          }}
        </Form.Item>
      </div>
    </Form>
  );
});

export default LocationFalsification;
