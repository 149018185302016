import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import { UIHelpers } from "@vas-common/ui-kit";
import { Switch } from "@vas-common/ui-kit";

import { RULE_ACTION_TAGS_MAP } from "ui/maps/ruleActionsMap";
import { useNotifications } from "ui/Notifications";
import { dateCustom } from "utils/dates";
import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";

import type { PrefixListRule, PrefixListRuleListType } from "store/mobx/types";

import s from "./index.module.scss";

const RULE_DIRECTIONS_MAP: Record<PrefixListRule["match_direction"], string> = {
  left_to_right: "\u2192",
  bidirectional: `\u2194`,
};

const LIST_TYPE_MAP: Record<PrefixListRuleListType, string> = {
  any: "Any",
  own_range: "Own range",
  prefix_list: "Prefix List",
};

export const PrefixListRulesTable = observer(function PrefixListRulesTable() {
  const history = useHistory();

  const { handleError } = useNotifications();

  const [isLoadingList, setIsLoadingList] = useState<Record<string, boolean>>(
    {}
  );

  const {
    firewallStore: { prefixListRules, getPrefixListRules, patchPrefixListRule },
  } = useStores();

  const { isLoading, data } = prefixListRules;

  useEffect(() => {
    getPrefixListRules();
  }, [getPrefixListRules]);

  const handleRowClick = (rule: PrefixListRule) => {
    history.push(`${URLS.prefixListRules}/${rule.id}`);
  };

  return !data.length && !isLoading ? (
    <div className={s.noData} data-test="prefixListRules-noData">
      <h1 className={s.fontTitle}>No Prefix List Rules found</h1>
      <p className={s.fontBody}>There are no prefix list rules yet.</p>
    </div>
  ) : (
    <table className={s.table} data-test="prefixListRules-table">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Scope</th>
          <th>
            Threshold, <br /> calls / period
          </th>
          <th>Actions</th>
          <th>Last Triggered</th>
        </tr>
      </thead>

      {/* Spinner */}
      {isLoading && (
        <tbody className={s.spin} data-test="prefixListRules-table-spin">
          <tr>
            <td>
              <Spin spinning />
            </td>
          </tr>
        </tbody>
      )}

      {/* Content */}
      <tbody className={s.content} data-test="prefixListRules-table-content">
        {data.map((rule) => {
          return (
            <tr
              key={rule.id}
              onClick={() => handleRowClick(rule)}
              data-test={`prefixListRules-item-${rule.id}`}
            >
              {/* Switch */}
              <td
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={s.switchCell}
              >
                <Switch
                  onChange={(value) => {
                    setIsLoadingList({
                      ...isLoadingList,
                      [rule.id]: true,
                    });

                    patchPrefixListRule(rule.id, {
                      enabled: value,
                    })
                      .catch(handleError)
                      .finally(() =>
                        setIsLoadingList((prevState) => ({
                          ...prevState,
                          [rule.id]: false,
                        }))
                      );
                  }}
                  loading={isLoadingList[rule.id]}
                  checked={rule.enabled}
                />
              </td>

              {/* Name */}
              <td>
                <div title={rule.name.length > 10 ? rule.name : ""}>
                  {rule.name}
                </div>
              </td>

              {/* Scope */}
              <td className={s.scopeCell}>
                <div>{`${LIST_TYPE_MAP[rule.list_type_left]} ${
                  rule.list_count_left ? `(${rule.list_count_left})` : ""
                }  ${RULE_DIRECTIONS_MAP[rule.match_direction]}  ${
                  LIST_TYPE_MAP[rule.list_type_right]
                } ${
                  rule.list_count_right ? `(${rule.list_count_right})` : ""
                }`}</div>
              </td>

              {/* Threshold */}
              <td className={!rule.threshold_enabled ? s.disabled : ""}>
                {!rule.threshold_enabled ? (
                  "None"
                ) : (
                  <>{`${rule.threshold_numeric} calls / ${rule.threshold_period}`}</>
                )}
              </td>

              {/* Actions */}
              <td>{rule.action ? RULE_ACTION_TAGS_MAP[rule.action] : null}</td>

              {/* Last Triggered */}
              <td
                className={`${s.lastTriggeredCell} ${
                  !rule.last_triggered_at ? s.disabled : ""
                }`}
              >
                <div
                  title={
                    rule.last_triggered_at
                      ? `${dateCustom(rule.last_triggered_at).format()}`
                      : ""
                  }
                >
                  {rule.last_triggered_at
                    ? dateCustom().isBefore(rule.last_triggered_at)
                      ? "just now"
                      : dateCustom(rule.last_triggered_at).fromNow()
                    : "Not triggered yet"}
                </div>

                {!rule.matched_today ? null : (
                  <div>{`${UIHelpers.formatNumber(
                    rule.matched_today
                  )} calls today`}</div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});
