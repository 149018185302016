import {
  NOTIFICATIONS_CHANNELS_SUCCESS, 
  NOTIFICATIONS_CHANNELS_ERROR, 
  NOTIFICATIONS_CHANNELS_ADD, 
  NOTIFICATIONS_CHANNELS_EDIT_SUCESS,
  NOTIFICATIONS_CHANNELS_EDIT_LOADING,
  NOTIFICATIONS_CHANNELS_EDIT_ERROR,
  NOTIFICATIONS_CHANNELS_DELETE,
} from "../actions";

export const notificationChanelsReducer = (state = [], action) => {
  switch (action.type) {
    case NOTIFICATIONS_CHANNELS_SUCCESS:
      return {...state, isFetching: false, items: [...action.items]};
    case NOTIFICATIONS_CHANNELS_ADD:
      return state;
    case NOTIFICATIONS_CHANNELS_EDIT_LOADING:
      return {...state, isFetching: true}
    case NOTIFICATIONS_CHANNELS_EDIT_SUCESS:
      return {...state, isFetching: false, isError: false};
    case NOTIFICATIONS_CHANNELS_EDIT_ERROR:
      return {...state, isFetching: false, isError: true}
    case NOTIFICATIONS_CHANNELS_DELETE:
      return state;
    case NOTIFICATIONS_CHANNELS_ERROR:
      return state;
    default:
      return state;
  }
};