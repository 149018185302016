import {STATS_FETCH_SUCCESS, STATS_FETCH_ERROR, STATS_FILTER_UPDATE} from "../actions";
import {millsecToDay, millsecToHour, calculateSeverity} from "../../utils/index";

export const statsReducer = (state = [], action) => {
  switch (action.type) {
    case STATS_FETCH_SUCCESS:

      let entries = aggregator.joinTimeWindowEntries(
        action.items,
        action.state.stats.filter.interval,
      );

      const {filter} = action.state.stats;
      const outboundStats = aggregator.aggregateByFraudType(
        entries?.filter(entry => entry.outbound));

      const inboundStats = aggregator.aggregateByFraudType(
        entries?.filter(entry => !entry.outbound));

      const allInboundTypes = action.state.fraudTypes
        ?.filter(x => x.inbound)
        .map(x => ({...x, ...inboundStats[x.value] || {entries: [], count: 0}}));

      const allOutboundTypes = action.state.fraudTypes
        ?.filter(x => x.outbound)
        ?.map(x => ({...x, ...outboundStats[x.value] || {entries: [], count: 0}}));

      const houtTick = millsecToHour(filter.interval) < 12 ? 12 : millsecToHour(filter.interval);

      return {
        ...state,
        inbound: allInboundTypes,
        outbound: allOutboundTypes,
        hourly: filter?.window === 'hour' ? aggregator.aggregateByTimeInterval(action.items?.slice(0, houtTick)) : [],
        daily: filter?.window === 'day' ? aggregator.aggregateByTimeInterval(action.items?.slice(0, millsecToDay(filter.interval))) : [],
      };
    case STATS_FILTER_UPDATE:
      return {...state, filter: {...state.filter, ...action.filter}};
    case STATS_FETCH_ERROR:
      return state;
    default:
      return state;
  }
};


const aggregator = {

  /**
   *
   * @param windows - array of hour/date time windows
   * @param interval - interval in milliseconds (1h, 4h, 12h, ...)
   * @return entries - array of all entries
   */
  joinTimeWindowEntries: function (windows, interval) {
    return windows
      ?.filter(window => {
        return Date.now() - window.window_end < interval
      })
      .reduce(
        (acc, x) => acc.concat(x.entries), []
      )
  },

  /**
   * aggregate statistics by hour/day intervals
   * @param windows - array of time windows
   * @return aggregated statistics
   */
  aggregateByTimeInterval: function (windows) {
    return windows?.map(window => {
      const item = {
        timestamp: window.window_start,
        outboundCalls: 0,
        outboundFraud: 0,
        inboundCalls: 0,
        inboundFraud: 0,
        inboundVerifiedCalls: 0,
        outboundVerifiedCalls: 0,
        outboundFraudAggregateByTypeFraud: [],
        inboundFraudAggregateByTypeFraud: []
      };

      window.entries.forEach(entry => {
        if (entry.outbound) {
          item.outboundCalls += entry.started_count;
          item.outboundVerifiedCalls += entry.verified_count;
          item.outboundFraud += entry.fraud_type > 0 ? entry.started_count : 0;
          if (entry.fraud_type > 0) {
            if (item.outboundFraudAggregateByTypeFraud.find(fr => fr.id_fraud_type === entry.fraud_type)) {
              item.outboundFraudAggregateByTypeFraud.find(fr => fr.id_fraud_type === entry.fraud_type).outboundFraud += entry.started_count;
            }
            else {
              item.outboundFraudAggregateByTypeFraud.push({
                id_fraud_type: entry.fraud_type,
                outboundFraud: entry.started_count
              })
            }
          }
        } else {
          item.inboundCalls += entry.started_count;
          item.inboundVerifiedCalls += entry.verified_count;
          item.inboundFraud += entry.fraud_type > 0 ? entry.started_count : 0;
          if (entry.fraud_type > 0) {
            if (item.inboundFraudAggregateByTypeFraud.find(fr => fr.id_fraud_type === entry.fraud_type)) {
              item.inboundFraudAggregateByTypeFraud.find(fr => fr.id_fraud_type === entry.fraud_type).inboundFraud += entry.started_count;
            }
            else {
              item.inboundFraudAggregateByTypeFraud.push({
                id_fraud_type: entry.fraud_type,
                inboundFraud: entry.started_count
              })
            }
          }
        }
      })
      return item;
    }).sort((w1, w2) => w1.timestamp > w2.timestamp ? 1 : -1)
  },


  /**
   * Filter entries by fraud type and summarize statistics
   * @return map like:
   * <fraud type id>: {
   *    "count": <sum started_count>,
   *    "duration": <sum duration>,
   *    "entries": <filtered entries>,
   * }]
   */
  aggregateByFraudType: function (entries) {
    const types = {};

    if (entries) {
      entries.forEach(entry => {
        let item = types[entry.fraud_type] || {
          count: 0, verifiedCount: 0, totalCount: 0, duration: 0, entries: []
        }
        item.count += entry.started_count;
        item.verifiedCount += entry.verified_count;
        item.duration += entry.duration;
        item.entries.push(entry);
        types[entry.fraud_type] = item;
      });

      Object.values(types).forEach(
        item => {
          item.percent = item.count / types[0].count * 100;
          item.gateways = this.aggregateGateways(item.entries, types[0].count);
          item.countries = this.aggregateCountries(item.entries, types[0].count);
          delete item.entries;
        }
      );

      Object.values(types).forEach(item => {
        item.gateways.forEach(gateway => {
          const g = types[0].gateways.find(g => g.id === gateway.id);
          if (g) {
            gateway.totalCount = g.count;
            gateway.percent = gateway.count / gateway.totalCount * 100;
            gateway.severity = calculateSeverity(gateway.percent, gateway.count);
          }
        });
        item.gateways.sort((g1, g2) => g1.percent > g2.percent ? -1 : 1)
      });

      // постранам
      Object.values(types).forEach(item => {
        item.countries.forEach(country => {
          const c = types[0].countries.find(c => c.country_iso === country.country_iso);
          if (c) {
            country.totalCount = c.count;
            country.percent = country.count / country.totalCount * 100;
            country.severity = calculateSeverity(country.percent, country.count);
          }
        });
        item.countries.sort((c1, c2) => c1.percent > c2.percent ? -1 : 1)
      });

      if (types[0]) {
        this.calculateTotalsByGateways(types[0], entries);
        this.calculateTotalsByCountries(types[0], entries);
      }
    }

    return types;
  },

  /**
   *
   * @param entries - source list
   * @returns array of gateway statistics
   */
  aggregateGateways: function (entries) {
    const gateways = {};

    entries.forEach(entry => {
      let gateway = gateways[entry.gateway_id] || {
        id: entry.gateway_id, networks: 0, count: 0, duration: 0, entries: []
      }
      gateway.count += entry.started_count;
      gateway.duration += entry.duration;
      gateway.entries.push(entry);
      gateways[entry.gateway_id] = gateway;
    });

    return Object.values(gateways).map(gateway => {
      gateway.networks = this.aggregateNetworks(gateway.entries);
      delete gateway.entries;
      return gateway;
    })
  },

  aggregateCountries: function (entries) {
    const countries = {};

    entries.forEach(entry => {
      let country = countries[entry.country_iso.toUpperCase()] || {
        country_iso: entry.country_iso.toUpperCase(), networks: 0, count: 0, duration: 0, entries: []
      }
      country.count += entry.started_count;
      country.duration += entry.duration;
      country.entries.push(entry);
      countries[entry.country_iso.toUpperCase()] = country;
    });

    return Object.values(countries).map(country => {
      country.networks = this.aggregateNetworks(country.entries);
      delete country.entries;
      return country;
    })
  },

  calculateTotalsByGateways: function (totals, entries) {
    let fraudByGateways = this.aggregateGateways(entries.filter(e => e.fraud_type > 0));
    totals.gateways.forEach(gateway => {
      gateway.isTotal = true;
      let g = fraudByGateways.find(g => gateway.id === g.id);
      gateway.totalCount = gateway.count;
      gateway.count = g ? g.count : 0;
      gateway.duration = g ? g.duration : 0;
      gateway.percent = gateway.count / gateway.totalCount * 100;
      gateway.severity = calculateSeverity(gateway.percent, gateway.count);
    });
    totals.gateways.sort((g1, g2) => g1.percent > g2.percent ? -1 : 1);
    totals.totalCount = totals.count;
    totals.count = fraudByGateways.map(g => g.count).reduce((a, b) => a + b, 0);
    totals.percent = totals.count / totals.totalCount * 100;
  },


  calculateTotalsByCountries: function (totals, entries) {
    let fraudByCountries = this.aggregateCountries(entries.filter(e => e.fraud_type > 0));
    totals.countries.forEach(country => {
      country.isTotal = true;
      let c = fraudByCountries.find(c => country.country_iso === c.country_iso);
      country.totalCount = country.count;
      country.count = c ? c.count : 0;
      country.duration = c ? c.duration : 0;
      country.percent = country.count / country.totalCount * 100;
      country.severity = calculateSeverity(country.percent, country.count);
    });
    totals.countries.sort((c1, c2) => c1.percent > c2.percent ? -1 : 1);
    totals.totalCountByCountry = totals.countByCountry;
    totals.countByCountry = fraudByCountries.map(c => c.count).reduce((a, b) => a + b, 0);
    totals.percentByCountry = totals.countByCountry / totals.totalCountByCountry * 100;
  },

  aggregateNetworks: function (entries) {
    const networks = {};

    entries.forEach(entry => {
      let network = networks[entry.partner_network_id] || {
        id: entry.partner_network_id, count: 0, duration: 0
      }
      network.count += entry.started_count;
      network.duration += entry.duration;
      networks[entry.partner_network_id] = network;
    });
    return Object.values(networks);
  },


};

