import { Button } from "antd";
import { showConfirm } from "ui/Modals/confirm";

import s from "./index.module.scss";

type DeleteButtonProps = {
  onDelete: () => Promise<unknown>;
  ruleName: string;
};

export const DeleteButton = ({ ruleName, onDelete }: DeleteButtonProps) => {
  const showDeleteConfirm = () =>
    showConfirm({
      title: "Delete Rule",
      content: (
        <>
          Are you sure you want to delete "
          <span className={s.fontBodyBold}>{ruleName}</span>"?
        </>
      ),
      okText: "Delete",
      okButtonProps: {
        className: s.btnDanger,
      },
      onOk: onDelete,
    });

  return (
    <Button
      className={s.btnSecondary}
      htmlType="button"
      onClick={showDeleteConfirm}
    >
      Delete
    </Button>
  );
};
