import React, {useEffect} from "react";
import {Button, Spin} from "antd";
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {URLS} from "constants/Urls";
import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import {useStores} from "store/mobx/useStore";

import s from "./ReportDetail.module.scss";
import ReportForm from "./ReportForm";
import {IReport} from "../../../../interfaces/IReports";


const ReportDetail = observer(() => {

  const { id } = useParams<{ id?: string }>();

  const {settingsStore: {
    getCurrentReportAsync,
    report,
    loadingReport,
    sendReportAsync,
    createReportAsync,
    deleteReportAsync,
    updateReportAsync,
    updatePredefinedReportAsync,
  },
    referenceStore: {
    notificationChannels,
    reference,
    }
  } = useStores();

  const isEdit =  Boolean(id);

  const {networks, countries, fraudTypes} = reference;
  const {isLoading, data} = notificationChannels;

  useEffect(() => {
    if(id) {
      getCurrentReportAsync(Number(id))
    }
  }, [getCurrentReportAsync, id]);


  const updateReport = (id: number | string, body: IReport) => {
    if(report?.predefined) {
      return updatePredefinedReportAsync(id, {enabled: body.enabled, notification_channel_ids: body.notification_channel_ids})
    } else {
      return updateReportAsync(id, body)
    }
  }

  return (
    <div className={s.wrapper}>
      <section className={s.grid}>
        <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
          {`${isEdit ? 'Edit' : 'New'} Regular Report`}
          <Link to={URLS.reports} className={s.mb8}>
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>
      </section>
      {((isEdit && loadingReport) || isLoading) ?
        <Spin
          className={s.spin}
          spinning
        />
        :
        <ReportForm
          isEdit={isEdit}
          channels={data}
          networks={networks}
          countries={countries}
          fraudTypes={fraudTypes}
          updateReport={updateReport}
          sendReport={sendReportAsync}
          deleteReport={deleteReportAsync}
          createReport={createReportAsync}
          report={isEdit ? report : undefined}
        />
      }
    </div>
  )
})

export default ReportDetail;
