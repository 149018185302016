import { NavLink } from "react-router-dom";
import { TFraudType } from "../../../store/mobx/types";

import s from "./index.module.scss";

type Props = {
  tabs: TFraudType[];
  pathname: string;
  setSizeDefault: () => void;
  setSelectedId: (id: number) => void;
};

export const Tabs = ({
  tabs,
  pathname,
  setSelectedId,
  setSizeDefault,
}: Props) => {
  const changeLink = (id: number) => {
    setSizeDefault();
    setSelectedId(id);
  };

  return (
    <div className={s.tabsContainer}>
      <div className={s.tabsWrapper}>
        {tabs.map((tab) => (
          <NavLink
            key={tab.name}
            to={`${pathname}/${tab.name.toLowerCase()}`}
            onClick={() => changeLink(tab.value)}
            className={s.tabLink}
            activeClassName={s.tabLinkActive}
          >
            {tab.full_name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
