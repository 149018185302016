import { Link } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "assets/svg/dashboard-2.svg";
import { ReactComponent as CallValidationIcon } from "assets/svg/call-validation-2.svg";
import { ReactComponent as FirewallIcon } from "assets/svg/firewall-2.svg";
import { ReactComponent as InfoIcon } from "assets/svg/info-2.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/settings-gear.svg";
import { ReactComponent as IconRejectedCalls } from "assets/svg/rejected-calls.svg";

import { URLS } from "constants/Urls";

import { ItemType } from "antd/es/menu/hooks/useItems";

export const topMenuItems: ItemType[] = [
  {
    label: <Link to={URLS.dashboard}>Dashboard</Link>,
    key: URLS.dashboard,
    icon: <DashboardIcon width="2rem" />,
  },
  {
    label: "Call Validation",
    key: URLS.callValidation,
    icon: <CallValidationIcon width="2rem" />,
    children: [
      {
        label: <Link to={URLS.inbound}>Inbound traffic</Link>,
        key: URLS.inbound,
      },
      {
        label: <Link to={URLS.outbound}>Outbound traffic </Link>,
        key: URLS.outbound,
      },
      {
        label: <Link to={URLS.fraudcalls}>Fraud call log</Link>,
        key: URLS.fraudcalls,
      },
      {
        label: <Link to={URLS.fraudRules}>Fraud Rules</Link>,
        key: URLS.fraudRules,
      },
    ],
  },
  {
    label: "Firewall",
    key: URLS.firewall,
    icon: <FirewallIcon width="2rem" />,
    children: [
      {
        label: <Link to={URLS.predefinedRules}>Predefined rules</Link>,
        key: URLS.predefinedRules,
      },
      {
        label: <Link to={URLS.unallocatedPrsRules}>Unallocated / PRS</Link>,
        key: URLS.unallocatedPrsRules,
      },
      {
        label: <Link to={URLS.prefixLists}>Prefix Lists</Link>,
        key: URLS.prefixLists,
      },
      {
        label: <Link to={URLS.prefixListRules}>Prefix List Rules</Link>,
        key: URLS.prefixListRules,
      },
    ],
  },
  {
    label: <Link to={URLS.rejectedCalls}>Rejected Calls</Link>,
    key: URLS.rejectedCalls,
    icon: <IconRejectedCalls width="2rem" />,
  },

  {
    label: "Information",
    key: URLS.infoPath,
    icon: <InfoIcon width="2rem" />,
    children: [
      {
        label: <Link to={URLS.participants}>Participants</Link>,
        key: URLS.participants,
      },
      {
        label: <Link to={URLS.fraudTypes}>Fraud types</Link>,
        key: URLS.fraudTypes,
      },
      {
        label: <Link to={URLS.status}>Status info</Link>,
        key: URLS.status,
      },
    ],
  },
  {
    label: "Settings",
    key: URLS.settingsPath,
    icon: <SettingsIcon width="2rem" />,
    children: [
      {
        label: <Link to={URLS.reports}>Regular reports</Link>,
        key: URLS.reports,
      },
      {
        label: <Link to={URLS.channels}>Notification channels</Link>,
        key: URLS.channels,
      },
    ],
  },
];
