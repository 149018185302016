import { ReactNode } from "react";
import ReactDOM from "react-dom";

type Props = {
  isShown: boolean;
  rootElement: Element | null;
  children: ReactNode;
};

export const Portal = (props: Props) => {
  const { isShown, rootElement, children } = props;

  return isShown && rootElement ? (
    ReactDOM.createPortal(children, rootElement)
  ) : (
    <>{children}</>
  );
};
