import React from "react";
import {TConfig, TStates, TStats} from "../store/mobx/types";

const styleCode = {
  color: 'rgb(183,22,19)',
  backgroundColor: 'rgba(255,255,255,0.9)',
  borderRadius: '2px',
  border: '1px solid rgba(38, 50, 56, 0.1)',
  padding: '0px 5px',
  fontSize: '13px',
  fontWeight: 400
}

export const getTextTooltip = (value: keyof TStats | keyof TStates | keyof TConfig) => {
  switch (value) {
    case 'provisioning_enabled':
      return 'If status provisioning is enabled - connector sends bind, unbind and ping messages to update its status';
    case 'last_bind_at':
      return <span>Timestamp of last received <code
        style={styleCode}>bind</code> request from connector (unix ms)</span>;
    case 'last_unbind_at':
      return <span>Timestamp of last received <code
        style={styleCode}>unbind</code> request from connector (unix ms)</span>;
    case 'last_ping_at':
      return <span>Timestamp of last received <code
        style={styleCode}>ping</code> request from connector (unix ms)</span>;
    case 'ping_interval':
      return <span>Ping interval</span>;
    case 'ping_timeout':
      return <span>Timeout after which connector will be considered dead if no <code style={styleCode}>ping</code> messages were received during this time interval</span>;
    case 'is_active_state':
      return <span>Defines whether the connector is considered <code style={styleCode}>active</code> at the moment</span>;
    case 'processed_status_count': return 'Full number of call events received from this connector after node start up';
    case 'processed_start_count': return <span>Number of <code style={styleCode}>start</code> call events received from this connector after node start up</span>;
    case 'processed_connect_count': return <span>Number of <code style={styleCode}>connect</code> call events received from this connector after node start up</span>;
    case 'processed_end_count': return <span>Number of <code style={styleCode}>end</code> call events received from this connector after node start up</span>;
    case 'unrecognized_status_count': return 'Number of call events with inrecognized value received from this connector after node start up';
    case 'last_message_at': return 'Timestamp of a last received message from this connector (unix ms)';
    case 'terminated_sessions_count': return 'Number of calls which were terminated via this connectorafter node start up';
    case 'realm': return 'Identifier of the connector, passed with each call event message';
    case 'status_provisioning_enabled': return 'If status provisioning is enabled - connector sends bind, unbind and ping messages to update its status';
    case 'supports_connect': return 'Connector supports processing of connect event';
    case 'supports_end': return 'Connector supports processing of end events';
    case 'supports_session_id': return 'Connector provides session ID value';
    case 'supports_origin_restarts': return 'Connector might send several start and end (if supported) events for the same outbound call, e.g. in case of re-routing';
    case 'supports_termination_restarts': return 'Connector might send several start and end (if supported) events for the same inbound call, e.g. in case of re-routing';
    case 'supports_duration': return 'Connector provides duration value alongside end event';
    case 'supports_termination_cause': return 'Connector provides call termination cause (ok or error) anolgside end event';
    case 'has_transit_traffic': return 'This connector might process transit traffic';
    case 'has_domestic_traffic': return 'This connector might process domestic traffic';
    case 'has_roaming_traffic': return 'This connector might process roaming traffic';
    case 'ack_enabled': return 'If acknowledgement is enabled, the connector awaits ack/nack responses for call start events';
    case 'termination_enabled': return 'Call termination for this connector is enabled';
    case 'terminate_after_connect': return 'Connector is able to terminate call only after the connect event occured';
    case 'termination_api_protocol': return 'Termination API protocol';
    case 'termination_api_host': return 'Termination API host';
    case 'termination_api_port': return 'Termination API port';
    default: return value.charAt(0).toUpperCase() + value.slice(1)
  }
}
