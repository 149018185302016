import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";

import {
  millsecToDay,
  millsecToHour,
  calculateSeverity,
} from "../../../../utils";
import { URLS } from "../../../../constants/Urls";
import { useStores } from "../../../../store/mobx/useStore";
import { date } from "utils/dates";
import { tickFormatter } from "../../../../utils/formatNumber";

import css from "../Dashboard.module.scss";

type Props = {
  data: any;
};

const Widget = observer((props: Props) => {
  let { status, deployment, intervalType, interval } = props.data;

  const {
    statisticsStore: { counters },
  } = useStores();

  const isUp =
    status?.cdb_heartbeat?.toLowerCase() === "ok" &&
    status?.grpc_transport?.toLowerCase() === "ok";

  const {
    loading,
    total,
    valid,
    inbound,
    percentInbound,
    outbound,
    percentOutbound,
  } = counters;

  const totalCalls = tickFormatter(total);
  const validCalls = tickFormatter(valid);
  const inboundCalls = tickFormatter(inbound);
  const outboundCalls = tickFormatter(outbound);

  return (
    <div className={css.widgetContainer}>
      <Link to={URLS.status} className={css.widget}>
        <div className={css.widgetInfo}>
          <div className={css.widgetTitle}>CR {deployment?.version}</div>
          <div>
            <div className={css.widgetDescription}>
              STATUS ({deployment && deployment.host})
            </div>
            {status && (
              <div className={css.widgetValue}>
                <div className={isUp ? css.darkgreenValue : css.orangeValue}>
                  {isUp ? "UP " : "WARN "}
                  <span>
                    {date.duration(Date.now() - status?.up_since).humanize()}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>

      <Link
        to={URLS.status}
        className={css.widget}
        data-test="connectionsWidget"
      >
        <div className={css.widgetInfo}>
          <div className={css.widgetTitle}>CR Connections</div>
          <div>
            <div className={css.widgetDescription}>TOTAL</div>
            {status && (
              <div className={css.widgetValue}>
                {status?.active_cr_connections}
              </div>
            )}
          </div>
        </div>
      </Link>

      <div className={css.widget}>
        <div className={css.widgetInfo}>
          <div className={css.widgetTitle}>Validated calls</div>
          <div>
            <div className={css.widgetDescription}>validated / total</div>
            <div className={css.widgetValue}>
              {loading ? (
                <Skeleton />
              ) : (
                <div className={css.validatedCalls}>
                  <div>{validCalls}</div>
                  <span>&nbsp;/ {`${totalCalls}`}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Link to={URLS.inbound} className={css.widget}>
        <div className={css.widgetInfo}>
          <div className={css.widgetTitle}>Inbound fraud calls</div>
          <div>
            <div className={css.widgetDescription}>
              {`${
                intervalType === "day"
                  ? `PAST ${millsecToDay(interval)}D`
                  : `PAST ${millsecToHour(interval)}H`
              }`}
            </div>
            <div className={css.widgetValue}>
              {loading ? (
                <Skeleton />
              ) : (
                <div
                  className={
                    css[`${calculateSeverity(percentInbound, inboundCalls)}`]
                  }
                >
                  {inboundCalls}
                  <span>
                    {" "}
                    {percentInbound?.toFixed(3) === "NaN"
                      ? 0
                      : percentInbound?.toFixed(3)}
                    %
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>

      <Link to={URLS.outbound} className={css.widget}>
        <div className={css.widgetInfo}>
          <div className={css.widgetTitle}>Outbound fraud calls</div>
          <div>
            <div className={css.widgetDescription}>
              {`${
                intervalType === "day"
                  ? `PAST ${millsecToDay(interval)}D`
                  : `PAST ${millsecToHour(interval)}H`
              }`}
            </div>
            <div className={css.widgetValue}>
              {loading ? (
                <Skeleton />
              ) : (
                <div
                  className={
                    css[`${calculateSeverity(percentOutbound, outboundCalls)}`]
                  }
                >
                  {outboundCalls}
                  <span>
                    {" "}
                    {percentOutbound?.toFixed(3) === "NaN"
                      ? 0
                      : percentOutbound?.toFixed(3)}
                    %
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
});

export default Widget;
