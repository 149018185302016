import { makeAutoObservable, runInAction } from "mobx";
import { PROJECT_ID_KEY } from "store/actions";

import type { RootStoreType } from "./rootStore";
import { getStatusInfo } from "utils/api";
import { StatusInfoState } from "./types";

export class StatusInfoStore {
  rootStore: RootStoreType;

  statusInfo?: StatusInfoState;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  setStatusInfo = () => {};

  getStatusInfo = async () => {
    try {
      const statusInfo = await getStatusInfo();

      if (
        !localStorage.getItem(PROJECT_ID_KEY) ||
        !statusInfo.node.networks.find(
          (item: any) =>
            item.id === Number(localStorage.getItem(PROJECT_ID_KEY))
        )
      ) {
        localStorage.setItem(
          PROJECT_ID_KEY,
          `${statusInfo.node.networks[0].id}`
        );
        window.location.reload();
      }

      runInAction(() => {
        this.statusInfo = {
          ...statusInfo,
          selectedNode:
            statusInfo.node?.networks.find(
              (net: any) =>
                net.id === Number(localStorage.getItem(PROJECT_ID_KEY))
            )?.name || statusInfo.node?.networks[0].name,
        };
      });
    } catch (err) {
      console.warn("[ERROR] getStatusInfo: ", err);
    }
  };
}
