type TID = { id?: string | number };
type TCL = { callLabel?: string };
type TCN = { connectorId?: string };

// prettier-ignore
export const URLS = {
  // Dashboard
  dashboard:                                         `/dashboard`,

  // Call-validation
  callValidation:                                    `/call-validation`,
  inbound:                                           `/call-validation/inbound`,
  inboundAll:                                        `/call-validation/inbound/all_traffic`,
  outbound:                                          `/call-validation/outbound`,
  outboundAll:                                       `/call-validation/outbound/all_traffic`,
  fraudcalls:                                        `/call-validation/fraudcalls`,
  fraudCallsDetail: ({ id = ":id" }: TID = {}) =>    `/call-validation/fraudcalls/${id}`,
  fraudRules:                                        `/call-validation/fraud-rules`,
  editFraudRule: ({ id = ":id" }: TID = {}) =>       `/call-validation/fraud-rules/${id}`,
  createFraudRule:                                   `/call-validation/fraud-rules/new`,


  // Rejected Calls
  rejectedCalls:                                                   `/rejected-calls`,
  rejectedCallDetails: ({ callLabel = ":callLabel" }: TCL = {}) => `/rejected-calls/${callLabel}`,

  // Firewall
  firewall:                                                          `/firewall`,
  predefinedRules:                                                   `/firewall/predefined-rules`,
  predefinedRulesLocationFalsification:                              `/firewall/predefined-rules/locationfalsification`,
  unallocatedPrsRules:                                               `/firewall/unallocated-prs-rules`,
  unallocatedPrsRulesCreate:                                         `/firewall/unallocated-prs-rules/new`,
  unallocatedPrsRulesCreateUpdate: ({ id = ":id" }: TID = {}) =>     `/firewall/unallocated-prs-rules/${id}`,
  prefixLists:                                                       `/firewall/prefix-lists`,
  prefixListsCreate:                                                 `/firewall/prefix-lists/new`,
  prefixListsCreateUpdate: ({ id = ":id" }: TID = {}) =>             `/firewall/prefix-lists/${id}`,
  prefixListView: ({ id = ":id" }: TID = {}) =>                      `/firewall/prefix-lists/${id}/view`,
  prefixListAddValues: ({ id = ":id" }: TID = {}) =>                 `/firewall/prefix-lists/${id}/add-values`,
  prefixListRules:                                                   `/firewall/prefix-list-rules`,
  prefixListRulesCreate:                                             `/firewall/prefix-list-rules/new`,
  prefixListRulesCreateUpdate: ({ id = ":id" }: TID = {}) =>         `/firewall/prefix-list-rules/${id}`,


  infoPath:                                   `/info`,
  participants:                               `/info/participants`,
  fraudTypes:                                 `/info/fraud-types`,
  status:                                     `/info/status`,
  connectorDetail: ({ connectorId = ":connectorId", }: TCN = {}) => `/info/connector/${connectorId}`,

  settingsPath:                                 `/settings`,
  reports:                                      `/settings/reports`,
  reportCreate:                                 `/settings/reports/new`,
  reportGenerate:                               `/settings/reports/generate/report`,
  reportUpdate: ({ id = ":id" }: TID = {}) =>   `/settings/reports/${id}`,
  channels:                                     `/settings/channels`,
  channelCreate:                                `/settings/channels/new`,
  channelUpdate: ({ id = ":id" }: TID = {}) =>  `/settings/channels/${id}`,
  blacklist:                                    `/settings/lists`,
  security:                                     `/settings/security`,

  monitoringPath:  `/monitoring`,
  users:           `/users`,
  login:           `/login`,
  callSearch:      `/debug/call_search`,
};
// prettier-ignore
