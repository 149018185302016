import { UIDates } from "@vas-common/ui-kit";

import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import type { DurationUnitType } from "dayjs/plugin/duration";

UIDates.dayjsUIKit.extend(relativeTime);
UIDates.dayjsUIKit.extend(duration);
UIDates.dayjsUIKit.extend(isSameOrBefore);

export const DD_MMM_TIME = UIDates.DD_MMM_TIME;
export const MMM_DD_TIME = UIDates.MMM_DD_TIME;
export const YYYY_MM_DD_TIME = UIDates.YYYY_MM_DD_TIME;

export const date = UIDates.dayjsUIKit.date;
export const dateUtc = UIDates.dayjsUIKit.dateUtc;
export const dateCustom = UIDates.dayjsUIKit.dateCustom;
export const isUtc = () => UIDates.dayjsUIKit.isUtc;

const UNITS: DurationUnitType[] = [
  "days",
  "hours",
  "minutes",
  "seconds",
  // "milliseconds",
];

const DURATION_UNITS_FORMAT_MAP: Record<string, string> = {
  days: "D[d] H[h] m[m] s[s]",
  hours: "H[h] m[m] s[s]",
  minutes: "m[m] s[s]",
  seconds: "s[s]",
  milliseconds: "SSS [ms]",
};

export const getDurationInLagrestUnits = (from: number, to?: number) => {
  // just a logical guard
  if (!to || from > to) return "N/A";

  const duration = date.duration(to - from);

  // find the largest units
  const largestUnit = UNITS.find((unit) => duration.as(unit) >= 1);

  return largestUnit
    ? `${duration.as(largestUnit).toPrecision(3)} ${largestUnit[0]}`
    : `${duration.as("milliseconds").toPrecision(3)} ms`;
};

export const getDurationString = (from: number, to?: number) => {
  // just a logical guard
  if (!to || from > to) return "N/A";

  const duration = date.duration(to - from);

  // find the largest units
  const largestUnit = UNITS.find((unit) => duration.as(unit) >= 1);

  const result = largestUnit
    ? duration.format(DURATION_UNITS_FORMAT_MAP[largestUnit])
    : duration.format(DURATION_UNITS_FORMAT_MAP.milliseconds);

  // remove all non-significant zeros
  const regex2 = /\b0+(\d+)/g;

  return result.replace(regex2, "$1");
};

export type DurationShortUnitType = Extract<
  DurationUnitType,
  "y" | "M" | "w" | "d" | "h" | "m" | "s" | "ms"
>;

export const UNITS_MAP: Record<DurationShortUnitType, string> = {
  y: "years",
  M: "months",
  w: "weeks",
  d: "days",
  h: "hours",
  m: "minutes",
  s: "seconds",
  ms: "milliseconds",
};
