import { getUsers, getRoles, deleteUser } from "../../utils/api";

export const LOGOUT = "LOGOUT";
export const SUCCESS = "SUCCESS";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const FAIL = "FAIL";

//users
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAILED = "USERS_FAILED";

//roles
export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_FAILED = "ROLES_FAILED";

export const FRAUD_TYPES_FETCH_SUCCESS = "FRAUD_TYPES_FETCH_SUCCESS";
export const FRAUD_TYPES_FETCH_ERROR = "FRAUD_TYPES_FETCH_ERROR";

export const GATEWAYS_FETCH_SUCCESS = "GATEWAYS_FETCH_SUCCESS";
export const GATEWAYS_FETCH_ERROR = "GATEWAYS_FETCH_ERROR";

export const NETWORKS_FETCH_SUCCESS = "NETWORKS_FETCH_SUCCESS";
export const NETWORKS_FETCH_ERROR = "NETWORKS_FETCH_ERROR";

export const STATS_FETCH_SUCCESS = "STATS_FETCH_SUCCESS";
export const STATS_FETCH_ERROR = "STATS_FETCH_ERROR";

export const FRAUD_RECORDS_FETCH_SUCCESS_LOADING =
  "FRAUD_RECORDS_FETCH_SUCCESS_LOADING";
export const FRAUD_RECORDS_FETCH_SUCCESS = "FRAUD_RECORDS_FETCH_SUCCESS";
export const FRAUD_RECORDS_FETCH_LOAD_MORE = "FRAUD_RECORDS_FETCH_LOAD_MORE";
export const FRAUD_RECORDS_FETCH_ERROR = "FRAUD_RECORDS_FETCH_ERROR";

export const NOTIFICATIONS_CHANNELS_SUCCESS = "NOTIFICATIONS_CHANNELS_SUCCESS";
export const NOTIFICATIONS_CHANNELS_ERROR = "NOTIFICATIONS_CHANNELS_ERROR";
export const NOTIFICATIONS_CHANNELS_ADD = "NOTIFICATIONS_CHANNELS_ADD";
export const NOTIFICATIONS_CHANNELS_EDIT_SUCESS = "NOTIFICATIONS_CHANNELS_EDIT";
export const NOTIFICATIONS_CHANNELS_EDIT_ERROR =
  "NOTIFICATIONS_CHANNELS_EDIT_ERROR";
export const NOTIFICATIONS_CHANNELS_EDIT_LOADING =
  "NOTIFICATIONS_CHANNELS_EDIT_LOADING";
export const NOTIFICATIONS_CHANNELS_DELETE = "NOTIFICATIONS_CHANNELS_DELETE";

export const STATS_FILTER_UPDATE = "STATS_FILTER_UPDATE";
export const SET_LOG_FILTERS = "SET_LOG_FILTERS";
export const SET_FRAUD_CALLS_FILTERS = "SET_FRAUD_CALLS_FILTERS";
export const ADD_NEW_CHANNEL = "ADD_NEW_CHANNEL";

export const REPORTS_FETCH_SUCCESS = "REPORTS_FETCH_SUCCESS";
export const REPORTS_SWITCH_ENABlED = "REPORTS_SWITCH_ENABlED";
export const REPORTS_FETCH_ERROR = "REPORTS_FETCH_ERROR";
export const REPORTS_FETCH_LOADING = "REPORTS_FETCH_LOADING";
export const REPORTS_CHANNELS_UPDATE = "REPORTS_CHANNELS_UPDATE";
export const REPORTS_CHANNELS_UPDATE_ERROR = "REPORTS_CHANNELS_UPDATE_ERROR";
export const REPORTS_CHANNELS_UPDATE_LOADING =
  "REPORTS_CHANNELS_UPDATE_LOADING";

export const SEND_NOW_REPORT_SUCCESS = "SEND_NOW_REPORT_SUCCESS";
export const SEND_NOW_REPORT_ERROR = "SEND_NOW_REPORT_ERROR";
export const SEND_NOW_REPORT_LOADING = "SEND_NOW_REPORT_LOADING";

export const RULES_LIST_FETCH_SUCCESS = "RULES_LIST_FETCH_SUCCESS";
export const RULES_LIST_FETCH_ERROR = "RULES_LIST_FETCH_ERROR";
export const RULES_LIST_FETCH_LOADING = "RULES_LIST_FETCH_LOADING";
export const RULES_CREATE_NEW_SUCESS = "RULES_CREATE_NEW_SUCESS";
export const RULES_UPDATE_SUCESS = "RULES_UPDATE_SUCESS";
export const RULES_UPDATE_LOADING = "RULES_UPDATE_LOADING";
export const RULES_UPDATE_ERROR = "RULES_UPDATE_ERROR";

export const PREFIX_LIST_SUCCESS = "PREFIX_LIST_SUCCESS";
export const PREFIX_LIST_LOADING = "PREFIX_LIST_LOADING";
export const PREFIX_LIST_ERROR = "PREFIX_LIST_ERROR";

export const SET_DASHBOARD_Y_SCROLL = "SET_DASHBOARD_Y_SCROLL";

export const PROJECT_ID_KEY = "abh.networkId";
export const PROTECT_TOKEN = "abh.CR_Console";

export const fetchErrored = (type) => {
  return {
    type,
    hasErrored: true,
  };
};

export const fetchSuccess = (type, items) => {
  return {
    type,
    items,
  };
};

const fetchItems = (path, success, error, loading, method, body) => {
  return (dispatch) => {
    loading && dispatch({ type: loading });

    return fetch(path, {
      headers: {
        "X-Network-ID": localStorage.getItem(PROJECT_ID_KEY),
        Authorization: `Bearer ${localStorage.getItem(PROTECT_TOKEN)}`,
      },
      method: method || "GET",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((items) => {
        dispatch(fetchSuccess(success, items));
        return items;
      })
      .catch((e) => {
        console.error(path, e);
        dispatch(fetchErrored(error));
      });
  };
};

export const updateFilter = (filter) => {
  return {
    type: STATS_FILTER_UPDATE,
    filter,
  };
};

export const setLogFilter = (filters) => {
  return {
    type: SET_LOG_FILTERS,
    filters,
  };
};

export const setFraudCallsFilter = (filters) => {
  return {
    type: SET_FRAUD_CALLS_FILTERS,
    filters,
  };
};

export const setScrollY = (y) => {
  return {
    type: SET_DASHBOARD_Y_SCROLL,
    y,
  };
};

export const fetchStats = (filter) => {
  return (dispatch, getState) => {
    fetch(`/api/v1/stats/${filter?.window === "day" ? "daily" : "hourly"}`, {
      headers: {
        "X-Network-ID": localStorage.getItem(PROJECT_ID_KEY),
        Authorization: `Bearer ${localStorage.getItem(PROTECT_TOKEN)}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((items) =>
        dispatch({
          type: STATS_FETCH_SUCCESS,
          state: getState(),
          items: items,
        })
      )
      .catch(() => {
        dispatch(fetchErrored(STATS_FETCH_ERROR));
      });
  };
};

const getSerachFilters = (filters) =>
  Object.keys(filters).reduce(
    (acc, cur) =>
      acc +
      cur +
      `${cur === "rule_ids" || cur === "fraud_types" ? "[]" : ""}` +
      "=" +
      filters[cur] +
      "&",
    ""
  );

export const fetchLastRecords = (filters) => {
  const serachFilters = getSerachFilters(filters);
  return fetchItems(
    `/api/v1/fraud_records/search?${serachFilters}`,
    FRAUD_RECORDS_FETCH_SUCCESS,
    FRAUD_RECORDS_FETCH_ERROR,
    FRAUD_RECORDS_FETCH_SUCCESS_LOADING
  );
};

export const fetchLoadMoreLastRecords = (filters) => {
  const serachFilters = getSerachFilters(filters);
  return fetchItems(
    `/api/v1/fraud_records/search?${serachFilters}`,
    FRAUD_RECORDS_FETCH_LOAD_MORE,
    FRAUD_RECORDS_FETCH_ERROR,
    FRAUD_RECORDS_FETCH_SUCCESS_LOADING
  );
};

export const fetchFraudTypes = () => {
  return fetchItems(
    "/api/v1/fraud_types",
    FRAUD_TYPES_FETCH_SUCCESS,
    FRAUD_TYPES_FETCH_ERROR
  );
};

export const fetchGateways = () => {
  return fetchItems(
    "/api/v1/gateways",
    GATEWAYS_FETCH_SUCCESS,
    GATEWAYS_FETCH_ERROR
  );
};

export const fetchNetworks = () => {
  return fetchItems(
    "/api/v1/networks",
    NETWORKS_FETCH_SUCCESS,
    NETWORKS_FETCH_ERROR
  );
};

export const fetchReports = () => {
  return fetchItems(
    "/api/v1/reports/tasks",
    REPORTS_FETCH_SUCCESS,
    REPORTS_FETCH_ERROR,
    REPORTS_FETCH_LOADING
  );
};

export const fetchPrefixList = () => {
  return fetchItems(
    "/api/v1/prefix_lists",
    PREFIX_LIST_SUCCESS,
    PREFIX_LIST_ERROR,
    PREFIX_LIST_LOADING
  );
};

export const getUsersAsync = () => {
  return (dispatch) => {
    getUsers()
      .then((res) => {
        dispatch({ type: USERS_SUCCESS, payload: res });
      })
      .catch(() => {
        dispatch({ type: USERS_FAILED });
      });
  };
};

export const deleteUserAsync = (id) => {
  deleteUser(id).then(() => {
    getUsersAsync();
  });
};

export const getRolesAsync = () => {
  return (dispatch) => {
    getRoles()
      .then((res) => {
        dispatch({ type: ROLES_SUCCESS, payload: res });
      })
      .catch(() => {
        dispatch({ type: ROLES_FAILED });
      });
  };
};
