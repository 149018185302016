import { CallControlRuleType } from "./reference";

export enum EGroupBy {
  country = "country",
  network = "network",
  gateway = "gateway",
}

export type TGroupBy = "country" | "network" | "gateway";

export enum EFilterParam {
  group_by_country = "group_by_country",
  group_by_gateway = "group_by_gateway_id",
  group_by_network = "group_by_network_id",
}

export type TTraffic = {
  loading: boolean;
  list: TTrafficList[];
  counter: number;
  percent: number;
};

export type TTrafficList = {
  severity: string;
  percent: number;
  dynamicData: string | number | undefined;
  country: string | undefined;
  network_id: number | undefined;
  gateway_id: number | undefined;
  fraudCalls: number;
  totalCalls: number;
  validCalls: number;
  fraudDuration: number;
};

export type TTrafficResponse = {
  alerted: number;
  blocked: number;
  fraudulent: number;
  fraudulent_connected: number;
  fraudulent_duration: number;
  rejected: number;
  total: number;
  total_connected: number;
  total_duration: number;
  validated_default: number;
  validated_dry_run: number;
  validation_errors: number;
  network_id?: number;
  country?: string;
  gateway_id?: number;
};

export type CallDirection = "inbound" | "outbound" | "transit";
export type TerminationResult = "success" | "failure";

export type TerminatedCallsParams = {
  page: number;
  page_size: number;
  rule_type: CallControlRuleType | null;
  rule_id: number | null;
  from: number | null;
  to: number | null;
  direction: CallDirection | null;
  termination_result: TerminationResult | null;
  a_number: string | null;
  b_number: string | null;
};

export type TerminatedCall = {
  call_label: string;
  rule_type: CallControlRuleType;
  rule_id: number;
  terminated_at: number;
  day_index: number;
  hour_index: number;
  started_at: number;
  ended_at: number;
  duration: number;
  session_id: string;
  direction: CallDirection;
  a_number: string;
  a_network_id: number;
  b_number: string;
  b_network_id: number;
  is_terminated: boolean;
  // TODO what else?
  reject_code_type: "sip";
  reject_code: number;
};

export type TerminatedCalls = {
  isLoading: boolean;
  hasNextPage: boolean;
  data: TerminatedCall[];
  params: TerminatedCallsParams;
};
