import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Button, Spin } from "antd";
import { URLS } from "../../../../constants/Urls";
import GenerateReportForm from "./GenerateReportForm";
import { useStores } from "../../../../store/mobx/useStore";
import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";

import s from "../detail/ReportDetail.module.scss";

const GenerateReport = observer(() => {
  const {
    referenceStore: { notificationChannels, reference },
    settingsStore: { generateReportAsync },
  } = useStores();

  const { networks, countries, fraudTypes } = reference;
  const { isLoading, data } = notificationChannels;

  return (
    <div className={s.wrapper}>
      <section className={s.grid}>
        <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
          Generate Report
          <Link to={URLS.reports} className={s.mb8}>
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>
      </section>

      {isLoading ? (
        <Spin className={s.spin} spinning />
      ) : (
        <GenerateReportForm
          channels={data}
          networks={networks}
          countries={countries}
          fraudTypes={fraudTypes}
          generateReport={generateReportAsync}
        />
      )}
    </div>
  );
});

export default GenerateReport;
