import { CSSProperties } from "react";

import s from "./Users.module.scss";

type Props = {
  style?: CSSProperties;
};

export const NoPerms = ({ style }: Props) => {
  return (
    <div className={s.noPerms} style={style}>
      <i className="material-icons-outlined">locked</i>You don't have permission
      to this data. <br />
      Please contact the server Administrator to get an access
    </div>
  );
};
