import React, {ChangeEvent, useState} from 'react'
import {Button, Input, Spin} from "antd";

import api from "../../../utils/api";
import Table from "./Table";

import s from "./CallSearch.module.scss";

type TFields = {
  aNumber: string,
  bNumber: string
}

const CallSearch = () => {

  const [fields, setFields] = useState<TFields>({
    aNumber: '',
    bNumber: ''
  })

  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const changeFields = (name: "aNumber" | "bNumber", e: ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [name]: e.target.value
    })
  }

  const {aNumber, bNumber} = fields;

  const search = () => {
    setLoading(true);
    const a = aNumber ? `a_number=${aNumber}` : '';
    const b = bNumber ? `b_number=${bNumber}` : '';
    const separator = a !== '' && b !== '' ? '&' : '';
    api({
      method: "GET",
      url: `/api/v1/calls/search?${a}${separator}${b}`
    }).then((res) => {
      setItems(res)
    }).catch(() => {
      setItems([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.header}>
          <div className={s.title}>Call Search</div>
        </div>
        <div className={s.filtersWrapper}>
          <div>
            <Input placeholder='A-number' value={aNumber} onChange={(e) => changeFields('aNumber', e)}/>
          </div>
          <div>
            <Input placeholder="B-number" value={bNumber} onChange={(e) => changeFields('bNumber', e)}/>
          </div>
          <Button
            type="primary"
            disabled={aNumber === '' && bNumber === ''}
            onClick={search}
          >
            Search
          </Button>
        </div>
        <div>
          {isLoading &&
            <div className={s.wrapperLoader}><Spin className={s.spin}/></div>}
          {(!isLoading && items.length > 0) && (
            <Table items={items}/>
          )}
        </div>
      </div>
    </div>
  )
}

export default CallSearch;
