import { Modal, ModalFuncProps } from "antd";
import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";

import s from "./index.module.scss";
import type { ReactNode } from "react";

const { confirm } = Modal;

type Props = {
  title: ReactNode;
  content: ReactNode;
  onOk: () => void;
  onCancel?: () => void;
  okText: string;
  cancelText?: string;
  okButtonProps?: ModalFuncProps["okButtonProps"];
  cancelButtonProps?: ModalFuncProps["okButtonProps"];
};

export const showConfirm = ({
  content,
  onOk,
  onCancel,
  title,
  okText,
  cancelText = "Cancel",
  okButtonProps,
  cancelButtonProps,
}: Props) =>
  confirm({
    width: 288,
    title: title,
    centered: true,
    icon: null,
    className: s.confirm,
    closable: true,
    maskClosable: true,
    closeIcon: <IconCross width="2rem" />,
    content: content,
    okButtonProps: okButtonProps || {
      className: s.btnPrimary,
    },
    okText,
    cancelText,
    cancelButtonProps: cancelButtonProps || {
      className: s.btnSecondary,
    },
    onOk() {
      return onOk();
    },
    onCancel() {
      return onCancel?.();
    },
  });
