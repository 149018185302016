import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";

import {useStores} from "../../../../store/mobx/useStore";
import {TypeEnum} from "../../../../store/mobx/types";
import {tickFormatter} from "../../../../utils/formatNumber";
import variables from '../../../../variables.module.scss';

import s from './fraudWidget.module.scss';

type TFraudTypes = {
  description: string | undefined,
  full_name: string,
  is_used: boolean,
  name: string,
  show_for_inbound: boolean,
  show_for_outbound: boolean,
  value: number
}

type TFraudState = {
  id: number,
  name: string,
  percent: number,
  count: number,
  countInbound: number,
  percentInbound: number,
  countOutbound: number,
  percentOutbound: number,
}

type Props = {
  fraudTypes: TFraudTypes[]
}

const FraudWidget: FC<Props> = observer(({fraudTypes}) => {

  const {statisticsStore: {setType, fraudScheduleCurrent}} = useStores();
  const {loading, data} = fraudScheduleCurrent;

  const [id, setId] = useState<number>(0);
  const [dataFrauds, setDataFrauds] = useState<TFraudState[]>([]);

  useEffect(() => {
    if (!loading) {
      const items = fraudTypes.filter((el) => el.name !== 'robo' && el.name !== 'wangiri').map((el) => {
        const findElem = data.find((item) => item.id === el.value);
        return {
          id: el.value,
          name: el.full_name,
          percent: findElem?.percent || 0,
          count: findElem?.count || 0,
          countInbound: findElem?.validatedInbound || 0,
          percentInbound: findElem?.percentInbound || 0,
          countOutbound: findElem?.validatedOutbound || 0,
          percentOutbound: findElem?.percentOutbound || 0,
        }
      })
      const sortFraudTypes = items.filter((el) => el.id).sort((x, y) => x.id - y.id)
      setDataFrauds(sortFraudTypes)
    }
  }, [data, fraudTypes, loading]);

  const onClick = (idx: number) => {
    if (idx === id) {
      setType(TypeEnum.all, null);
      setId(0);
    } else {
      if (idx === 0) {
        setType(TypeEnum.all, null);
        setId(0);
      } else {
        setType(TypeEnum.current, idx);
        setId(idx);
      }
    }
  }

  const getStyle = (percent: number) => {
    if (percent > 0.1) return s.percentCriticalBlocks;
    if (percent > 0.000) return s.percentHighBlocks;
    return s.percentCleanBlocks;
  };

  return (
    <div className={s.fraudWidgetContainer}>
      {dataFrauds.map((el, _i) => (
        <div
          key={_i}
          onClick={() => el.percent ? onClick(el.id) : undefined}
          className={el.percent > 0 ? s.fraudWidgetBlock : s.fraudWidgetBlockClear}
          style={{
            backgroundColor: `${id === el.id ? "transparent" : "transparent"}`,
            border: `${id === el.id ? `3px solid ${variables.inboundColor}` : "1px solid #E2E2E2"}`
          }}
        >
          <div className={s.blockContent}>
            <div className={el.percent > 0 ? s.fraudName : s.fraudNameClear}>
              {el.name}
            </div>
            <div className={el.countInbound ? s.fraudType : s.fraudTypeClear}>
              <div className={el.percentInbound > 0 ? s.fraudTypeInbound : s.fraudTypeIndClear}/>
              <span>Inbound</span>
            </div>
            <div className={el.countOutbound ? s.fraudType : s.fraudTypeClear}>
              <div className={el.percentOutbound > 0 ? s.fraudTypeOutbound : s.fraudTypeIndClear}/>
              <span>Outbound</span>
            </div>
            <div className={getStyle(el.percent)}>
              <div className={s.circle}/>
              <div className={s.percentColor}>
                {tickFormatter(el.count)}
                &nbsp;/&nbsp;
                {`${el.percent.toFixed(3)} %`}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

export default FraudWidget;

