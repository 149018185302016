import { ReactNode, useEffect } from "react";
import { message as antMessage } from "antd";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";

import { useStores } from "store/mobx/useStore";
import { ReactComponent as IconInfo } from "assets/svg/info-2.svg";
import { ReactComponent as IconError } from "assets/svg/error-circle.svg";

import s from "./index.module.scss";
import { NoticeType } from "antd/es/message/interface";

const ICONS_MAP: Record<NoticeType, ReactNode> = {
  info: <IconInfo width="2rem" />,
  error: <IconError width="2rem" />,
  // TODO make all icon types
  loading: <></>,
  success: <></>,
  warning: <></>,
};

// TODO check Tawbar toast messages implementation
/**
 * This hook should be used only once on the project (see the App component).
 * To show massages use Mobx MessageStore
 */
export const useMessages = () => {
  const history = useHistory();

  const [messageApi, contextHolder] = antMessage.useMessage();

  const {
    messagesStore: { message },
  } = useStores();

  useEffect(() => {
    if (message) {
      const key = v4();

      messageApi.open({
        key,
        type: "info",
        icon: ICONS_MAP[message.type || "info"],
        className: s.message,
        duration: 0,
        onClick: () => messageApi.destroy(key),
        ...message,
      });
    }
  }, [message, messageApi]);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      messageApi.destroy();
    });

    return () => {
      unlisten();
    };
  }, [history, messageApi]);

  return {
    // showMessage,
    // messageApi,
    contextHolder,
  };
};
