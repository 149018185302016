import { ReactComponent as IconSlack } from "assets/svg/slack-icon.svg";
import { ReactComponent as IconEmail } from "assets/svg/email-icon.svg";
import { ReactComponent as IconHTTP } from "assets/svg/webhook.svg";

import type { ReactNode } from "react";
import type { NotificationTypes } from "store/mobx/types";

export const NOTIFICATION_TYPE_ICONS_MAP: Record<NotificationTypes, ReactNode> =
  {
    email: <IconEmail width="2rem" />,
    slack: <IconSlack width="2rem" />,
    http: <IconHTTP width="2rem" />,
  };
