import { useLayoutEffect } from "react";
import { Divider } from "antd";
import { observer } from "mobx-react";

import { date } from "utils/dates";
import { useStores } from "../../../store/mobx/useStore";

import css from "./StatusInfo.module.scss";

const StatusInfo = observer(() => {
  const {
    referenceStore: {
      getConnectorsAsync,
      // availableConnectors
    },
    statusInfoStore: { statusInfo },
  } = useStores();

  const { deployment, status, selectedNode } = statusInfo || {};
  // const { loading, connectors } = availableConnectors;

  useLayoutEffect(() => {
    getConnectorsAsync();
  }, [getConnectorsAsync]);

  return (
    <div className={css.container}>
      <div className={css.mainInfoContainer}>
        <div className={css.titleContainer}>
          <span className={css.title}>Status info</span>
          <span className={css.description}>
            Here are displayed overall configuration parameters and other
            deployment information.
          </span>
        </div>

        <Divider />

        <div className={css.infoBlock}>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>Current Participant</div>
            <div>{selectedNode}</div>
          </div>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>CR Client Version</div>
            <div data-test="Settings-CR-Client-Version">
              {deployment?.version}
            </div>
          </div>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>CR UI Version</div>
            <div>{process.env.REACT_APP_VERSION}</div>
          </div>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>IP Address</div>
            <div data-test="Settings-IP-Address">{deployment?.host}</div>
          </div>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>GRPC Port</div>
            <div data-test="Settings-GRP-Port">{deployment?.grpc_port}</div>
          </div>

          <div className={css.rowInfo}>
            <div className={css.subTitle}>Console Port</div>
            <div data-test="Settings-Console-Port">
              {deployment?.console_port}
            </div>
          </div>
        </div>

        <Divider />

        <div className={css.infoBlock}>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>GRPC Transport</div>
            <div
              className={
                status?.grpc_transport?.toLowerCase() === "ok"
                  ? css.greenStatus
                  : css.orangeStatus
              }
              data-test="Settings-GRPC-Transport"
            >
              {status?.grpc_transport?.toUpperCase()}
            </div>
          </div>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>CDB Heartbeat</div>
            <div
              className={
                status?.cdb_heartbeat?.toLowerCase() === "ok"
                  ? css.greenStatus
                  : css.orangeStatus
              }
              data-test="Settings-CDB-Heartbeat"
            >
              {status?.cdb_heartbeat?.toUpperCase()}
            </div>
          </div>
          <div className={css.rowInfo}>
            <div className={css.subTitle}>Uptime</div>
            <div>
              {status?.up_since
                ? date.duration(Date.now() - status?.up_since).humanize()
                : ""}
            </div>
          </div>

          {/* <Divider /> */}

          {/* {!loading && (
            <>
              <div className={css.rowInfo}>
                <div className={css.subTitle}>
                  CR Connections ({connectors.length})
                </div>
              </div>
              {connectors.map((el, idx) => (
                <div className={css.rowInfo} key={idx}>
                  <div className={css.subTitle}>
                    {`${el.configuration.label} connector`}
                  </div>
                  <div
                    className={
                      el.states.is_active_state
                        ? css.greenStatus
                        : css.grayStatus
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {el.states.is_active_state ? "Active" : "Not active"}
                  </div>
                </div>
              ))}
            </>
          )} */}
        </div>
      </div>
    </div>
  );
});

export default StatusInfo;
