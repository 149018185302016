import React, {FC, useCallback} from "react";
import {observer} from "mobx-react";
import {Spin} from 'antd';

import {useStores} from "../../../store/mobx/useStore";
import {Filter} from "../filter/Filter";
import {Table} from "../table/Table";

import s from './OutboundTab.module.scss'

type Props = {
  value: number,
  callsGroupBy: string,
}

export const OutboundTab: FC<Props> = observer(({value, callsGroupBy}) => {

  const {
    trafficStore: {
      outbound,
      setSize,
      size
    },
    referenceStore: {
      reference
    },
    statisticsStore: {
      filters
    }
  } = useStores()

  const {list, loading, percent, counter} = outbound;

  const {from, to} = filters;

  const {loading: loadingReference, networks, gateways, fraudTypes} = reference;

  const getLoad = useCallback((): boolean | undefined => {
    if (!loading) {
      return loadingReference
    }
    if (!loadingReference) {
      return loading
    }
  }, [loading, loadingReference])

  const isLoaded = getLoad() === undefined ? true : getLoad();

  const loadMore = () => {
    setSize()
  }

  const data = list.slice(0, size);

  const hasMore = data.length < list.length;

  return (
    <div className={s.container}>
      <Filter
        count={counter}
        percent={percent}
        dataType={callsGroupBy}
      />
      {isLoaded ?
        <div className={s.wrapperLoader}>
          <Spin/>
        </div>
        :
        <>
          {data.length > 0 ?
            <Table
              data={data}
              idFraud={value}
              hasMore={hasMore}
              fraudType="outbound"
              from={from}
              to={to}
              loadMore={loadMore}
              networks={networks}
              gateways={gateways}
              fraudTypes={fraudTypes}
              callsGroupBy={callsGroupBy}
            />
            :
            <div><span className={s.noData}>No items found</span></div>
          }
        </>
      }
    </div>
  );
})

