export const formatNumberCalls = (record: number) => {
  return record > 1000 ? `${(record / 1000).toFixed(record > 100000 ? 1 : 2)}K` : record.toLocaleString();
}

export const numberDivision = (dividend: number, divider: number, digits: number): string => {
  const value = dividend / divider;
  return String(parseFloat(value.toString()).toFixed(digits));
}

export const tickFormatter = (count: number) => {
  if(count < 10000) {
    return String(count.toLocaleString())
  }
  if(count >= 10000 && count <= 100000) {
    const value = numberDivision(count, 1000, 1)
    return value + "K";
  }
  if(count > 100000) {
    const value = numberDivision(count, 1000, 0)
    return value + "K";
  }
  return count;
}
