import api from "utils/api";
import type {
  LocationFalsificationRuleSettings,
  PredefinedRuleUpdateBody,
} from "store/mobx/types";

export const getLocationFalsificationRuleSettings = () =>
  api({
    method: "GET",
    url: `/api/v1/firewall/location_falsification`,
  }) as Promise<LocationFalsificationRuleSettings>;

export const patchLocationFalsificationRuleSettings = (
  data: PredefinedRuleUpdateBody
) =>
  api({
    method: "PATCH",
    url: `/api/v1/firewall/location_falsification`,
    data,
  }) as Promise<LocationFalsificationRuleSettings>;

export const updateLocationFalsificationRuleSettings = (
  data: PredefinedRuleUpdateBody
) =>
  api({
    method: "PUT",
    url: `/api/v1/firewall/location_falsification`,
    data,
  }) as Promise<LocationFalsificationRuleSettings>;
