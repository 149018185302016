import _qs, { IStringifyOptions } from "qs";
import { IParseOptions } from "qs";

export const qs = {
  stringify: (obj: any, options?: IStringifyOptions) => {
    return _qs.stringify(obj, {
      allowDots: true,
      arrayFormat: "brackets",
      encodeValuesOnly: true,
      skipNulls: true,
      ...options,
    });
  },
  parse: (
    str: string,
    options?: IParseOptions & { decoder?: never | undefined }
  ) => {
    return _qs.parse(str, options);
  },
};
