import { useEffect, useState, useMemo } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Spin } from "antd";
import { FilterForm, UIHelpers } from "@vas-common/ui-kit";

import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";
import { useNotifications } from "ui/Notifications";

import { PrefixListViewTable } from "./PrefixListViewTable";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import { ReactComponent as IconPencil } from "assets/svg/edit-pencil.svg";
import { ReactComponent as IconPlus } from "assets/svg/plus.svg";

import type { TFields } from "@vas-common/ui-kit/dist/esm/types/components/FilterForm/types";

import s from "./index.module.scss";

const PrefixListView = observer(() => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    firewallStore: { getPrefixListById, prefixListsMap },
  } = useStores();

  const currentListData = prefixListsMap[Number(id)];
  const [isLoading, setIsLoading] = useState(!currentListData);

  const { error, handleError } = useNotifications(() =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  );

  useEffect(() => {
    let isCanceled = false;

    if (id && !currentListData) {
      getPrefixListById(id)
        .catch((err) => handleError(err, true))
        .finally(() => {
          !isCanceled && setIsLoading(false);
        });
    } else {
      !isCanceled && setIsLoading(false);
    }

    return () => {
      isCanceled = true;
    };
  }, [id, getPrefixListById, currentListData, handleError]);

  const FILTER_FIELDS: TFields[] = useMemo(
    () => [
      {
        type: "text",
        label: "Prefix",
        name: "prefix",
        activeDefault: true,
        showLabels: true,
      },
    ],
    []
  );

  const onFilterChange = (values: unknown, queryString: string) => {
    history.push({
      search: queryString,
    });
  };

  return isLoading ? (
    <Spin className={s.spin} spinning />
  ) : error?.status === 404 ? (
    <div className={s.notFoundContainer}>
      <h1 className={`${s.fontTitle} ${s.titleBackBtn}`}>
        Not found
        <Link to={URLS.prefixLists} className={s.mb8}>
          <Button className={s.btnFlat}>
            <IconArrowBack width="2rem" />
          </Button>
        </Link>
      </h1>
    </div>
  ) : (
    <div className={s.container}>
      <section>
        <div className={s.head}>
          <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
            {currentListData?.name || "List Name"}

            <Link to={URLS.prefixLists}>
              <Button className={s.btnFlat}>
                <IconArrowBack width="2rem" />
              </Button>
            </Link>
          </h1>

          <Link to={`${URLS.prefixLists}/${id}`}>
            <Button className={s.btnSecondary}>
              <IconPencil width="2rem" />
              Edit
            </Button>
          </Link>
        </div>
      </section>

      <section className={`${s.grid} ${s.gap24}`}>
        {/* Description */}
        <div className={s.row}>
          <div className={`${s.grid} ${s.gap4}`}>
            <div className={s.itemHead}>Description</div>
            <div className={s.fontBody}>{currentListData?.description}</div>
          </div>
        </div>

        {/* ValueType Expiration QTY */}
        <div className={s.row}>
          <div className={`${s.grid} ${s.gap4}`}>
            <div className={s.itemHead}>Value type</div>
            <div className={`${s.fontBody} ${s.capitalize}`}>
              {currentListData?.values_type}
            </div>
          </div>

          <div className={`${s.grid} ${s.gap4}`}>
            <div className={s.itemHead}>Expiration</div>
            <div className={s.fontBody}>
              {currentListData?.expirable ? "Expirable" : "Not Expirable"}
            </div>
          </div>

          <div className={`${s.grid} ${s.gap4}`}>
            <div className={s.itemHead}>Quantity</div>
            <div className={s.fontBody}>
              {currentListData?.values_count
                ? UIHelpers.formatNumber(currentListData.values_count)
                : "-"}
            </div>
          </div>
        </div>
      </section>

      <section className={`${s.grid} ${s.gap16}`}>
        <div className={s.subHead}>
          <h2 className={s.fontTitle}>Values</h2>

          <Link to={`${URLS.prefixListAddValues({ id })}`}>
            <Button className={s.btnPrimary}>
              <IconPlus width="2rem" />
              Add new
            </Button>
          </Link>
        </div>

        <div className={s.filterContainer}>
          <FilterForm
            name={`Prefix List ${id} values`}
            onChange={onFilterChange}
            fields={FILTER_FIELDS}
          />
        </div>

        <PrefixListViewTable />
      </section>
    </div>
  );
});

export default PrefixListView;
