import { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Input,
  Button,
  InputNumber,
  Form,
  Checkbox,
  Tooltip,
  Spin,
} from "antd";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { Select } from "@vas-common/ui-kit";

import { RULE_ACTION_TAGS_MAP } from "ui/maps";
import {
  NotificationChannelTag,
  FraudTypesTag,
  CountryTag,
  NetworkTag,
  ResetButton,
  NetworkLabel,
  CountryLabel,
  NotificationChannelLabel,
  DeleteButton,
} from "ui/FormControls";
import { URLS } from "constants/Urls";
import { useStores } from "store/mobx/useStore";
import {
  ruleActionStringToArray,
  arrayToRuleActionString,
} from "utils/ruleActions";
import dictionary from "assets/dictionary.json";
import { useNotifications } from "ui/Notifications";
import { useOnLeavePage } from "utils/useOnLeavePage";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";

import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Direction } from "interfaces/IRules";

import s from "./index.module.scss";

type FormValues = {
  action: ("alert" | "block")[];
  alert_on_threshold_once: boolean;
  countries: string[];
  direction: Direction;
  enabled: boolean;
  fraud_types: number[] | null;
  name: string;
  network_ids: number[] | null;
  notification_channel_ids: number[];
  threshold_enabled: boolean;
  threshold_numeric: number | null;
  threshold_period: string | null;
};

export const FraudRulesForm = observer(() => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const isEditRuleMode = id !== "new";

  const [form] = Form.useForm<FormValues>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    referenceStore: { reference, notificationChannels },
    fraudRulesStore: {
      fraudRules,
      isLoading: isLoadingFraudRules,
      updateFraudRule,
      createFraudRule,
      deleteFraudRule,
    },
  } = useStores();

  const { fraudTypes, countries, networks } = reference;

  const { handleError, resetNotification, setNotification, scrollTo } =
    useNotifications(() =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    );

  const currentFraudRule = fraudRules.find((item) => item.id === Number(id));

  // this state can be set while redirecting from Participants page
  const { idCountry: predefinedCountry, idNetwork: predefinedNetwork } =
    (history.location.state || {}) as {
      idNetwork: number;
      idCountry: string;
    };

  const [isLoading, setIsloading] = useState(
    isEditRuleMode && !Boolean(currentFraudRule)
  );

  const [initialValues, setInitialValues] = useState<FormValues>({
    action: ruleActionStringToArray(currentFraudRule?.action),
    alert_on_threshold_once: currentFraudRule?.alert_on_threshold_once || false,
    countries: currentFraudRule?.countries || [
      ...(predefinedCountry ? [predefinedCountry] : []),
    ],
    direction: currentFraudRule?.direction || "all",
    enabled: currentFraudRule?.enabled ?? true,
    fraud_types: currentFraudRule?.fraud_types || [],
    name: currentFraudRule?.name || "",
    network_ids: currentFraudRule?.network_ids || [
      ...(predefinedNetwork ? [predefinedNetwork] : []),
    ],
    notification_channel_ids: currentFraudRule?.notification_channel_ids || [],
    threshold_enabled: currentFraudRule?.threshold_enabled || false,
    threshold_numeric: currentFraudRule?.threshold_numeric || null,
    threshold_period: currentFraudRule?.threshold_period || null,
  });

  const [isThresholdDisabled, setIsThresholdDisabled] = useState(
    !currentFraudRule?.threshold_enabled
  );

  const [isNotificationChannelsDisabled, setIsNotificationChannelsDisabled] =
    useState(!currentFraudRule || currentFraudRule?.action === "block");

  useEffect(() => {
    if (!isLoadingFraudRules) {
      if (currentFraudRule) {
        setInitialValues({
          action: ruleActionStringToArray(currentFraudRule.action),
          alert_on_threshold_once: currentFraudRule.alert_on_threshold_once,
          countries: currentFraudRule.countries || [],
          direction: currentFraudRule.direction || "all",
          enabled: currentFraudRule.enabled,
          fraud_types: currentFraudRule.fraud_types,
          name: currentFraudRule.name,
          network_ids: currentFraudRule.network_ids,
          notification_channel_ids: currentFraudRule.notification_channel_ids,
          threshold_enabled: currentFraudRule.threshold_enabled,
          threshold_numeric: currentFraudRule.threshold_numeric || null,
          threshold_period: currentFraudRule.threshold_period || null,
        });

        setIsThresholdDisabled(!currentFraudRule.threshold_enabled);
        setIsNotificationChannelsDisabled(currentFraudRule.action === "block");
      }
      setIsloading(false);
    }
  }, [isLoadingFraudRules, currentFraudRule]);

  useOnLeavePage({
    onLeave: () => {
      scrollTo.bottom();
      form.submit();
    },
    shouldDisplayMessage: () => {
      const currentValues = form.getFieldsValue();
      const isChanged = !isEqual(currentValues, initialValues);
      return isChanged && !isSubmitting && !isEmpty(currentValues);
    },
  });

  const handleThresholdEnabled = ({ target }: CheckboxChangeEvent) => {
    setIsThresholdDisabled(!target.checked);
  };

  const handleIsNotificationChannelsDisabled = (e: CheckboxChangeEvent) => {
    setIsNotificationChannelsDisabled(!e.target.checked);
  };

  const handleDeleteRule = async () => {
    return deleteFraudRule(Number(id))
      .then(() => history.push(URLS.fraudRules))
      .catch(handleError);
  };

  const isEmptyNotificationChannels = notificationChannels.data.length === 0;

  if (isLoading) {
    return <Spin className={s.spin} spinning />;
  }

  return isEditRuleMode && !currentFraudRule ? (
    <div className={s.container}>
      <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
        Not found
        <Link to={URLS.fraudRules} className={s.mb8}>
          <Button className={s.btnFlat}>
            <IconArrowBack width="2rem" />
          </Button>
        </Link>
      </h1>
    </div>
  ) : (
    <Form
      form={form}
      initialValues={initialValues}
      className={`${s.form} ${s.container}`}
      layout="vertical"
      onFinish={(values) => {
        setIsSubmitting(true);
        resetNotification();

        const action = arrayToRuleActionString(values.action);

        const data = {
          ...values,
          action: action,
        };

        return (
          isEditRuleMode
            ? updateFraudRule(Number(id), data)
            : createFraudRule(data)
        )
          .then(() => {
            history.push(URLS.fraudRules);
          })
          .then(() => {
            scrollTo.top();
            setNotification(
              {
                type: "success",
                data: {
                  message: isEditRuleMode
                    ? "Successfully Updated"
                    : "Successfully Created",
                },
              },
              1500
            );
          })
          .catch((err) => {
            setIsSubmitting(false);
            handleError(err);
          });
      }}
    >
      <h1 className={`${s.fontMainTitle} ${s.mb32} ${s.titleBackBtn}`}>
        {isEditRuleMode ? "Edit Fraud Rule" : "New Fraud Rule"}

        <Link to={URLS.fraudRules}>
          <Button className={s.btnFlat}>
            <IconArrowBack width="2rem" />
          </Button>
        </Link>
      </h1>

      <div className={`${s.grid} ${s.gap50}`}>
        {/* General */}
        <section className={`${s.grid} ${s.gap24}`}>
          <div>
            <h2 className={`${s.fontTitle} ${s.mb4}`}>General</h2>

            <p className={s.fontBody}>
              {dictionary.descriptions.rules.fraudRules.general}
            </p>
          </div>

          {/* Name Active */}
          <div className={s.row}>
            <Form.Item
              label="Rule Name"
              name="name"
              required={false}
              rules={[{ required: true, message: "Rule Name is required" }]}
            >
              <Input className={s.input} placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              className={s.activeCheckbox}
              name="enabled"
              valuePropName="checked"
            >
              <Checkbox className={s.checkbox}>
                <div className={s.fontBody}>Enabled</div>
              </Checkbox>
            </Form.Item>
          </div>
        </section>

        {/* Scope */}
        <section className={`${s.grid} ${s.gap24}`}>
          <div>
            <h2 className={`${s.fontTitle} ${s.mb4}`}>Scope</h2>

            <p className={s.fontBody}>
              {/* {dictionary.descriptions.rules.fraudRules.scope} */}
            </p>
          </div>

          <Form.Item
            label="Direction"
            name="direction"
            required={false}
            rules={[
              {
                required: true,
                message: "Direction is required",
              },
            ]}
          >
            <Select
              options={[
                { value: "all", label: "All directions" },
                { value: "inbound", label: "Inbound" },
                { value: "outbound", label: "Outbound" },
              ]}
              onChange={() => {
                form.setFieldValue("fraud_types", []);
              }}
            />
          </Form.Item>

          <Form.Item noStyle dependencies={["direction"]}>
            {({ getFieldValue }) => {
              const currentDirection = getFieldValue(
                "direction"
              ) as FormValues["direction"];

              const options = fraudTypes
                .filter((item) => {
                  if (!item.is_used) return false;

                  if (currentDirection === "all") return true;

                  return currentDirection === "inbound"
                    ? item.show_for_inbound
                    : item.show_for_outbound;
                })
                .map(({ value, full_name }) => ({
                  value,
                  label: full_name,
                }));

              return (
                <Form.Item
                  label="Rule Types"
                  name="fraud_types"
                  required={false}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="All rule types"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    mode="multiple"
                    options={options}
                    tagRender={FraudTypesTag}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item label="Countries" name="countries">
            <Select
              allowClear
              showSearch
              mode="multiple"
              placeholder="All countries"
              onChange={(value: string[]) => {
                const currentNetworkIds = form.getFieldValue(
                  "network_ids"
                ) as number[];

                // populate networks
                const currentNetworks = currentNetworkIds.map((id) =>
                  networks.find((network) => network.id === id)
                );

                const filteredNetworkIds = currentNetworks
                  .filter((network) =>
                    value.some((val) => val === network?.country_iso)
                  )
                  .map((item) => item?.id);

                form.setFieldValue("network_ids", filteredNetworkIds);
              }}
              options={countries.map((country) => ({
                value: country.code,
                label: <CountryLabel country={country} />,
              }))}
              filterOption={(input, option: any) =>
                (option?.label?.props?.country?.value.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              tagRender={CountryTag}
            />
          </Form.Item>

          <Form.Item noStyle dependencies={["countries"]}>
            {({ getFieldValue }) => {
              const currentCountries = getFieldValue("countries") as string[];
              const isEmptyCountries = !currentCountries.length;

              const filteredNetworks = isEmptyCountries
                ? networks
                : networks.filter((network) => {
                    return currentCountries.some(
                      (country) => network.country_iso === country
                    );
                  });

              return (
                <Form.Item label="Destination networks" name="network_ids">
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    placeholder="All networks"
                    filterOption={(input, option: any) =>
                      (option?.label?.props?.network?.name.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={filteredNetworks.map((network) => ({
                      value: network.id,
                      label: <NetworkLabel network={network} />,
                    }))}
                    tagRender={NetworkTag}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </section>

        {/* Threshold */}
        <section className={`${s.grid} ${s.gap24}`}>
          <div>
            <h2 className={`${s.fontTitle} ${s.mb4}`}>Threshold</h2>

            <p className={s.fontBody}>
              {/* {dictionary.descriptions.rules.fraudRules.threshold} */}
            </p>
          </div>

          <div>
            <div className={`${s.rowCheckbox} ${s.mb16}`}>
              <Form.Item name="threshold_enabled" valuePropName="checked">
                <Checkbox
                  className={s.checkbox}
                  onChange={handleThresholdEnabled}
                >
                  <div className={s.fontBody}>Enable threshold</div>
                </Checkbox>
              </Form.Item>
            </div>

            <div className={s.rowCheckbox}>
              <Form.Item
                label="Calls"
                name="threshold_numeric"
                style={{ width: "6.4rem" }}
              >
                <InputNumber
                  className={s.input}
                  disabled={isThresholdDisabled}
                />
              </Form.Item>

              <Form.Item
                label="Periodicity"
                name="threshold_period"
                style={{ width: "15.2rem" }}
              >
                <Select
                  allowClear
                  disabled={isThresholdDisabled}
                  options={[
                    { value: "hour", label: "Hour" },
                    { value: "day", label: "Day" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                className={s.alertOnceWrapper}
                name="alert_on_threshold_once"
                valuePropName="checked"
              >
                <Checkbox className={s.checkbox} disabled={isThresholdDisabled}>
                  <div className={`${s.fontBody} ${s.alertOnce}`}>
                    Alert once
                    <Tooltip
                      placement="right"
                      title={dictionary.tooltips.alertOnce}
                    >
                      <IconHelp width="2rem" />
                    </Tooltip>
                  </div>
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </section>

        {/* Actions */}
        <section className={`${s.grid} ${s.gap24}`}>
          <div>
            <h2 className={`${s.fontTitle} ${s.mb4}`}>Actions</h2>

            <p className={s.fontBody}>
              {/* {dictionary.descriptions.rules.fraudRules.actions} */}
            </p>
          </div>

          <div className={`${s.grid} ${s.gap16}`}>
            <Form.Item
              name="action"
              className={s.checkboxGroup}
              required={false}
              rules={[{ required: true, message: "Rule Action is required" }]}
              normalize={(value) => {
                value.sort();
                return value;
              }}
            >
              <Checkbox.Group>
                <Checkbox
                  className={s.checkbox}
                  value="alert"
                  onChange={handleIsNotificationChannelsDisabled}
                  disabled={isEmptyNotificationChannels}
                >
                  {RULE_ACTION_TAGS_MAP["alert"]}
                </Checkbox>
                <Checkbox className={s.checkbox} value="block">
                  {RULE_ACTION_TAGS_MAP["block"]}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label="Notification Channels"
              name="notification_channel_ids"
              dependencies={["action"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isAlertOn = Boolean(
                      getFieldValue("action")?.includes("alert")
                    );
                    const isEmptyChannels = !value || value.length === 0;

                    if (isAlertOn && isEmptyChannels) {
                      return Promise.reject(
                        new Error(
                          "At least one notification channel should be selected"
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                mode="multiple"
                tagRender={NotificationChannelTag}
                options={notificationChannels.data.map((channel) => ({
                  value: channel.id,
                  label: <NotificationChannelLabel channel={channel} />,
                }))}
                filterOption={(input, option: any) =>
                  (option?.label?.props?.channel?.name.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={isNotificationChannelsDisabled}
              />
            </Form.Item>
          </div>
        </section>

        {/* Buttons */}
        <section className={s.section}>
          <div className={s.buttonsRow}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldsError, getFieldsValue, resetFields }) => {
                const isValid = getFieldsError().every(
                  (field) => field.errors.length === 0
                );
                const currentValues = getFieldsValue();
                const isChanged = !isEqual(currentValues, initialValues);
                return (
                  <>
                    <Button
                      className={s.btnPrimary}
                      htmlType="submit"
                      disabled={!isChanged || !isValid}
                      loading={isSubmitting}
                    >
                      Save
                    </Button>

                    {isEditRuleMode ? null : (
                      <ResetButton onOk={resetFields} disabled={!isChanged} />
                    )}
                  </>
                );
              }}
            </Form.Item>

            {isEditRuleMode && initialValues.name && (
              <DeleteButton
                ruleName={initialValues.name}
                onDelete={handleDeleteRule}
              />
            )}
          </div>
        </section>
      </div>
    </Form>
  );
});
