import {NETWORKS_FETCH_SUCCESS, NETWORKS_FETCH_ERROR} from "../actions";

export const countriesReducer = (state = [], action) => {
  switch (action.type) {
    case NETWORKS_FETCH_SUCCESS:
      return groupByCountry(action.items);
    case NETWORKS_FETCH_ERROR:
      return state;
    default:
      return state;
  }
};

export const groupByCountry = (items) => {
  items.sort((a, b) => a.country_iso.localeCompare(b.country_iso))
  const result = [];

  items.forEach((item, index) => {
    if (item.country_iso.trim() === "") return;
    let inbound_international_calls_count_total = 0;
    let outbound_international_calls_count_total = 0;
    let inbound_international_calls_count_participants = 0;
    let outbound_international_calls_count_participants = 0;
    let inbound_international_calls_count_in_progress = 0;
    let outbound_international_calls_count_in_progress = 0;
    let participants_count = 0;
    let progress_count = 0;
    let net_count = 0;
    if (!result.length || items[index].country_iso !== items[index - 1].country_iso) {
      inbound_international_calls_count_total = items.filter((f) => f.country_iso === item.country_iso).reduce((acc, cur) => acc + cur.inbound_international_calls_count, 0);
      outbound_international_calls_count_total = items.filter((f) => f.country_iso === item.country_iso).reduce((acc, cur) => acc + cur.outbound_international_calls_count, 0);

      participants_count = items.filter((f) => (f.participation_type === 'Full' || f.participation_type === 'Partial') && f.country_iso === item.country_iso).length;
      progress_count = items.filter((f) => f.participation_type === 'In progress' && f.country_iso === item.country_iso).length;

      inbound_international_calls_count_participants = items.filter((f) => (f.participation_type === 'Full' || f.participation_type === 'Partial') && f.country_iso === item.country_iso).reduce((acc, cur) => acc + cur.inbound_international_calls_count, 0);
      outbound_international_calls_count_participants = items.filter((f) => (f.participation_type === 'Full' || f.participation_type === 'Partial') && f.country_iso === item.country_iso).reduce((acc, cur) => acc + cur.outbound_international_calls_count, 0);

      inbound_international_calls_count_in_progress = items.filter((f) => f.participation_type === 'In progress' && f.country_iso === item.country_iso).reduce((acc, cur) => acc + cur.inbound_international_calls_count, 0);
      outbound_international_calls_count_in_progress = items.filter((f) => f.participation_type === 'In progress' && f.country_iso === item.country_iso).reduce((acc, cur) => acc + cur.outbound_international_calls_count, 0);
      net_count = items.filter((f) => f.country_iso === item.country_iso
        && f.type !== 'Unallocated' && f.type !== 'Premium' && f.type !== 'Country').length;
    }


    let newCountry = {
      country_iso: item.country_iso,
      inbound_international_calls_count_total,
      outbound_international_calls_count_total,
      inbound_international_calls_count_participants,
      outbound_international_calls_count_participants,
      inbound_international_calls_count_in_progress,
      outbound_international_calls_count_in_progress,
      participants_count,
      progress_count,
      filter_type: "all",
      net_count,
      networks: [
        {
          id: item.id,
          country_iso: item.country_iso,
          name: item.name,
          type: item.type,
          participation_type: item.participation_type,
          inbound_international_calls_count: item.inbound_international_calls_count,
          outbound_international_calls_count: item.outbound_international_calls_count,
          prefix_count: item.prefix_count
        }]
    };

    if (!result.length) {
      result.push(newCountry)
    } else {
      if (items[index].country_iso === items[index - 1].country_iso) {
        let newNetobj = {
          id: item.id,
          country_iso: item.country_iso,
          name: item.name,
          type: item.type,
          participation_type: item.participation_type,
          inbound_international_calls_count: item.inbound_international_calls_count,
          outbound_international_calls_count: item.outbound_international_calls_count,
          prefix_count: item.prefix_count
        }
        result[result.length - 1].networks.push(newNetobj);
      } else {
        result.push(newCountry)
      }
    }
  });

  return result;
}
