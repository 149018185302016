import { useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";

import { Tabs } from "../tabs/tabs";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { InboundTab } from "./InboundTab";
import { useStores } from "../../../store/mobx/useStore";

const DEFAULT_TAB = {
  value: 0,
  name: "All_traffic",
  full_name: "All traffic",
  show_for_inbound: true,
  show_for_outbound: true,
  description: "",
  is_used: true,
};

export const Inbound = observer(() => {
  const {
    trafficStore: {
      getInboundCallsAsync,
      callsGroupBy,
      selectedId,
      setSelectedId,
      setSizeDefault,
    },
    statisticsStore: { filters },
    referenceStore: { reference },
  } = useStores();

  const { path } = useRouteMatch();

  const { fraudTypes } = reference;

  useLayoutEffect(() => {
    getInboundCallsAsync(filters, selectedId);
  }, [filters, selectedId, callsGroupBy, getInboundCallsAsync]);

  useEffect(() => {
    return () => {
      setSizeDefault();
    };
  }, [setSizeDefault]);

  const tabs = Array.isArray(fraudTypes)
    ? [DEFAULT_TAB, ...fraudTypes.filter((el) => el.show_for_inbound)]
    : [];

  return (
    <div>
      {tabs && (
        <>
          <Tabs
            tabs={tabs}
            pathname={path}
            setSelectedId={setSelectedId}
            setSizeDefault={setSizeDefault}
          />
          <Switch>
            {tabs.map((fraudType) => {
              return (
                <Route
                  key={fraudType.value}
                  path={`${path}/${fraudType.name.toLowerCase()}`}
                  children={() => (
                    <InboundTab {...fraudType} callsGroupBy={callsGroupBy} />
                  )}
                />
              );
            })}
            <Redirect
              from={`${path}`}
              to={`${path}/${
                tabs[0] ? tabs[0].name.toLowerCase() : "all_traffic"
              }`}
            />
          </Switch>
        </>
      )}
    </div>
  );
});
