export const getErrorMessage = (err: any) => {
  const errorType = Object.prototype.toString.call(
    err?.response?.data?.errors
  ) as `[object ${string}]`;

  const isObject = errorType === "[object Object]";
  const isArray = errorType === "[object Array]";

  let errorMessage = isObject
    ? JSON.stringify(err?.response?.data?.errors, null, 2)
    : isArray
    ? err?.response?.data?.errors.join(", ")
    : null;

  return (
    errorMessage ||
    JSON.stringify(
      err?.response?.data?.error ||
        err?.response?.data ||
        err?.message ||
        "Something went wrong"
    )
  );
};
