import { useMemo, useRef, useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { FilterForm } from "@vas-common/ui-kit";

import { URLS } from "constants/Urls";
import { useIntersectionObserver } from "utils/useIntersectionObserver";

import { PrefixListsTable } from "./PrefixListsTable";
import { Portal } from "ui/Portal";

import { ReactComponent as IconPlus } from "assets/svg/plus.svg";

import dictionary from "assets/dictionary.json";

import s from "./index.module.scss";

import type { TFields } from "@vas-common/ui-kit/dist/esm/types/components/FilterForm/types";

const PrefixLists = () => {
  const history = useHistory();

  const FILTER_FIELDS: TFields[] = useMemo(
    () => [
      {
        type: "text",
        label: "Name",
        name: "name",
        activeDefault: true,
        showLabels: true,
        "data-test": "prefixLists-input-name",
      },
      {
        type: "select",
        label: "Value Type",
        name: "values_type",
        activeDefault: true,
        showLabels: true,
        items: ["prefixes", "numbers"].map((item) => ({
          label: item,
          value: item,
        })),
        "data-test": "prefixLists-input-type",
      },
      {
        type: "select",
        label: "Expirable",
        name: "expirable",
        activeDefault: true,
        showLabels: true,
        items: [
          {
            label: "Expirable",
            value: "true",
          },
          {
            label: "Non-expirable",
            value: "false",
          },
        ],
        "data-test": "prefixLists-input-exp",
      },
    ],
    []
  );

  const headerRef = useRef<HTMLDivElement | null>(null);

  const [isShown, setIsShown] = useState(false);

  const [ref] = useIntersectionObserver(
    (elements, _observer) => {
      elements.forEach((element) => {
        if (!element.isIntersecting) {
          setIsShown(true);
          headerRef.current?.classList.add(s.tStick);
        } else {
          setIsShown(false);
          headerRef.current?.classList.remove(s.tStick);
        }
      });
    },
    {
      threshold: [1],
    }
  );

  const onFilterChange = (values: unknown, queryString: string) => {
    history.push({
      search: queryString,
    });
  };

  return (
    <>
      {/* Container for sticky header */}
      <div ref={headerRef} className={s.stickyHeader} />

      <div className={s.container}>
        <div className={s.headContainer}>
          <div className={s.head}>
            <h1 className={s.fontMainTitle}>Prefix Lists</h1>

            <Link to={URLS.prefixListsCreate} data-test="prefixLists-add-btn">
              <Button className={s.btnPrimary}>
                <IconPlus width="2rem" />
                Add New
              </Button>
            </Link>
          </div>

          <p className={s.fontBody}>
            {dictionary.descriptions.rules.prefixList.description}
          </p>
        </div>

        <div className={s.section}>
          <div
            ref={ref}
            className={s.filterContainer}
            data-test="prefixLists-filters"
          >
            <Portal rootElement={headerRef.current} isShown={isShown}>
              <FilterForm
                name="prefixLists"
                onChange={onFilterChange}
                fields={FILTER_FIELDS}
              />
            </Portal>
          </div>

          <PrefixListsTable />
        </div>
      </div>
    </>
  );
};

export default PrefixLists;
