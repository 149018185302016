import api from "utils/api";
import type {
  FlashcallProtectionRuleSettings,
  PredefinedRuleUpdateBody,
} from "store/mobx/types";

export const getFlashcallProtectionRuleSettings = () =>
  api({
    method: "GET",
    url: `/api/v1/firewall/flashcall`,
  }) as Promise<FlashcallProtectionRuleSettings>;

export const patchFlashcallProtectionRuleSettings = (
  data: PredefinedRuleUpdateBody
) =>
  api({
    method: "PATCH",
    url: `/api/v1/firewall/flashcall`,
    data,
  }) as Promise<FlashcallProtectionRuleSettings>;

export const updateFlashcallProtectionRuleSettings = (
  data: PredefinedRuleUpdateBody
) =>
  api({
    method: "PUT",
    url: `/api/v1/firewall/flashcall`,
    data,
  }) as Promise<FlashcallProtectionRuleSettings>;
