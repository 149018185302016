import { CSSProperties, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { connect, useDispatch } from "react-redux";
import { Spin } from "antd";

import {
  fetchGateways,
  fetchNetworks,
  fetchReports,
  SUCCESS,
  updateFilter,
} from "../store/actions";

import { URLS } from "constants/Urls";
import { hasPerm, Perms } from "constants/Perms";
import ProtectedRoute from "components/protectedRoute";

import { useIntercomWatcher } from "utils/intercomWatcher";
import { intervalParams, windowStepParams } from "../utils/savedParams";
import { useStores } from "../store/mobx/useStore";
import { Navigation } from "./navigation/Navigation";
import { Notification } from "ui/Notifications";
import { useMessages } from "ui/Messages";
import NotificationList from "./settings/NotificationChannels/NotificationsList";
import ConnectorDetail from "components/info/connectorDetail";
import CallSearch from "components/debug/callSearch";
import Reports from "./settings/regularReports/Reports";
import { FraudRules } from "components/call-validation/fraud-rules/FraudRules";
import { Inbound, Outbound } from "components/monitoring";
import { FraudTypes } from "components/info";
import { FraudCallsTable } from "components/monitoring/dashboard/fraudCallsTable/FraudCallsTable";
import { FraudRulesForm } from "components/call-validation/fraud-rules/FraudRulesForm/FraudRulesForm";
import { DetailFraudCard } from "components/monitoring/dashboard/detailFraudCard/DetailFraudCard";
import Participants from "components/info/participants/Participants";
import Dashboard from "components/monitoring/dashboard/Dashboard";
import Users from "components/users";
import Login from "components/login";
import RejectedCalls from "components/rejectedCalls";
import RejectedCallDetails from "components/rejectedCalls/rejectedCallDetails";
import PredefinedRules from "components/firewall/predefinedRules";
import LocationFalsification from "components/firewall/predefinedRules/locationFalsification";
import UnallocatedPrsRules from "components/firewall/unallocatedPrsRules";
import UnallocatedPrsRulesCreateUpdate from "components/firewall/unallocatedPrsRules/unallocatedPrsRulesCreateUpdate";
import PrefixListRules from "components/firewall/prefixListRules";
import PrefixListRulesCreateUpdate from "components/firewall/prefixListRules/prefixListRulesCreateUpdate";
import PrefixListView from "components/firewall/prefixLists/prefixListView";
import PrefixListAddValues from "components/firewall/prefixLists/prefixListsAddValues";
import PrefixLists from "components/firewall/prefixLists";
import PrefixListsCreateUpdate from "components/firewall/prefixLists/prefixListsCreateUpdate";
import StatusInfo from "components/settings/StatusInfo/StatusInfo";
import Security from "components/settings/Security";
import ReportDetail from "./settings/regularReports/detail";
import NotificationChannelForm from "components/settings/NotificationChannels/NotificationChannelsForm";
import GenerateReport from "./settings/regularReports/generate";

import s from "./App.module.scss";

const getBackgroundColor = (path: string) => {
  switch (true) {
    case path.startsWith(URLS.dashboard) ||
      path.startsWith(URLS.fraudTypes) ||
      path.startsWith(URLS.status) ||
      path.startsWith(URLS.inbound) ||
      path.startsWith(URLS.outbound): {
      return { "--bg-color": "var(--blue-light)" };
    }
  }
};

const App = observer((props: any) => {
  const { fetchReports } = props;

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const {
    referenceStore: {
      getReferenceAsync,
      getCallControlRules,
      getNotificationChannels,
    },
    userStore: { getUserData, status, loading, user },
    statusInfoStore: { getStatusInfo },
    fraudRulesStore: { getFraudRules },
  } = useStores();

  const { contextHolder } = useMessages();

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (status === SUCCESS) {
      getCallControlRules();
      getReferenceAsync();

      const filters = {
        intervalParams,
        window: windowStepParams,
      };

      dispatch(updateFilter({ ...filters }));
      getStatusInfo();
      getNotificationChannels();
      fetchReports();
      getFraudRules();
    }
  }, [
    dispatch,
    fetchReports,
    getFraudRules,
    getStatusInfo,
    getReferenceAsync,
    status,
    getNotificationChannels,
    getCallControlRules,
  ]);

  useIntercomWatcher();

  if (loading) {
    return (
      <div className={s.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <div
      className={s.app}
      style={getBackgroundColor(pathname) as CSSProperties}
    >
      <div className={s.container}>
        {status && pathname !== "/login" && <Navigation />}

        <main className={s.content}>
          {/* Container for Antd Messages */}
          {contextHolder}
          <Notification className={s.notification} />

          <Switch>
            <ProtectedRoute path={URLS.dashboard} component={Dashboard} />
            <ProtectedRoute
              exact
              path={URLS.fraudCallsDetail()}
              component={DetailFraudCard}
            />

            {/* Call Validation */}
            <ProtectedRoute path={URLS.inbound} component={Inbound} />
            <ProtectedRoute path={URLS.outbound} component={Outbound} />
            <ProtectedRoute
              exact
              path={URLS.fraudcalls}
              component={FraudCallsTable}
            />
            <ProtectedRoute
              exact
              path={URLS.fraudRules}
              component={FraudRules}
            />
            <ProtectedRoute
              exact
              path={URLS.editFraudRule()}
              component={FraudRulesForm}
              shouldAccess={() =>
                Boolean(user && hasPerm(user, Perms.RULES_ANY))
              }
            />

            {/* Firewall */}
            <ProtectedRoute
              exact
              path={URLS.predefinedRules}
              component={PredefinedRules}
            />
            <ProtectedRoute
              exact
              path={URLS.predefinedRulesLocationFalsification}
              component={LocationFalsification}
            />
            {/* <ProtectedRoute
              exact
              path={URLS.predefinedRulesFlashcallProtection}
              component={FlashcallProtection}
            /> */}

            <ProtectedRoute
              exact
              path={URLS.unallocatedPrsRules}
              component={UnallocatedPrsRules}
            />
            <ProtectedRoute
              exact
              path={URLS.unallocatedPrsRulesCreateUpdate()}
              component={UnallocatedPrsRulesCreateUpdate}
            />
            <ProtectedRoute
              exact
              path={URLS.prefixLists}
              component={PrefixLists}
            />
            <ProtectedRoute
              exact
              path={URLS.prefixListsCreateUpdate()}
              component={PrefixListsCreateUpdate}
            />
            <ProtectedRoute
              exact
              path={URLS.prefixListView()}
              component={PrefixListView}
            />
            <ProtectedRoute
              exact
              path={URLS.prefixListAddValues()}
              component={PrefixListAddValues}
            />

            <ProtectedRoute
              exact
              path={URLS.prefixListRules}
              component={PrefixListRules}
            />
            <ProtectedRoute
              exact
              path={URLS.prefixListRulesCreateUpdate()}
              component={PrefixListRulesCreateUpdate}
            />

            {/* Rejected Calls */}
            <ProtectedRoute
              exact
              path={URLS.rejectedCalls}
              component={RejectedCalls}
            />
            <ProtectedRoute
              exact
              path={URLS.rejectedCallDetails()}
              component={RejectedCallDetails}
            />

            {/* Information */}
            <ProtectedRoute path={URLS.participants} component={Participants} />
            <ProtectedRoute path={URLS.fraudTypes} component={FraudTypes} />
            <ProtectedRoute path={URLS.status} component={StatusInfo} />
            <ProtectedRoute
              path={URLS.connectorDetail()}
              component={ConnectorDetail}
            />

            {/* Settings */}
            <ProtectedRoute exact path={URLS.reports} component={Reports} />
            <ProtectedRoute
              exact
              path={URLS.reportCreate}
              component={ReportDetail}
            />
            <ProtectedRoute
              exact
              path={URLS.reportUpdate()}
              component={ReportDetail}
            />

            <ProtectedRoute
              exact
              path={URLS.reportGenerate}
              component={GenerateReport}
            />

            <ProtectedRoute
              exact
              path={URLS.channels}
              component={NotificationList}
            />

            <ProtectedRoute
              exact
              path={URLS.channelUpdate()}
              component={NotificationChannelForm}
            />

            {/* <ProtectedRoute path={URLS.blacklist} component={Blacklist} /> */}
            <ProtectedRoute path={URLS.security} component={Security} />

            {/* Account settings */}
            <ProtectedRoute path={URLS.users} component={Users} />

            <ProtectedRoute
              exact
              path={URLS.callSearch}
              component={CallSearch}
            />

            {/* Login */}
            <Route path={URLS.login} children={<Login />} />

            <Redirect to={URLS.dashboard} />
          </Switch>
        </main>
      </div>
    </div>
  );
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchNetworks: () => dispatch(fetchNetworks()),
    fetchGateways: () => dispatch(fetchGateways()),
    fetchReports: () => dispatch(fetchReports()),
  };
};

export default connect(() => ({}), mapDispatchToProps)(App);
