import {FraudRule} from "../../interfaces/IRules";
import {ICountryes} from "../../interfaces/ICountryes";
import {IFraudTypes} from "../../interfaces/IFraudTypes";
import {IGataways} from "../../interfaces/IGataways";
import {INetworks} from "../../interfaces/INetworks";
import {
  RULES_LIST_FETCH_SUCCESS,
  RULES_LIST_FETCH_LOADING,
  RULES_LIST_FETCH_ERROR,
  GATEWAYS_FETCH_SUCCESS,
  FRAUD_TYPES_FETCH_SUCCESS,
  NETWORKS_FETCH_SUCCESS
} from "../actions";
import {groupByCountry} from "./countries";

interface IState {
  fraudTypes: IFraudTypes[],
  gataways: IGataways[],
  countryes: ICountryes[],
  networks: INetworks[],
  isFetching: boolean,
  items: FraudRule[],
}

export const rulesListReducer = (state: IState, action: {type: any; items: FraudRule[]}) => {
  switch (action.type) {
    case GATEWAYS_FETCH_SUCCESS:
      return {...state, gataways: action.items}
    case FRAUD_TYPES_FETCH_SUCCESS:
      return {...state, fraudTypes: action.items}
    case NETWORKS_FETCH_SUCCESS:
      return {...state, networks: action.items, countryes: groupByCountry(action.items)}
    case RULES_LIST_FETCH_LOADING:
      return {...state, isFetching: true}
    case RULES_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        rules: action.items,
        isFetching: false,
      };
    }
    case RULES_LIST_FETCH_ERROR:
      return {...state, isFetching: false}
    default:
      return {...state};
  }
};
