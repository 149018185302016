import React, {FC, useMemo} from "react";
import {observer} from "mobx-react";
import {TimeSeriesChart} from "@vas-common/ui-kit";

import { dateCustom } from 'utils/dates';
import {useStores} from "../../../store/mobx/useStore";
import {callsType} from "../../../utils/constants";
import type {TGroupByDataTimeSeriesChart} from "@vas-common/ui-kit/dist/cjs/types/components/TimeSeriesChart";

type Props = {
  intervalType: TGroupByDataTimeSeriesChart
}

const CallsChart: FC<Props> = observer(({intervalType}) => {

  const {
    statisticsStore: {
      fraudSchedule,
      filterType,
      filters,
    }
  } = useStores();

  const {internationalList, validList, domesticList, loading} = fraudSchedule;

  const data = useMemo(() => {
    switch (filterType) {
      case callsType.international:
        return internationalList.map((el) => ({...el, subject1: el.inboundFraud, subject2: el.outboundFraud}));
      case callsType.validated:
        return validList.map((el) => ({...el, subject1: el.inboundFraud, subject2: el.outboundFraud}));
      case callsType.domestic:
        return domesticList.map((el) => ({...el, subject1: el.outboundFraud + el.inboundFraud}));
      default:
        return internationalList;
    }
  }, [domesticList, filterType, internationalList, validList]);

  const {from, to} = filters;

  const fromDate = dateCustom(new Date()).subtract(from, 'millisecond').valueOf();
  const toDate = to ? dateCustom(new Date()).subtract(to, 'millisecond').valueOf() : undefined;

  const DefaultChart = () => {
    return (
      <TimeSeriesChart
        from={fromDate}
        to={toDate}
        loader={loading}
        groupBy={intervalType}
        data={data}
        subjects={{
          inbound: {label: 'Inbound', color: '#82CA9D'},
          outbound: {label: 'Outbound', color: '#8884D8'}
        }}
      />
    )
  }

  const DomesticChart = () => {
    return (
      <TimeSeriesChart
        from={fromDate}
        to={toDate}
        groupBy={intervalType}
        data={data}
        subjects={{
          domestic: {label: 'Domestic', color: '#82CA9D'},
        }}
      />
    )
  }

  return (
      filterType === callsType.domestic ? <DomesticChart/> : <DefaultChart/>
  );
})

export default CallsChart;
