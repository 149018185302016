import { Button } from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { useStores } from "store/mobx/useStore";
import { NoPerms } from "components/users/NoPerms";
import { FraudRulesTable } from "./FraudRulesTable";

import { URLS } from "constants/Urls";
import { hasPerm, Perms } from "constants/Perms";

import { ReactComponent as IconPlus } from "assets/svg/plus.svg";
import dictionary from "assets/dictionary.json";

import s from "./index.module.scss";

export const FraudRules = observer(() => {
  const {
    userStore: { user },
  } = useStores();

  return (
    <div className={s.container}>
      <div className={s.headContainer}>
        <div className={s.head}>
          <h1 className={s.fontMainTitle} data-test="fraudRules-title">
            Fraud Rules
          </h1>

          <Link to={URLS.createFraudRule} data-test="fraudRules-title-add-btn">
            <Button
              className={s.btnPrimary}
              disabled={user && !hasPerm(user, Perms.RULES_ANY)}
            >
              <IconPlus width="2rem" />
              Add New Rule
            </Button>
          </Link>
        </div>
      </div>

      <div className={s.tableContainer}>
        <p className={s.fontBody}>
          {dictionary.descriptions.rules.fraudRules.table}
        </p>

        {user && hasPerm(user, Perms.RULES_ANY, Perms.RULES_READ) ? (
          <FraudRulesTable />
        ) : (
          <NoPerms />
        )}
      </div>
    </div>
  );
});
