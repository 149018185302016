import { FC, MouseEventHandler } from "react";
import { useHistory } from "react-router-dom";

import { URLS } from "../../../../constants/Urls";
import { ReactComponent as OutboundIcon } from "assets/svg/outbound.svg";
import { ReactComponent as InboundIcon } from "assets/svg/inbound.svg";
import { ReactComponent as DirectionIcon } from "assets/svg/direction.svg";
import { ReactComponent as CallIcon } from "assets/svg/settings_phone_black_24dp-1.svg";
import FlagIcon from "../../../flag/FlagIcon";
import { FRAUD_TYPES } from "../../../../utils/constants";
import {
  TFraudCall,
  TFraudType,
  TGateway,
  TNetwork,
} from "../../../../store/mobx/types";
import { dateCustom } from "utils/dates";
import { RULE_ACTION_TAGS_MAP } from "ui/maps";

import s from "./FraudCallsTable.module.scss";

type Props = {
  item: TFraudCall;
  fraudTypes: TFraudType[];
  networks: TNetwork[];
  gateways: TGateway[];
  direction: string[];
  setPosition?: (val: number | null) => void;
};

export const TableRow: FC<Props> = ({
  item,
  gateways,
  fraudTypes,
  networks,
  setPosition,
}) => {
  const history = useHistory();

  const onRouteRule: MouseEventHandler<HTMLTableCellElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.applied_rule_id) {
      history.push(URLS.editFraudRule({ id: item.applied_rule_id }));
    } else {
      history.push(URLS.fraudRules);
    }
  };

  const renderRuleAction = () => {
    if (item.blocked) {
      return RULE_ACTION_TAGS_MAP["block"];
    }

    if (item.alerted && !item.blocked) {
      return RULE_ACTION_TAGS_MAP["alert"];
    }

    return <div className={s.noRules}>No rules</div>;
  };

  const getNetworkName = (netId: number) => {
    const name = networks.find((el) => el.id === netId)?.name || null;
    return name && name.length > 20 ? name.substring(0, 25) + "..." : name;
  };

  const renderNoRemoteCallLabel = (item: TFraudCall | null): string => {
    if (item) {
      if (item.outbound && item.fraud_type === FRAUD_TYPES.STRETCH) {
        let diff = Math.round(
          ((item.carried_call?.ended_at || 0) -
            (item.carried_call?.remote_ended_at || 0)) /
            1000
        );
        return `call was stretched for ${diff} seconds`;
      }
      if (item.outbound && item.fraud_type === FRAUD_TYPES.PBX) {
        return "your switching equipment might have been hacked";
      }
    }
    return "No match in remote network";
  };

  const getGateway = () =>
    gateways.find((el) => el.id === item.carried_call?.gateway_id)?.name || "";

  const fraudName =
    fraudTypes.find((el) => el.value === item.fraud_type)?.full_name ||
    "Provisional";

  const getDuration = () => {
    if (item.carried_call) {
      if (
        item.carried_call.connected_at > 0 ||
        item.carried_call.duration > 0
      ) {
        return (
          <td title="Call was connected">
            <div className={s.durationWrapper}>
              {(item.carried_call.connected_at > 0 ||
                item.carried_call.duration > 0) && (
                <CallIcon className={`${s.icon} ${s.active}`} />
              )}
              {item.carried_call.duration > 0 &&
                dateCustom(item.carried_call.duration * 1000).format("H:mm:ss")}
            </div>
          </td>
        );
      }
    }

    if (item.remote_call) {
      if (
        item.remote_call.connected_at > 0 ||
        item.remote_call.remote_duration > 0
      ) {
        return (
          <td>
            <div
              className={`${s.durationWrapper} ${s.remote}`}
              title="Call was connected"
            >
              {(item.remote_call.connected_at > 0 ||
                item.remote_call.remote_duration > 0) && (
                <CallIcon className={`${s.icon} ${s.active}`} />
              )}
              {item.remote_call.remote_duration > 0 &&
                dateCustom(item.remote_call.remote_duration * 1000).format(
                  "H:mm:ss"
                )}
            </div>
          </td>
        );
      }
    }
    return (
      <td>
        <div />
      </td>
    );
  };

  const CountryFlag = ({ netId }: { netId: number }) => {
    const network = networks.find((el) => el.id === netId) || null;
    return (
      <FlagIcon
        className={s.countryIcon}
        country={network ? network.country_iso : "ZZ"}
      />
    );
  };

  const handleRowClick = () => {
    const anchor = document.getElementById("anchor");

    if (anchor && setPosition) {
      setPosition(anchor.scrollTop);
    }

    history.push(`${URLS.fraudcalls}/${item.id}`);
  };

  return (
    <tr onClick={handleRowClick}>
      <td>
        {item.outbound ? (
          <OutboundIcon className={s.outboundIcon} />
        ) : (
          <InboundIcon className={s.inboundIcon} />
        )}
      </td>

      <td title={fraudName.length > 9 ? fraudName : ""}>
        <div>{fraudName}</div>
      </td>
      <td>{getGateway()}</td>

      {/* A-NUMBER */}
      <td className={s.cellNumber}>
        {item.carried_call ? (
          <div
            className={s.row}
            title={getNetworkName(item.carried_call.origin_network_id) || ""}
          >
            <CountryFlag netId={item.carried_call.origin_network_id} />
            <strong>{item.carried_call.a_number}</strong>{" "}
            <label className={s.networkName}>
              {" "}
              - {getNetworkName(item.carried_call.origin_network_id)}
            </label>
          </div>
        ) : (
          <div title={"This fraud record does not affect fraud statistics"}>
            No carried call
          </div>
        )}

        {item.remote_call ? (
          <div
            className={s.row}
            style={{ opacity: 0.5 }}
            title={getNetworkName(item.remote_call.origin_network_id) || ""}
          >
            <CountryFlag netId={item.remote_call.origin_network_id} />
            {item.remote_call.a_number}{" "}
            <label className={s.networkName}>
              {" "}
              - {getNetworkName(item.remote_call.origin_network_id)}
            </label>
          </div>
        ) : (
          <div className={s.remoteCall}>{renderNoRemoteCallLabel(item)}</div>
        )}
      </td>

      {/* DIRECTION */}
      <td className={s.directionColumn}>
        <DirectionIcon className={s.directionIcon} />
      </td>

      {/* B_NUMBER */}
      <td className={s.cellNumber}>
        {item.carried_call ? (
          <div
            className={s.row}
            title={
              getNetworkName(item.carried_call.destination_network_id) || ""
            }
          >
            <CountryFlag netId={item.carried_call.destination_network_id} />
            <strong>{item.carried_call.b_number}</strong>{" "}
            <label className={s.networkName}>
              {" "}
              - {getNetworkName(item.carried_call.destination_network_id)}
            </label>
          </div>
        ) : (
          <div>-</div>
        )}

        {item.remote_call ? (
          <div
            className={s.row}
            style={{ opacity: 0.5 }}
            title={
              getNetworkName(item.remote_call.destination_network_id) || ""
            }
          >
            <CountryFlag netId={item.remote_call.destination_network_id} />
            {item.remote_call.b_number}{" "}
            <label className={s.networkName}>
              {" "}
              - {getNetworkName(item.remote_call.destination_network_id)}
            </label>
          </div>
        ) : (
          <div>-</div>
        )}
      </td>

      {/* DURATION */}
      {getDuration()}

      {/* TIME */}
      <td>
        <div>{dateCustom(item.timestamp).format("MMM DD, HH:mm:ss")}</div>
      </td>

      {/* RULE ACTION */}
      <td onClick={onRouteRule}>{renderRuleAction()}</td>
    </tr>
  );
};
