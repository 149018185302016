import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import { UIHelpers, Switch } from "@vas-common/ui-kit";

import FlagIcon from "components/flag/FlagIcon";
import { RULE_ACTION_TAGS_MAP } from "ui/maps/ruleActionsMap";
import { useNotifications } from "ui/Notifications";

import { dateCustom } from "utils/dates";
import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";

import type {
  UnallocatedPrsRule,
  UnallocatedPrsRuleCountrySelection,
} from "store/mobx/types";

import s from "./index.module.scss";

const COUNTRY_SELECTIONS_MAP: Record<
  UnallocatedPrsRuleCountrySelection,
  string
> = {
  all: "All countries",
  exclude: "Excluding",
  include: "Including",
};

export const UnallocatedPrsRulesTable = observer(
  function UnallocatedPrsRulesTable() {
    const history = useHistory();

    const { handleError } = useNotifications();

    const [isLoadingList, setIsLoadingList] = useState<Record<string, boolean>>(
      {}
    );

    const {
      firewallStore: {
        unallocatedPrsRules,
        getUnallocatedPrsRules,
        patchUnallocatedPrsRule,
      },
    } = useStores();

    const { isLoading, data } = unallocatedPrsRules;

    useEffect(() => {
      getUnallocatedPrsRules();
    }, [getUnallocatedPrsRules]);

    const handleRowClick = (rule: UnallocatedPrsRule) => {
      history.push(`${URLS.unallocatedPrsRules}/${rule.id}`, {
        rule: { ...toJS(rule) },
      });
    };

    return !data.length && !isLoading ? (
      <div className={s.noData} data-test="unallocated-prs-no-data">
        <h1 className={s.fontTitle}>No Unallocated / PRS rules found</h1>
        <p className={s.fontBody}>There are no rules yet.</p>
      </div>
    ) : (
      <table className={s.table} data-test="unallocated-prs-table">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Scope</th>
            <th>Threshold</th>
            <th>Actions</th>
            <th>Last Triggered</th>
          </tr>
        </thead>

        {/* Content */}
        <tbody className={s.content}>
          {data.map((rule) => {
            const extraCountries = rule.countries.length - 3;

            return (
              <tr
                key={rule.id}
                onClick={() => handleRowClick(rule)}
                data-test={`unallocated-prs-item-${rule.id}`}
              >
                {/* Switch */}
                <td
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className={s.switchCell}
                >
                  <Switch
                    onChange={(value) => {
                      setIsLoadingList({
                        ...isLoadingList,
                        [rule.id]: true,
                      });

                      patchUnallocatedPrsRule(rule.id, {
                        enabled: value,
                      })
                        .catch(handleError)
                        .finally(() =>
                          setIsLoadingList((prevState) => ({
                            ...prevState,
                            [rule.id]: false,
                          }))
                        );
                    }}
                    loading={isLoadingList[rule.id]}
                    checked={rule.enabled}
                  />
                </td>

                {/* Name */}
                <td>
                  <div title={rule.name.length > 8 ? rule.name : ""}>
                    {rule.name}
                  </div>
                </td>

                {/* Scope */}
                <td className={s.scopeCell}>
                  <div>
                    {`${rule.type === "prs" ? "PRS" : "Unallocated"} | `}
                    {rule.direction === "all" ? (
                      "Inbound & Outbound"
                    ) : (
                      <span className={s.capitalize}>{rule.direction}</span>
                    )}
                  </div>

                  <div className={s.row}>
                    {COUNTRY_SELECTIONS_MAP[rule.country_selection]}
                    {rule.countries.slice(0, 3).map((country) => (
                      <FlagIcon
                        key={country}
                        country={country || "ZZ"}
                        title={UIHelpers.countries.getCountryName(country)}
                      />
                    ))}
                    {extraCountries > 0 ? `+${extraCountries} more` : null}
                  </div>
                </td>

                {/* Threshold */}
                <td className={!rule.threshold_enabled ? s.disabled : ""}>
                  {!rule.threshold_enabled ? (
                    "None"
                  ) : (
                    <>{`${rule.threshold_numeric} calls / ${rule.threshold_period}`}</>
                  )}
                </td>

                {/* Actions */}
                <td>
                  {rule.action ? RULE_ACTION_TAGS_MAP[rule.action] : null}
                </td>

                {/* Last Triggered */}
                <td
                  className={`${s.lastTriggeredCell} ${
                    !rule.last_triggered_at ? s.disabled : ""
                  }`}
                >
                  <div
                    title={
                      rule.last_triggered_at
                        ? `${dateCustom(rule.last_triggered_at).format()}`
                        : ""
                    }
                  >
                    {rule.last_triggered_at
                      ? dateCustom().isBefore(rule.last_triggered_at)
                        ? "just now"
                        : dateCustom(rule.last_triggered_at).fromNow()
                      : "Not triggered yet"}
                  </div>

                  {!rule.matched_today ? null : (
                    <div>{`${UIHelpers.formatNumber(
                      rule.matched_today
                    )} calls today`}</div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>

        {/* Spinner */}
        {isLoading && (
          <tbody className={s.spin} data-test="unallocated-prs-spin">
            <tr>
              <td>
                <Spin spinning />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    );
  }
);
