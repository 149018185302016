import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Spin } from "antd";
import { UIHelpers } from "@vas-common/ui-kit";

import { qs } from "utils/qs";
import { URLS } from "constants/Urls";
import { useStores } from "store/mobx/useStore";
import { dateCustom } from "utils/dates";

import { useNotifications } from "ui/Notifications";

import { ReactComponent as IconCheckMark } from "assets/svg/check-mark.svg";
import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";

import type { PrefixList, PrefixListsState } from "store/mobx/types";

import s from "./index.module.scss";

export const PrefixListsTable = observer(function PrefixListsTable() {
  const history = useHistory();
  const { search } = useLocation();

  const { handleError, resetNotification } = useNotifications();

  const {
    firewallStore: { getPrefixLists, prefixLists, setPrefixListsLoading },
  } = useStores();

  const { data, isLoading, hasNextPage } = prefixLists;

  useEffect(() => {
    setPrefixListsLoading(true);

    const { expirable, name, values_type } = qs.parse(search, {
      ignoreQueryPrefix: true,
    }) as Record<string, undefined>;

    let resultQuery: PrefixListsState["params"] = {
      page: 1,
      page_size: 20,
      expirable: expirable,
      name: name,
      values_type: values_type,
    };

    resetNotification();
    getPrefixLists(handleError, resultQuery);

    return getPrefixLists.cancel;
  }, [search, handleError, resetNotification, getPrefixLists, setPrefixListsLoading]);

  const handleRowClick = (prefixList: PrefixList) => {
    history.push(`${URLS.prefixLists}/${prefixList.id}/view`);
  };

  const handleLoadMore = () => {
    setPrefixListsLoading(true);
    const currentPage = prefixLists.params.page;

    resetNotification();
    getPrefixLists(handleError, { page: (currentPage || 0) + 1 });
  };

  return !data.length && !isLoading ? (
    <div className={s.noData} data-test="prefixLists-noData">
      <h1 className={s.fontTitle}>No Prefix Lists found</h1>
      <p className={s.fontBody}>There are no Prefix Lists yet.</p>
    </div>
  ) : (
    <table className={s.table} data-test="prefixLists-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Value Type</th>
          <th>Expirable</th>
          <th className={s.qtyCell}>QTY</th>
          <th>Last Updated At</th>
        </tr>
      </thead>

      {/* Spinner */}
      {isLoading && (
        <tbody
          className={s.spin}
          style={
            {
              "--bottom-offset": hasNextPage ? "var(--row-height)" : 0,
            } as React.CSSProperties
          }
          data-test="prefixLists-table-spin"
        >
          <tr>
            <td>
              <Spin spinning />
            </td>
          </tr>
        </tbody>
      )}

      {/* Content */}
      <tbody className={s.content} data-test="prefixLists-table-content">
        {data.map((prefixList) => {
          return (
            <tr
              key={prefixList.id}
              onClick={() => handleRowClick(prefixList)}
              data-test={`prefixLists-item-${prefixList.id}`}
            >
              {/* Name */}
              <td title={prefixList.name.length > 16 ? prefixList.name : ""}>
                <div>{prefixList.name}</div>
              </td>

              {/* Description */}
              <td
                title={
                  prefixList.description.length > 19
                    ? prefixList.description
                    : ""
                }
              >
                <div>{prefixList.description}</div>
              </td>

              {/* Value Type */}
              <td>{prefixList.values_type}</td>

              {/* Expirable */}
              <td className={s.expirableCell}>
                {prefixList.expirable ? (
                  <IconCheckMark width="2rem" className={s.checkmark} />
                ) : (
                  <IconCross width="2rem" className={s.cross} />
                )}
              </td>

              {/* QTY */}
              <td className={s.qtyCell}>
                {UIHelpers.formatNumber(prefixList.values_count)}
              </td>

              {/* Last Updated AT */}
              <td>{dateCustom(prefixList.updated_at).format()}</td>
            </tr>
          );
        })}
      </tbody>

      {/* Load More */}
      {hasNextPage && (
        <tbody className={s.loadMore}>
          <tr>
            <td>
              <Button
                className={s.btnSecondary}
                disabled={isLoading}
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
});
