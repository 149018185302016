import { useCallback, useRef, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Alert, Button } from "antd";

import { useStores } from "store/mobx/useStore";
import { getErrorMessage } from "./helpers";

import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";

import s from "./index.module.scss";

type NotificationProps = {
  className?: string;
};

export const Notification = observer(function Notification({
  className,
}: NotificationProps) {
  const {
    notificationsStore: { notification, resetNotification },
  } = useStores();

  const [state, setState] = useState(notification);
  const [isVisible, setIsVisible] = useState(Boolean(notification));

  useEffect(() => {
    let isCanceled = false;

    if (notification) {
      setIsVisible(true);
      setState(notification);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        if (!isCanceled) {
          setState(null);
        }
      }, 1000);
    }

    return () => {
      isCanceled = true;
    };
  }, [state, notification]);

  return state?.silent ? null : (
    <div className={`${s.container} ${!isVisible ? s.hidden : ""}`}>
      {state && (
        <div className={`${s.wrapper} ${className}`}>
          <Alert
            className={`${s.notification}`}
            message={state.data.title || state.type}
            description={
              state.type === "error" ? (
                <pre className={s.fontBody}>{state.data.message}</pre>
              ) : (
                <p className={s.fontBody}>{state.data.message}</p>
              )
            }
            type={state.type || "info"}
          />
          <Button
            className={`${s.btnClose} ant-alert-close-icon`}
            type="text"
            onClick={resetNotification}
          >
            <IconCross width="2rem" />
          </Button>
        </div>
      )}
    </div>
  );
});

export const useNotifications = (onNotification?: () => void) => {
  const eventHandler = useRef(onNotification);

  const {
    notificationsStore: { notification, resetNotification, setNotification },
  } = useStores();

  useEffect(() => {
    return resetNotification;
  }, [resetNotification]);

  const handleError = useCallback(
    (_err: unknown, silent: boolean = false) => {
      const error = _err as any;
      const status = error?.code || error?.response?.status;

      setNotification({
        type: "error",
        data: {
          status: status && Number(status),
          message:
            status && Number(status) >= 500
              ? "Internal server error"
              : getErrorMessage(_err),
        },
        silent,
      });

      eventHandler.current && eventHandler.current();
    },
    [setNotification, eventHandler]
  );

  const scrollTo = {
    top: () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    bottom: () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    },
  };

  return {
    resetNotification,
    handleError,
    setNotification,
    error: notification?.type === "error" ? notification.data : null,
    scrollTo,
  };
};
