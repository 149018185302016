import {IReport, IReports} from "../../interfaces/IReports";
import {
  REPORTS_FETCH_SUCCESS,
  REPORTS_FETCH_ERROR,
  REPORTS_FETCH_LOADING,
  REPORTS_CHANNELS_UPDATE,
  REPORTS_CHANNELS_UPDATE_LOADING,
  SEND_NOW_REPORT_SUCCESS,
  SEND_NOW_REPORT_ERROR,
  SEND_NOW_REPORT_LOADING,
} from "../actions";

export const reportsReducer = (state: IReports = {isError: false, isFetching: false}, action: {type: any; items: IReport[]}) => {
  switch (action.type) {
    case REPORTS_FETCH_LOADING:
      return {...state, isFetching: true}
    case REPORTS_FETCH_SUCCESS:
      const defaultObj = {
        attach_call_log: true,
        notification_channel_ids: []
      }

      const daily = action.items.find(item => item.periodicity === "daily"
        && item.network_ids === null && item.gateway_ids === null && item.fraud_types === null && item.countries === null) || {...defaultObj, type: "daily"}

      const weekly = action.items.find(item => item.periodicity === "weekly"
        && item.network_ids === null && item.gateway_ids === null && item.fraud_types === null && item.countries === null) || {...defaultObj, type: "weekly"}

      const monthly = action.items.find(item => item.periodicity === "monthly"
        && item.network_ids === null && item.gateway_ids === null && item.fraud_types === null && item.countries === null) || {...defaultObj, type: "monthly"}

      return {
        ...state,
        isFetching: false,
        isError: false,

        items: {
          reportsAll: action.items,
          reportsByType: [{...daily}, {...weekly}, {...monthly}],
        }
      }
    case REPORTS_FETCH_ERROR:
      return {...state, isFetching: false, isError: true};
    case REPORTS_CHANNELS_UPDATE_LOADING:
      return {...state, isFetching: true}
    case REPORTS_CHANNELS_UPDATE:
      const reportsBy = state.items?.reportsByType;
      //@ts-ignore
      reportsBy[state.items.reportsByType.findIndex(it => it.id === action.items.id)] = {...action.items}

      return {
        ...state,
        isFetching: false,
        items: {
          ...state.items,
          reportsByType: reportsBy,
        }
      };
    case SEND_NOW_REPORT_LOADING:
      return {...state, isFetching: true}
    case SEND_NOW_REPORT_SUCCESS:
      return {...action.items, isFetching: false}
    case SEND_NOW_REPORT_ERROR:
      return {...state, isFetching: false}
    default:
      return state;
  }
};
