import { Button } from "antd";
import { Link } from "react-router-dom";

import { URLS } from "constants/Urls";

import { UnallocatedPrsRulesTable } from "./UnallocatedPrsRulesTable";

import { ReactComponent as IconPlus } from "assets/svg/plus.svg";

import dictionary from "assets/dictionary.json";

import s from "./index.module.scss";

const UnallocatedPrsRules = () => {
  return (
    <div className={s.container}>
      <div className={s.headContainer}>
        <div className={s.head}>
          <h1 className={s.fontMainTitle} data-test="unallocated-prs-title">
            Unallocated / PRS
          </h1>

          <Link
            to={URLS.unallocatedPrsRulesCreate}
            data-test="unallocated-prs-title-add-btn"
          >
            <Button className={s.btnPrimary}>
              <IconPlus width="2rem" />
              Add New Rule
            </Button>
          </Link>
        </div>
      </div>

      <div className={s.tableContainer}>
        <p className={s.fontBody}>
          {dictionary.descriptions.rules.unallocatedPrs}
        </p>

        <UnallocatedPrsRulesTable />
      </div>
    </div>
  );
};

export default UnallocatedPrsRules;
