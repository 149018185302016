import React, {FC} from "react";
import ReactJson from 'react-json-view'

import s from './CallSearch.module.scss'

type Props = {
  items: any[]
}

const Table:FC<Props> = ({items}) => {
  return (
    <div className={s.wrapperTable}>
      <ReactJson src={items} name={false}/>
    </div>

  )
}

export default Table;
