import {uniqBy} from 'lodash';
import {
  FRAUD_RECORDS_FETCH_SUCCESS,
  FRAUD_RECORDS_FETCH_LOAD_MORE,
  SET_FRAUD_CALLS_FILTERS,
  FRAUD_RECORDS_FETCH_SUCCESS_LOADING,
} from "../actions";

export const lastRecordsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FRAUD_CALLS_FILTERS: {
      return {
        ...state,
        fraudCallsFilters: action.filters,
      }
    }
    case FRAUD_RECORDS_FETCH_SUCCESS_LOADING:
      return {...state, isFetching: true}
    case FRAUD_RECORDS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isFetching: false
      };
    case FRAUD_RECORDS_FETCH_LOAD_MORE:
      return {
        ...state,
        isFetching: false,
        items: uniqBy([...state.items, ...action.items], 'id'),
      }
    default:
      return state;
  }
};
