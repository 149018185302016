import { makeAutoObservable, runInAction } from "mobx";
import { PROTECT_TOKEN, SUCCESS, FAIL } from "store/actions";

import type { User, UserStatus } from "store/mobx/types/user";
import type { RootStoreType } from "./rootStore";
import { authenticate, getUserData } from "utils/api";
import { AxiosError } from "axios";

export class UserStore {
  rootStore: RootStoreType;

  status?: UserStatus;
  user?: User;
  loading: boolean = true;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  logOut = () => {
    localStorage.removeItem(PROTECT_TOKEN);
    this.status = undefined;
    this.user = undefined;
    this.loading = false;
  };

  setData = (status: UserStatus, data?: User) => {
    this.status = status;
    this.user = data;
    this.loading = false;
  };

  getUserData = async () => {
    try {
      const response = await getUserData();
      this.setData(SUCCESS, response);
    } catch (err: unknown) {
      if ((err as AxiosError)?.response?.status === 401) {
        this.logOut();
      } else {
        this.setData(SUCCESS);
      }
    }
  };

  authenticate = async (values: { login: string; password: string }) => {
    try {
      const { token } = await authenticate(values);
      localStorage.setItem(PROTECT_TOKEN, token);
      return this.getUserData();
    } catch (err) {
      runInAction(() => {
        this.loading = false;
        this.status = FAIL;
      });
    }
  };
}
