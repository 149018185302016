import { TFiltersCalls } from "../types";

export const getParamsFromFilters = (filters: TFiltersCalls): string => {
  let str = "";
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case "fraud_types":
          str = `${str}&fraud_types[]=${value}`;
          return;
        case "direction":
          str = `${str}&direction=${value}`;
          return;
        case "countries":
          if (value !== "null") {
            str = `${str}&countries[]=${value}`;
          }
          return;
        case "network_ids":
          str = `${str}&network_ids[]=${value}`;
          return;
        case "gateway_ids":
          str = `${str}&gateway_ids[]=${value}`;
          return;
      }
    }
  });
  return str;
};

export const getParamsFromFiltersStatistic = (
  filters: TFiltersCalls
): string => {
  const objFilters = { ...filters };
  if (objFilters.countries) {
    if (objFilters.gateway_ids) {
      delete objFilters["gateway_ids"];
    }
  }
  let str = "";
  Object.entries(objFilters).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case "fraud_types":
          str = `${str}&fraud_type_ids[]=${value}`;
          return;
        case "direction":
          str = `${str}&direction=${value}`;
          return;
        case "countries":
          if (value !== "null") {
            str = `${str}&countries[]=${value}`;
          }
          return;
        case "network_ids":
          str = `${str}&network_ids[]=${value}`;
          return;
        case "gateway_ids":
          str = `${str}&gateway_ids[]=${value}`;
          return;
      }
    }
  });
  return str;
};
