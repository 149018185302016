import { useState, useEffect } from "react";
import { Button, Input, DatePicker, Form } from "antd";
import { useHistory, Link, useParams } from "react-router-dom";

import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";
import { prefixListValuesValidateBatch } from "utils/api";
import { useNotifications } from "ui/Notifications";

import dictionary from "assets/dictionary.json";
import { showConfirm, Upload } from "./helpers";
import { dateCustom } from "utils/dates";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import { ReactComponent as IconCalendar } from "assets/svg/calendar.svg";
import { ReactComponent as IconClear } from "assets/svg/cancel-circle.svg";

import type { PrfixListValuesForm } from "store/mobx/types";

import s from "./index.module.scss";

const PrefixListAddValues = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    firewallStore: {
      prefixListValuesAddBatch,
      prefixListsMap,
      getPrefixListById,
    },
  } = useStores();

  const currentPrefixList = prefixListsMap[Number(id)];

  useEffect(() => {
    if (!currentPrefixList && id) {
      getPrefixListById(id);
    }
  }, [id, getPrefixListById, currentPrefixList]);

  const [form] = Form.useForm<PrfixListValuesForm>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleError, resetNotification, setNotification } = useNotifications(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
  );

  return (
    <Form
      form={form}
      className={`${s.container} ${s.form}`}
      layout="vertical"
      onFinish={(values) => {
        setIsSubmitting(true);
        resetNotification();

        const data = {
          ...values,
          expires_at: values?.expires_at?.valueOf(),
        };

        return prefixListValuesValidateBatch(data)
          .then(
            (validationResult) =>
              new Promise((resolve, reject) => {
                showConfirm({
                  onOk: () => resolve(""),
                  // handle this cancel silently
                  onCancel: () => reject("cancel"),
                  validationResult,
                });
              })
          )
          .then(() => prefixListValuesAddBatch(Number(id), data))
          .then(() => {
            history.push(`${URLS.prefixLists}/${id}/view`);
          })
          .then(() => {
            setNotification(
              {
                type: "success",
                data: {
                  message: "Successfully Added Values",
                },
              },
              1500
            );
          })
          .catch((err) => {
            setIsSubmitting(false);
            if (err === "cancel") return;
            handleError(err);
          });
      }}
    >
      <section className={s.grid}>
        <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
          Add new values
          <Link to={`${URLS.prefixLists}/${id}/view`}>
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>

        <p className={s.mt8}>
          {dictionary.descriptions.rules.prefixList.addValues}
        </p>
      </section>

      <section className={`${s.row}`}>
        <div className={`${s.grid} ${s.gap24}`}>
          {/* Raw Values */}
          <Form.Item
            label="Values"
            name="raw_values"
            required={false}
            rules={[{ required: true, message: "Prefixes are required" }]}
          >
            <Input.TextArea className={s.textArea} rows={3} />
          </Form.Item>

          {/* Expiration date */}
          {!currentPrefixList?.expirable ? null : (
            <Form.Item label="Expiration date · optional" name="expires_at">
              <DatePicker
                className={s.datePicker}
                suffixIcon={<IconCalendar width="2rem" />}
                clearIcon={<IconClear width="2rem" />}
                disabledDate={(current) =>
                  current.add(1, "day").isBefore(dateCustom())
                }
              />
            </Form.Item>
          )}

          {/* Description */}
          <Form.Item
            label="Description · optional"
            name="description"
            required={false}
          >
            <Input className={s.input} />
          </Form.Item>
        </div>

        <div className={s.flex}>
          <div className={s.fontTHead}>OR</div>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isUploadDisabled = getFieldValue("value");
              return <Upload disabled={isUploadDisabled} />;
            }}
          </Form.Item>
        </div>
      </section>

      {/* Buttons */}
      <section className={s.grid}>
        <div className={s.buttonsRow}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsError }) => {
              const isValid = getFieldsError().every(
                (field) => field.errors.length === 0
              );

              return (
                <>
                  <Button
                    className={s.btnPrimary}
                    htmlType="submit"
                    disabled={!isValid}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                </>
              );
            }}
          </Form.Item>
        </div>
      </section>
    </Form>
  );
};

export default PrefixListAddValues;
