import { ReactNode, useEffect, useState, useMemo } from "react";
import type { FormInstance, FormItemProps, MenuProps } from "antd";
import { Button, Checkbox, Dropdown, Form } from "antd";

import { ReactComponent as IconGear } from "assets/svg/settings-gear.svg";

import { splitToAlwaysOnAndDynamic } from "./helpers";

// TODO pre-config it in UI-Kit
import { qs } from "utils/qs";

import s from "./index.module.scss";
import QueryString from "qs";

export type TSelectItems = {
  label: string;
  value: string;
};

type ParseArgs = {
  queryParams?: QueryString.ParsedQs;
  value?: PossibleValues;
};

export type FilterField = FormItemProps & {
  name: string;
  component: ReactNode | ((form: FormInstance) => ReactNode);
  activeDefault?: boolean;
  alwaysOn?: boolean;
  /**
   * it's going to be a custom parser function that handles
   * value from queryString prepaering for the form control itself. This way we can avoid any conventions
   * hided in the logic of Filters. (Pattern - OPEN-CLOSED)
   * If parsing the field value from the queryString requires some more complex logic than queryParams[key], then you should supply the parse method.
   * @param args [ParseArgs] - if args includes "queryParams" then you should provide a logic of how to extract values and construct a form value.
   *                         -  if args includes "value" then you should provide a logic of how to transform vvalue to a queryParams object
   * @returns Record<string, unknown>
   */
  parse?: (args: ParseArgs) => Record<string, unknown>;
};

export type PossibleValues = string | number | string[] | number[];

export type Props = {
  fieldsSet: FilterField[];
  name: string;
  onChange: (
    values: Record<string, PossibleValues>,
    queryString: string
  ) => void;

  showSearchButton?: boolean;
  loading?: boolean;
  className?: string;
};

// TODO Move to UI-Kit
export const FilterForm = ({
  fieldsSet,
  name,
  onChange,
  showSearchButton = false,
  loading = false,
  className = "",
}: Props) => {
  const STORAGE_NAME = `${name}.activeFields`;

  const initialValues = useMemo(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    return fieldsSet.reduce<Record<string, unknown>>((acc, field) => {
      if (field.parse) {
        return { ...acc, ...field.parse({ queryParams }) };
      }

      return queryParams[field.name]
        ? { ...acc, [field.name]: queryParams[field.name] }
        : acc;
    }, {});
  }, [fieldsSet]);

  const [form] = Form.useForm();

  const { alwaysOnControls, dynamicControls } = useMemo(
    () => splitToAlwaysOnAndDynamic(fieldsSet),
    [fieldsSet]
  );

  const [currentFiltersSet, setCurrentFiltersSet] = useState<FilterField[]>([]);

  useEffect(() => {
    setCurrentFiltersSet((prevState) => {
      if (prevState.length === 0) {
        // initial load
        const savedFilterControlNames: string[] = JSON.parse(
          localStorage.getItem(STORAGE_NAME) || "[]"
        );

        return savedFilterControlNames.length
          ? [
              // alwaysOn controls never get to localStorage so we work with dynamicControls only
              ...dynamicControls.filter(
                (field) =>
                  // initially we enable all fields from localStorage ...
                  savedFilterControlNames.includes(field.name) ||
                  // ... and any field that has value in initial state (from queryString)
                  initialValues[field.name]
              ),
              ...alwaysOnControls,
            ]
          : [
              ...dynamicControls.filter(
                (field) =>
                  // if nothing kept in localstorage we add default fields to the set
                  field.activeDefault ||
                  // ... and any field that has value in initial state (from queryString)
                  initialValues[field.name]
              ),
              ...alwaysOnControls,
            ];
      } else {
        // subsequent load
        // replace prevState fields with the fresh ones
        return prevState.map(
          (field) =>
            [...alwaysOnControls, ...dynamicControls].find(
              (control) => control.name === field.name
            ) || field
        );
      }
    });
  }, [alwaysOnControls, dynamicControls, STORAGE_NAME, initialValues]);

  const onFinish = (values: Record<string, PossibleValues>) => {
    const resultValues = Object.entries(values)
      // filter all falsy values
      .filter(([, value]) => value)
      .reduce<Record<string, unknown>>((acc, [key, value]) => {
        const currentField = fieldsSet.find((item) => item.name === key);

        if (currentField && currentField.parse) {
          return { ...acc, ...currentField.parse({ value: value }) };
        }

        return { ...acc, [key]: value };
      }, {});

    onChange(values, `?${qs.stringify(resultValues)}`);
  };

  const onDropdownClick: MenuProps["onClick"] = ({ key, domEvent }) => {
    domEvent.preventDefault();
    domEvent.stopPropagation();

    const isActive = currentFiltersSet.some((control) => control.name === key);
    const foundControl = dynamicControls.find((item) => item.name === key);

    return setCurrentFiltersSet((prevState) => {
      const { dynamicControls: currentDynamic } =
        splitToAlwaysOnAndDynamic(prevState);

      // this filter keeps in sync the order of dropdown items + fieldsSet + currentFiltersSet
      const orderedNewDynamicArr = dynamicControls.filter((control) =>
        [...currentDynamic, foundControl].find(
          (item) => item?.name === control.name
        )
      );

      const newState = isActive
        ? prevState.filter((control) => control.name !== key)
        : [...orderedNewDynamicArr, ...alwaysOnControls];

      const newStateDynamicKeys = orderedNewDynamicArr.map((item) => item.name);

      localStorage.setItem(STORAGE_NAME, JSON.stringify(newStateDynamicKeys));

      return newState;
    });
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      className={`${s.form} ${s.container} ${className}`}
      autoComplete="off"
      onFinish={onFinish}
      layout="vertical"
    >
      {currentFiltersSet.map(
        ({ component, activeDefault, alwaysOn, parse, name, ...rest }) => (
          <Form.Item
            key={name}
            // when we use callback as a component do not pass name to the Item
            name={typeof component === "function" ? undefined : name}
            {...rest}
          >
            {component}
          </Form.Item>
        )
      )}

      <div className={s.buttons}>
        {dynamicControls.length ? (
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            menu={{
              items: dynamicControls.map((control) => ({
                key: control.name,
                label: (
                  <Checkbox
                    className={s.checkbox}
                    checked={currentFiltersSet.some(
                      (item) => item.name === control.name
                    )}
                  >
                    {control.label}
                  </Checkbox>
                ),
              })),
              onClick: onDropdownClick,
            }}
          >
            <Button className={s.btnSecondary}>
              <IconGear width="2rem" />
            </Button>
          </Dropdown>
        ) : null}

        {showSearchButton && (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue, setFieldsValue }) => {
              const currentValues = getFieldsValue();
              const isEmptyCurrentValues =
                !Object.values(currentValues).some(Boolean);

              const isEmptyQueryString = !window.location.search;

              return (
                <>
                  <Button
                    htmlType="button"
                    disabled={isEmptyQueryString && isEmptyCurrentValues}
                    onClick={() => {
                      const currentValues = getFieldsValue();
                      const erased = Object.fromEntries(
                        Object.keys(currentValues).map((key) => [key, null])
                      );
                      setFieldsValue(erased);
                      onChange({}, `?${qs.stringify({})}`);
                    }}
                    className={s.btnSecondary}
                  >
                    Reset
                  </Button>

                  <Button
                    className={s.btnPrimary}
                    htmlType="submit"
                    disabled={loading}
                  >
                    Search
                  </Button>
                </>
              );
            }}
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

export default FilterForm;
