import { useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import { connect } from "react-redux";
import { Button, Input, Modal, Spin, Select, notification } from "antd";

import { useStores } from "store/mobx/useStore";
import { getRolesAsync, getUsersAsync } from "store/actions";
import { deleteUser } from "../../utils/api";
import { TStateFields } from "./types";
import api from "../../utils/api";
import Table from "./table/Table";

import s from "./Users.module.scss";
import { hasPerm, Perms } from "../../constants/Perms";

const { confirm } = Modal;
const { Option } = Select;

export const defaultStateFields = {
  create: true,
  login: "",
  name: "",
  password: "",
  role_id: undefined,
};

const Users = observer((props: any) => {
  const { getUsers, getRoles, users, roles } = props;

  const {
    userStore: { user },
  } = useStores();

  const [fields, setFields] = useState<TStateFields>(defaultStateFields);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    getUsers();
    getRoles();
  }, [getRoles, getUsers]);

  const showButton = hasPerm(user, Perms.USERS_ANY);

  const deleteUserId = (id: string) => {
    deleteUser(id)
      .then(() => {
        getUsers();
      })
      .catch(() => {
        notification.warning({
          message: "Something went wrong. Please try again later.",
          duration: 3,
        });
      });
  };

  const showConfirm = (name: string, id: string) => {
    confirm({
      title: `Delete ${name}?`,
      onOk: () => deleteUserId(id),
    });
  };

  const handleOk = () => {
    setLoading(true);
    if (fields.create) {
      createUser();
    } else {
      editUser();
    }
  };

  const success = () => {
    getUsers();
    setLoading(false);
    setIsVisible(false);
  };

  const fail = (err: any) => {
    const { data, status } = err.response;
    if (data && status === 400) {
      const { errors } = data;
      let str = "";
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(errors)) {
        str = `${str} ${value}`;
      }
      notification.warning({ message: str, duration: 3 });
    } else {
      notification.warning({
        message: "Something went wrong. Please try again later.",
        duration: 3,
      });
    }
    setLoading(false);
  };

  const createUser = () => {
    const values = {
      login: fields.login,
      name: fields.name,
      password: fields.password,
      role_id: fields.role_id,
    };
    api({ method: "POST", url: "/api/v1/users", data: values })
      .then(() => {
        success();
      })
      .catch((err) => {
        fail(err);
      });
  };

  const editUser = () => {
    const findUser = users.list.find((el: any) => el.id === fields.id);
    let values: any = {};
    if (fields.name !== findUser.name) {
      values.name = fields.name;
    }
    if (fields.login !== findUser.login) {
      values.login = fields.login;
    }
    if (fields.role_id !== findUser.role_id) {
      values.role_id = fields.role_id;
    }
    if (fields.password) {
      values.password = fields.password;
    }
    api({ method: "PUT", url: `/api/v1/users/${fields.id}`, data: values })
      .then(() => {
        success();
      })
      .catch((err) => {
        fail(err);
      });
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const validate = (): boolean => {
    const { password, role_id, login, name, create } = fields;
    if (create) {
      return !(password && role_id && login && name);
    } else {
      return !(login && name && role_id);
    }
  };

  const onChangeField = (value: string, field: string) => {
    setFields({
      ...fields,
      [field]: value,
    });
  };

  const create = () => {
    setFields(defaultStateFields);
    setIsVisible(true);
  };

  const edit = (values: TStateFields) => {
    setFields(values);
    setIsVisible(true);
  };

  const getDescription = (id: number) => {
    const find = roles.list.find((el: any) => el.id === id);
    if (find) {
      return find.description;
    }
    return null;
  };

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.header}>
          <div className={s.headerInfo}>
            <div className={s.title}>User management</div>
            <div className={s.info}>
              Your CR instance is configured using authorized access. <br />
              Use this screen to set up user roles.
            </div>
          </div>
          {showButton && (
            <Button type="primary" className={s.create} onClick={create}>
              Create user
            </Button>
          )}
        </div>
        {users.loading && roles.loading ? (
          <div className={s.loader}>
            <Spin />
          </div>
        ) : (
          <Table
            users={users.list}
            roles={roles.list}
            edit={edit}
            showButton={showButton}
            showConfirm={showConfirm}
          />
        )}
      </div>
      <Modal
        title={fields.create ? "Create user" : "Edit user"}
        visible={isVisible}
        onOk={handleOk}
        closable={false}
        centered
        okText={fields.create ? "Create" : "Save"}
        okButtonProps={{
          disabled: validate(),
          loading: loading,
        }}
        onCancel={handleCancel}
        className={s.modalCreate}
      >
        <div className={s.form}>
          <p className={s.label}>Provide USER INFORMATION</p>
          <div className={`${s.full} ${s.nameField}`}>
            <Input
              value={fields.name}
              placeholder="Full name"
              onChange={(e) => onChangeField(e.target.value, "name")}
            />
          </div>
          <div className={s.half}>
            <Input
              value={fields.login}
              placeholder="Login / email"
              onChange={(e) => onChangeField(e.target.value, "login")}
            />
            <Input.Password
              value={fields.password}
              placeholder={fields.create ? "Password" : "••••••••"}
              onChange={(e) => onChangeField(e.target.value, "password")}
            />
          </div>
          <div className={s.select}>
            <p className={s.label}>SELECT USER ROLE</p>
            <Select
              value={fields.role_id}
              placeholder="Role"
              onChange={(e: any) => onChangeField(e, "role_id")}
            >
              {roles.list.map((el: any, index: number) => (
                <Option key={index} value={el?.id}>
                  {el?.name}
                </Option>
              ))}
            </Select>
            {fields.role_id && (
              <p className={s.description}>{getDescription(fields.role_id)}</p>
            )}
          </div>
        </div>

        <div></div>
      </Modal>
    </div>
  );
});

const mapStateToProps = (state: any) => {
  return {
    users: state.users,
    roles: state.roles,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUsers: () => dispatch(getUsersAsync()),
    getRoles: () => dispatch(getRolesAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
