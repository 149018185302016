import { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Spin } from "antd";

import FlagIcon from "components/flag/FlagIcon";
import { useStores } from "store/mobx/useStore";
import { dateCustom, getDurationString } from "utils/dates";
import { getTerminatedCallByLabel } from "utils/api/abh-call-registry";
import { URLS } from "constants/Urls";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";

import type { TerminatedCall } from "store/mobx/types";
import type { TNetwork } from "store/mobx/types";

import s from "./index.module.scss";

const RejectedCallDetails = observer(() => {
  const history = useHistory<{ call?: TerminatedCall; search?: string }>();
  const { callLabel } = useParams<{ callLabel?: string }>();

  const {
    referenceStore: { reference },
  } = useStores();

  const [state, setState] = useState<null | TerminatedCall>(
    history.location.state?.call || null
  );

  const [error, setError] = useState(false);

  useEffect(() => {
    let isCanceled = false;

    if (callLabel) {
      const fetchData = async () => {
        try {
          const response = await getTerminatedCallByLabel(callLabel);
          if (!isCanceled) setState(response);
        } catch (err) {
          if (!isCanceled) setError(true);
          console.warn("[Error] getTerminatedCallByLabel: ", err);
        }
      };

      fetchData();
    }

    return () => {
      isCanceled = true;
    };
  }, [callLabel]);

  const { networks } = reference;

  const { aNetwork, bNetwork } = networks.reduce<{
    aNetwork?: TNetwork;
    bNetwork?: TNetwork;
  }>((acc, network) => {
    if (network.id === state?.a_network_id) {
      acc.aNetwork = network;
    }

    if (network.id === state?.b_network_id) {
      acc.bNetwork = network;
    }

    return acc;
  }, {});

  const terminatedAfter =
    state && getDurationString(state.started_at, state.terminated_at);
  const endedAfter =
    state && getDurationString(state.started_at, state.ended_at);

  return (
    <div className={s.container}>
      <div className={s.head}>
        <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
          Call #{callLabel}
          <Link
            to={{
              pathname: URLS.rejectedCalls,
              search: history.location.state?.search,
            }}
            className={s.mb8}
          >
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>
      </div>

      {error ? (
        <div className={s.noData}>Not Found</div>
      ) : !state ? (
        <Spin spinning />
      ) : (
        <div className={s.details}>
          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>Ditrection</h2>
            <div>{state.direction}</div>
          </div>

          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>A Number</h2>
            <div className={s.row}>
              <FlagIcon country={aNetwork?.country_iso || "N/A"} />
              {state.a_number}
            </div>
          </div>

          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>B Number</h2>
            <div className={s.row}>
              <FlagIcon country={bNetwork?.country_iso || "N/A"} />
              {state.b_number}
            </div>
          </div>

          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>Origin Network</h2>
            <div>{aNetwork?.name}</div>
          </div>

          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>Destination Network</h2>
            <div>{bNetwork?.name || "N/A"}</div>
          </div>

          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>Started at</h2>
            <div>
              {dateCustom(state.started_at).format("YYYY-MM-DD HH:mm:ss")}
            </div>
          </div>

          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>Terminated after</h2>
            <div>{terminatedAfter || "N/A"}</div>
          </div>

          <div className={s.detailsItem}>
            <h2 className={s.fontSubtitle}>Ended after</h2>
            <div>{endedAfter || "N/A"}</div>
          </div>
        </div>
      )}
    </div>
  );
});

export default RejectedCallDetails;
