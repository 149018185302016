import { observer } from "mobx-react";
import { Radio } from "antd";

import { useStores } from "../../../store/mobx/useStore";
import { callsType } from "../../../utils/constants";

const FilterCallsChart = observer(() => {
  const {
    statisticsStore: { filterType, setFilterType },
  } = useStores();

  return (
    <div>
      <Radio.Group
        value={filterType}
        buttonStyle="solid"
        onChange={(e) => setFilterType(e.target.value)}
      >
        <Radio.Button value={callsType.international}>
          International
        </Radio.Button>

        <Radio.Button value={callsType.validated}>Validated</Radio.Button>

        <Radio.Button value={callsType.domestic}>National</Radio.Button>
      </Radio.Group>
    </div>
  );
});

export default FilterCallsChart;
