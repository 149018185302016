import React, {FC} from "react";
import {FRAUD_TYPES} from "../../../../utils/constants";
import {TFraudDetail, TNetwork} from "../../../../store/mobx/types";

type Props = {
  data: TFraudDetail,
  networks: TNetwork[],
  fraudTypes?: Array<any>
}

export const FraudCallDescription: FC<Props> = ({data, fraudTypes, networks}) => {

  let description = fraudTypes?.find((t) => t.value === data.fraud_type)?.description;

  const getNetworkName = (netId?: number) => {
    const item = networks.find((el) => el.id === netId);
    return item ? item.name : '-'
  }

  const originNet = <strong>{getNetworkName(data.carried_call?.origin_network_id)}</strong>;
  const remoteOriginNet = <strong>{getNetworkName(data.remote_call?.origin_network_id)}</strong>;
  const destNet = <strong>{getNetworkName(data.carried_call?.destination_network_id)}</strong>;
  const remoteDestNet = <strong>{getNetworkName(data.remote_call?.destination_network_id)}</strong>;
  const aNumber = <strong>{data.carried_call?.a_number}</strong>;
  const bNumber = <strong>{data.carried_call?.b_number}</strong>;
  const remoteANumber = <strong>{data.remote_call?.a_number}</strong>;
  const remoteBNumber = <strong>{data.remote_call?.b_number}</strong>;
  const durationDiff =
    data.carried_call?.ended_at && data.carried_call?.remote_ended_at ?
      Math.round((data.carried_call?.ended_at - data.carried_call?.remote_ended_at) / 1000) : 0;

  const Separator = () => (<div style={{height: 10}}/>);

  const Space = () => <>{` `}</>;

  switch (data.fraud_type) {
    // eslint-disable-next-line no-lone-blocks
    case FRAUD_TYPES.UNCONFIRMED: {
      if (!data.outbound) {
        return <>
          Сall from {originNet} A-number {aNumber} to {destNet} B-number {bNumber} has reached terminating equipment,<Space/>
          but call origination is not confirmed by {originNet}.
        </>
      }
    }
      break;
    case FRAUD_TYPES.CLI: {
      if (data.outbound)
        return <>
          Call originated from {originNet} A-number {aNumber} to {destNet} B-number {bNumber}, but call termination is<Space/>
          not confirmed by {destNet}. <Separator/> At the same time {destNet} reports a call to B-number {bNumber} from<Space/>
          an A-number belonging to another network.<Separator/> The original A-number of {originNet} was probably<Space/>
          replaced.
        </>
      else return <>
        Call from {originNet} A-number {aNumber} to {destNet} B-number {bNumber} <Space/>
        has reached terminating equipment, but call origination is not confirmed by {originNet}.<Separator/>
        At the same time {remoteOriginNet} reports a call from A-number {remoteANumber} to {remoteDestNet}<Space/>
        B-number {remoteBNumber}, but the call is not present on the terminating switching equipment.<Separator/>
        The original A-number of {remoteOriginNet} was probably replaced with<Space/>
        A-number of {originNet}: {remoteANumber} to {aNumber}.
      </>
    }
    case FRAUD_TYPES.FAS: {
      if (data.outbound)
        return <>
          Сall originated from {originNet} A-number {aNumber} to {destNet} B-number {bNumber},<Space/>
          but the call is not present on the terminating switching equipment.
        </>
      else return <>
        {remoteOriginNet} reports a call from A-number {remoteANumber} to {remoteDestNet} B-number {remoteBNumber},<Space/>
        but the call is not present on the terminating switching equipment.
      </>
    }
    case FRAUD_TYPES.STRETCH: {
      if (data.outbound)
        return <>
          Call originated from {originNet} A-number {aNumber} to {destNet} B-number {bNumber}.<Space/>
          Call was set up correctly, but the terminating network {destNet} reports that it ended<Space/>
          the call {durationDiff} seconds earlier than your switching equipment has received the end event for this<Space/>
          call.<Separator/>
          The call was probably stretched.
        </>
      else return <>{description}</>
    }
    case FRAUD_TYPES.PBX: {
      if (data.outbound)
        return <>
          Call from {originNet} A-number {aNumber} to {destNet} B-number {bNumber}<Space/>
          originated and was successfully validated.<Separator/>
          At the same time {destNet} reports that it has received a notification from<Space/>
          another network about a call to the same B-number {bNumber}<Space/>
          that is not present on the terminating switching equipment.<Separator/>
          {originNet} equipment possibly hacked and the call was routed through it.
        </>
      else return <>
        Сall from {originNet} A-number {aNumber} to {destNet} B-number {bNumber}<Space/>
        terminated and successfully validated.<Separator/>
        At the same time {remoteOriginNet} reports a call from its A-number {remoteANumber} to<Space/>
        the same {remoteDestNet} B-number {remoteBNumber}, but call is not present<Space/>
        on the terminating switching equipment.<Separator/>
        {originNet} equipment possibly hacked.
      </>
    }
    case FRAUD_TYPES.BYPASS: {
      if (data.outbound)
        return <>
          Call from {originNet} A-number {aNumber} to {destNet} B-number {bNumber}<Space/>
          originated, but is not present on the terminating switching equipment.<Separator/>
          At the same time {destNet} reports the termination of a domestic call<Space/>
          to the same B-number {bNumber} within the same time window.<Separator/>
          The call was probably rerouted to bypass the international interconnect.<Space/>
        </>
      else return <>
        Domestic call from {originNet} A-number {aNumber} to {destNet} B-number {bNumber}<Space/>
        terminated on switching equipment.<Separator/>
        At the same time {remoteOriginNet} reports a call from<Space/>
        A-number {remoteANumber} to {remoteDestNet} B-number {remoteBNumber},<Space/>
        but it is not present on the terminating switching equipment.<Separator/>
        The call was probably rerouted to bypass the international interconnect.<Space/>
      </>
    }

  }

  return <>{description}</>;

}

