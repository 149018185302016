import React from "react";

import {ReactComponent as LogoABH} from "../assets/svg/logoWhite.svg";
import {ReactComponent as LogoCV} from "../assets/svg/logoCV.svg";

const host =  window.location.host;

export const site = {
  theme: 'default',
  title: "AB Handshake Control CentreCR",
  logoCaption: "Control Centre",
  logo: <LogoABH/>,
}


if (host.includes('cv.qntk')) {
  site.theme = 'whitelabel';
  site.title = 'Call Validation Tool - Control Centre';
  site.logoCaption = "Control Centre"
  site.logo = <LogoCV/>;
}

document.documentElement.setAttribute('data-theme', site.theme)
document.title = `${site.title}`;

