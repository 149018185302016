import api from "../index";
import { IReport, IReportBodyGenerate } from "../../../interfaces/IReports";

export const getReports = () =>
  api<IReport[]>({
    method: "GET",
    url: `/api/v1/reports/tasks`,
  });

export const updateReport = (id: number | string, body: IReport) =>
  api<void>({
    method: "PUT",
    url: `/api/v1/reports/tasks/${id}`,
    data: {
      ...body,
    },
  });

export const updatePredefinedReport = (
  id: number | string,
  body: Pick<IReport, "enabled" | "notification_channel_ids">
) =>
  api<void>({
    method: "PATCH",
    url: `/api/v1/reports/tasks/${id}`,
    data: {
      ...body,
    },
  });

export const sendReport = (id: number | string) =>
  api<void>({
    method: "POST",
    url: `/api/v1/reports/tasks/${id}/execute`,
  });

export const getCurrentReport = (id: string | number) =>
  api<IReport>({
    method: "GET",
    url: `/api/v1/reports/tasks/${id}`,
  });

export const generateReport = (body: IReportBodyGenerate) =>
  api<void>({
    method: "POST",
    url: `/api/v1/reports/generate`,
    data: {
      ...body,
    },
  });

export const createReport = (body: Partial<IReport>) =>
  api<void>({
    method: "POST",
    url: "/api/v1/reports/tasks",
    data: {
      ...body,
    },
  });

export const deleteReport = (id: string | number) =>
  api<void>({ method: "DELETE", url: `/api/v1/reports/tasks/${id}` });
