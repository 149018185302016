import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import merge from "lodash/merge";
import { makeAutoObservable, runInAction, action, toJS } from "mobx";

import { stringToRuleActionBooleans } from "utils/ruleActions";

import {
  getTcgRuleSettings,
  createUnallocatedPrsRule,
  updateUnallocatedPrsRule,
  deleteUnallocatedPrsRule,
  getSipRejectCodes,
  getUnallocatedPrsRules,
  getPrefixLists,
  getPrefixListById,
  updatePrefixList,
  createPrefixList,
  deletePrefixList,
  getPrefixListUsage,
  getPrefixListValues,
  getPrefixListRules,
  updatePrefixListRule,
  patchPrefixListRule,
  createPrefixListRule,
  deletePrefixListRule,
  prefixListValuesDeleteBatch,
  prefixListValuesAddCsv,
  prefixListValuesAddBatch,
  patchTcgRuleSettings,
  patchUnallocatedPrsRule,
} from "utils/api";

import {
  getLocationFalsificationRuleSettings,
  updateLocationFalsificationRuleSettings,
  patchLocationFalsificationRuleSettings,
  getFlashcallProtectionRuleSettings,
  patchFlashcallProtectionRuleSettings,
  updateFlashcallProtectionRuleSettings,
} from "utils/api/abh-call-registry";

import type {
  TcgRuleSettings,
  LocationFalsificationRuleSettings,
  LocationFalsificationRuleState,
  PredefinedRuleUpdateBody,
  TcgRuleState,
  UnallocatedPrsRule,
  SipRejectCode,
  SipRejectCodesState,
  PrefixListsState,
  PrefixList,
  PrfixListValuesForm,
  PrefixListRule,
  PrefixListRulesState,
  PrefixListExtended,
  FlashcallProtectionState,
  FlashcallProtectionRuleSettings,
  PredefinedRuleFlashCallUpdateBody,
} from "store/mobx/types";

import type { RootStoreType } from "./rootStore";

type Settings = {
  tcg: TcgRuleState;
  locationFalsification: LocationFalsificationRuleState;
  flashcallProtection: FlashcallProtectionState;
};

type UnallocatedPrsState = {
  isLoading: boolean;
  params: {
    page: number;
    // page_size: number;
  };
  data: UnallocatedPrsRule[];
};

export class FirewallStore {
  rootStore: RootStoreType;

  settings: Settings = {
    tcg: {
      isLoading: true,
    },
    locationFalsification: {
      isLoading: true,
    },
    flashcallProtection: {
      isLoading: false,
    },
  };

  unallocatedPrsRules: UnallocatedPrsState = {
    isLoading: true,
    params: {
      page: 1,
      // page_size: 20,
    },
    data: [],
  };

  sipRejectCodes: SipRejectCodesState = {
    data: [],
    isLoading: true,
  };

  prefixLists: PrefixListsState = {
    isLoading: true,
    hasNextPage: true,
    params: {},
    data: [],
  };

  prefixListRules: PrefixListRulesState = {
    isLoading: true,
    params: { page: 1 },
    data: [],
  };

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  get prefixListsMap() {
    return this.prefixLists.data.reduce<Record<string, PrefixListExtended>>(
      (acc, list) => {
        return { ...acc, [list.id]: list };
      },
      {}
    );
  }

  setTcgRuleSettings = (settings?: TcgRuleSettings) => {
    this.settings.tcg.settings = settings;
    this.settings.tcg.isLoading = false;
  };

  setLocationFalsificationRuleSettings = (
    newSettings?: LocationFalsificationRuleSettings
  ) => {
    const currentSettings = this.settings.locationFalsification.settings;

    // just optimization. Avoid re-rendering if state is the same
    if (!isEqual(currentSettings, newSettings)) {
      this.settings.locationFalsification.settings = newSettings;
    }
    this.settings.locationFalsification.isLoading = false;
  };

  setFlashcallProtectionRuleSettings = (
    newSettings?: FlashcallProtectionRuleSettings
  ) => {
    const currentSettings = this.settings.flashcallProtection.settings;

    // just optimization. Avoid re-rendering if state is the same
    if (!isEqual(currentSettings, newSettings)) {
      this.settings.flashcallProtection.settings = newSettings;
    }
    this.settings.flashcallProtection.isLoading = false;
  };

  setUnallocatedPrsRules = (rules: UnallocatedPrsRule[]) => {
    this.unallocatedPrsRules.data = rules;
    this.unallocatedPrsRules.isLoading = false;
  };

  setSipRejectCodes = (newCodes: SipRejectCode[]) => {
    const currentCodes = this.sipRejectCodes.data;

    // just optimization. Avoid re-rendering if state is the same
    if (!isEqual(currentCodes, newCodes)) {
      this.sipRejectCodes.data = newCodes;
    }

    this.sipRejectCodes.isLoading = false;
  };

  setPrefixLists = (newLists: PrefixList[]) => {
    this.prefixLists.data = newLists;
    this.prefixLists.isLoading = false;
  };

  prefixListsSetHasNextPage = (value: boolean) => {
    this.prefixLists.hasNextPage = value;
  };

  addUpdatePrefixList = (prefixList: PrefixList) => {};

  setPrefixListsLoading = (value: boolean) => {
    this.prefixLists.isLoading = value;
  };

  setPrefixListRules = (rules: PrefixListRule[]) => {
    this.prefixListRules.data = rules;
    this.prefixListRules.isLoading = false;
  };

  getTcgRuleSettings = async () => {
    try {
      const data = await getTcgRuleSettings();
      this.setTcgRuleSettings(data);
    } catch (err) {
      console.warn("[ERROR] getTcgRuleSettings: ", err);
    }
  };

  getLocationFalsificationRuleSettings = async () => {
    try {
      const data = await getLocationFalsificationRuleSettings();
      this.setLocationFalsificationRuleSettings(data);
    } catch (err) {
      console.warn("[ERROR] getLocationFalsificationRuleSettings: ", err);
    }
  };

  getFlashcallProtectionRuleSettings = async () => {
    try {
      const data = await getFlashcallProtectionRuleSettings();
      this.setFlashcallProtectionRuleSettings(data);
    } catch (err) {
      console.warn("[ERROR] getFlashcallProtectionRuleSettings: ", err);
    }
  };

  getUnallocatedPrsRules = async () => {
    try {
      const data = await getUnallocatedPrsRules(
        this.unallocatedPrsRules.params
      );
      this.setUnallocatedPrsRules(data);
    } catch (err) {
      console.warn("[ERROR] getUnallocatedPrsRules: ", err);
    }
  };

  createUnallocatedPrsRule = async (data: Partial<UnallocatedPrsRule>) => {
    const newRule = await createUnallocatedPrsRule(data);

    const { alert, block } = stringToRuleActionBooleans(newRule.action);

    runInAction(() => {
      this.unallocatedPrsRules.data = [
        newRule,
        ...this.unallocatedPrsRules.data,
      ];

      this.rootStore.referenceStore.callControlRules = [
        ...this.rootStore.referenceStore.callControlRules,
        {
          id: newRule.id,
          alert: alert,
          block: block,
          enabled: newRule.enabled,
          name: newRule.name,
          notification_channel_ids: newRule.notification_channel_ids,
          threshold: newRule.threshold_numeric,
          type: newRule.type,
        },
      ];
    });
  };

  updateUnallocatedPrsRule = async (
    id: number,
    data: Partial<UnallocatedPrsRule>
  ) => {
    const updatedRule = await updateUnallocatedPrsRule(id, data);

    const { alert, block } = stringToRuleActionBooleans(updatedRule.action);

    runInAction(() => {
      this.unallocatedPrsRules.data = this.unallocatedPrsRules.data.map(
        (rule) => (rule.id === updatedRule.id ? updatedRule : rule)
      );

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore.callControlRules.map((item) =>
          item.type === updatedRule.type && item.id === id
            ? {
                id: item.id,
                alert: alert,
                block: block,
                enabled: updatedRule.enabled,
                name: updatedRule.name,
                notification_channel_ids: updatedRule.notification_channel_ids,
                threshold: updatedRule.threshold_numeric,
                type: updatedRule.type,
              }
            : item
        );
    });
  };

  patchUnallocatedPrsRule = async (
    id: number,
    data: Partial<UnallocatedPrsRule>
  ) => {
    const updatedRule = await patchUnallocatedPrsRule(id, data);

    const { alert, block } = stringToRuleActionBooleans(updatedRule.action);

    runInAction(() => {
      this.unallocatedPrsRules.data = this.unallocatedPrsRules.data.map(
        (rule) => (rule.id === updatedRule.id ? updatedRule : rule)
      );

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore.callControlRules.map((item) =>
          item.type === updatedRule.type && item.id === id
            ? {
                id: item.id,
                alert: alert,
                block: block,
                enabled: updatedRule.enabled,
                name: updatedRule.name,
                notification_channel_ids: updatedRule.notification_channel_ids,
                threshold: updatedRule.threshold_numeric,
                type: updatedRule.type,
              }
            : item
        );
    });
  };

  deleteUnallocatedPrsRule = async (id: number) => {
    await deleteUnallocatedPrsRule(id);

    runInAction(() => {
      this.unallocatedPrsRules.data = this.unallocatedPrsRules.data.filter(
        (rule) => rule.id !== id
      );

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore?.callControlRules.filter(
          (item) => !(item.type === "prs" && item.id === id)
        );
    });
  };

  patchTcgRuleSettings = async (data: PredefinedRuleUpdateBody) => {
    const prevSettingsValue = this.settings.tcg.settings;

    const newSettings = prevSettingsValue && {
      ...prevSettingsValue,
      ...data,
    };

    this.setTcgRuleSettings(newSettings);

    try {
      const responseData = await patchTcgRuleSettings(data);
      this.setTcgRuleSettings(responseData);
    } catch (err) {
      this.setTcgRuleSettings(prevSettingsValue);
      throw err;
    }
  };

  updateLocationFalsificationRuleSettings = async (
    data: PredefinedRuleUpdateBody
  ) => {
    const prevSettingsValue = this.settings.locationFalsification.settings;

    const newSettings = prevSettingsValue && {
      ...prevSettingsValue,
      ...data,
    };

    this.setLocationFalsificationRuleSettings(newSettings);

    try {
      const responseData = await updateLocationFalsificationRuleSettings(data);
      this.setLocationFalsificationRuleSettings(responseData);
    } catch (err) {
      this.setLocationFalsificationRuleSettings(prevSettingsValue);
      throw err;
    }
  };

  updateFlashcallProtectionRuleSettings = async (
    data: PredefinedRuleFlashCallUpdateBody
  ) => {
    const prevSettingsValue = this.settings.flashcallProtection.settings;

    const newSettings = prevSettingsValue && {
      ...prevSettingsValue,
      ...data,
    };

    this.setFlashcallProtectionRuleSettings(newSettings);

    try {
      const responseData = await updateFlashcallProtectionRuleSettings(data);
      this.setFlashcallProtectionRuleSettings(responseData);
    } catch (err) {
      this.setFlashcallProtectionRuleSettings(prevSettingsValue);
      throw err;
    }
  };

  patchLocationFalsificationRuleSettings = async (
    data: PredefinedRuleUpdateBody
  ) => {
    const prevSettingsValue = this.settings.locationFalsification.settings;

    const newSettings = prevSettingsValue && {
      ...prevSettingsValue,
      ...data,
    };

    this.setLocationFalsificationRuleSettings(newSettings);

    try {
      const responseData = await patchLocationFalsificationRuleSettings(data);
      this.setLocationFalsificationRuleSettings(responseData);
    } catch (err) {
      this.setLocationFalsificationRuleSettings(prevSettingsValue);
      throw err;
    }
  };

  patchFlashcallProtectionRuleSettings = async (
    data: PredefinedRuleUpdateBody
  ) => {
    const prevSettingsValue = this.settings.flashcallProtection.settings;

    const newSettings = prevSettingsValue && {
      ...prevSettingsValue,
      ...data,
    };

    this.setFlashcallProtectionRuleSettings(newSettings);

    try {
      const responseData = await patchFlashcallProtectionRuleSettings(data);
      this.setFlashcallProtectionRuleSettings(responseData);
    } catch (err) {
      this.setFlashcallProtectionRuleSettings(prevSettingsValue);
      throw err;
    }
  };

  getSipRejectCodes = async () => {
    try {
      const data = await getSipRejectCodes();
      this.setSipRejectCodes(data);
    } catch (err) {
      console.warn("[ERROR] getSipRejectCodes: ", err);
    }
  };

  // getPrefixListById = async (id: string) => {
  //     const data = await getPrefixListById(id);
  //     console.log({ data });
  //     // this.setPrefixLists(data);

  // };

  getPrefixLists = debounce(
    action(
      async (
        errorHandler?: (err: unknown) => void,
        params?: PrefixListsState["params"]
      ) => {
        // omit page from comparison
        const { page: nextPage = 1, ...rest } = params || {};

        const currentParams = this.prefixLists.params;
        const newParams = {
          ...currentParams,
          ...rest,
        };

        const isEqualQueries = isEqual(currentParams, newParams);

        if (isEqualQueries) {
          // Queries are equal => do not reset data

          // Check if it's a page bump up?
          const isNextPageRequest = nextPage > Number(currentParams.page);

          let resultRequestParams: PrefixListsState["params"];

          if (isNextPageRequest) {
            this.prefixLists.params.page = nextPage;
            resultRequestParams = this.prefixLists.params;
          } else {
            const isEmptyStore = this.prefixLists.data.length === 0;
            // fetch silently
            if (!isEmptyStore) {
              this.prefixLists.isLoading = false;
            }

            // TODO move to initial params
            const { page: currentPage = 1, page_size: currentPageSize = 20 } =
              this.prefixLists.params;
            const newPageSize = Number(currentPage) * Number(currentPageSize);

            resultRequestParams = {
              ...this.prefixLists.params,
              page: 1,
              // re-fetch all the data at once
              page_size: newPageSize,
            };
          }

          try {
            const result = await getPrefixLists(resultRequestParams);

            const hasNextPage =
              Number(resultRequestParams.page_size) <= result.length;

            this.setPrefixLists(
              isNextPageRequest
                ? [...this.prefixLists.data, ...result]
                : // just merge fresh data with the current one
                  [...merge(toJS(this.prefixLists.data), result)]
            );

            this.prefixListsSetHasNextPage(hasNextPage);
            // this.setPrefixListsLoading(false);
          } catch (err) {
            console.warn("[ERROR] getTerminatedCalls: ", err);
            errorHandler?.(err);
          }
        } else {
          // Queries are NOT equal => do override data

          // store new params before fetch
          this.prefixLists.params = {
            ...this.prefixLists.params,
            ...params,
          };

          try {
            const result = await getPrefixLists(this.prefixLists.params);

            const hasNextPage =
              Number(this.prefixLists.params.page_size) <= result.length;

            this.setPrefixLists(result);
            this.prefixListsSetHasNextPage(hasNextPage);
          } catch (err) {
            console.warn("[ERROR] getPrefixLists: ", err);
            errorHandler?.(err);
          }
        }
      }
    ),
    700
  );

  getPrefixListById = async (id: string) => {
    const currentList = await getPrefixListById(id);

    runInAction(() => {
      const filteredList = this.prefixLists.data.filter(
        (list) => list.id !== currentList.id
      );

      this.prefixLists.data = [...filteredList, currentList];
    });
  };

  updatePrefixList = async (id: number, data: Partial<PrefixList>) => {
    const updatedPrefixList = await updatePrefixList(id, data);

    runInAction(() => {
      this.prefixLists.data = this.prefixLists.data.map((list) =>
        list.id === updatedPrefixList.id
          ? { ...list, ...updatedPrefixList } // merge here to keep list.values prop
          : list
      );
    });
  };

  createPrefixList = async (data: PrefixList) => {
    const newList = await createPrefixList(data);

    runInAction(() => {
      this.prefixLists.data = [...this.prefixLists.data, newList];
    });
  };

  createPrefixListRule = async (data: PrefixListRule) => {
    const newRule = await createPrefixListRule(data);

    runInAction(() => {
      this.prefixListRules.data = [newRule, ...this.prefixListRules.data];

      const { alert, block } = stringToRuleActionBooleans(newRule.action);

      this.rootStore.referenceStore.callControlRules = [
        ...this.rootStore.referenceStore.callControlRules,
        {
          id: newRule.id,
          name: newRule.name,
          type: "prefixlist",
          notification_channel_ids: newRule.notification_channel_ids || [],
          threshold: newRule.threshold_numeric,
          alert,
          block,
          enabled: newRule.enabled,
        },
      ];
    });
  };

  updatePrefixListRule = async (id: number, data: Partial<PrefixListRule>) => {
    const updatedPrefixListRule = await updatePrefixListRule(id, data);

    runInAction(() => {
      this.prefixListRules.data = this.prefixListRules.data.map((rule) =>
        rule.id === updatedPrefixListRule.id ? updatedPrefixListRule : rule
      );

      const { alert, block } = stringToRuleActionBooleans(
        updatedPrefixListRule.action
      );

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore.callControlRules.map((rule) =>
          rule.type === "prefixlist" && rule.id === updatedPrefixListRule.id
            ? {
                ...rule,
                name: updatedPrefixListRule.name,
                type: "prefixlist",
                notification_channel_ids:
                  updatedPrefixListRule.notification_channel_ids || [],
                threshold: updatedPrefixListRule.threshold_numeric,
                alert,
                block,
                enabled: updatedPrefixListRule.enabled,
              }
            : rule
        );
    });
  };

  deletePrefixList = async (id: number) => {
    await deletePrefixList(id);

    runInAction(() => {
      this.prefixLists.data = this.prefixLists.data.filter(
        (list) => list.id !== id
      );
    });
  };

  // Get PrefixList Usage and Values
  expandPrefixList = async (id: string) => {
    const isListExist = this.prefixLists.data.find(
      (list) => list.id === Number(id)
    );

    if (!isListExist) {
      await this.getPrefixListById(id);
    }

    const pendingUsage = getPrefixListUsage(id);
    const pendingValues = getPrefixListValues(id);

    const [usageResult, valuesResult] = await Promise.allSettled([
      pendingUsage,
      pendingValues,
    ]);

    const usage =
      usageResult.status === "fulfilled" ? usageResult.value : undefined;
    const values =
      valuesResult.status === "fulfilled" ? valuesResult.value : undefined;

    runInAction(() => {
      this.prefixLists.data = this.prefixLists.data.map((list) =>
        list.id === Number(id)
          ? ({
              ...list,
              usage,
              values,
            } as PrefixListExtended)
          : list
      );
    });
  };

  prefixListValuesAddCsv = async (id: number, data: { file_path: string }) => {
    const newValues = await prefixListValuesAddCsv(id, data);

    runInAction(() => {
      this.prefixLists.data = this.prefixLists.data.map((list) => {
        if (list.id === id) {
          const newValuesSet = list.values
            ? list.values.reduce((acc, currentItem) => {
                const foundItem = acc.find(
                  (item) => item.value === currentItem.value
                );

                return foundItem ? acc : [...acc, currentItem];
              }, newValues)
            : newValues;

          return {
            ...list,
            values_count: newValuesSet.length,
            values: newValuesSet,
          };
        }

        return list;
      });
    });
  };

  prefixListValuesDelete = async (id: number, values: string[]) => {
    try {
      await prefixListValuesDeleteBatch(id, { values });

      runInAction(() => {
        this.prefixLists.data = this.prefixLists.data.map((list) =>
          list.id === id
            ? {
                ...list,
                values: list?.values?.filter(
                  (item) => !values.includes(item.value)
                ),
              }
            : list
        );
      });
    } catch (err) {
      console.warn("[ERROR] prefixListValuesDelete: ", err);
    }
  };

  // Add only one single value
  // prefixListValuesAdd = async (id: number, values: PrfixListValuesForm) => {
  //   const data = await createPrefixListValue(id, values);
  // };

  prefixListValuesAddBatch = async (id: number, data: PrfixListValuesForm) => {
    const newValues = await prefixListValuesAddBatch(id, data);

    runInAction(() => {
      this.prefixLists.data = this.prefixLists.data.map((list) => {
        if (list.id === id) {
          const newValuesSet = list.values
            ? list.values.reduce((acc, currentItem) => {
                const foundItem = acc.find(
                  (item) => item.value === currentItem.value
                );
                return foundItem ? acc : [...acc, currentItem];
              }, newValues)
            : newValues;

          return {
            ...list,
            values_count: newValuesSet.length,
            values: newValuesSet,
          };
        }

        return list;
      });
    });
  };

  getPrefixListRules = async () => {
    try {
      const data = await getPrefixListRules(this.prefixListRules.params);
      this.setPrefixListRules(data);
    } catch (err) {
      console.warn("[ERROR] getPrefixListRules: ", err);
    }
  };

  patchPrefixListRule = async (id: number, data: Partial<PrefixListRule>) => {
    const updatedRule = await patchPrefixListRule(id, data);

    runInAction(() => {
      this.prefixListRules.data = this.prefixListRules.data.map((rule) =>
        rule.id === updatedRule.id ? updatedRule : rule
      );
    });
  };

  deletePrefixListRule = async (id: number) => {
    await deletePrefixListRule(id);

    runInAction(() => {
      this.prefixListRules.data = this.prefixListRules.data.filter(
        (rule) => rule.id !== id
      );

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore.callControlRules.filter(
          (rule) => !(rule.type === "prefixlist" && rule.id === id)
        );
    });
  };
}
