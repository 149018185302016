import { Button } from "antd";

import { showConfirm } from "ui/Modals/confirm";

import { ReactComponent as IconDelete } from "assets/svg/delete.svg";

import s from "./index.module.scss";

type DeleteButtonProps = {
  loading: boolean;
  count: number;
  onOk: () => void;
};

export const DeleteButton = ({ loading, count, onOk }: DeleteButtonProps) => {
  const handleDelete = () =>
    showConfirm({
      title: "Delete Prefixes",
      content: (
        <>{`You’re deleting ${count} selected prefix${
          count > 1 ? "es" : ""
        }.`}</>
      ),
      okText: "Delete",
      okButtonProps: {
        className: s.btnDanger,
      },
      onOk: onOk,
    });

  return (
    <Button
      className={s.btnFlat}
      onClick={handleDelete}
      loading={loading}
      danger
    >
      <IconDelete width="2rem" />
      Delete
    </Button>
  );
};
