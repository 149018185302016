import api from "utils/api";

import type { TerminatedCallsParams, TerminatedCall } from "store/mobx/types";

export const getTerminatedCalls = (query: TerminatedCallsParams) =>
  api<TerminatedCall[]>({
    method: "GET",
    url: `/api/v1/terminated_calls`,
    params: query,
  });

export const getTerminatedCallByLabel = (label: string) =>
  api<TerminatedCall>({
    method: "GET",
    url: `/api/v1/terminated_calls/${label}`,
  });

type GenReportResponse = {
  id: number;
  created_at: number;
  status: "pending" | "in_progress" | "failed" | "done";
  name: string; // file name
  abs_path: string;
  my_network_id: number;
  direction: "unknown";
};

export const createTerminatedCallsReport = (
  data: Partial<TerminatedCallsParams>
) =>
  api<GenReportResponse>({
    method: "POST",
    url: "/api/v1/terminated_calls/reports",
    data,
  });

type GetReportResponse = {
  size: number;
  uri: string;
} & GenReportResponse;

export const getTerminatedCallsReport = (id: number) =>
  api<GetReportResponse>({
    method: "GET",
    url: `/api/v1/terminated_calls/reports/${id}`,
  });
