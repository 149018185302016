import { Button } from "antd";
import { useParams, Link } from "react-router-dom";
import { UIHelpers } from "@vas-common/ui-kit";

import { dateCustom, YYYY_MM_DD_TIME, isUtc } from "utils/dates";

import { URLS } from "constants/Urls";

import type {
  UnallocatedPrsRule,
  UnallocatedPrsRuleType,
} from "store/mobx/types";

import s from "./index.module.scss";

type StatisticProps = {
  statistic: Pick<
    UnallocatedPrsRule,
    "last_triggered_at" | "matched_today" | "matched_yesterday"
  >;
  ruleType?: UnallocatedPrsRuleType;
};

export const Statistic = ({ statistic, ruleType }: StatisticProps) => {
  const { id } = useParams<{ id?: string }>();

  const isTriggered = Boolean(statistic.last_triggered_at);

  return (
    <div className={`${s.statistic} ${!isTriggered ? s.notTriggered : ""}`}>
      <p className={s.fontBody}>
        {`${
          isTriggered
            ? `Last triggered at ${dateCustom(
                statistic.last_triggered_at
              ).format(YYYY_MM_DD_TIME)} ${isUtc() ? "UTC" : ""}`
            : "Not triggered yet"
        }${
          statistic.matched_today
            ? `, ${UIHelpers.formatNumber(
                statistic.matched_today
              )} calls affected today`
            : ""
        }${
          statistic.matched_yesterday
            ? `, ${UIHelpers.formatNumber(
                statistic.matched_yesterday
              )} calls affected yesterday`
            : ""
        }`}
      </p>

      {statistic.last_triggered_at ? (
        <Link
          to={{
            pathname: URLS.rejectedCalls,
            search: `rule_type=${ruleType}&rule_id=${id}`,
          }}
        >
          <Button className={s.btnPrimary}>View Rejected Calls</Button>
        </Link>
      ) : null}
    </div>
  );
};
