import { useCallback, useEffect, useMemo, useState } from "react";
import { Select, Table } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { DateRangePicker } from "@vas-common/ui-kit";
import { v4 } from "uuid";
import { observer } from "mobx-react";
import sortBy from "lodash/sortBy";

import tableColumns from "./TableColumns/tableColumns";
import expandedTableColumns, {
  fixedAntdTable,
} from "./TableColumns/expandedTableColumns";
import { useStores } from "../../../store/mobx/useStore";
import { TParticipantsFormatNetwork } from "../../../store/mobx/types";

import s from "./Participation.module.scss";
import { dateCustom } from "utils/dates";
import { DateRangePickerValue } from "@vas-common/ui-kit/dist/esm/types/components/DateRangePicker/types";

export const filterOptions = {
  all: "all",
  allParticipantsNetworks: "All participating networks",
};

export const Participants = observer(() => {
  const {
    statisticsStore: {
      getParticipantsAsync,
      setFormatParticipants,
      participantsData,
      participantsFormat,
      filtersParticipants,
      setFiltersParticipants,
    },
    referenceStore: { reference },
  } = useStores();

  const [filter, setFilter] = useState<string>(
    filterOptions.allParticipantsNetworks
  );

  const { loading: loadingParticipantsData } = participantsData;
  const { networks, loading: loadingReference } = reference;
  const { loading, list } = participantsFormat;
  const { from } = filtersParticipants;

  const handleChange = (value: DateRangePickerValue) => {
    if (value) {
      const [from] = typeof value === "string" ? JSON.parse(value) : value;
      setFiltersParticipants(from);
    }
  };

  const getLoad = useCallback(() => {
    if (!loading) {
      return loadingReference;
    }
    if (!loadingReference) {
      return loading;
    }
  }, [loading, loadingReference]);

  const isLoaded = useMemo(() => {
    return getLoad() === undefined ? true : getLoad();
  }, [getLoad]);

  useEffect(() => {
    getParticipantsAsync();
  }, [getParticipantsAsync, from]);

  useEffect(() => {
    if (!loadingParticipantsData && !loadingReference) {
      setFormatParticipants(networks);
    }
  }, [
    loadingParticipantsData,
    loadingReference,
    networks,
    setFormatParticipants,
  ]);

  const expandedTable = (records: TParticipantsFormatNetwork[]) => {
    return (
      <Table
        className={s.tableExpanded}
        rowKey={(record) => `${record.id} ${v4()}`}
        dataSource={records}
        showHeader={false}
        rowClassName="row-participants"
        columns={
          filter === filterOptions.allParticipantsNetworks
            ? fixedAntdTable
            : expandedTableColumns
        }
        pagination={false}
      />
    );
  };

  const data = useMemo(() => {
    const data =
      filter === filterOptions.allParticipantsNetworks
        ? list.filter((el) => {
            const { networks } = el;
            return networks.some(
              (network) => network.participantType !== "None"
            );
          })
        : list;
    return sortBy(data, "country");
  }, [filter, list]);

  const participantNetworks = list
    .filter((el) => el.nodes)
    .reduce((a, b) => a + b.participantsActive, 0);

  const totalNetworksTotal = list.reduce((a, b) => a + b.participantsTotal, 0);

  return (
    <div className={s.container}>
      <div className={s.mainContainer}>
        <div className={s.titleContainer}>
          <div className={s.title}>Participants</div>

          <div className={s.filterWrapper}>
            <div className={s.filter}>
              <Select
                className={s.selectNetworks}
                value={filter}
                onChange={(e: string) => setFilter(e)}
              >
                <Select.Option value={filterOptions.allParticipantsNetworks}>
                  Participating networks
                </Select.Option>
                <Select.Option value={filterOptions.all}>
                  All networks
                </Select.Option>
              </Select>

              <div className={s.description}>
                <strong>{participantNetworks}</strong> active
                participants,&nbsp;
                <strong>{totalNetworksTotal}</strong> networks total
              </div>
            </div>

            <div className={s.statistic}>
              <span>Statistics for</span>

              <DateRangePicker
                value={`[${from}, ${dateCustom().valueOf()}]`}
                onChange={handleChange}
                setOptions={["24h", "7d", "30d"]}
              />
            </div>
          </div>
        </div>
        <br /> <br />
        <Table
          loading={isLoaded}
          rowKey={(record) => `${record.id}`}
          className={s.table}
          dataSource={data}
          columns={tableColumns}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: (record) => expandedTable(record.networks),
            expandIcon: (props) =>
              props.expanded ? <CaretUpOutlined /> : <CaretDownOutlined />,
          }}
          pagination={false}
        />
      </div>
    </div>
  );
});

export default Participants;
