import { useEffect, useState } from "react";
import {
  Button,
  Spin,
  Input,
  InputNumber,
  Form,
  Checkbox,
  Space,
  Tooltip,
} from "antd";
import { useHistory, Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { Select } from "@vas-common/ui-kit";

import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";
import { getUnallocatedPrsRuleById } from "utils/api";
import { useNotifications } from "ui/Notifications";
import {
  ResetButton,
  DeleteButton,
  CountryTag,
  NotificationChannelTag,
} from "ui/FormControls";
import { RULE_ACTION_TAGS_MAP } from "ui/maps";
import { useOnLeavePage } from "utils/useOnLeavePage";
import {
  arrayToRuleActionString,
  ruleActionStringToArray,
} from "utils/ruleActions";
import dictionary from "assets/dictionary.json";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";

import { Statistic } from "./helpers";

import type {
  UnallocatedPrsRule,
  UnallocatedPrsRuleCountrySelection,
  UnallocatedPrsRuleCreateBody,
} from "store/mobx/types";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import s from "./index.module.scss";

const UnallocatedPrsRulesCreateUpdate = observer(() => {
  const history = useHistory<{ rule?: UnallocatedPrsRule }>();
  const { id } = useParams<{ id?: string }>();

  const isEditRuleMode = Boolean(id && id !== "new");

  const currentRuleData: UnallocatedPrsRule | null =
    history.location.state?.rule || null;

  const [isThresholdDisabled, setIsThresholdDisabled] = useState(
    !currentRuleData?.threshold_enabled
  );
  const [isCountriesHidden, setIsCountriesHidden] = useState(
    !currentRuleData || currentRuleData?.country_selection === "all"
  );
  const [isNotificationChannelsDisabled, setIsNotificationChannelsDisabled] =
    useState(currentRuleData?.action === "block");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [initialValues, setInitialValues] =
    useState<UnallocatedPrsRuleCreateBody>({
      name: currentRuleData?.name || "",
      enabled: currentRuleData?.enabled || false,
      type: currentRuleData?.type,
      direction: currentRuleData?.direction,
      country_selection: currentRuleData?.country_selection || "all",
      countries: currentRuleData?.countries || [],
      threshold_enabled: currentRuleData?.threshold_enabled || false,
      alert_on_threshold_once:
        currentRuleData?.alert_on_threshold_once || false,
      threshold_period: currentRuleData?.threshold_period,
      threshold_numeric: currentRuleData?.threshold_numeric,
      action: ruleActionStringToArray(currentRuleData?.action),
      notification_channel_ids: currentRuleData?.notification_channel_ids || [],
    });

  const [statistic, setStatistic] = useState<
    Pick<
      UnallocatedPrsRule,
      "last_triggered_at" | "matched_today" | "matched_yesterday"
    >
  >({
    last_triggered_at: currentRuleData?.last_triggered_at || 0,
    matched_today: currentRuleData?.matched_today || 0,
    matched_yesterday: currentRuleData?.matched_yesterday || 0,
  });

  const { error, handleError, resetNotification, setNotification } =
    useNotifications(() =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    );

  const [isLoading, setIsloading] = useState(true);

  const [form] = Form.useForm<UnallocatedPrsRuleCreateBody>();

  const {
    firewallStore: {
      createUnallocatedPrsRule,
      updateUnallocatedPrsRule,
      deleteUnallocatedPrsRule,
    },
    referenceStore: {
      notificationChannels,
      reference: { countries },
    },
  } = useStores();

  useEffect(() => {
    let isCanceled = false;

    if (isEditRuleMode && !currentRuleData) {
      const fetchRule = async () => {
        try {
          const response = await getUnallocatedPrsRuleById(Number(id));

          if (!isCanceled) {
            setInitialValues({
              ...response,
              action: ruleActionStringToArray(response.action),
            });
            setIsThresholdDisabled(!response.threshold_enabled);
            setIsCountriesHidden(response.country_selection === "all");
            setIsNotificationChannelsDisabled(response.action === "block");
            setStatistic({
              last_triggered_at: response.last_triggered_at,
              matched_today: response.matched_today,
              matched_yesterday: response.matched_yesterday,
            });
            setIsloading(false);
          }
        } catch (err) {
          if (!isCanceled) {
            handleError(err, true);
            setIsloading(false);
          }
        }
      };

      fetchRule();
    } else {
      setIsloading(false);
    }

    return () => {
      isCanceled = true;
    };
  }, [id, currentRuleData, isEditRuleMode, handleError]);

  useOnLeavePage({
    onLeave: () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
      form.submit();
    },
    shouldDisplayMessage: () => {
      const currentValues = form.getFieldsValue();
      const isChanged = !isEqual(currentValues, initialValues);
      return isChanged && !isSubmitting && !isEmpty(currentValues);
    },
  });

  const handleThresholdEnabled = ({ target }: CheckboxChangeEvent) => {
    setIsThresholdDisabled(!target.checked);
  };

  const handleCountriesHidden = (value: UnallocatedPrsRuleCountrySelection) => {
    setIsCountriesHidden(value === "all");
  };

  const handleIsNotificationChannelsDisabled = (e: CheckboxChangeEvent) => {
    setIsNotificationChannelsDisabled(!e.target.checked);
  };

  const handleDeleteRule = async () =>
    deleteUnallocatedPrsRule(Number(id))
      .then(() => history.push(URLS.unallocatedPrsRules))
      .catch(handleError);

  const isEmptyNotificationChannels = notificationChannels.data.length === 0;

  return isLoading ? (
    <Spin className={s.spin} spinning />
  ) : error?.status === 404 ? (
    <div className={s.notFoundContainer}>
      <h1 className={`${s.fontTitle} ${s.titleBackBtn}`}>
        Not found
        <Link to={URLS.unallocatedPrsRules} className={s.mb8}>
          <Button className={s.btnFlat}>
            <IconArrowBack width="2rem" />
          </Button>
        </Link>
      </h1>
    </div>
  ) : (
    <Form
      form={form}
      className={`${s.container} ${s.form}`}
      initialValues={initialValues}
      layout="vertical"
      onFinish={(values) => {
        setIsSubmitting(true);
        resetNotification();

        if (values.country_selection === "all") {
          values.countries = [];
        }

        const action = arrayToRuleActionString(values.action);

        const data = {
          ...values,
          action: action,
        };

        return (
          isEditRuleMode
            ? updateUnallocatedPrsRule(Number(id), data)
            : createUnallocatedPrsRule(data)
        )
          .then(() => {
            history.push(URLS.unallocatedPrsRules);
          })
          .then(() => {
            setNotification(
              {
                type: "success",
                data: {
                  message: isEditRuleMode
                    ? "Successfully Updated"
                    : "Successfully Created",
                },
              },
              1500
            );
          })
          .catch((err) => {
            setIsSubmitting(false);
            handleError(err);
          });
      }}
    >
      <section className={s.section}>
        <h1 className={`${s.fontMainTitle} ${s.mb24} ${s.titleBackBtn}`}>
          {isEditRuleMode ? "Edit Rule" : "New Rule"}

          <Link to={URLS.unallocatedPrsRules} className={s.mb8}>
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>

        {isEditRuleMode && initialValues.enabled ? (
          <Statistic ruleType={initialValues.type} statistic={statistic} />
        ) : null}

        {/* Name Active */}
        <div className={s.row}>
          <Form.Item
            label="Rule Name"
            name="name"
            required={false}
            rules={[{ required: true, message: "Rule Name is required" }]}
          >
            <Input className={s.input} />
          </Form.Item>

          <Form.Item
            className={s.activeCheckbox}
            name="enabled"
            valuePropName="checked"
          >
            <Checkbox className={s.checkbox}>
              <div className={s.fontBody}>Enabled</div>
            </Checkbox>
          </Form.Item>
        </div>
      </section>

      {/* Rule Scope */}
      <section className={s.section}>
        <h2 className={`${s.fontTitle} ${s.mb16}`}>Rule Scope</h2>

        <div className={s.column}>
          <div className={s.row}>
            <Form.Item
              label="PREFIXES TYPE"
              name="type"
              required={false}
              rules={[{ required: true, message: "Rule Type is required" }]}
              tooltip={{
                title: dictionary.tooltips.prefixesType,
                icon: <IconHelp width="1.6rem" />,
              }}
            >
              <Select
                options={[
                  { value: "unalloc", label: "Unallocated" },
                  { value: "prs", label: "PRS" },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="CALL DIRECTION"
              name="direction"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Direction is required",
                },
              ]}
            >
              <Select
                options={[
                  { value: "all", label: "All" },
                  { value: "in", label: "Inbound" },
                  { value: "out", label: "Outbound" },
                ]}
              />
            </Form.Item>
          </div>

          <div className={s.row}>
            <Form.Item
              label="Countries"
              tooltip={{
                title: dictionary.tooltips.countries,
                icon: <IconHelp width="1.6rem" />,
              }}
            >
              <Space.Compact className={s.countrySelect}>
                <Form.Item name="country_selection" noStyle>
                  <Select
                    options={[
                      { value: "include", label: "Include" },
                      { value: "exclude", label: "Exclude" },
                      { value: "all", label: "All countries" },
                    ]}
                    onChange={handleCountriesHidden}
                  />
                </Form.Item>

                <Form.Item
                  name="countries"
                  noStyle
                  dependencies={["country_selection"]}
                  rules={[
                    (formInstance) => ({
                      validator: (_, value) => {
                        const countrySelection = formInstance.getFieldValue(
                          "country_selection"
                        ) as UnallocatedPrsRuleCountrySelection;

                        const isAllCountries = countrySelection === "all";
                        const isEmptyArray = value.length === 0;

                        if (!isAllCountries && isEmptyArray) {
                          return Promise.reject(
                            "Countries list should not be empty"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  hidden={isCountriesHidden}
                >
                  <Select
                    mode="multiple"
                    tagRender={CountryTag}
                    options={countries.map(({ code, value }) => ({
                      value: code.toLocaleLowerCase(),
                      label: value,
                    }))}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </div>
        </div>
      </section>

      {/* Threshold */}
      <section className={s.section}>
        <h2 className={`${s.fontTitle} ${s.mb16}`}>Threshold</h2>

        <div className={`${s.rowCheckbox} ${s.mb16}`}>
          <Form.Item name="threshold_enabled" valuePropName="checked">
            <Checkbox className={s.checkbox} onChange={handleThresholdEnabled}>
              <div className={s.fontBody}>Apply threshold</div>
            </Checkbox>
          </Form.Item>
        </div>

        <div className={s.rowCheckbox}>
          <Form.Item
            label="Calls"
            name="threshold_numeric"
            style={{ width: "6.4rem" }}
          >
            <InputNumber className={s.input} disabled={isThresholdDisabled} />
          </Form.Item>

          <Form.Item
            label="Period"
            name="threshold_period"
            style={{ width: "15.2rem" }}
          >
            <Select
              allowClear
              disabled={isThresholdDisabled}
              options={[
                { value: "hour", label: "Hour" },
                { value: "day", label: "Day" },
              ]}
            />
          </Form.Item>

          <Form.Item
            className={s.alertOnceWrapper}
            name="alert_on_threshold_once"
            valuePropName="checked"
          >
            <Checkbox className={s.checkbox} disabled={isThresholdDisabled}>
              <div className={`${s.fontBody} ${s.alertOnce}`}>
                Alert once
                <Tooltip
                  placement="right"
                  title={dictionary.tooltips.alertOnce}
                >
                  <IconHelp width="2rem" />
                </Tooltip>
              </div>
            </Checkbox>
          </Form.Item>
        </div>
      </section>

      {/* Rule Action */}
      <section className={s.section}>
        <h2 className={`${s.fontTitle} ${s.mb16}`}>Rule Action</h2>

        <div className={s.column}>
          <Form.Item
            name="action"
            className={s.checkboxGroup}
            required={false}
            rules={[{ required: true, message: "Rule Action is required" }]}
            normalize={(value) => {
              value.sort();
              return value;
            }}
          >
            <Checkbox.Group>
              <Checkbox
                className={s.checkbox}
                value="alert"
                onChange={handleIsNotificationChannelsDisabled}
                disabled={isEmptyNotificationChannels}
              >
                {RULE_ACTION_TAGS_MAP["alert"]}
              </Checkbox>
              <Checkbox className={s.checkbox} value="block">
                {RULE_ACTION_TAGS_MAP["block"]}
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            label="Notification Channels"
            name="notification_channel_ids"
            dependencies={["action"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const isAlertOn = Boolean(
                    getFieldValue("action")?.includes("alert")
                  );
                  const isEmptyChannels = !value || value.length === 0;

                  if (isAlertOn && isEmptyChannels) {
                    return Promise.reject(
                      new Error(
                        "At least one notification channel should be selected"
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              mode="multiple"
              tagRender={NotificationChannelTag}
              options={notificationChannels.data.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              disabled={isNotificationChannelsDisabled}
            />
          </Form.Item>
        </div>
      </section>

      {/* Buttons */}
      <section className={s.section}>
        <div className={s.buttonsRow}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsError, getFieldsValue, resetFields }) => {
              const isValid = getFieldsError().every(
                (field) => field.errors.length === 0
              );
              const currentValues = getFieldsValue();
              const isChanged = !isEqual(currentValues, initialValues);
              return (
                <>
                  <Button
                    className={s.btnPrimary}
                    htmlType="submit"
                    disabled={!isChanged || !isValid}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>

                  {isEditRuleMode ? null : (
                    <ResetButton onOk={resetFields} disabled={!isChanged} />
                  )}
                </>
              );
            }}
          </Form.Item>

          {isEditRuleMode && (
            <DeleteButton
              onDelete={handleDeleteRule}
              ruleName={initialValues.name}
            />
          )}
        </div>
      </section>
    </Form>
  );
});

export default UnallocatedPrsRulesCreateUpdate;
