import { Button } from "antd";
import { Link } from "react-router-dom";

import { PrefixListRulesTable } from "./PrefixListRulesTable";

import { ReactComponent as IconPlus } from "assets/svg/plus.svg";

import { URLS } from "constants/Urls";
import dictionary from "assets/dictionary.json";

import s from "./index.module.scss";

const PrefixListRules = () => {
  return (
    <div className={s.container}>
      <div className={s.headContainer}>
        <div className={s.head}>
          <h1 className={s.fontMainTitle}>Prefix List Rules</h1>

          <Link
            to={URLS.prefixListRulesCreate}
            data-test="prefixListRules-add-btn"
          >
            <Button className={s.btnPrimary}>
              <IconPlus width="2rem" />
              Add New
            </Button>
          </Link>
        </div>

        <p className={s.fontBody}>
          {dictionary.descriptions.rules.prefixListRule.description}
        </p>
      </div>

      <div className={s.tableContainer}>
        <PrefixListRulesTable />
      </div>
    </div>
  );
};

export default PrefixListRules;
