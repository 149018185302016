import axios, { AxiosRequestConfig } from "axios";

import { PROJECT_ID_KEY, PROTECT_TOKEN } from "store/actions";
import { qs } from "utils/qs";
import { getParamsFromFilters } from "store/mobx/utils";

import { IRulesForm } from "interfaces/IRules";
import { IReport } from "interfaces/IReports";
import type {
  CallControleRule,
  TcgRuleSettings,
  PredefinedRuleUpdateBody,
  UnallocatedPrsRule,
  SipRejectCode,
  PrefixList,
  PrefixListsState,
  PrfixListValuesForm,
  PrefixListRule,
  PrefixListUsage,
  PrefixListValue,
  User,
  StatusInfo,
} from "store/mobx/types";

// TODO remove any. Set as unknown
export default function api<T = any>(conf: AxiosRequestConfig) {
  const config = conf;

  const currentNetwork = localStorage.getItem(PROJECT_ID_KEY);

  config.headers = {
    Authorization: `Bearer ${localStorage.getItem(PROTECT_TOKEN)}`,
    ...(currentNetwork && { "X-Network-ID": currentNetwork }),
  };

  config.paramsSerializer = (params: Record<string, string>) =>
    qs.stringify(params);

  return axios(config).then((response) => {
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 202
    )
      return response.data as T;
    return Promise.reject(response.data);
  });
}

export const getLogsId = (body: any, str?: string) => {
  const bodyData = getParamsFromFilters(body);
  return api({
    method: "POST",
    url: `/api/v1/fraud_logs/generate?${bodyData}${str}`,
  });
};

export const getLogsUrl = (id: number) => {
  return api({
    method: "GET",
    url: `/api/v1/fraud_logs/${id}`,
    timeout: 30000,
  });
};

export const getSelectedRule = (id: any) => {
  return api({
    method: "GET",
    url: `/api/v1/call_control/fraud_rules/${id}`,
  });
};

export const deleteRule = (id: any) => {
  return api({
    method: "DELETE",
    url: `/api/v1/call_control/fraud_rules/${id}`,
  });
};

export const updateRule = (body: IRulesForm) => {
  return api({
    method: "PUT",
    url: `/api/v1/call_control/fraud_rules/${body.id}`,
    data: body,
  });
};

export const patchRule = (body: IRulesForm) => {
  return api({
    method: "PATCH",
    url: `/api/v1/call_control/fraud_rules/${body.id}`,
    data: body,
  });
};

export const createNewRule = (body: IRulesForm) => {
  const dataBody: IRulesForm = {
    ...body,
    priority: 50,
    enabled: true,
  };

  return api({
    method: "POST",
    url: `/api/v1/call_control/fraud_rules`,
    data: dataBody,
  });
};

export const switchRule = (id: number, flag: boolean) => {
  return api({
    method: "PUT",
    url: `/api/v1/call_control/fraud_rules/${id}/toggle?enabled=${flag}`,
  });
};

export const updateReportChannels = (id: number, body: IReport) => {
  return api({
    method: "PUT",
    url: `/api/v1/reports/tasks/${id}`,
    data: body,
  });
};

export const crateReportChannels = (body: IReport) => {
  return api({
    method: "POST",
    url: `/api/v1/reports/tasks`,
    data: body,
  });
};

export const sendNowReport = (idChannel: number) => {
  return api({
    method: "POST",
    url: `/api/v1/reports/tasks/${idChannel}/execute`,
  });
};

export const getDetailInfoFraudCall = (id: number | string) => {
  return api({
    method: "GET",
    url: `/api/v1/fraud_records/${id}`,
  });
};

export const getUserData = () => {
  return api({
    method: "GET",
    url: "/api/v1/account",
  }) as Promise<User>;
};

export const getStatusInfo = () => {
  return api({
    method: "GET",
    url: "/api/v1/status",
  }) as Promise<StatusInfo>;
};

export const authenticate = (data: { login: string; password: string }) => {
  return api({
    method: "POST",
    url: "/api/v1/login",
    data,
  }) as Promise<{ token: string }>;
};

export const getUsers = () => {
  return api({
    method: "GET",
    url: "/api/v1/users",
  });
};

export const getRoles = () => {
  return api({
    method: "GET",
    url: "/api/v1/roles",
  });
};

export const deleteUser = (id: string) => {
  return api({
    method: "DELETE",
    url: `/api/v1/users/${id}`,
  });
};

export const getCallControlRules = () =>
  api<CallControleRule[]>({
    method: "GET",
    url: `/api/v1/call_control_rules`,
  });

export const getTcgRuleSettings = () =>
  api<TcgRuleSettings>({
    method: "GET",
    url: `/api/v1/firewall/tcg`,
  });

export const patchTcgRuleSettings = (data: PredefinedRuleUpdateBody) =>
  api<TcgRuleSettings>({
    method: "PATCH",
    url: `/api/v1/firewall/tcg`,
    data,
  });

export const getUnallocatedPrsRules = (query: {
  page: number;
  // page_size: number;
}) =>
  api<UnallocatedPrsRule[]>({
    method: "GET",
    url: `/api/v1/firewall/unallocated_prs_rules`,
    params: query,
  });

export const getUnallocatedPrsRuleById = (id: number) =>
  api<UnallocatedPrsRule>({
    method: "GET",
    url: `/api/v1/firewall/unallocated_prs_rules/${id}`,
  });

export const createUnallocatedPrsRule = (data: Partial<UnallocatedPrsRule>) =>
  api<UnallocatedPrsRule>({
    method: "POST",
    url: `/api/v1/firewall/unallocated_prs_rules`,
    data,
  });

export const updateUnallocatedPrsRule = (
  id: number,
  data: Partial<UnallocatedPrsRule>
) =>
  api<UnallocatedPrsRule>({
    method: "PUT",
    url: `/api/v1/firewall/unallocated_prs_rules/${id}`,
    data,
  });

export const patchUnallocatedPrsRule = (
  id: number,
  data: Partial<UnallocatedPrsRule>
) =>
  api<UnallocatedPrsRule>({
    method: "PATCH",
    url: `/api/v1/firewall/unallocated_prs_rules/${id}`,
    data,
  });

export const deleteUnallocatedPrsRule = (id: number) =>
  api({
    method: "DELETE",
    url: `/api/v1/firewall/unallocated_prs_rules/${id}`,
  });

export const getSipRejectCodes = () =>
  api<SipRejectCode[]>({
    method: "GET",
    url: `/api/v1/reject_codes/sip`,
  });

export const getPrefixLists = (params?: PrefixListsState["params"]) =>
  api<PrefixList[]>({
    method: "GET",
    url: `/api/v1/prefix_lists`,
    params,
  });

export const getPrefixListById = (id: string) =>
  api<PrefixList>({
    method: "GET",
    url: `/api/v1/prefix_lists/${id}`,
  });

export const getPrefixListUsage = (id: string) =>
  api<PrefixListUsage>({
    method: "GET",
    url: `/api/v1/prefix_lists/${id}/usage`,
  });

export const getPrefixListValues = (id: string) =>
  api<PrefixListValue[]>({
    method: "GET",
    url: `/api/v1/prefix_lists/${id}/values`,
  });

export const createPrefixListValue = (id: number, data: PrfixListValuesForm) =>
  api<PrefixListValue[]>({
    method: "POST",
    url: `/api/v1/prefix_lists/${id}/values`,
    data,
  });

export const createPrefixListValueBatch = (
  id: number,
  data: PrfixListValuesForm
) =>
  api<PrefixListValue[]>({
    method: "POST",
    url: `/api/v1/prefix_lists/${id}/values`,
    data,
  });

export const updatePrefixList = (id: number, data: Partial<PrefixList>) =>
  api<PrefixList>({
    method: "PUT",
    url: `/api/v1/prefix_lists/${id}`,
    data,
  });

export const createPrefixList = (
  data: Pick<PrefixList, "name" | "description" | "values_type" | "expirable">
) =>
  api<PrefixList>({
    method: "POST",
    url: `/api/v1/prefix_lists`,
    data,
  });

export const deletePrefixList = (id: number) =>
  api({
    method: "DELETE",
    url: `/api/v1/prefix_lists/${id}`,
  });

export type PrefixListValidationResult = {
  add_count: number;
  ignore_count: number;
  ignored_values: string[];
};

export const prefixListValuesValidateBatch = (data: PrfixListValuesForm) =>
  api<PrefixListValidationResult>({
    method: "POST",
    url: `/api/v1/prefix_lists/validate_batch`,
    data,
  });

export const prefixListValuesValidateCsv = (data: { file_path: string }) =>
  api<PrefixListValidationResult>({
    method: "POST",
    url: `/api/v1/prefix_lists/validate_batch_csv`,
    data,
  });

export const prefixListValuesAddBatch = (
  id: number,
  data: PrfixListValuesForm
) =>
  api<PrefixListValue[]>({
    method: "POST",
    url: `/api/v1/prefix_lists/${id}/values_batch`,
    data,
  });

export const prefixListValuesAddCsv = (
  id: number,
  data: { file_path: string }
) =>
  api<PrefixListValue[]>({
    method: "POST",
    url: `/api/v1/prefix_lists/${id}/values_batch_csv`,
    data,
  });

export const prefixListValuesDeleteBatch = (
  id: number,
  data: { values: string[] }
) =>
  api({
    method: "DELETE",
    url: `/api/v1/prefix_lists/${id}/values_batch`,
    data,
  });

export const getPrefixListRules = (query: {
  page: number;
  // page_size: number;
}) =>
  api<PrefixListRule[]>({
    method: "GET",
    url: `/api/v1/firewall/prefix_list_rules`,
    params: query,
  });

export const getPrefixListRuleById = (id: number) =>
  api<PrefixListRule>({
    method: "GET",
    url: `/api/v1/firewall/prefix_list_rules/${id}`,
  });

export const updatePrefixListRule = (
  id: number,
  data: Partial<PrefixListRule>
) =>
  api<PrefixListRule>({
    method: "PUT",
    url: `/api/v1/firewall/prefix_list_rules/${id}`,
    data,
  });

export const patchPrefixListRule = (
  id: number,
  data: Partial<Pick<PrefixListRule, "name" | "enabled" | "priority">>
) =>
  api<PrefixListRule>({
    method: "PATCH",
    url: `/api/v1/firewall/prefix_list_rules/${id}`,
    data,
  });

export const createPrefixListRule = (data: PrefixListRule) =>
  api<PrefixListRule>({
    method: "POST",
    url: `/api/v1/firewall/prefix_list_rules`,
    data,
  });

export const deletePrefixListRule = (id: number) =>
  api<unknown>({
    method: "DELETE",
    url: `/api/v1/firewall/prefix_list_rules/${id}`,
  });
