import React, {FC, useLayoutEffect} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {observer} from "mobx-react";
import {Spin, Tooltip} from "antd";
import {QuestionCircleOutlined} from '@ant-design/icons'

import {ReactComponent as BackArrow} from "../../../assets/svg/backArrow.svg";
import {useStores} from "../../../store/mobx/useStore";
import {getTextTooltip} from "../../../utils/getTextTooltip";
import {TStates, TStats, TConfig} from "../../../store/mobx/types";
import { dateCustom } from "utils/dates";

import s from './ConnectorDetail.module.scss';
import {tickFormatter} from "../../../utils/formatNumber";

type MatchParams = {
  connectorId: string;
}

type ElementProps = {
  data: TStates | TStats | TConfig
}

type ReformType = {
  name: keyof TStats | keyof TStates | keyof TConfig
  value: number | boolean | string | null
}

export const formatValue = (value: number | null | string | boolean) => {
  switch (typeof (value)) {
    case 'number':
      return tickFormatter(value);
    case 'boolean':
      return value ? 'Yes' : 'No';
    case 'object':
      return '-';
    case 'string': {
      const isDate = dateCustom(value).format('YYYY-MM-DD HH:mm:ss');
      if (isDate === 'Invalid date') {
        return value === '' ? '-' : value;
      }
      return isDate;
    }
    default:
      return value;
  }
}


const Element: FC<ElementProps> = ({data}) => {

  const objToArr: ReformType[] = Object.entries(data).map(([key, value]) => {
    return {
      name: key as any,
      value: value
    }
  })

  return (
    <div>
      {objToArr.map((el: ReformType, index: number) => (
        <div key={index} className={s.cardInfo}>
          <div className={s.cardRow}>
            <div className={s.descriptionCol}>
              <Tooltip title={() => getTextTooltip(el.name)}>
                {el.name.replaceAll('_', ' ')}
                <QuestionCircleOutlined className={s.iconQuestion}/>
              </Tooltip>
            </div>
            <div className={s.valueCol}>{formatValue(el.value)}</div>
          </div>
        </div>

      ))}
    </div>
  )
}

const ConnectorDetail = observer(() => {
  const {params} = useRouteMatch<MatchParams>();
  const history = useHistory();

  const {referenceStore: {getConnectorsAsync, availableConnectors}} = useStores();


  useLayoutEffect(() => {
    getConnectorsAsync()
  }, [getConnectorsAsync])

  const {connectors, loading} = availableConnectors;

  const connector = connectors.find((el) => el.configuration.label === params.connectorId) || null;

  const handleRouteBack = () => {
    history.goBack()
  }

  return (

    <div className={s.container}>
      {loading ?
        <div className={s.wrapperLoader}>
          <Spin/>
        </div> : (
          <div className={s.content}>
            <div className={s.wrapperHeader}>
              <div className={s.nav}>
                <BackArrow onClick={() => handleRouteBack()}/>
              </div>
              <span className={s.titleHead}>{`${String(params.connectorId)} connector`}</span>
            </div>
            {connector && (
              <>
                <div className={s.mainInfoContainer}>
                  <div className={s.titleContainer}>
                    <span className={s.title}>Connector state</span>
                    <Element data={connector.states}/>
                  </div>
                </div>
                <div className={s.mainInfoContainer}>
                  <div className={s.titleContainer}>
                    <span className={s.title}>Statistics</span>
                    <Element data={connector.stats}/>
                  </div>
                </div>
                <div className={s.mainInfoContainer}>
                  <div className={s.titleContainer}>
                    <span className={s.title}>Configuration</span>
                    <Element data={connector.configuration}/>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
    </div>
  )
})

export default ConnectorDetail;
