import { useEffect, useRef } from "react";

export const useIntersectionObserver = (
  cb: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    const currentElement = ref.current;

    const observer = new IntersectionObserver(cb, options);

    observer.observe(ref.current);

    return () => {
      observer.unobserve(currentElement);
    };
  }, [ref, cb, options]);

  return [ref];
};
