import { FraudRule } from "interfaces/IRules";
import api from "utils/api";

export const getFraudRules = () =>
  api<FraudRule[]>({
    method: "GET",
    // TODO make sure "page=1&on_page=100" parameters are correct
    url: `/api/v1/call_control/fraud_rules?page=1&on_page=100`,
  });

export const patchFraudRule = (id: number, data: Partial<FraudRule>) =>
  api<FraudRule>({
    method: "PATCH",
    url: `/api/v1/call_control/fraud_rules/${id}`,
    data,
  });

export const createFraudRule = (data: Partial<FraudRule>) =>
  api<FraudRule>({
    method: "POST",
    url: "/api/v1/call_control/fraud_rules",
    data,
  });

export const updateFraudRule = (id: number, data: Partial<FraudRule>) =>
  api<FraudRule>({
    method: "PUT",
    url: `/api/v1/call_control/fraud_rules/${id}`,
    data,
  });

export const deleteFraudRule = (id: number) =>
  api<unknown>({
    method: "DELETE",
    url: `/api/v1/call_control/fraud_rules/${id}`,
  });
