export const groupBy = (arr, property) => {
  return arr.reduce((memo, x) => {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
};

export const millsecToDay = (value) => Math.round(value / 3600 / 24 / 1000);
export const millsecToHour = (value) => Math.round(value / 3600 / 1000);

export const calculateSeverity = (percent, callsCount) => {
  switch(true) {
    case percent > 0.1 && callsCount > 50: return 'critical';
    case percent > 0.01: return 'high';
    case percent > 0.000: return 'low';
    default: return 'clean';
  } 
};
