import { Tooltip, Dropdown, Menu } from "antd";
import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons";

import { dateCustom } from "utils/dates";
import { TStateFields } from "../types";

import s from "../Users.module.scss";

type Props = {
  item: any;
  roles: Array<any>;
  edit: (values: TStateFields) => void;
  showButton: boolean;
  showConfirm: (str: string, idx: string) => void;
};

const TableRow = ({ item, roles, showButton, showConfirm, edit }: Props) => {
  const findRole = roles.find((el) => el.id === item.role_id);

  const handleMenuClick = (elem: any) => {
    const { key } = elem;
    if (key === "delete") {
      showConfirm(item.name, item.id);
    } else {
      const values: TStateFields = {
        create: false,
        id: item.id,
        name: item.name,
        login: item.login,
        password: "",
        role_id: item.role_id,
      };
      edit(values);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">Edit user</Menu.Item>
      <Menu.Item key="delete">Delete user</Menu.Item>
    </Menu>
  );

  return (
    <tr key={item.id} className={s.row}>
      <td className={s.name}>
        <UserOutlined />
        <span className={s.userName}>{item.name}</span>
      </td>
      <td className={s.role}>
        <Tooltip title={findRole?.description || ""}>
          <div>
            <span className={s.userRole}>{findRole?.name || ""}</span>
            <QuestionCircleOutlined />
          </div>
        </Tooltip>
      </td>
      <td className={s.login}>{item.login}</td>
      <td className={s.created}>{dateCustom(item.created_at).format("LL")}</td>
      <td>
        {showButton && (
          <Dropdown
            placement="bottomRight"
            overlay={menu}
            trigger={["hover"]}
            className={s.dropdown}
          >
            <div className={s.icon}>
              <i className={`material-icons-outlined ${s.icon2}`}>more_vert</i>
            </div>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
