import {makeAutoObservable, runInAction} from "mobx";

import sortBy from 'lodash/sortBy';

import api from "../../utils/api";
import {EFilterParam, EGroupBy, TTraffic, TTrafficResponse, TFilter, TGroupBy} from "./types";
import {SAVED_FILTER_TYPE_CALLS_ABH_CR, windowInterval} from "../../utils/constants";
import {calculateSeverity} from "../../utils";
import {defaultTraffic} from "./default";
import { dateCustom } from 'utils/dates';

import type { RootStoreType } from "./rootStore";
export class TrafficStore {
  rootStore: RootStoreType;

  outbound: TTraffic = {
    ...defaultTraffic
  }
  inbound: TTraffic = {
    ...defaultTraffic
  }
  size: number = 10;
  callsGroupBy: string = localStorage.getItem(SAVED_FILTER_TYPE_CALLS_ABH_CR) || EGroupBy.network;
  selectedId: number | null = null;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false })
  }

  setSize = () => {
    this.size = this.size + 10
  }

  setSizeDefault = () => {
    this.size = 10;
  }

  setSelectedId = (value: null | number) => {
    this.selectedId = value;
  }

  setCallsGroupBy = (value: TGroupBy) => {
    this.callsGroupBy = value;
  }

  getDynamicData = (elem: TTrafficResponse) => {
    switch (this.callsGroupBy) {
      case EGroupBy.network:
        return elem.network_id;
      case EGroupBy.gateway:
        return elem.gateway_id;
      case EGroupBy.country:
        return elem.country;
    }
  }

  setOutbound = (res: TTrafficResponse[]) => {
    const sortByTotal: TTrafficResponse[] = sortBy(res, 'fraudulent').reverse();
    switch (this.callsGroupBy) {
      case EGroupBy.gateway: {
        let total: number = 0;
        let totalFraud: number = 0;
        const newArrayCallsList = sortByTotal.filter((el) => el.gateway_id !== undefined).map((el) => {
          const percent = el.fraudulent / el.total * 100 || 0;
          total = total + el.total;
          totalFraud = totalFraud + el.fraudulent;
          return {
            severity: calculateSeverity(percent, el.total),
            percent: percent,
            dynamicData: this.getDynamicData(el),
            country: el.country,
            network_id: el.network_id,
            gateway_id: el.gateway_id,
            fraudCalls: el.fraudulent,
            totalCalls: el.total,
            validCalls: el.validated_default,
            fraudDuration: Number(Math.floor(el.fraudulent_duration / 1000).toFixed(0))
          }
        })
        this.outbound = {
          loading: false,
          list: newArrayCallsList,
          counter: totalFraud,
          percent:  totalFraud / total * 100 || 0
        }
        return;
      }
      case EGroupBy.country: {
        let total: number = 0;
        let totalFraud: number = 0;
        const newArrayCallsList = sortByTotal.filter((el) => el.country?.trim() !== "").map((el) => {
          const percent = el.fraudulent / el.total * 100 || 0;
          total = total + el.total;
          totalFraud = totalFraud + el.fraudulent;
          return {
            severity: calculateSeverity(percent, el.total),
            percent: percent,
            dynamicData: this.getDynamicData(el),
            country: el.country,
            network_id: el.network_id,
            gateway_id: el.gateway_id,
            fraudCalls: el.fraudulent,
            totalCalls: el.total,
            validCalls: el.validated_default,
            fraudDuration: Number(Math.floor(el.fraudulent_duration / 1000).toFixed(0))
          }
        })
        this.outbound = {
          loading: false,
          list: newArrayCallsList,
          counter: totalFraud,
          percent:  totalFraud / total * 100 || 0
        }
        return;
      }
      case EGroupBy.network: {
        let total: number = 0;
        let totalFraud: number = 0;
        const newArrayCallsList = sortByTotal.map((el) => {
          const percent = el.fraudulent / el.total * 100 || 0;
          total = total + el.total;
          totalFraud = totalFraud + el.fraudulent;
          return {
            severity: calculateSeverity(percent, el.total),
            percent: percent,
            dynamicData: this.getDynamicData(el),
            country: el.country,
            network_id: el.network_id,
            gateway_id: el.gateway_id,
            fraudCalls: el.fraudulent,
            totalCalls: el.total,
            validCalls: el.validated_default,
            fraudDuration: Number(Math.floor(el.fraudulent_duration / 1000).toFixed(0))
          }
        })
        this.outbound = {
          loading: false,
          list: newArrayCallsList,
          counter: totalFraud,
          percent:  totalFraud / total * 100 || 0
        }
        return;
      }
    }
  }

  setInbound = (res: TTrafficResponse[]) => {
    const sortByTotal: TTrafficResponse[] = sortBy(res, 'fraudulent').reverse();
    switch (this.callsGroupBy) {
      case EGroupBy.gateway: {
        let total: number = 0;
        let totalFraud: number = 0;
        const newArrayCallsList = sortByTotal.filter((el) => el.gateway_id !== undefined).map((el) => {
          const percent = el.fraudulent / el.total * 100 || 0;
          total = total + el.total;
          totalFraud = totalFraud + el.fraudulent;
          return {
            severity: calculateSeverity(percent, el.total),
            percent: percent,
            dynamicData: this.getDynamicData(el),
            country: el.country,
            network_id: el.network_id,
            gateway_id: el.gateway_id,
            fraudCalls: el.fraudulent,
            totalCalls: el.total,
            validCalls: el.validated_default,
            fraudDuration: Number(Math.floor(el.fraudulent_duration / 1000).toFixed(0))
          }
        })
        this.inbound = {
          loading: false,
          list: newArrayCallsList,
          counter: totalFraud,
          percent:  totalFraud / total * 100 || 0
        }
        return;
      }
      case EGroupBy.country: {
        let total: number = 0;
        let totalFraud: number = 0;
        const newArrayCallsList = sortByTotal.filter((el) => el.country?.trim() !== "").map((el) => {
          const percent = el.fraudulent / el.total * 100 || 0;
          total = total + el.total;
          totalFraud = totalFraud + el.fraudulent;
          return {
            severity: calculateSeverity(percent, el.total),
            percent: percent,
            dynamicData: this.getDynamicData(el),
            country: el.country,
            network_id: el.network_id,
            gateway_id: el.gateway_id,
            fraudCalls: el.fraudulent,
            totalCalls: el.total,
            validCalls: el.validated_default,
            fraudDuration: Number(Math.floor(el.fraudulent_duration / 1000).toFixed(0))
          }
        })
        this.inbound = {
          loading: false,
          list: newArrayCallsList,
          counter: totalFraud,
          percent:  totalFraud / total * 100 || 0
        }
        return;
      }
      case EGroupBy.network: {
        let total: number = 0;
        let totalFraud: number = 0;
        const newArrayCallsList = sortByTotal.map((el) => {
          const percent = el.fraudulent / el.total * 100 || 0;
          total = total + el.total;
          totalFraud = totalFraud + el.fraudulent;
          return {
            severity: calculateSeverity(percent, el.total),
            percent: percent,
            dynamicData: this.getDynamicData(el),
            country: el.country,
            network_id: el.network_id,
            gateway_id: el.gateway_id,
            fraudCalls: el.fraudulent,
            totalCalls: el.total,
            validCalls: el.validated_default,
            fraudDuration: Number(Math.floor(el.fraudulent_duration / 1000).toFixed(0))
          }
        })
        this.inbound = {
          loading: false,
          list: newArrayCallsList,
          counter: totalFraud,
          percent:  totalFraud / total * 100 || 0
        }
        return;
      }
    }
  }

  getFilterParam = () => {
    switch (this.callsGroupBy) {
      case EGroupBy.network:
        return EFilterParam.group_by_network;
      case EGroupBy.gateway:
        return EFilterParam.group_by_gateway;
      case EGroupBy.country:
        return EFilterParam.group_by_country;
    }
  }

  getOutboundCallsAsync = (filters: TFilter, id: number | null) => {
    this.outbound = {
      ...this.outbound,
      loading: true,
    }
    const groupByParam = this.getFilterParam();
    const window = filters.from >= 3600 * 24 * 7 * 1000 ? windowInterval.day : windowInterval.hour;
    const from = dateCustom(new Date()).subtract(filters.from, 'millisecond').valueOf();
    let to = 0;
    if(filters.to) {
      to = dateCustom(new Date()).subtract(filters.to, 'millisecond').valueOf();
    }
    api<TTrafficResponse[]>({
      method: "GET",
      url: `/api/v1/stats/combined?${groupByParam}=1&direction=outbound&from=${from}${to > 0 ? '&to=' + to : ''}&window=${window}${id ? '&fraud_type_ids[]=' + id : ''}`
    }).then((res) => {
      runInAction(() => {
        this.setOutbound(res)
      })
    }).catch(() => {
      runInAction(() => {
        this.setOutbound([])
      })
    })
  }

  getInboundCallsAsync = (filters: TFilter, id: number | null) => {
    this.inbound = {
      ...this.inbound,
      loading: true,
    }
    const groupByParam = this.getFilterParam();
    const window = filters.from >= 3600 * 24 * 7 * 1000 ? windowInterval.day : windowInterval.hour;
    const from = dateCustom(new Date()).subtract(filters.from, 'millisecond').valueOf();
    let to = 0;
    if(filters.to) {
      to = dateCustom(new Date()).subtract(filters.to, 'millisecond').valueOf();
    }
    api<TTrafficResponse[]>({
      method: "GET",
      url: `/api/v1/stats/combined?${groupByParam}=1&direction=inbound&from=${from}${to > 0 ? '&to=' + to : ''}&window=${window}${id ? '&fraud_type_ids[]=' + id : ''}`
    }).then((res) => {
      runInAction(() => {
        this.setInbound(res)
      })
    }).catch(() => {
      runInAction(() => {
        this.setInbound([])
      })
    })
  }

}
