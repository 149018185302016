import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { FilterForm } from "@vas-common/ui-kit";

import { ReactComponent as IconDownload } from "assets/svg/download.svg";

import { TFiltersCalls, THideFiltersCalls } from "../../../../store/mobx/types";
import { getLogsId, getLogsUrl } from "../../../../utils/api";
import { hasPerm, Perms } from "../../../../constants/Perms";
import { useStores } from "../../../../store/mobx/useStore";
import { NoPerms } from "../../../users/NoPerms";
import { Table } from "./Table";
import FraudSchedule from "./FraudSchedule";
import { dateCustom } from "utils/dates";

import type {
  PossibleValues,
  TFields,
} from "@vas-common/ui-kit/dist/cjs/types/components/FilterForm/types";

import s from "./FraudCallsTable.module.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const FraudCallsTable = observer(() => {
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const anchor = useRef<null | HTMLDivElement>(null);

  const {
    userStore: { user },
    callsStore: {
      fraudState,
      filters,
      setFiltersArray,
      hideFilters,
      setPosition,
      getCallsAsync,
      setHideFilters,
      setFraudScheduleAsync,
    },
    referenceStore: { reference },
  } = useStores();

  const { loading: loadingFraudState, data } = fraudState;
  const {
    loading: loadingReference,
    fraudTypes,
    networks,
    gateways,
    direction,
  } = reference;

  const getLoad = useCallback(() => {
    if (!loadingFraudState) {
      return loadingReference;
    }
    if (!loadingReference) {
      return loadingFraudState;
    }
  }, [loadingFraudState, loadingReference]);

  const isLoaded = getLoad() === undefined ? true : getLoad();

  useLayoutEffect(() => {
    const searchValues: any = {};
    const params: any = new URLSearchParams(window.location.search);
    for (const param of params) {
      const [key, value] = param;
      searchValues[key] = value;
    }
    setFiltersArray(searchValues).then((filters: TFiltersCalls) => {
      getCallsAsync(filters, false, true);
      setFraudScheduleAsync(filters);
    });
  }, [getCallsAsync, setFiltersArray, setFraudScheduleAsync]);

  const loadMore = () => {
    const filtersParam: THideFiltersCalls = {
      page: hideFilters.page + 1,
      page_size: 20,
    };
    setHideFilters(filtersParam);
    getCallsAsync(filters, true);
  };

  const downloadData = async () => {
    setLoading(true);
    const {
      countries,
      direction,
      gateway_ids,
      fraud_types,
      network_ids,
      from_ts,
      till_ts,
    } = filters;
    setLoading(true);

    const body = {
      countries: countries !== undefined ? [countries] : undefined,
      direction: direction || "any",
      fraud_types: fraud_types !== undefined ? [fraud_types] : undefined,
      gateway_ids: gateway_ids !== undefined ? [gateway_ids] : undefined,
      network_ids: network_ids !== undefined ? [network_ids] : undefined,
    };
    let strDataParams: string = "";

    if (from_ts) {
      strDataParams = `&from_ts=${from_ts}`;
    } else {
      const from = dateCustom().subtract(1, "month").valueOf();
      strDataParams = `&from_ts=${from}`;
    }

    if (till_ts) {
      strDataParams = strDataParams + `&till_ts=${till_ts}`;
    }

    const { id } = await getLogsId(body, strDataParams);

    const startTime = dateCustom().valueOf();
    let urlData;

    const interval = setInterval(async () => {
      urlData = await getLogsUrl(id);
      onShowLogs(urlData);
    }, 2000);

    const onShowLogs = (urlData: any) => {
      let data = urlData;

      if (data?.status === "done") {
        setLoading(false);
        clearInterval(interval);

        let url = urlData.uri.replace("/console", "/api");

        notification.success({ message: "The file report is ready" });

        window.location.href = window.location.origin + url;
      }

      if (dateCustom().diff(startTime, "seconds") > 15) {
        setLoading(false);
        clearInterval(interval);
        notification.error({ message: "Something wrong, please try again..." });
      }
    };
  };

  const fields = useMemo((): TFields[] => {
    return [
      {
        type: "select",
        label: "Direction",
        name: "direction",
        showLabels: true,
        activeDefault: true,
        items: reference?.direction.map((el) => ({
          label: el,
          value: el,
        })),
      },
      {
        type: "select",
        label: "Fraud type",
        name: "fraud_types",
        showLabels: true,
        activeDefault: true,
        items: reference?.fraudTypes.map((el) => ({
          label: el.full_name,
          value: String(el.value),
        })),
      },
      {
        type: "select",
        label: "Country",
        name: "countries",
        showLabels: true,
        activeDefault: false,
        items: reference?.countries
          .filter((el) => el.code !== "null")
          .map((el) => ({
            label: el.value,
            value: el.code,
          })),
      },
      {
        type: "select",
        label: "Networks",
        name: "network_ids",
        showLabels: true,
        activeDefault: false,
        items: reference?.networks
          .filter((el) => {
            if (filters.countries) {
              return el.country_iso === filters.countries;
            }
            return true;
          })
          .map((el) => ({
            label: `${el.country_iso} / ${el.name}`,
            value: String(el.id),
          })),
      },
      {
        type: "select",
        label: "Carrier",
        name: "gateway_ids",
        showLabels: true,
        activeDefault: false,
        items: reference?.gateways.map((el) => ({
          label: el.name,
          value: String(el.id),
        })),
      },
      {
        type: "range",
        label: "Selected Dates",
        name: "from_ts/till_ts",
        showLabels: true,
        activeDefault: true,
        utc: false,
      },
    ];
  }, [
    filters.countries,
    reference?.countries,
    reference?.direction,
    reference?.fraudTypes,
    reference?.gateways,
    reference?.networks,
  ]);

  const onChangeFilters = (
    values: Record<string, PossibleValues>,
    queryString: string
  ) => {
    history.replace(`${queryString}`);

    setFiltersArray(values).then((filters: TFiltersCalls) => {
      getCallsAsync(filters, false, true);
      setFraudScheduleAsync(filters);
    });
  };

  return (
    <div className={s.wrapper} ref={anchor} id="anchor">
      <div className={s.content}>
        <div className={s.header}>
          <div className={s.title}>Fraud calls</div>
          <div className={s.buttons}>
            <Button
              className={s.btnFlat}
              onClick={downloadData}
              loading={loading}
              disabled={user && !hasPerm(user, Perms.FRAUD_LOGS_ANY)}
            >
              <IconDownload width="2rem" />
              DOWNLOAD
            </Button>
          </div>
        </div>

        <div className={s.filtersWrapper}>
          <FilterForm
            fields={fields}
            name="abh.cr.log"
            loading={loadingFraudState}
            onChange={onChangeFilters}
          />
        </div>

        <div className={s.wrapperSchedule}>
          <FraudSchedule />
        </div>

        {user &&
        !hasPerm(user, Perms.FRAUD_RECORDS_ANY, Perms.FRAUD_RECORDS_READ) ? (
          <NoPerms />
        ) : isLoaded ? (
          <Spin indicator={antIcon} className={s.spin} />
        ) : (
          <Table
            data={data}
            networks={networks}
            gateways={gateways}
            fraudTypes={fraudTypes}
            direction={direction}
            setPosition={setPosition}
          />
        )}
        <div className={s.loadMoreBtn}>
          <Button
            hidden={data.length === 0}
            loading={loadingFraudState}
            onClick={loadMore}
          >
            Load more
          </Button>
        </div>
      </div>
    </div>
  );
});
