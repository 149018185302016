import { FC, useEffect, useState } from "react";
import { Button, Checkbox, ConfigProvider, Form, Input } from "antd";
import { useHistory } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { Select } from "@vas-common/ui-kit";

import { useNotifications } from "../../../../ui/Notifications";
import { IReport } from "interfaces/IReports";
import { URLS } from "constants/Urls";

import {
  NotificationChannelTag,
  FraudTypesTag,
  CountryTag,
  NetworkTag,
} from "ui/FormControls/Select/tags";
import {
  EPeriodicity,
  periodicityItems,
  PredefinedBlockGeneral,
  PredefinedBlockScope,
} from "../helper";
import {
  NotificationChannel,
  TCountries,
  TFraudType,
  TNetwork,
} from "store/mobx/types";
import {
  NetworkLabel,
  CountryLabel,
  NotificationChannelLabel,
} from "ui/FormControls/Select/labels";
import { RenderEmpty } from "../helper";

import { ReactComponent as IconSend } from "assets/svg/send.svg";
import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";

import s from "./ReportDetail.module.scss";

type Props = {
  isEdit: boolean;
  report?: IReport;
  channels: NotificationChannel[];
  networks: TNetwork[];
  countries: TCountries[];
  fraudTypes: TFraudType[];
  deleteReport: (id: number) => Promise<void>;
  sendReport: (id: number) => Promise<void>;
  createReport: (body: Partial<IReport>) => Promise<void>;
  updateReport: (id: number, body: IReport) => Promise<void>;
};

export type ReportFormValues = {
  name: string;
  periodicity?: EPeriodicity;
  enabled: boolean;
  fraud_types?: number[];
  countries?: string[];
  network_ids?: number[];
  notification_channel_ids: number[];
};

const filteredCountries = (networks: TNetwork[], countries: string[]) => {
  return networks.filter((network) => {
    return countries.some((country) => {
      return country === network.country_iso.toLowerCase();
    });
  });
};

const ReportForm: FC<Props> = ({
  report,
  isEdit,
  deleteReport,
  createReport,
  updateReport,
  sendReport,
  networks,
  countries,
  channels,
  fraudTypes,
}) => {
  const history = useHistory();

  const [initialValues, setInitialValues] = useState<Partial<ReportFormValues>>(
    {
      name: "",
      enabled: false,
      notification_channel_ids: [],
    }
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [networkData, setNetworkData] = useState<TNetwork[]>([]);

  const [form] = Form.useForm<ReportFormValues>();

  const predefined = report?.predefined || false;

  const { handleError, setNotification, resetNotification } = useNotifications(
    () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  );

  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        name: report.name,
        enabled: report.enabled,
        periodicity: report.periodicity,
        fraud_types: report.fraud_types,
        countries: report.countries?.map((el) => el.toLocaleLowerCase()) || [],
        network_ids: report.network_ids,
        notification_channel_ids: report.notification_channel_ids,
      });
      setInitialValues({
        name: report.name,
        enabled: report.enabled,
        notification_channel_ids: report.notification_channel_ids,
      });
    } else {
      form.resetFields();
    }
  }, [report, form]);

  useEffect(() => {
    if (report?.countries) {
      const filteredNetworks = filteredCountries(networks, report.countries);
      setNetworkData(filteredNetworks);
    }
  }, [networks, report]);

  const handleSendReport = (id: number) => {
    setIsLoading(true);
    sendReport(id)
      .then(() => {
        setNotification(
          { type: "success", data: { message: "Report is sent" } },
          1500
        );
      })
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeCountry = (value: string[]) => {
    const filteredNetworks = filteredCountries(networks, value);
    setNetworkData(filteredNetworks);
    const arrayCountries: number[] | undefined =
      form.getFieldValue("countries_iso");
    if (Array.isArray(arrayCountries)) {
      const filtered = filteredNetworks.filter((el) => {
        return arrayCountries.some((country) => {
          return country === el.id;
        });
      });
      form.setFieldsValue({ network_ids: filtered.map((el) => el.id) });
    } else {
      form.setFieldsValue({ network_ids: undefined });
    }
  };

  const handleDeleteReport = () => {
    if (report) {
      deleteReport(report.id)
        .then(() => {
          history.push(URLS.reports);
        })
        .then(() => {
          setNotification(
            { type: "success", data: { message: "Successfully Deleted" } },
            1500
          );
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      className={`${s.container} ${s.form}`}
      initialValues={initialValues}
      layout="vertical"
      autoComplete="off"
      onFinish={(values) => {
        setIsSubmitting(true);
        resetNotification();
        if (isEdit && report) {
          updateReport(report!.id, {
            ...report,
            ...values,
            countries: values?.countries?.map((el) => el.toUpperCase()) || [],
          })
            .then(() => {
              history.push(URLS.reports);
            })
            .then(() => {
              setNotification(
                { type: "success", data: { message: "Successfully Updated" } },
                1500
              );
            })
            .catch((err) => {
              setIsSubmitting(false);
              handleError(err);
            });
        } else {
          createReport(values)
            .then(() => {
              history.push(URLS.reports);
            })
            .then(() => {
              setNotification(
                { type: "success", data: { message: "Successfully Created" } },
                1500
              );
            })
            .catch((err) => {
              setIsSubmitting(false);
              handleError(err);
            });
        }
      }}
      onValuesChange={(changedValues) => {
        if (changedValues.hasOwnProperty("countries")) {
          changeCountry(changedValues.countries);
        }
      }}
    >
      <section className={`${s.grid} ${s.gap24}`}>
        <div>
          <h3 className={s.fontTitle}>General</h3>
          {/*<p className={s.fontBody}>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu.</p>*/}
        </div>
        <div className={`${s.row} ${s.items}`}>
          {!predefined && (
            <div>
              <Form.Item
                label="Report name"
                name="name"
                required={false}
                rules={[{ required: true, message: "Report name is required" }]}
              >
                <Input className={s.input} placeholder="Enter name" />
              </Form.Item>
            </div>
          )}
          <div
            className={`${s.checkboxWrapper} ${
              predefined ? s.predefinedCheckbox : ""
            }`}
          >
            <Form.Item
              name="enabled"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Checkbox className={s.checkbox}>
                <div className={s.body}>Active</div>
              </Checkbox>
            </Form.Item>
          </div>
        </div>
        {predefined ? (
          <PredefinedBlockGeneral report={report} />
        ) : (
          <div className={s.row}>
            <Form.Item
              label="Periodicity"
              name="periodicity"
              required={false}
              rules={[{ required: true, message: "Periodicity is required" }]}
            >
              <Select
                placeholder="Select periodicity"
                options={periodicityItems.map((el) => ({
                  value: el.value,
                  label: el.label.charAt(0).toUpperCase() + el.label.slice(1),
                }))}
              />
            </Form.Item>
          </div>
        )}
        <div>
          <h3 className={s.fontTitle}>Scope</h3>
          <p className={s.fontBody}>
            Define the parameters of fraudulent traffic in the report.
          </p>
        </div>
        {predefined ? (
          <PredefinedBlockScope />
        ) : (
          <>
            <div className={s.row}>
              <Form.Item label="Fraud Types" name="fraud_types">
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Select fraud type"
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={fraudTypes
                    .filter((item) => item.is_used)
                    .map((el) => ({
                      value: el.value,
                      label: el.full_name,
                    }))}
                  tagRender={FraudTypesTag}
                />
              </Form.Item>
            </div>
            <div className={s.row}>
              <Form.Item
                label="Countries"
                name="countries"
                tooltip={{
                  title:
                    "Select countries for the further destination networks choice. If some countries are selected, and no destination networks are chosen, " +
                    "it means that all the destination networks of the selected countries are chosen.",
                  icon: <IconHelp width="1.6rem" />,
                }}
              >
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Select country"
                  options={countries.map((country) => ({
                    value: country.code.toLocaleLowerCase(),
                    label: <CountryLabel country={country} />,
                  }))}
                  filterOption={(input, option: any) =>
                    (option?.label?.props?.country?.value.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  tagRender={CountryTag}
                />
              </Form.Item>
            </div>
            <div className={s.row}>
              <Form.Item
                label="Destination Network"
                name="network_ids"
                tooltip={{
                  title:
                    "Choose destination networks among those, which refer to the selected countries. " +
                    "If some countries are selected, and one/some destination networks are chosen, then only these chosen " +
                    "destination networks (not the whole countries) will be considered in the report.",
                  icon: <IconHelp width="1.6rem" />,
                }}
              >
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Select network"
                  filterOption={(input, option: any) =>
                    (option?.label?.props?.network?.name.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={networkData.map((network) => ({
                    value: network.id,
                    label: <NetworkLabel network={network} />,
                  }))}
                  tagRender={NetworkTag}
                />
              </Form.Item>
            </div>
          </>
        )}
        <div>
          <h3 className={s.fontTitle}>Send to</h3>
        </div>
        <div className={s.row}>
          <ConfigProvider renderEmpty={() => <RenderEmpty />}>
            <Form.Item
              label="Notification channels"
              name="notification_channel_ids"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Notification channels is required",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                mode="multiple"
                filterOption={(input, option: any) =>
                  (option?.label?.props?.channel?.name.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select Notification Channels"
                options={channels.map((el) => ({
                  value: el.id,
                  label: <NotificationChannelLabel channel={el} />,
                }))}
                tagRender={NotificationChannelTag}
              />
            </Form.Item>
          </ConfigProvider>
        </div>
      </section>
      <section className={s.buttonBar}>
        <div className={s.buttonsRow}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsError, getFieldsValue }) => {
              const isValid = getFieldsError().every(
                (field) => field.errors.length === 0
              );
              const currentValues = getFieldsValue();
              const isChanged = !isEqual(currentValues, initialValues);
              return (
                <>
                  <Button
                    className={s.btnPrimary}
                    htmlType="submit"
                    disabled={!isChanged || !isValid}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                </>
              );
            }}
          </Form.Item>
          {!predefined && (
            <Button className={s.btnSecondary} onClick={handleDeleteReport}>
              {isEdit ? "Delete" : "Rest"}
            </Button>
          )}
        </div>
        <div>
          {isEdit && report && report.notification_channel_ids.length > 0 && (
            <div>
              <Button
                className={s.btnFlat}
                onClick={() => handleSendReport(report.id)}
                loading={isLoading}
              >
                Send now
                <IconSend width="2rem" />
              </Button>
            </div>
          )}
        </div>
      </section>
    </Form>
  );
};

export default ReportForm;
