import { useState } from "react";
import { Upload as _Upload, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { prefixListValuesValidateCsv } from "utils/api";
import { URLS } from "constants/Urls";
import { useStores } from "store/mobx/useStore";
import { useNotifications } from "ui/Notifications";
import { showConfirm as _showConfirm } from "ui/Modals";

import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as IconUpload } from "assets/svg/upload.svg";

import type { PrefixListValidationResult } from "utils/api";
import type { UploadProps } from "antd";

import s from "./index.module.scss";

const { Dragger } = _Upload;

type Props = {
  onOk: () => void;
  onCancel: () => void;
  validationResult: PrefixListValidationResult;
};

export const showConfirm = ({ onCancel, onOk, validationResult }: Props) => {
  _showConfirm({
    title: "Add new values",
    content: <ModalContent {...validationResult} />,
    okText: "save",
    onOk: () => {
      onOk();
    },
    onCancel: () => {
      onCancel();
    },
  });
};

export const ModalContent = ({
  add_count,
  ignore_count,
  ignored_values,
}: PrefixListValidationResult) => {
  return (
    <div>
      <p>
        {add_count} value{add_count > 1 || add_count === 0 ? "s" : ""} will be
        added.
      </p>

      {Boolean(ignore_count) && (
        <>
          <p>
            {` ${ignore_count} invalid value${
              ignore_count > 1 ? "s" : ""
            } will be ignored:`}
          </p>

          {ignored_values.length > 20 ? null : (
            <ul className={s.ignoredValuesList}>
              {ignored_values.map((item) => (
                <li key={item}>
                  <div>{item}</div>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

type CustomUploadProps = {
  disabled: boolean;
};

export const Upload = ({ disabled }: CustomUploadProps) => {
  const [uploading, setUploading] = useState(false);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const { handleError } = useNotifications(() =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  );

  const {
    firewallStore: { prefixListValuesAddCsv },
  } = useStores();

  const uploadProps: UploadProps = {
    name: "file",
    className: s.upload,
    multiple: false,
    action: "/api/v1/upload",
    onChange(info) {
      const { status } = info.file;

      setUploading(true);

      if (status === "done") {
        const newFile = info.file?.response?.filename || "";

        return prefixListValuesValidateCsv({
          file_path: newFile,
        })
          .then((validationResult) => {
            showConfirm({
              onOk: () => {
                return prefixListValuesAddCsv(Number(id), {
                  file_path: newFile,
                })
                  .then(() => {
                    history.push(`${URLS.prefixLists}/${id}/view`);
                  })
                  .catch((err) => {
                    handleError(err);
                    setUploading(false);
                  });
              },
              onCancel: () => {
                setUploading(false);
              },
              validationResult,
            });
          })
          .catch((err) => {
            handleError(err);
          });
      } else if (status === "error") {
        handleError({ message: 'Upload "Error"' });
      }
    },

    showUploadList: false,
    maxCount: 1,
    disabled,
  };

  return (
    <Dragger {...uploadProps}>
      <div className={s.uploadIcons}>
        <IconUpload width="4.8rem" className={`${uploading ? s.hide : ""}`} />
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          className={`${s.uploadSpin} ${uploading ? s.show : ""}`}
          spinning
        />
      </div>

      <p className={s.fontBody}>Drag & drop to upload</p>
      <p className={s.fontBody}>
        or <b>browse</b>
      </p>
    </Dragger>
  );
};
