import { observer } from "mobx-react";
import { Spin } from "antd";

import { useStores } from "../../../store/mobx/useStore";

import s from "./index.module.scss";

export const FraudTypes = observer(() => {
  const {
    referenceStore: {
      reference: { fraudTypes, loading },
    },
  } = useStores();

  return (
    <div className={s.container}>
      <div className={s.mainContainer}>
        <div className={s.title}>Fraud types</div>
        {loading ? (
          <div className={s.wrapperLoader}>
            <Spin />
          </div>
        ) : (
          <>
            {fraudTypes
              .filter((x) => x.description)
              .map((x) => (
                <TypeItem
                  key={x.value}
                  full_name={x.full_name}
                  description={x.description}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
});

type Props = {
  full_name: string;
  description?: string;
};

const TypeItem = ({ full_name, description }: Props) => {
  return (
    <div className={s.itemWrapper}>
      <div className={s.itemName}>{full_name}</div>
      <div className={s.itemDescription}>{description}</div>
    </div>
  );
};
