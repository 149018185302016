import React from "react";
import {Link} from "react-router-dom";

import {IReport} from "../../../interfaces/IReports";
import {URLS} from "../../../constants/Urls";

import s from "./detail/ReportDetail.module.scss";

export enum EPeriodicity {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly'
}

export const periodicityItems: {label: string, value: string}[] = [
  {label:EPeriodicity.Daily, value: EPeriodicity.Daily.toLowerCase()},
  {label:EPeriodicity.Weekly, value: EPeriodicity.Weekly.toLowerCase()},
  {label:EPeriodicity.Monthly, value: EPeriodicity.Monthly.toLowerCase()}
];

export const PredefinedBlockGeneral = ({report}: {report?: IReport}) => {
  return (
    <>
      <div className={s.row}>
        <div className={s.fontSubtitle}>Report name</div>
        <p className={s.fontBody}><span className={s.capitalize}>{`${report?.periodicity} `}</span>overall report</p>
      </div>
      <div className={s.row}>
        <div className={s.fontSubtitle}>Periodicity</div>
        <p><span className={s.capitalize}>{report?.periodicity}</span></p>
      </div>
    </>
  )
}

export const PredefinedBlockScope = () => {
  return (
    <>
      <div className={s.row}>
        <div className={s.fontSubtitle}>Fraud types</div>
        <p className={s.fontBody}>All types</p>
      </div>
      <div className={s.row}>
        <div className={s.fontSubtitle}>Countries</div>
        <p className={s.fontBody}>All countries</p>
      </div>
      <div className={s.row}>
        <div className={s.fontSubtitle}>Destination Networks</div>
        <p className={s.fontBody}>All networks</p>
      </div>
    </>
  )
}

export const RenderEmpty = () => {
  return (
    <div className={s.empty}>
      You have no channels to attach. Please, go to
      <Link to={URLS.channels}>Notification Channels</Link>
      section to add them.
    </div>
  )
}
