import { useState } from "react";
import { Button, ConfigProvider, Form, DatePicker } from "antd";
import { useHistory } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { Select } from "@vas-common/ui-kit";

import { useNotifications } from "../../../../ui/Notifications";

import {
  NotificationChannelTag,
  FraudTypesTag,
  CountryTag,
  NetworkTag,
} from "ui/FormControls/Select/tags";
import {
  NetworkLabel,
  CountryLabel,
  NotificationChannelLabel,
} from "ui/FormControls/Select/labels";
import { RenderEmpty } from "../helper";
import {
  NotificationChannel,
  TCountries,
  TFraudType,
  TNetwork,
} from "../../../../store/mobx/types";
import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";
import { ReactComponent as IconCalendar } from "assets/svg/calendar.svg";
import { ReactComponent as IconClear } from "assets/svg/cancel-circle.svg";
import { IReportBodyGenerate } from "../../../../interfaces/IReports";
import { URLS } from "../../../../constants/Urls";
import dictionary from "assets/dictionary.json";

import s from "../detail/ReportDetail.module.scss";

export type ReportFormValues = {
  dates: Date[];
  fraud_types?: number[];
  countries?: string[];
  network_ids?: number[];
  notification_channel_ids?: number[];
};

const initialValues: ReportFormValues = {
  dates: [],
  fraud_types: [],
  countries: [],
  network_ids: [],
  notification_channel_ids: [],
};

type Props = {
  channels: NotificationChannel[];
  networks: TNetwork[];
  countries: TCountries[];
  fraudTypes: TFraudType[];
  generateReport: (data: IReportBodyGenerate) => Promise<void>;
};

const GenerateReportForm = ({
  channels,
  networks,
  countries,
  fraudTypes,
  generateReport,
}: Props) => {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm<ReportFormValues>();

  const { handleError, setNotification, resetNotification } = useNotifications(
    () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  );

  return (
    <Form
      form={form}
      className={`${s.container} ${s.form}`}
      initialValues={initialValues}
      layout="vertical"
      autoComplete="off"
      onFinish={(values) => {
        setIsSubmitting(true);
        resetNotification();

        const {
          dates,
          countries,
          notification_channel_ids,
          fraud_types,
          network_ids,
        } = values;

        const [from_ts, till_ts] = dates;

        const body: IReportBodyGenerate = {
          from_ts: from_ts.valueOf(),
          till_ts: till_ts.valueOf(),
          countries: countries?.length ? countries : [],
          fraud_types: fraud_types ? fraud_types : [],
          network_ids: network_ids ? network_ids : [],
          notification_channel_ids: notification_channel_ids
            ? notification_channel_ids
            : [],
        };

        return generateReport(body)
          .then(() => {
            history.push(URLS.reports);
          })
          .then(() => {
            setNotification(
              { type: "success", data: { message: "Successfully generated" } },
              1500
            );
          })
          .catch((err) => {
            setIsSubmitting(false);
            handleError(err);
          });
      }}
    >
      <section className={`${s.grid} ${s.gap24}`}>
        <h3 className={s.fontTitle}>Scope</h3>
        {/*<p className={s.fontBody}>Define the parameters of fraudulent traffic in the report.</p>*/}

        <Form.Item
          label="Time Range"
          name="dates"
          required={false}
          rules={[{ required: true, message: "Time range is required" }]}
        >
          <DatePicker.RangePicker
            className={s.datePicker}
            suffixIcon={<IconCalendar width="2rem" />}
            clearIcon={<IconClear width="2rem" />}
          />
        </Form.Item>

        <Form.Item label="Fraud Types" name="fraud_types">
          <Select
            allowClear
            showSearch
            mode="multiple"
            placeholder="Select fraud type"
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={fraudTypes
              .filter((item) => item.is_used)
              .map((el) => ({
                value: el.value,
                label: el.full_name,
              }))}
            tagRender={FraudTypesTag}
          />
        </Form.Item>

        <Form.Item
          label="Countries"
          name="countries"
          tooltip={{
            title: dictionary.tooltips.reports.generateReport.countries,
            icon: <IconHelp width="1.6rem" />,
          }}
        >
          <Select
            allowClear
            showSearch
            mode="multiple"
            placeholder="Select country"
            options={countries.map((country) => ({
              value: country.code,
              label: <CountryLabel country={country} />,
            }))}
            filterOption={(input, option: any) =>
              (option?.label?.props?.country?.value.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            tagRender={CountryTag}
            onChange={(value: string[]) => {
              const currentNetworkIds = form.getFieldValue(
                "network_ids"
              ) as number[];

              // populate networks
              const currentNetworks = currentNetworkIds.map((id) =>
                networks.find((network) => network.id === id)
              );

              const filteredNetworkIds = currentNetworks
                .filter((network) =>
                  value.some((val) => val === network?.country_iso)
                )
                .map((item) => item?.id);

              form.setFieldValue("network_ids", filteredNetworkIds);
            }}
          />
        </Form.Item>

        <Form.Item noStyle dependencies={["countries"]}>
          {({ getFieldValue }) => {
            const currentCountries = getFieldValue("countries") as string[];
            const isEmptyCountries = !currentCountries.length;

            const filteredNetworks = isEmptyCountries
              ? []
              : networks.filter((network) => {
                  return currentCountries.some(
                    (country) => network.country_iso === country
                  );
                });

            return (
              <Form.Item
                label="Destination Network"
                name="network_ids"
                tooltip={{
                  title:
                    dictionary.tooltips.reports.generateReport
                      .destinationNetwork,
                  icon: <IconHelp width="1.6rem" />,
                }}
              >
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Select network"
                  filterOption={(input, option: any) =>
                    (option?.label?.props?.network?.name.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={filteredNetworks.map((network) => ({
                    value: network.id,
                    label: <NetworkLabel network={network} />,
                  }))}
                  tagRender={NetworkTag}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <h3 className={s.fontTitle}>Send to</h3>

        <div className={s.row}>
          <ConfigProvider renderEmpty={() => <RenderEmpty />}>
            <Form.Item
              label="Notification channels"
              name="notification_channel_ids"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Notification channels is required",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                mode="multiple"
                placeholder="Select Notification Channels"
                filterOption={(input, option: any) =>
                  (option?.label?.props?.channel?.name.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={channels.map((el) => ({
                  value: el.id,
                  label: <NotificationChannelLabel channel={el} />,
                }))}
                tagRender={NotificationChannelTag}
              />
            </Form.Item>
          </ConfigProvider>
        </div>
      </section>

      <div className={s.buttonsRow}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldsError, getFieldsValue }) => {
            const isValid = getFieldsError().every(
              (field) => field.errors.length === 0
            );
            const currentValues = getFieldsValue();
            const isChanged = !isEqual(currentValues, initialValues);
            return (
              <>
                <Button
                  className={s.btnPrimary}
                  htmlType="submit"
                  disabled={!isChanged || !isValid}
                  loading={isSubmitting}
                >
                  Generate
                </Button>
              </>
            );
          }}
        </Form.Item>

        <Button className={s.btnSecondary} onClick={() => form.resetFields()}>
          Reset
        </Button>
      </div>
    </Form>
  );
};

export default GenerateReportForm;
