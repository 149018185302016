import { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Input } from "antd";
// TODO move new filters to UI-Kit
// import { FilterForm } from "@vas-common/ui-kit";
import { FilterForm } from "./filter";
import { Select, DateRangePicker } from "@vas-common/ui-kit";
import { dateUtc } from "utils/dates";

import { useStores } from "store/mobx/useStore";
import { RejectedCallsTable } from "./RejectedCallsTable";
import { Portal } from "ui/Portal";
import { useNotifications } from "ui/Notifications";

import { useIntersectionObserver } from "utils/useIntersectionObserver";

import { ReactComponent as IconDownload } from "assets/svg/download.svg";

import type { FilterField } from "./filter";

import s from "./index.module.scss";

const RejectedCalls = observer(() => {
  const history = useHistory();

  const headerRef = useRef<HTMLDivElement | null>(null);
  const [isShown, setIsShown] = useState(false);
  const [ref] = useIntersectionObserver(
    (elements, _observer) => {
      elements.forEach((element) => {
        if (!element.isIntersecting) {
          setIsShown(true);
          headerRef.current?.classList.add(s.tStick);
        } else {
          setIsShown(false);
          headerRef.current?.classList.remove(s.tStick);
        }
      });
    },
    {
      threshold: [0.5],
    }
  );

  const {
    referenceStore: { reference, callControlRulesByTypes },
    callsStore: {
      terminatedCalls,
      downloadTerminatedCallsReport,
      setTerminatedCallsReportIsLoading,
      isTerminatedCallsReportLoading,
    },
  } = useStores();

  const { handleError } = useNotifications();

  const { isLoading } = terminatedCalls;
  const { direction } = reference;

  const onFilterChange = (values: unknown, queryString: string) => {
    history.push({
      search: queryString,
    });
  };

  const handleDownloadReport = () =>
    downloadTerminatedCallsReport()
      .catch(handleError)
      .finally(() => setTerminatedCallsReportIsLoading(false));

  const FILTER_FIELDS = useMemo((): FilterField[] => {
    return [
      {
        label: "Call Direction",
        name: "direction",
        component: (
          <Select
            placeholder="All directions"
            allowClear
            optionFilterProp="children"
            options={direction.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        ),
      },
      {
        name: "rule_type/rule_id",
        label: "Rules",
        parse: ({ queryParams, value }) => {
          if (queryParams) {
            const ruleType = queryParams["rule_type"];
            const ruleId = queryParams["rule_id"];
            return ruleType && ruleId
              ? { "rule_type/rule_id": `${ruleType} ${ruleId}` }
              : {};
          }

          const [ruleType, ruleId] = (value as string).split(" ");
          return ruleType && ruleId
            ? { rule_type: ruleType, rule_id: ruleId }
            : {};
        },
        component: (
          <Select
            placeholder="All rules"
            allowClear
            showSearch
            popupMatchSelectWidth={false}
            options={Object.entries(callControlRulesByTypes).map(
              ([label, options]) => ({
                label,
                options: options.map((item) => ({
                  ...item,
                  value: `${label} ${item.value}`,
                })),
              })
            )}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        ),
      },
      {
        name: "termination_result",
        label: "Termination result",
        component: (
          <Select
            placeholder="All results"
            allowClear
            optionFilterProp="children"
            options={["success", "failure"].map((item) => ({
              label: item,
              value: item,
            }))}
          />
        ),
      },
      {
        label: "A-number",
        name: "a_number",
        normalize: (val) => (!val ? undefined : val),
        component: (
          <Input className={s.input} placeholder="All numbers" allowClear />
        ),
      },
      {
        label: "B-number",
        name: "b_number",
        normalize: (val) => (!val ? undefined : val),
        component: (
          <Input className={s.input} placeholder="All numbers" allowClear />
        ),
      },
      {
        label: "Time Range",
        name: "from/to",
        activeDefault: true,
        alwaysOn: true,
        parse: ({ queryParams, value }) => {
          if (queryParams) {
            const rawFrom = queryParams["from"];
            const rawTo = queryParams["to"];
            return rawFrom && rawTo
              ? { "from/to": [Number(rawFrom), Number(rawTo)] }
              : {};
          }

          const [from, to] = value as [number, number];
          return from && to ? { from, to } : {};
        },
        component: (
          <DateRangePicker
            allowClear
            disabledDate={(current) => current.isAfter(dateUtc())}
            placeholder="All time"
          />
        ),
      },
    ];
  }, [direction, callControlRulesByTypes]);

  return (
    <>
      {/* Container for sticky header */}
      <div ref={headerRef} className={s.stickyHeader} />

      <div className={s.container}>
        <div className={s.head}>
          <h1 className={s.fontMainTitle}>Rejected Calls</h1>

          <Button
            className={s.btnFlat}
            onClick={handleDownloadReport}
            loading={isTerminatedCallsReportLoading}
          >
            <IconDownload width="2rem" />
            DOWNLOAD
          </Button>
        </div>

        <div
          ref={ref}
          className={s.filterContainer}
          data-test="prefixLists-filters"
        >
          <Portal rootElement={headerRef.current} isShown={isShown}>
            <FilterForm
              className={s.filter}
              loading={isLoading}
              showSearchButton
              name="rejectedCalls"
              onChange={onFilterChange}
              fieldsSet={FILTER_FIELDS}
            />
          </Portal>
        </div>

        <RejectedCallsTable />
      </div>
    </>
  );
});

export default RejectedCalls;
