import { Tag } from "antd";

import { useStores } from "store/mobx/useStore";

import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";
import { ReactComponent as IconRectangle } from "assets/svg/rectangle.svg";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import s from "./index.module.scss";

export const PrefixListTag = (props: CustomTagProps) => {
  const { value, onClose, label } = props;

  const {
    firewallStore: { prefixLists },
  } = useStores();

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const foundList = prefixLists.data.find((list) => list.id === value);

  return (
    <Tag onMouseDown={onPreventMouseDown} className={`${s.selectTag}`}>
      {label}

      <IconRectangle height="2rem" className={s.icon} />

      {foundList?.values_count ?? ""}

      <IconCross
        width="2rem"
        onClick={() => onClose()}
        className={`${s.icon} ${s.closeIcon}`}
      />
    </Tag>
  );
};
