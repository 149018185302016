import { Radio } from "antd";
import { RadioChangeEvent } from "antd/es/radio";

import {
  aggregationTypeFilter,
  SAVED_FILTER_TYPE_CALLS_ABH_CR,
} from "utils/constants";
import SelectInterval from "./SelectInterval";
import { useStores } from "store/mobx/useStore";
import { tickFormatter } from "utils/formatNumber";

import s from "./index.module.scss";

type Props = {
  count: number;
  percent: number;
  dataType: string;
};

export const Filter = ({ count, percent, dataType }: Props) => {
  const {
    trafficStore: { setCallsGroupBy, setSizeDefault },
  } = useStores();

  const handleChangeDataType = (e: RadioChangeEvent) => {
    const value = e.target.value;
    localStorage.setItem(SAVED_FILTER_TYPE_CALLS_ABH_CR, value);
    setCallsGroupBy(value);
    setSizeDefault();
  };

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.filterTitle}>
          Found{" "}
          <span className={s.mark}>
            {tickFormatter(count)} ({percent && percent.toFixed(3)}%)
          </span>{" "}
          fraud calls
        </div>

        <div className={s.filterContainer}>
          <Radio.Group
            defaultValue={dataType}
            buttonStyle="solid"
            onChange={handleChangeDataType}
          >
            <Radio.Button
              value={aggregationTypeFilter.country}
              data-test="In-Out-Bound-Filter-ByCountry"
            >
              By Country
            </Radio.Button>
            <Radio.Button
              value={aggregationTypeFilter.network}
              data-test="In-Out-Bound-Filter-ByNetwork"
            >
              By Network
            </Radio.Button>
            <Radio.Button
              value={aggregationTypeFilter.gateway}
              data-test="In-Out-Bound-Filter-ByGateway"
            >
              By Gateway
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className={s.wrapper}>
        <SelectInterval />
      </div>
    </div>
  );
};
