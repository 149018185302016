import { Children, ReactNode, useState } from "react";
import { UIHelpers } from "@vas-common/ui-kit";
import { useHistory } from "react-router-dom";

import { useStores } from "store/mobx/useStore";
import { observer } from "mobx-react";
import { Spin, Switch } from "antd";

import { ReactComponent as IconCheckMark } from "assets/svg/check-mark.svg";
import { ReactComponent as IconArrowInbound } from "assets/svg/arrow-inbound.svg";
import { ReactComponent as IconArrowOutbound } from "assets/svg/arrow-outbound.svg";
import { ReactComponent as IconDot } from "assets/svg/chip-dot.svg";

import { dateCustom } from "utils/dates";
import FlagIcon from "components/flag/FlagIcon";
import { useNotifications } from "ui/Notifications";
import { RULE_ACTION_TAGS_MAP } from "ui/maps";
import { URLS } from "constants/Urls";

import type { Direction } from "interfaces/IRules";

import s from "./index.module.scss";

type ChipProps = {
  children: ReactNode;
  title?: string;
};

const Chip = ({ children, title }: ChipProps) => {
  return (
    <div className={s.chip} title={title}>
      {Children.map(children, (child, ...rest) => {
        return typeof child === "string" ? <span>{child}</span> : child;
      })}
    </div>
  );
};

export const DIRECTION_MAP: Record<Direction, ReactNode> = {
  all: (
    <>
      <Chip title="Inbound">
        <IconArrowInbound width="2rem" />
        Inbound
      </Chip>
      <Chip title="Outbound">
        <IconArrowOutbound width="2rem" />
        Outbound
      </Chip>
    </>
  ),
  inbound: (
    <Chip title="Inbound">
      <IconArrowInbound width="2rem" />
      Inbound
    </Chip>
  ),
  outbound: (
    <Chip title="Outbound">
      <IconArrowOutbound width="2rem" />
      Outbound
    </Chip>
  ),
};

export const FraudRulesTable = observer(() => {
  const history = useHistory();
  const [isLoadingList, setIsLoadingList] = useState<Record<string, boolean>>(
    {}
  );

  const { handleError } = useNotifications();

  const {
    referenceStore: {
      notificationChannels,
      reference: { fraudTypes },
    },
    fraudRulesStore: {
      fraudRules,
      isLoading: isLoadingfraudRules,
      patchFraudRule,
    },
  } = useStores();

  const { isLoading: isLoadingNotificationChannels } = notificationChannels;

  const isLoading = isLoadingfraudRules || isLoadingNotificationChannels;

  const handleRowClick = (ruleId: number) => {
    history.push(`${URLS.editFraudRule({ id: ruleId })}`);
  };

  if (!fraudRules.length || isLoading) {
    return <Spin spinning />;
  }

  return !fraudRules.length && !isLoading ? (
    <div className={s.noData} data-test="fraudRules-no-data">
      There are no rules yet
    </div>
  ) : (
    <table className={s.table} data-test="fraudRules-table">
      <thead>
        <tr>
          <th>Rule Name</th>
          <th>Scope</th>
          <th>Threshold</th>
          <th>Action</th>
          <th>Last Triggered</th>
        </tr>
      </thead>

      {/* Content */}
      <tbody className={s.content}>
        {fraudRules.map((rule) => {
          const extraCountries = (rule.countries?.length || 0) - 3;
          const extraFraudTypes = (rule.fraud_types?.length || 0) - 3;

          const currentFraudTypes = rule.fraud_types?.map((fraudTypeValue) =>
            fraudTypes.find(({ value }) => fraudTypeValue === value)
          );

          const curentCountries = rule.countries?.map((countryCode) => ({
            countryCode,
            countryName: UIHelpers.countries.getCountryName(countryCode),
          }));

          return (
            <tr
              key={rule.id}
              onClick={() => handleRowClick(rule.id)}
              data-test={`fraudRules-item-${rule.id}`}
            >
              {/* Rule Name */}
              <td
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={s.ruleNameCell}
              >
                <Switch
                  className={s.switch}
                  checkedChildren={<IconCheckMark width="2rem" />}
                  onChange={(value) => {
                    setIsLoadingList({
                      ...isLoadingList,
                      [rule.id]: true,
                    });

                    patchFraudRule(rule.id, {
                      enabled: value,
                    })
                      .catch(handleError)
                      .finally(() =>
                        setIsLoadingList((prevState) => ({
                          ...prevState,
                          [rule.id]: false,
                        }))
                      );
                  }}
                  loading={isLoadingList[rule.id]}
                  checked={rule.enabled}
                />

                <div title={rule.name.length > 8 ? rule.name : ""}>
                  {rule.name}
                </div>
              </td>

              {/* Scope */}
              <td>
                <ul className={s.scopeList}>
                  <li className={`${s.row} ${s.gap4}`}>
                    {DIRECTION_MAP[rule.direction]}
                  </li>
                  <li className={`${s.row} ${s.gap4}`}>
                    {currentFraudTypes?.length ? (
                      <>
                        {currentFraudTypes.slice(0, 3).map((item) => (
                          <Chip key={item?.value} title={item?.full_name}>
                            <IconDot width="2rem" />
                            {item?.full_name}
                          </Chip>
                        ))}
                        {extraFraudTypes > 0 ? (
                          <span
                            className={s.more}
                            title={currentFraudTypes
                              .slice(3)
                              .map((item) => item?.full_name)
                              .join("\n")}
                          >
                            +{extraFraudTypes} more
                          </span>
                        ) : null}
                      </>
                    ) : (
                      <Chip>All fraud types</Chip>
                    )}
                  </li>
                  <li className={`${s.row} ${s.gap4}`}>
                    {curentCountries?.length ? (
                      <>
                        {curentCountries
                          .slice(0, 3)
                          .map(({ countryCode, countryName }) => {
                            return (
                              <Chip key={countryCode} title={countryName}>
                                <FlagIcon country={countryCode || "ZZ"} />
                                {countryName}
                              </Chip>
                            );
                          })}
                        {extraCountries > 0 ? (
                          <span
                            className={s.more}
                            title={curentCountries
                              .slice(3)
                              .map(({ countryName }) => countryName)
                              .join("\n")}
                          >
                            +{extraCountries} more
                          </span>
                        ) : null}
                      </>
                    ) : (
                      <Chip>All countries</Chip>
                    )}
                  </li>
                </ul>
              </td>

              {/* Threshold */}
              <td className={!rule.threshold_enabled ? s.disabled : ""}>
                {rule.threshold_enabled ? (
                  <div className={`${s.row} ${s.gap4} ${s.wrap}`}>
                    <span>{rule.threshold_numeric}</span>
                    {`calls / ${rule.threshold_period}`}
                  </div>
                ) : (
                  "-"
                )}
              </td>

              {/* Actions */}
              <td className={s.ruleActionsCell}>
                {rule.action ? RULE_ACTION_TAGS_MAP[rule.action] : null}
              </td>

              {/* Last Triggered */}
              <td
                className={`${s.lastTriggeredCell} ${
                  !rule.last_triggered_at ? s.disabled : ""
                }`}
              >
                <div
                  title={
                    rule.last_triggered_at
                      ? `${dateCustom(rule.last_triggered_at).format()}`
                      : ""
                  }
                >
                  {rule.last_triggered_at
                    ? dateCustom().isBefore(rule.last_triggered_at)
                      ? "just now"
                      : dateCustom(rule.last_triggered_at).fromNow()
                    : "Not triggered yet"}
                </div>

                {!rule.matched_today ? null : (
                  <div>{`${UIHelpers.formatNumber(
                    rule.matched_today
                  )} calls today`}</div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});
