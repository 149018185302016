import api from "utils/api";
import { NotificationChannel } from "store/mobx/types";

export const getNotificationChannels = () =>
  api<NotificationChannel[]>({
    method: "GET",
    url: `/api/v1/notifications/channels`,
  });

export const createNotificationChannel = (data: Partial<NotificationChannel>) =>
  api<NotificationChannel>({
    method: "POST",
    url: `/api/v1/notifications/channels`,
    data,
  });

export const getNotificationChannelById = (id: string) =>
  api<NotificationChannel>({
    method: "GET",
    url: `/api/v1/notifications/channels/${id}`,
  });

export const updateNotificationChannel = (
  id: number,
  data: Partial<NotificationChannel>
) =>
  api<NotificationChannel>({
    method: "PUT",
    url: `/api/v1/notifications/channels/${id}`,
    data,
  });

export const deleteNotificationChannel = (id: number) =>
  api<void>({
    method: "DELETE",
    url: `/api/v1/notifications/channels/${id}`,
  });

export const getAllNotificationChannelsTypes = () =>
  api<unknown>({
    method: "GET",
    url: `/api/v1/notifications/channel-types`,
  });
