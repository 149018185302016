import React from "react";
import { Tag } from "antd";

import FlagIcon from "components/flag/FlagIcon";

import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import s from "./index.module.scss";

export const CountryTag = (props: CustomTagProps) => {
  const { label, value, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag onMouseDown={onPreventMouseDown} className={s.selectTag}>
      {typeof label === 'string' && <FlagIcon country={value || "ZZ"} />}
      {label}
      <IconCross
        className={s.closeIcon}
        width="2rem"
        onClick={() => onClose()}
      />
    </Tag>
  );
};
