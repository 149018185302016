import { ReactComponent as IconReject } from "assets/svg/cancel-circle.svg";
import { ReactComponent as IconAlert } from "assets/svg/bell.svg";
import { ReactComponent as IconCalendar } from "assets/svg/calendar.svg";

import type { ReactNode } from "react";
import type { RuleActions } from "store/mobx/types";

import s from "./index.module.scss";

type Props = {
  alert?: boolean;
  reject?: boolean;
  schedule?: boolean;
};

const RuleAction = ({ alert, reject }: Props) => {
  const style = {
    ...(alert && { "--color": "var(--orange-a)" }),
    ...(reject && { "--color": "var(--danger-base)" }),
  };

  return (
    <div className={s.ruleAction} style={style as React.CSSProperties}>
      <span className={s.circle} />
      {alert && "Alert"}
      {reject && "Reject"}
    </div>
  );
};

const RuleActionTag = ({ reject, alert, schedule }: Props) => {
  const style = {
    ...(alert && {
      "--color": "var(--warning-base)",
      "--icon-color": "var(--warning-base)",
      "--background-color": "var(--warning-base-rgb)",
    }),
    ...(reject && {
      "--color": "var(--danger-base)",
      "--icon-color": "var(--danger-base)",
      "--background-color": "var(--danger-base-rgb)",
    }),
    ...(schedule && {
      "--color": "var(--info-base)",
      "--icon-color": "var(--info-base)",
      "--background-color": "var(--info-base-rgb)",
    }),
  };

  return (
    <div className={s.ruleActionTag} style={style as React.CSSProperties}>
      {alert && (
        <>
          <IconAlert width="2rem" />
          Alert
        </>
      )}

      {reject && (
        <>
          <IconReject width="2rem" />
          Reject
        </>
      )}

      {schedule && (
        <>
          <IconCalendar width="2rem" />
          Schedule Attempts
        </>
      )}
    </div>
  );
};

export const RULE_ACTIONS_MAP: Record<RuleActions, ReactNode> = {
  block: <RuleAction reject />,
  alert: <RuleAction alert />,
  block_and_alert: (
    <>
      <RuleAction alert /> <RuleAction reject />
    </>
  ),
};

export const RULE_ACTION_TAGS_MAP: Record<RuleActions | "schedule", ReactNode> =
  {
    block: <RuleActionTag reject />,
    alert: <RuleActionTag alert />,
    block_and_alert: (
      <>
        <RuleActionTag alert /> <RuleActionTag reject />
      </>
    ),
    schedule: <RuleActionTag schedule />,
  };
