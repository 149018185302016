import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Spin, Tooltip } from "antd";
import { observer } from "mobx-react";
import { Switch } from "@vas-common/ui-kit";

import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";
import { RULE_ACTION_TAGS_MAP, NOTIFICATION_TYPE_ICONS_MAP } from "ui/maps";
import {
  dateCustom,
  // getDurationInLagrestUnits
} from "utils/dates";
import { useNotifications } from "ui/Notifications";
import dictionary from "assets/dictionary.json";

import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";

import s from "./index.module.scss";

const PredefinedRules = observer(() => {
  const isCanceled = useRef(false);
  const [isLoadingTcg, setIsloadingTcg] = useState(false);
  const [isLoadingLocationFalsification, setIsloadingLocationFalsification] =
    useState(false);
  const [isLoadingFlashcallProtection, setIsloadingFlashcallProtection] =
    useState(false);

  const { resetNotification, handleError } = useNotifications(() =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  );

  const {
    firewallStore: {
      getLocationFalsificationRuleSettings,
      getFlashcallProtectionRuleSettings,
      getTcgRuleSettings,
      patchTcgRuleSettings,
      patchLocationFalsificationRuleSettings,
      patchFlashcallProtectionRuleSettings,
      getSipRejectCodes,
      settings,
      sipRejectCodes,
    },
    referenceStore: { notificationChannels },
  } = useStores();

  useEffect(() => {
    // TODO handle error with Notifications component for all four requests
    getLocationFalsificationRuleSettings();
    getFlashcallProtectionRuleSettings();
    getTcgRuleSettings();
    getSipRejectCodes();
  }, [
    getLocationFalsificationRuleSettings,
    getFlashcallProtectionRuleSettings,
    getTcgRuleSettings,
    getSipRejectCodes,
  ]);

  useEffect(() => {
    return () => {
      isCanceled.current = true;
    };
  }, []);

  const { tcg, locationFalsification, flashcallProtection } = settings;

  return (
    <div className={s.container}>
      <h1 className={s.fontMainTitle}>Predefined Rules</h1>

      {/* TCG */}
      <Spin spinning={tcg.isLoading} data-test="tcg-calls-spin">
        <div className={s.widget}>
          <div className={s.headRow}>
            <div className={s.title}>
              <Switch
                onChange={(value) => {
                  setIsloadingTcg(true);
                  resetNotification();

                  return patchTcgRuleSettings({ enabled: value })
                    .catch(isCanceled.current ? null : handleError)
                    .finally(() => {
                      if (isCanceled.current) return;
                      setIsloadingTcg(false);
                    });
                }}
                loading={isLoadingTcg}
                checked={tcg.settings?.enabled}
                data-test="tcg-calls-switch"
              />
              <h2 className={s.fontTitle} data-test="tcg-calls-title">
                Reject TCG Calls
              </h2>
            </div>

            <div className={s.buttons}>
              <Link
                to={{
                  pathname: URLS.rejectedCalls,
                  search: `rule_type=${tcg.settings?.type}&rule_id=${tcg.settings?.id}`,
                }}
                data-test="tcg-calls-view"
              >
                <Button className={s.btnSecondary}>View Rejected Calls</Button>
              </Link>
            </div>
          </div>

          <div className={s.rowEven}>
            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Actions</h3>
              <div className={s.rowActions}>
                {tcg.settings && RULE_ACTION_TAGS_MAP[tcg.settings.action]}
              </div>
            </div>

            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Last Triggered</h3>
              <div>
                {tcg.settings?.last_triggered_at
                  ? dateCustom(tcg.settings.last_triggered_at).format()
                  : "-"}
              </div>
            </div>

            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Applied times today</h3>
              <div>{tcg.settings?.matched_today || 0}</div>
            </div>
          </div>

          <div>
            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Description</h3>
              <div className={s.item}>
                {dictionary.descriptions.rules.tcg}
                <br />
                <br />
                {dictionary.notes.support.notAccessToTestCallGeneration}
              </div>
            </div>
          </div>
        </div>
      </Spin>

      <hr className={s.hr} />

      {/* Location Falsification */}
      <Spin
        spinning={
          locationFalsification.isLoading ||
          sipRejectCodes.isLoading ||
          notificationChannels.isLoading
        }
        data-test="location-falsification-spin"
      >
        <div className={s.widget}>
          <div className={s.headRow}>
            <div className={s.title}>
              <Switch
                onChange={(value) => {
                  setIsloadingLocationFalsification(true);
                  resetNotification();

                  return patchLocationFalsificationRuleSettings({
                    enabled: value,
                  })
                    .catch(isCanceled.current ? null : handleError)
                    .finally(() => {
                      if (isCanceled.current) return;
                      setIsloadingLocationFalsification(false);
                    });
                }}
                loading={isLoadingLocationFalsification}
                checked={locationFalsification.settings?.enabled}
                data-test="location-falsification-switch"
              />
              <h2
                className={s.fontTitle}
                data-test="location-falsification-title"
              >
                Location Falsification Detection
              </h2>
            </div>

            <div className={s.buttons}>
              <Link
                to={URLS.predefinedRulesLocationFalsification}
                data-test="location-falsification-edit"
              >
                <Button className={s.btnPrimary}>Edit</Button>
              </Link>

              <Link
                to={{
                  pathname: URLS.rejectedCalls,
                  search: `rule_type=${locationFalsification.settings?.type}&rule_id=${locationFalsification.settings?.id}`,
                }}
                data-test="location-falsification-view"
              >
                <Button className={s.btnSecondary}>View Rejected Calls</Button>
              </Link>
            </div>
          </div>

          <div className={s.rowEven}>
            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Actions</h3>
              <div className={s.rowActions}>
                {locationFalsification.settings &&
                  RULE_ACTION_TAGS_MAP[locationFalsification.settings.action]}
              </div>
            </div>

            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Last Triggered</h3>
              <div>
                {locationFalsification.settings?.last_triggered_at
                  ? dateCustom(
                      locationFalsification.settings.last_triggered_at
                    ).format()
                  : "-"}
              </div>
            </div>

            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Applied times today</h3>
              <div>{locationFalsification.settings?.matched_today || 0}</div>
            </div>
          </div>

          {Boolean(locationFalsification.settings?.show_sip_reject_codes) && (
            <div className={s.item}>
              <h3 className={`${s.fontSubtitle} ${s.flex}`}>
                SIP Reject Codes
                <Tooltip
                  placement="right"
                  title={dictionary.tooltips.sipRejectCodes}
                  className={s.sipTooltip}
                >
                  <IconHelp width="2rem" />
                </Tooltip>
              </h3>
              <div className={s.sipCodes}>
                {Boolean(
                  locationFalsification.settings?.sip_reject_codes.length
                )
                  ? locationFalsification.settings?.sip_reject_codes.map(
                      (sipCode) => {
                        const currentSipCode = sipRejectCodes.data.find(
                          ({ code }) => code === sipCode
                        );

                        return (
                          <div
                            className={s.sipCode}
                            key={sipCode}
                            title={currentSipCode?.label}
                          >
                            {sipCode}
                          </div>
                        );
                      }
                    )
                  : "-"}
              </div>
            </div>
          )}

          <div className={s.item}>
            <h3 className={s.fontSubtitle}>Notification Channels</h3>
            <div className={s.channels}>
              {locationFalsification.settings?.notification_channel_ids &&
              notificationChannels.data.length
                ? locationFalsification.settings.notification_channel_ids.map(
                    (channel) => {
                      const currentChannel = notificationChannels.data.find(
                        (item) => item.id === channel
                      );

                      return !currentChannel ? null : (
                        <div className={s.channel} key={currentChannel.id}>
                          {NOTIFICATION_TYPE_ICONS_MAP[currentChannel.type]}
                          {currentChannel.name}
                        </div>
                      );
                    }
                  )
                : "-"}
            </div>
          </div>

          <div className={s.item}>
            <h3 className={s.fontSubtitle}>Description</h3>
            <div className={s.item}>
              {dictionary.descriptions.rules.locationFalsification}
              <br />
              <br />
              {dictionary.notes.support.notSupportFeature}
            </div>
          </div>
        </div>
      </Spin>

      <hr className={s.hr} />

      {/* Flashcall Protection */}
      <Spin
        spinning={
          flashcallProtection.isLoading ||
          sipRejectCodes.isLoading ||
          notificationChannels.isLoading
        }
        data-test="flashcall-protection-spin"
      >
        <div className={s.widget}>
          <div className={s.headRow}>
            <div className={s.title}>
              <Switch
                onChange={(value) => {
                  setIsloadingFlashcallProtection(true);
                  resetNotification();

                  return patchFlashcallProtectionRuleSettings({
                    enabled: value,
                  })
                    .catch(isCanceled.current ? null : handleError)
                    .finally(() => {
                      if (isCanceled.current) return;
                      setIsloadingFlashcallProtection(false);
                    });
                }}
                loading={isLoadingFlashcallProtection}
                checked={flashcallProtection.settings?.enabled}
                data-test="flashcall-protection-switch"
              />
              <h2
                className={s.fontTitle}
                data-test="flashcall-protection-title"
              >
                Flashcall Protection
              </h2>
            </div>

            <div className={s.buttons}>
              {/* <Link to={URLS.predefinedRulesFlashcallProtection}>
                <Button className={s.btnPrimary}>Edit</Button>
              </Link> */}

              <Link
                to={{
                  pathname: URLS.rejectedCalls,
                  search: `rule_type=${flashcallProtection.settings?.type}&rule_id=${flashcallProtection.settings?.id}`,
                }}
                data-test="flashcall-protection-view"
              >
                <Button className={s.btnSecondary}>View Rejected Calls</Button>
              </Link>
            </div>
          </div>

          <div className={s.rowEven}>
            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Actions</h3>
              <div className={s.rowActions}>
                {RULE_ACTION_TAGS_MAP.schedule}
              </div>
            </div>

            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Last Triggered</h3>
              <div>
                {flashcallProtection.settings?.last_triggered_at
                  ? dateCustom(
                      flashcallProtection.settings.last_triggered_at
                    ).format()
                  : "-"}
              </div>
            </div>

            <div className={s.item}>
              <h3 className={s.fontSubtitle}>Applied times today</h3>
              <div>{flashcallProtection.settings?.matched_today || 0}</div>
            </div>
          </div>

          {/* <div className={s.rowEven}> */}
          {/* <div className={s.item}>
              <h3 className={s.fontSubtitle}>Reject Interval</h3>
              <div className={s.rowActions}>
                {flashcallProtection.settings?.reject_interval
                  ? `${flashcallProtection.settings?.reject_interval} seconds`
                  : "-"}
              </div>
            </div> */}

          {/* <div className={s.item}>
              <h3 className={s.fontSubtitle}>Allow Interval</h3>
              <div>
                {flashcallProtection.settings
                  // TODO chenge return format
                  ? getDurationInLagrestUnits(
                      0,
                      flashcallProtection.settings?.allow_interval
                    )
                  : "-"}
              </div>
            </div> */}

          {/* Placeholder for even grid */}
          {/* <div className={s.item} />
          </div> */}

          {/* {Boolean(flashcallProtection.settings?.show_sip_reject_codes) && (
            <div className={s.item}>
              <h3 className={`${s.fontSubtitle} ${s.flex}`}>
                SIP Reject Codes
                <Tooltip
                  placement="right"
                  title={dictionary.tooltips.sipRejectCodes}
                  className={s.sipTooltip}
                >
                  <IconHelp width="2rem" />
                </Tooltip>
              </h3>

              <div className={s.sipCodes}>
                {Boolean(flashcallProtection.settings?.sip_reject_codes.length)
                  ? flashcallProtection.settings?.sip_reject_codes.map(
                      (sipCode) => {
                        const currentSipCode = sipRejectCodes.data.find(
                          ({ code }) => code === sipCode
                        );

                        return (
                          <div
                            className={s.sipCode}
                            key={sipCode}
                            title={currentSipCode?.label}
                          >
                            {sipCode}
                          </div>
                        );
                      }
                    )
                  : "-"}
              </div>
            </div>
          )} */}

          <div className={s.item}>
            <h3 className={s.fontSubtitle}>Description</h3>
            <div className={s.item}>
              {dictionary.descriptions.rules.flashcallProtection}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
});

export default PredefinedRules;
