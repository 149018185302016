import { createContext } from "react";

import { NotificationsStore } from "./NotificationsStore";
import { StatisticsStore } from "./StatisticsStore";
import { TrafficStore } from "./TrafficStore";
import { CallsStore } from "./CallsStore";
import { ReferenceStore } from "./ReferenceStore";
import { FirewallStore } from "./FirewallStore";
import { UserStore } from "./UserStore";
import { StatusInfoStore } from "./StatusInfoStore";
import { SettingsStore } from "./SettingsStore";
import { FraudRulesStore } from "./FraudRulesStore";
import { MessagesStore } from "./MessagesStore";

class RootStore {
  // to implement Singleton pattern
  static instance: RootStoreType;

  notificationsStore!: NotificationsStore;
  referenceStore!: ReferenceStore;
  statisticsStore!: StatisticsStore;
  trafficStore!: TrafficStore;
  callsStore!: CallsStore;
  userStore!: UserStore;
  firewallStore!: FirewallStore;
  statusInfoStore!: StatusInfoStore;
  settingsStore!: SettingsStore;
  fraudRulesStore!: FraudRulesStore;
  messagesStore!: MessagesStore;

  constructor() {
    if (!RootStore.instance) {
      this.notificationsStore = new NotificationsStore(this);
      this.referenceStore = new ReferenceStore(this);
      this.statisticsStore = new StatisticsStore(this);
      this.trafficStore = new TrafficStore(this);
      this.callsStore = new CallsStore(this);
      this.userStore = new UserStore(this);
      this.firewallStore = new FirewallStore(this);
      this.statusInfoStore = new StatusInfoStore(this);
      this.settingsStore = new SettingsStore(this);
      this.fraudRulesStore = new FraudRulesStore(this);
      this.messagesStore = new MessagesStore(this);

      RootStore.instance = this;
    } else {
      return RootStore.instance;
    }
  }
}

export type RootStoreType = RootStore;

export const rootStoreContext = createContext(new RootStore());

export const rootStore = new RootStore();
