import { makeAutoObservable, runInAction } from "mobx";

import type { RootStoreType } from "./rootStore";
import { FraudRule } from "interfaces/IRules";
import {
  getFraudRules,
  patchFraudRule,
  updateFraudRule,
  createFraudRule,
  deleteFraudRule,
} from "utils/api/abh-call-registry";
import { stringToRuleActionBooleans } from "utils/ruleActions";

export class FraudRulesStore {
  rootStore: RootStoreType;

  isLoading: boolean = true;
  fraudRules: FraudRule[] = [];

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  setFraudRules = (data: FraudRule[]) => {
    this.fraudRules = data;
    this.isLoading = false;
  };

  getFraudRules = async () => {
    try {
      const rules = await getFraudRules();
      // just transform all country codes to uppercase
      const mappedRules = rules.map((rule) => ({
        ...rule,
        countries: rule.countries
          ? rule.countries.map((item) => item.toUpperCase())
          : rule.countries,
      }));
      this.setFraudRules(mappedRules);
    } catch (err) {
      console.warn("[ERROR] fetchFraudRules: ", err);
    }
  };

  patchFraudRule = async (id: number, data: Partial<FraudRule>) => {
    const updatedRule = await patchFraudRule(id, data);
    const { alert, block } = stringToRuleActionBooleans(updatedRule.action);

    runInAction(() => {
      this.fraudRules = this.fraudRules.map((rule) =>
        rule.id === updatedRule.id
          ? {
              ...updatedRule,
              // just transform all country codes to uppercase
              countries: updatedRule.countries
                ? updatedRule.countries.map((item) => item.toUpperCase())
                : updatedRule.countries,
            }
          : rule
      );

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore.callControlRules.map((item) =>
          item.type === "fraud" && item.id === id
            ? {
                id: item.id,
                alert: alert,
                block: block,
                enabled: updatedRule.enabled,
                name: updatedRule.name,
                notification_channel_ids: updatedRule.notification_channel_ids,
                threshold: updatedRule.threshold_numeric,
                type: "fraud",
              }
            : item
        );
    });
  };

  updateFraudRule = async (id: number, data: Partial<FraudRule>) => {
    const updatedFraudRule = await updateFraudRule(id, data);
    runInAction(() => {
      this.fraudRules = this.fraudRules.map((rule) =>
        rule.id === updatedFraudRule.id ? updatedFraudRule : rule
      );

      const { alert, block } = stringToRuleActionBooleans(
        updatedFraudRule.action
      );

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore.callControlRules.map((rule) =>
          rule.type === "fraud" && rule.id === updatedFraudRule.id
            ? {
                ...rule,
                name: updatedFraudRule.name,
                type: "fraud",
                notification_channel_ids:
                  updatedFraudRule.notification_channel_ids || [],
                threshold: updatedFraudRule.threshold_numeric,
                alert,
                block,
                enabled: updatedFraudRule.enabled,
              }
            : rule
        );
    });
  };

  createFraudRule = async (data: Partial<FraudRule>) => {
    const newRule = await createFraudRule(data);

    runInAction(() => {
      this.fraudRules = [newRule, ...this.fraudRules];

      const { alert, block } = stringToRuleActionBooleans(newRule.action);

      this.rootStore.referenceStore.callControlRules = [
        ...this.rootStore.referenceStore.callControlRules,
        {
          id: newRule.id,
          name: newRule.name,
          type: "fraud",
          notification_channel_ids: newRule.notification_channel_ids || [],
          threshold: newRule.threshold_numeric,
          alert,
          block,
          enabled: newRule.enabled,
        },
      ];
    });
  };

  deleteFraudRule = async (id: number) => {
    await deleteFraudRule(id);

    runInAction(() => {
      this.fraudRules = this.fraudRules.filter((rule) => rule.id !== id);

      this.rootStore.referenceStore.callControlRules =
        this.rootStore.referenceStore.callControlRules.filter(
          (rule) => !(rule.type === "fraud" && rule.id === id)
        );
    });
  };
}
