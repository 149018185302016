import { makeAutoObservable } from "mobx";
import { RootStoreType } from "./rootStore";
import { IReport } from "interfaces/IReports";
import {
  createReport,
  deleteReport,
  generateReport,
  getCurrentReport,
  getReports,
  sendReport,
  updatePredefinedReport,
  updateReport,
} from "utils/api/abh-call-registry";

export class SettingsStore {
  rootStore: RootStoreType;

  reports: IReport[] = [];
  loadingReports: boolean = true;
  report: IReport | undefined = undefined;
  loadingReport: boolean = true;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  setReports = (data: IReport[]) => {
    this.reports = data;
    this.loadingReports = false;
  };

  setCurrentReportStatus = (id: number, enable: boolean) => {
    this.reports = this.reports.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          enabled: enable,
        };
      } else {
        return {
          ...el,
        };
      }
    });
  };

  setCurrentReport = (report: IReport | undefined, isLoading?: boolean) => {
    this.report = report;
    this.loadingReport = isLoading ? isLoading : false;
  };

  getReportsAsync = async () => {
    this.loadingReports = true;
    try {
      const data = await getReports();
      this.setReports(data);
    } catch (err) {
      console.warn("[ERROR] getReports: ", err);
    }
  };

  updateReportAsync = (id: number | string, body: IReport) =>
    updateReport(id, body);

  updatePredefinedReportAsync = (
    id: number | string,
    body: Pick<IReport, "enabled" | "notification_channel_ids">
  ) => updatePredefinedReport(id, body);

  sendReportAsync = (id: number | string) => sendReport(id);

  getCurrentReportAsync = async (id: string | number) => {
    this.loadingReport = true;
    try {
      const data = await getCurrentReport(id);
      this.setCurrentReport(data);
    } catch (err) {
      console.warn("[ERROR] getCurrentReport: ", err);
    }
  };

  // TODO move this shit code to the API ASAP!!!!
  generateReportAsync = generateReport;

  createReportAsync = createReport;

  deleteReportAsync = deleteReport;
}
