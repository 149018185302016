import {makeAutoObservable, runInAction} from "mobx";
import groupBy from 'lodash/groupBy';

import api from "../../utils/api";
import { dateCustom } from 'utils/dates';
import {
  TType,
  TypeEnum,
  TCounter,
  TStatsCombinedResponse,
  TFilter,
  TStatsCombinedDataTimeResponse,
  TFraudScheduleCurrent,
  TStatsCombinedFraudTypeResponse,
  TListCalls,
  TParticipantsState,
  TNetwork,
  TParticipantsFormat,
  TTotalCallsSchedule
} from "./types";
import {
  defaultCounters,
  defaultFilters,
  defaultFraudCurrentState,
  defaultParticipantsState,
  defaultTotalCallsState
} from "./default";
import {callsType, SAVED_FILTER_TYPE_ABH_CR, types, windowInterval} from "../../utils/constants";

import type { RootStoreType } from "./rootStore";
export class StatisticsStore {
  rootStore: RootStoreType;

  counters: TCounter = {
    ...defaultCounters,
    loading: true
  };
  filters: TFilter = defaultFilters;
  fraudSchedule: TTotalCallsSchedule = {
    ...defaultTotalCallsState,
    loading: true
  }
  fraudScheduleCurrent: TFraudScheduleCurrent = {
    ...defaultFraudCurrentState,
    loading: true
  }
  type: TType = TypeEnum.all;
  currentId: null | number = null;
  filterType: string = localStorage.getItem(SAVED_FILTER_TYPE_ABH_CR) || callsType.international;
  participantsData: TParticipantsState = {
    ...defaultParticipantsState,
    loading: true
  }
  participantsFormat: TParticipantsFormat = {
    loading: true,
    list: []
  }
  filtersParticipants: {
    from: number,
  } = {
    from: dateCustom().subtract(1, 'month').valueOf(),
  }

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false })
  }


  setFiltersParticipants = (from: number) => {
    this.filtersParticipants = {
      from: from
    }
  }

  setFilters = (params: Partial<TFilter>) => {
    this.filters = {
      ...this.filters,
      ...params
    }
  }

  setFilterType = (value: string) => {
    localStorage.setItem(SAVED_FILTER_TYPE_ABH_CR, value);
    this.filterType = value;
  }

  setType = (type: TType, id: number | null) => {
    this.type = type;
    this.currentId = id;
  }

  setCounters = (res: TStatsCombinedResponse[]) => {
    if (res.length) {
      const inboundFraudulent = res.find((el) => el.direction === types.inbound)?.fraudulent || 0;
      const inboundTotal = res.find((el) => el.direction === types.inbound)?.total || 0;
      const percentInbound = inboundFraudulent / inboundTotal * 100;
      const outboundFraudulent = res.find((el) => el.direction === types.outbound)?.fraudulent || 0;
      const outboundTotal = res.find((el) => el.direction === types.outbound)?.total || 0;
      const percentOutbound = outboundFraudulent / outboundTotal * 100;
      const valid = res.reduce((counter, item) => {
        return counter + item.validated_default;
      }, 0);
      const total = res.reduce((counter, item) => {
        return counter + item.total;
      }, 0);
      const counters = {
        loading: false,
        inbound: inboundFraudulent,
        percentInbound: percentInbound,
        outbound: outboundFraudulent,
        percentOutbound: percentOutbound,
        valid: valid,
        total: total
      }
      this.counters = {
        ...counters
      }
    } else {
      this.counters = {
        ...defaultCounters,
        loading: false
      }
    }
  }

  setParticipantsData = (res: TStatsCombinedDataTimeResponse[]) => {
    if (res.length) {
      const callsGroupedByNetwork = groupBy(res, 'network_id');

      const arrayGroupByNetwork = Object.entries(callsGroupedByNetwork);

      let inboundTotal = 0;
      let outboundTotal = 0;

      const formatArray = arrayGroupByNetwork.map(([key, arrCalls]) => {
        let inbound = 0;
        let outbound = 0;
        let validate = 0;
        if (Array.isArray(arrCalls)) {
          arrCalls.forEach((el: TStatsCombinedDataTimeResponse) => {
            if (el.direction === types.outbound) {
              outbound = outbound + el.total;
              inboundTotal = inboundTotal + el.total;
              validate = validate + el.validated_default;
            } else {
              inbound = inbound + el.total;
              outboundTotal = outboundTotal + el.total;
              validate = validate + el.validated_default;
            }
          })
        }

        return {
          id: Number(key),
          inbound: inbound,
          outbound: outbound,
          validate: validate
        }
      })

      this.participantsData = {
        loading: false,
        list: formatArray,
        inboundTotal: inboundTotal,
        outboundTotal: outboundTotal
      }

    } else {
      this.participantsData = {
        loading: false,
        list: [],
        inboundTotal: 0,
        outboundTotal: 0
      }
    }
  }

  setFraudScheduleCurrent = (res: TStatsCombinedFraudTypeResponse[]) => {
    if (res.length) {
      const objGroupByFraudType = groupBy(res, 'fraud_type');
      const arrayGroupByFraudType = Object.entries(objGroupByFraudType);
      const formatArrayGroupByFraudType = arrayGroupByFraudType.map(([key, value]) => {
        const newArray = value.filter((el) => el.direction).sort((x, y) => x.datetime - y.datetime);
        let fraudulent = 0;
        let count = 0;
        newArray.forEach((el) => {
          fraudulent = fraudulent + el.fraudulent;
          count = count + el.total;
        })
        const percent = fraudulent / count * 100 || 0;
        const inbound = newArray.filter((el) => el.direction === types.inbound);
        let validatedInbound = 0;
        let countInbound = 0;
        inbound.forEach((el) => {
          validatedInbound = validatedInbound + el.validated_default;
          countInbound = countInbound + el.total;
        })
        const percentInbound = validatedInbound / countInbound * 100 || 0;
        const outbound = newArray.filter((el) => el.direction === types.outbound);
        let validatedOutbound = 0;
        let countOutbound = 0;
        outbound.forEach((el) => {
          validatedOutbound = validatedOutbound + el.validated_default;
          countOutbound = countOutbound + el.total;
        })
        const percentOutbound = validatedOutbound / countOutbound * 100 || 0;
        const newArrayItems = newArray.map((el) => {
          const item = newArray.filter((x) => x.datetime === el.datetime);
          const inbound = item.find((x) => x.direction === types.inbound)?.fraudulent || 0;
          const outbound = item.find((x) => x.direction === types.outbound)?.fraudulent || 0;
          return {
            timestamp: el.datetime,
            inboundFraud: inbound,
            outboundFraud: outbound,
          }
        })
        // @ts-ignore
        const uniqueObjArray = [...new Map(newArrayItems.map((item) => [item["timestamp"], item])).values()];
        return {
          id: Number(key),
          list: uniqueObjArray,
          count: fraudulent,
          percent: percent,
          validatedInbound: validatedInbound,
          percentInbound: percentInbound,
          validatedOutbound: validatedOutbound,
          percentOutbound: percentOutbound
        }
      })
      this.fraudScheduleCurrent = {
        loading: false,
        data: formatArrayGroupByFraudType,
      };
    } else {
      this.fraudScheduleCurrent = {
        loading: false,
        data: [],
      };
    }
  }

  setFraudSchedule = (res: TStatsCombinedDataTimeResponse[]) => {
    if (res.length) {
      const resSort = res.filter((el) => el.direction)
        .sort((x, y) => x.datetime - y.datetime);
      let arrayInternationalCalls: TListCalls[] = [];
      let arrayValidCalls: TListCalls[] = [];
      let arrayDomesticCalls: TListCalls[] = [];
      const arrayAllCalls = resSort.map((el) => {
        const item = resSort.filter((x) => x.datetime === el.datetime);
        const inboundTotal = item.find((x) => x.direction === types.inbound)?.total || 0;
        const outboundTotal = item.find((x) => x.direction === types.outbound)?.total || 0;
        const inboundValid = item.find((x) => x.direction === types.inbound)?.validated_default || 0;
        const outboundValid = item.find((x) => x.direction === types.outbound)?.validated_default || 0;
        const inboundDomestic = item.find((x) => x.direction === types.inbound)?.domestic_total || 0;
        const outboundDomestic = item.find((x) => x.direction === types.outbound)?.domestic_total || 0;
        const fraudulentInbound = item.find((x) => x.direction === types.inbound)?.fraudulent || 0;
        const fraudulentOutbound = item.find((x) => x.direction === types.outbound)?.fraudulent || 0;
        const totalItem = {
          timestamp: el.datetime,
          inboundFraud: inboundTotal,
          outboundFraud: outboundTotal
        }
        const validItem = {
          timestamp: el.datetime,
          inboundFraud: inboundValid,
          outboundFraud: outboundValid
        };
        const domesticItem = {
          timestamp: el.datetime,
          inboundFraud: inboundDomestic,
          outboundFraud: outboundDomestic
        }
        arrayInternationalCalls.push(totalItem);
        arrayValidCalls.push(validItem);
        arrayDomesticCalls.push(domesticItem);
        return {
          timestamp: el.datetime,
          inboundFraud: fraudulentInbound,
          outboundFraud: fraudulentOutbound,
        }
      })

      // @ts-ignore
      const uniqueArrayAllCalls = [...new Map(arrayAllCalls.map((item) => [item["timestamp"], item])).values()];
      // @ts-ignore
      const uniqueArrayTotalCalls = [...new Map(arrayInternationalCalls.map((item) => [item["timestamp"], item])).values()];
      // @ts-ignore
      const uniqueArrayValidCalls = [...new Map(arrayValidCalls.map((item) => [item["timestamp"], item])).values()];
      // @ts-ignore
      const uniqueArrayDomesticCalls = [...new Map(arrayDomesticCalls.map((item) => [item["timestamp"], item])).values()];
      this.fraudSchedule = {
        loading: false,
        list: uniqueArrayAllCalls,
        internationalList: uniqueArrayTotalCalls,
        validList: uniqueArrayValidCalls,
        domesticList: uniqueArrayDomesticCalls
      };
    } else {
      this.fraudSchedule = {
        loading: false,
        list: [],
        internationalList: [],
        validList: [],
        domesticList: []
      };
    }
  }

  getCountersAsync = () => {
    this.counters = {
      ...this.counters,
      loading: true,
    }
    const window = this.filters.from >= 3600 * 24 * 7 * 1000 ? windowInterval.day : windowInterval.hour;
    const from = dateCustom(new Date()).subtract(this.filters.from, 'millisecond').valueOf();
    let to = 0;
    if (this.filters.to) {
      to = dateCustom(new Date()).subtract(this.filters.to, 'millisecond').valueOf();
    }
    api<TStatsCombinedResponse[]>({
      method: "GET",
      url: `/api/v1/stats/combined?group_by_direction=1&from=${from}${to > 0 ? '&to=' + to : ''}&window=${window}`
    }).then((res) => {
      runInAction(() => {
        this.setCounters(res)
      })
    }).catch(() => {
      runInAction(() => {
        this.setCounters([])
      })
    })
  }

  getFraudCallsTypesAsync = () => {
    this.fraudScheduleCurrent = {
      ...this.fraudScheduleCurrent,
      loading: true,
    }
    const window = this.filters.from >= 3600 * 24 * 7 * 1000 ? windowInterval.day : windowInterval.hour;
    const from = dateCustom(new Date()).subtract(this.filters.from, 'millisecond').valueOf();
    let to = 0;
    if (this.filters.to) {
      to = dateCustom(new Date()).subtract(this.filters.to, 'millisecond').valueOf();
    }
    api<TStatsCombinedFraudTypeResponse[]>({
      method: "GET",
      url: `/api/v1/stats/combined?group_by_direction=1&group_by_fraud_type_id=1&from=${from}${to > 0 ? '&to=' + to : ''}&window=${window}&group_by_datetime=1`
    }).then((res) => {
      runInAction(() => {
        this.setFraudScheduleCurrent(res)
      })
    }).catch(() => {
      runInAction(() => {
        this.setFraudScheduleCurrent([])
      })
    })
  }

  getFraudCallsScheduleAsync = () => {
    this.fraudSchedule = {
      ...defaultTotalCallsState,
      loading: true,
    }
    const window = this.filters.from >= 3600 * 24 * 7 * 1000 ? windowInterval.day : windowInterval.hour;
    const from = dateCustom(new Date()).subtract(this.filters.from, 'millisecond').valueOf();
    let to = 0;
    if (this.filters.to) {
      to = dateCustom(new Date()).subtract(this.filters.to, 'millisecond').valueOf();
    }
    api<TStatsCombinedDataTimeResponse[]>({
      method: "GET",
      url: `/api/v1/stats/combined?group_by_direction=1&from=${from}${to > 0 ? '&to=' + to : ''}&group_by_datetime=1&window=${window}`
    }).then((res) => {
      runInAction(() => {
        this.setFraudSchedule(res)
      })
    }).catch(() => {
      runInAction(() => {
        this.setFraudSchedule([])
      })
    })
  }

  setFormatParticipants = (networks: TNetwork[]) => {
    const {list} = this.participantsData;

    const arrayNetworks = networks.map((el) => {
      const findElem = list.find((x) => x.id === el.id)
      return {
        id: el.id,
        country: el.name,
        nodes: el.active_nodes_count > 0,
        inbound: findElem?.inbound || 0,
        outbound: findElem?.outbound || 0,
        validate: findElem?.validate || 0,
        prefixes: el.prefix_count,
        iso: el.country_iso,
        type: el.type,
        participantType: el.participation_type
      }
    })

    const objGroupByCountryIso = groupBy(networks, 'country_iso');

    const arrayGroupByCountryIso = Object.entries(objGroupByCountryIso);

    const formatArrayGroupByCountryIso = arrayGroupByCountryIso.map(([key, value], index) => {
      let inbound = 0;
      let outbound = 0;
      let validate = 0;

      value.forEach((el: TNetwork) => {
        const findElement = arrayNetworks.find((x) => x.id === el.id);
        if (findElement) {
          inbound = inbound + findElement.inbound;
          outbound = outbound + findElement.outbound;
          validate = validate + findElement.validate;
        }
      })

      return {
        id: index,
        country: key.trim() ? key : 'ZZ',
        participantsActive: value.filter((el: TNetwork) => el.participation_type === 'Full' || el.participation_type === 'Partial').length,
        participantsTotal: value.filter((el: TNetwork) => el.participation_type).length,
        nodes: !!value.filter((el: TNetwork) => el.active_nodes_count > 0).length,
        inbound: inbound,
        outbound: outbound,
        validate: validate,
        prefixes: value.reduce((a, b) => a + b.prefix_count, 0),
        networks: arrayNetworks.filter((x) => x.iso === key)
      }
    });

    this.participantsFormat = {
      loading: false,
      list: formatArrayGroupByCountryIso
    }
  }

  getParticipantsAsync = () => {
    this.participantsData = {
      ...defaultParticipantsState,
      loading: true,
    };

    const now = dateCustom().valueOf();

    const window =
      now - this.filtersParticipants.from >= 3600 * 24 * 7 * 1000
        ? windowInterval.day
        : windowInterval.hour;

    api<TStatsCombinedDataTimeResponse[]>({
      method: "GET",
      url: `/api/v1/stats/combined?group_by_direction=1&group_by_network_id=1&from=${this.filtersParticipants.from}&window=${window}`,
    })
      .then((res) => {
        this.setParticipantsData(res);
      })
      .catch(() => {
        this.setParticipantsData([]);
      });
  }
}
