import {FC, useCallback, useState} from "react";
import {Button, Tooltip, Spin } from "antd";
import {useHistory} from "react-router-dom";
import { Switch } from "@vas-common/ui-kit";

import {IReport} from "interfaces/IReports";
import {useNotifications} from "../../../ui/Notifications";
import {ReactComponent as IconSend} from "assets/svg/send.svg";
import {ReactComponent as EmailIcon} from "assets/svg/mail.svg";
import {ReactComponent as SlackIcon} from "assets/svg/slack.svg";
import {ReactComponent as HttpIcon} from "assets/svg/webhook.svg";
import {URLS} from "../../../constants/Urls";
import {NotificationChannel, TFraudType, TNetwork} from "store/mobx/types";
import s from './Reports.module.scss';

type Props = {
  reports: IReport[];
  isLoading: boolean;
  networks: TNetwork[];
  fraudTypes: TFraudType[],
  isLoadingChannel: boolean;
  updateReport: (id: number | string, body: IReport) => Promise<void>;
  sendReport: (id: number | string) => Promise<void>;
  notificationChannels: NotificationChannel[];
  setCurrentReportStatus: (id: number, enable: boolean) => void;
  custom?: boolean
}

type SwitchItemProps = Pick<Props, "updateReport" | 'setCurrentReportStatus'> & { report: IReport};

type SendItemProps = Pick<Props, 'sendReport'> & { report: IReport};

const SwitchItem:FC<SwitchItemProps> = ({report, updateReport, setCurrentReportStatus}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleError } = useNotifications(() =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  );

  return (
    <Switch
      disabled={report.notification_channel_ids.length === 0}
      onChange={async (value, event) => {
        event.stopPropagation();
        setIsLoading(true);
        return updateReport(report.id, {...report, enabled: value})
          .then(() => {
            setCurrentReportStatus(report.id, value)
          }).catch((error) => {
            handleError(error)
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
      loading={isLoading}
      checked={report.enabled}
    />
  )
}

const SendItem:FC<SendItemProps> = ({report,sendReport}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleError, setNotification } = useNotifications(() =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  );

  const handleSendReport = () => {
    setIsLoading(true);
    sendReport(report.id)
      .then(() => {
        setNotification({type: "success", data: {message: "Report is sent",}}, 1500);
      })
      .catch((handleError))
      .finally(() => {
        setIsLoading(false);
      })
  }

  return (
      <Button
        loading={isLoading}
        className={s.btnFlat}
        disabled={report.notification_channel_ids.length === 0}
        onClick={(e) => {
          e.stopPropagation()
          if(report.notification_channel_ids.length !== 0) {
            handleSendReport()
          }
        }}
      >
        Send Now
        <IconSend width="2rem" />
      </Button>
  )
}

const ReportsTable:FC<Props> = ({
                                  reports,
                                  isLoading,
                                  networks,
                                  fraudTypes,
                                  isLoadingChannel,
                                  updateReport,
                                  sendReport,
                                  setCurrentReportStatus,
                                  custom,
                                  notificationChannels
}) => {

  const history = useHistory();

  const goToReport = (event: any, id: number) => {
    if(event?.target?.className === 'ant-switch-inner') {
      return;
    }
    history.push(`${URLS.reportUpdate({id: String(id)})}`);
  }

  const getScope = useCallback((fraudIds: number[], networkIds: number[]) => {
    const filterFraudTypes = fraudIds.filter((id) => {
      return fraudTypes.some((type) => type.value === id);
    })
    const scopeFraudTypes: string[] = filterFraudTypes.length === 0 ? ['All fraud types'] :
      (filterFraudTypes.length < 4 ? filterFraudTypes.map((el, idx) => {
      const findItem = fraudTypes.find((type) => type.value === el);
      if(idx === fraudIds.length -1) {
        return findItem?.full_name || '';
      } else {
        return `${findItem?.full_name}`
      }
    }): [`${fraudIds.length} fraud types`]);

    const filterNetworks = networkIds.filter((id) => {
      return networks.some((network) => network.id === id);
    })
    const scopeNetworks: string[] = filterNetworks.length === 0 ? ['All networks'] :
      (filterNetworks.length < 4 ? filterNetworks.map((el, idx) => {
        const findItem = networks.find((network) => network.id === el);
        if(idx === networkIds.length -1) {
          return findItem?.name || '';
        } else {
          return `${findItem?.name}`
        }
      }): [`${networkIds.length} networks`]);

    const contentTypesItems = scopeFraudTypes.join(', ');
    const tooltipTypesItems = filterFraudTypes.map((el) => {
      const findItem = fraudTypes.find((x) => x.value === el);
      return `${findItem?.full_name}`
    }).join(', ')

    const contentNetworksItems = scopeNetworks.join(', ');
    const tooltipNetworksItems = filterNetworks.map((el) => {
      const findItem = networks.find((network) => network.id === el);
      return `${findItem?.name}`
    }).join(', ')

    return (
      <div className={s.rowSpace}>
        <Tooltip
          placement="top"
          title={tooltipTypesItems || 'All fraud types'}
        >
          {contentTypesItems}
        </Tooltip>
        <div> / </div>
        <Tooltip
          placement="top"
          title={tooltipNetworksItems || 'All networks'}
        >
          {contentNetworksItems}
        </Tooltip>
      </div>
    )

  },[fraudTypes, networks])

  return (
    <div className={s.wrapperTable}>
      {!reports.length && !isLoading ? (
        <div className={s.noData}>
          <p className={s.fontBody}>There are no records.</p>
        </div>
      ) : (
        <table className={s.table}>
          <thead>
          <tr className={custom ? s.custom : ''}>
            <th/>
            <th>Report Name</th>
            {custom &&
              <th> Scope </th>
            }
            <th>Periodicity</th>
            <th>notification Channels</th>
            <th/>
          </tr>
          </thead>
          <tbody className={s.content}>
          {reports.map((report) => {
            return (
              <tr key={report.id} className={custom ? s.custom : ''} onClick={(event) => goToReport(event, report.id)}>
                <td>
                  <SwitchItem report={report} updateReport={updateReport} setCurrentReportStatus={setCurrentReportStatus} />
                </td>
                <td>
                  <div>
                    {custom ?
                      <span>{report.name}</span> :
                      <>
                        <span className={s.capitalize}>{report.periodicity}</span><span> overall report</span>
                      </>
                    }

                  </div>
                </td>
                {custom && (
                  <td>{getScope(report.fraud_types, report.network_ids)}</td>
                )}
                <td>
                  <div className={s.capitalize}>{report.periodicity}</div>
                </td>
                <td>
                  {report.notification_channel_ids.length === 0 ?
                    <div className={s.empty}>No channels added</div> :
                    report.notification_channel_ids.map((el) => {
                    const channel = notificationChannels.find((channel) => channel.id === el);
                    let Icon = <span/>;
                    if(channel) {
                      switch (channel.type) {
                        case 'email' : Icon = <EmailIcon/>; break;
                        case 'slack' : Icon = <SlackIcon/>; break;
                        case 'http' : Icon = <HttpIcon/>; break;
                        default: break;
                      }
                    }
                    return (
                      <Tooltip
                        key={el}
                        placement="top"
                        title={channel?.name || ''}
                      >
                        {Icon}
                      </Tooltip>
                      )
                  })}
                </td>
                <td>
                  <SendItem report={report} sendReport={sendReport}/>
                </td>
              </tr>
            )}
          )}
          </tbody>
          {(isLoading || isLoadingChannel) && (
            <tbody className={s.spin}>
            <tr>
              <td>
                <Spin spinning />
              </td>
            </tr>
            </tbody>
          )}
        </table>
      )}
    </div>
  )
}

export default ReportsTable;
