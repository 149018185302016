export enum FRAUD_TYPES {
  UNCONFIRMED = 1,
  CLI = 2,
  FAS = 4,
  STRETCH = 8,
  PBX = 16,
  BYPASS = 32
}

export enum EPredefinedReports {
  monthly = "monthly",
  weekly = "weekly",
  daily = "daily",
}

export const aggregationType = {
  gateway: "gateway",
  country: "country",
}

export const aggregationTypeFilter = {
  gateway: "gateway",
  network: "network",
  country: "country",
}

export const callsType = {
  international: "International",
  validated: "Validated",
  domestic: "Domestic"
}

export const windowInterval = {
  day: "day",
  hour: "hour",
}

export const types = {
  inbound: "inbound",
  outbound: "outbound"
}

export const dayInMillisec = 3600 * 24 * 1000;


export const reportsType = [
  {
    label: "Daily overall report",
    type: "daily",
    id: "0",
  },
  {
    label: "Weekly overall report",
    type: "weekly",
    id: "1",
  },
  {
    label: "Monthly overall report",
    type: "monthly",
    id: "2",
  },
]

export const SAVED_INTERVAL_ABH_CR = 'savedInterval.abh.cr';

export const SAVED_INTERVAL_TO_ABH_CR = 'savedIntervalTo.abh.cr';

export const SAVED_FILTER_TYPE_ABH_CR = 'savedFilterType.abh.cr';

export const SAVED_FILTER_TYPE_CALLS_ABH_CR = 'savedFilterTypeCalls.abh.cr'

type typesChannel = ("slack" | "email")[]
export const channelTypes: typesChannel = ["slack", "email"];
