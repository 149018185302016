import React, {FC} from "react";
import {observer} from "mobx-react";
import {TimeSeriesChart} from "@vas-common/ui-kit";

import {useStores} from "../../../store/mobx/useStore";
import {TypeEnum} from "../../../store/mobx/types";
import { dateCustom } from 'utils/dates';
import type {TGroupByDataTimeSeriesChart} from "@vas-common/ui-kit/dist/cjs/types/components/TimeSeriesChart";

type Props = {
  intervalType: TGroupByDataTimeSeriesChart;
}

const FraudCallsChart: FC<Props> = observer(({intervalType}) => {

  const {
    statisticsStore: {
      fraudSchedule,
      fraudScheduleCurrent,
      type,
      currentId,
      filters
    }
  } = useStores();

  const {from, to} = filters;

  const findCurrentSchedule = fraudScheduleCurrent.data.find((el) => el.id === currentId)?.list || [];

  const arraySchedule = type === TypeEnum.all ? fraudSchedule.list : findCurrentSchedule;

  const formatData = arraySchedule.map((el) => ({
    timestamp: el.timestamp,
    subject1: el.inboundFraud,
    subject2: el.outboundFraud
  }))

  const fromDate = dateCustom(new Date()).subtract(from, 'millisecond').valueOf();
  const toDate = to ? dateCustom(new Date()).subtract(to, 'millisecond').valueOf() : undefined;

  return (
    <TimeSeriesChart
      from={fromDate}
      to={toDate}
      height={160}
      groupBy={intervalType}
      data={formatData}
      subjects={{
        inbound: {label: 'Inbound', color: '#f15dc1'},
        outbound: {label: 'Outbound', color: '#a369e8'}
      }}
    />
  );
})

export default FraudCallsChart;
