import { applyMiddleware, compose, createStore } from "redux";

import reduxThunk from "redux-thunk";
import { rootReducer } from "./reducers";
import { millsecToHour, millsecToDay } from "../utils/index";
import { aggregationType, windowInterval } from "../utils/constants";
import { intervalParams, windowStepParams } from "../utils/savedParams";
import { dateCustom } from "utils/dates";

const middleware = [reduxThunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const currentdateTime = new Date();

export const defaultState = {
  users: {
    loading: true,
    list: [],
  },
  roles: {
    loading: true,
    list: [],
  },
  references: {
    gatewayNames: {},
    networkNames: {},
    fraudTypeNames: {},
  },

  gateways: [],
  lastRecords: {
    items: [],
    isFetching: false,
  },

  stats: {
    filter: {
      interval: intervalParams,
      window: windowStepParams,
      dataType: aggregationType.gateway,
    },
    inbound: [],
    outbound: [],
    hourly: [],
    daily: [],
    outboundStats: {},
    inboundStats: {},
  },

  filters: {
    logFilters: {
      from_ts:
        windowStepParams === windowInterval.hour
          ? dateCustom(currentdateTime).subtract(
              millsecToHour(intervalParams),
              "hours"
            )
          : dateCustom(currentdateTime).subtract(
              millsecToDay(intervalParams),
              "days"
            ),
      till_ts: dateCustom(currentdateTime),
    },
    fraudCallsFilters: {
      page: 1,
    },
  },
};

export const store = createStore(
  rootReducer,
  defaultState,
  composeEnhancers(applyMiddleware(...middleware))
);
