import { Popover, Spin } from "antd";
import { observer } from "mobx-react";
import { useStores } from "store/mobx/useStore";

import { INotificationChannelsData } from "interfaces/INotificationChannels";
import { ReactComponent as EmailIcon } from "assets/svg/mail.svg";
import { ReactComponent as SlackIcon } from "assets/svg/slack.svg";
import { ReactComponent as HttpIcon } from "assets/svg/webhook.svg";
import { URLS } from "constants/Urls";
import { useHistory } from "react-router-dom";

import s from "./Notifications.module.scss";

export const getDescription = (channel: INotificationChannelsData) => {
  if (
    !channel.usage?.fraud_rule_ids.length &&
    !channel.usage?.report_task_ids.length
  ) {
    return "Not used yet";
  }
  if (
    channel.usage?.fraud_rule_ids.length &&
    channel.usage?.report_task_ids.length
  ) {
    return `${channel.usage?.fraud_rule_ids.length} rules, ${channel.usage?.report_task_ids.length} reports`;
  } else {
    return `${
      channel.usage?.fraud_rule_ids.length ||
      channel.usage?.report_task_ids.length
    } ${channel.usage?.fraud_rule_ids.length ? "rules" : "reports"}`;
  }
};

const NotificationTable = observer(() => {
  const history = useHistory();

  const {
    referenceStore: { notificationChannels },
  } = useStores();

  const { isLoading, data: channels } = notificationChannels;

  const handleRowClick = (channel: INotificationChannelsData) => {
    history.push(`${URLS.channelUpdate({ id: String(channel.id) })}`);
  };

  const getType = (type: string) => {
    let Icon = <div />;
    let name = "";
    switch (type) {
      case "email":
        Icon = <EmailIcon />;
        name = "Email";
        break;
      case "slack":
        Icon = <SlackIcon />;
        name = "#slack-channel";
        break;
      case "http":
        Icon = <HttpIcon />;
        name = "HTTP";
        break;
      default:
        Icon = <div />;
        break;
    }
    return (
      <div className={s.flex}>
        {Icon}
        {name}
      </div>
    );
  };

  return !channels.length && !isLoading ? (
    <div className={s.noData}>
      <p className={s.fontBody}>There are no records.</p>
    </div>
  ) : (
    <table className={s.table}>
      <thead>
        <tr>
          <th>Channel Name</th>
          <th>Channel type</th>
          <th>URL</th>
          <th>Used for</th>
        </tr>
      </thead>
      <tbody className={s.content}>
        {channels.map((channel) => {
          const content = (
            <div>
              {channel.entries && Object.values(channel.entries).join(", ")}
            </div>
          );
          return (
            <tr key={channel.id} onClick={() => handleRowClick(channel)}>
              <td>
                <div>{channel.name}</div>
              </td>
              <td>
                <div>{getType(channel.type)}</div>
              </td>
              <td>
                <Popover content={content} trigger="hover">
                  <div>
                    {channel.entries &&
                      Object.values(channel.entries).join(", ")}
                  </div>
                </Popover>
              </td>
              <td>
                <div
                  className={
                    channel.usage?.fraud_rule_ids.length ||
                    channel.usage?.report_task_ids.length
                      ? ""
                      : s.notUsed
                  }
                >
                  {getDescription(channel)}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
      {isLoading && (
        <tbody className={s.spin}>
          <tr>
            <td>
              <Spin spinning />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
});

export default NotificationTable;
