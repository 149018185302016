import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { showConfirm } from "ui/Modals/confirm";

import s from "./index.module.scss";

type Props = {
  onLeave: () => void;
  shouldDisplayMessage: () => boolean;
};

export const useOnLeavePage = (props: Props) => {
  const history = useHistory();

  const propsRef = useRef(props);
  useEffect(() => {
    propsRef.current = props;
  });

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      if (propsRef.current.shouldDisplayMessage()) {
        const { destroy } = showConfirm({
          title: "Save Rule",
          content: (
            <>
              You trying to exit without save. <br /> Do you want save?
            </>
          ),
          okText: "Save",
          cancelText: "Don't Save",
          cancelButtonProps: {
            className: s.btnSecondary,
            onClick: () => {
              destroy();
              unblock();
              history.push(pathname);
            },
          },
          onOk: propsRef.current.onLeave,
        });

        return false;
      }
    });

    return unblock;
  }, [history]);
};
