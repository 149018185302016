import React from "react";

import {TNetwork} from "store/mobx/types";
import FlagIcon from "components/flag/FlagIcon";

import s from "./index.module.scss";

export const NetworkLabel = ({network}: {network: TNetwork}) => {
  return (
    <span className={s.label}><FlagIcon country={network?.country_iso || "ZZ"}/>
      {network.country_iso} / {network.name} {network.mccmnc ? `(${network.mccmnc})` : ''}
    </span>
  )
}
