import React from "react";
import { Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import { useStores } from "store/mobx/useStore";
import { NOTIFICATION_TYPE_ICONS_MAP } from "ui/maps";

import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";

import s from "./index.module.scss";

export const NotificationChannelTag = (props: CustomTagProps) => {
  const { label, value, onClose } = props;

  const {
    referenceStore: { notificationChannels },
  } = useStores();

  const { data } = notificationChannels;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const { type, name } = data.find((channel) => channel.id === value) || {};

  return (
    <Tag onMouseDown={onPreventMouseDown} className={s.selectTag}>
      {type ? NOTIFICATION_TYPE_ICONS_MAP[type] : null}
      {typeof label === "string" ? label : name}
      <IconCross
        width="2rem"
        onClick={() => onClose()}
        className={s.closeIcon}
      />
    </Tag>
  );
};
