import {IPrefixList} from "../../interfaces/IPrefixList";
import {PREFIX_LIST_ERROR, PREFIX_LIST_LOADING, PREFIX_LIST_SUCCESS} from "../actions";

export const prefixListsReducer = (state = [], action: {type: any, items: IPrefixList[]}) => {
  switch (action.type) {
    case PREFIX_LIST_SUCCESS: {
      return {
        items: action.items,
        isFetching: false,
      };
    }
    case PREFIX_LIST_LOADING: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case PREFIX_LIST_ERROR: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default: return state;
  }
};