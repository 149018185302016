import { useLayoutEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { SUCCESS } from "../../store/actions";
import { useStores } from "store/mobx/useStore";

export const useIntercomWatcher = () => {
  const {
    userStore: { loading, status },
    statusInfoStore: { statusInfo },
  } = useStores();

  const { boot, update, shutdown } = useIntercom();

  useLayoutEffect(() => {
    if (loading === false) {
      if (status === SUCCESS) {
        if (statusInfo && statusInfo.node?.networks.length > 0) {
          const info = statusInfo.node.networks[0];
          setTimeout(() => {
            boot({
              userId: `${info.id}`,
              name: info.name,
            });
            update({
              hideDefaultLauncher: false,
            });
          }, 2000);
        }
      } else {
        shutdown();
      }
    }
    // eslint-disable-next-line
  }, [status, loading, statusInfo]);
};
