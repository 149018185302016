import React, { ChangeEvent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { Form, Input, Button, notification } from "antd";

import { FAIL, SUCCESS } from "../../store/actions";
import { useStores } from "store/mobx/useStore";

import s from "./Login.module.scss";

type TState = {
  login: string;
  password: string;
};

const Login = observer(() => {
  const history = useHistory();

  const {
    userStore: { status, authenticate, logOut },
  } = useStores();

  const [values, setValues] = useState<TState>({
    login: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (status === SUCCESS) {
      history.push("/");
    }

    if (status === FAIL) {
      notification.error({
        message: "Incorrect login or password, please try again",
      });
    }

    setIsLoading(false);
  }, [history, status]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    authenticate({ login: values.login, password: values.password });
  };

  const onChangeField = (
    e: ChangeEvent<HTMLInputElement>,
    field: "login" | "password"
  ) => {
    logOut();
    setValues({
      ...values,
      [field]: e.target.value,
    });
  };

  const validate = () => {
    const { login, password } = values;
    return !(login && password);
  };

  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <div className={s.info}>
          <div className={s.logo} />
          <div className={s.infoText}>
            <span className={s.bold}>Sign in</span>
            <span>to Control center</span>
          </div>
        </div>
        <div>
          <Form onFinish={onSubmit} autoComplete="off" className={s.form}>
            <Input
              placeholder="Login"
              value={values.login}
              onChange={(e) => onChangeField(e, "login")}
            />
            <Input.Password
              placeholder="Password"
              value={values.password}
              onChange={(e) => onChangeField(e, "password")}
            />
            <Button
              type="primary"
              htmlType="submit"
              disabled={validate()}
              loading={isLoading}
            >
              Sign in
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
});

export default Login;
