import { combineReducers } from "redux";
import { fraudTypesReducer } from "./fraudTypes";
import { countriesReducer } from "./countries";
import { gatewaysReducer } from "./gateways";
import { referencesReducer } from "./references";
import { lastRecordsReducer } from "./lastRecords";
import { statsReducer } from "./stats";
import { filtersReducer } from "./filters";
import { notificationChanelsReducer } from "./notificationChanels";
import { reportsReducer } from "./reports";
import { rulesListReducer } from "./rulesList";
import { prefixListsReducer } from "./prefixList";
import { dashboardReducer } from "./dashboard";
import { usersReducer } from "./users";
import { rolesReducer } from "./roles";

export const rootReducer = combineReducers({
  users: usersReducer,
  roles: rolesReducer,
  fraudTypes: fraudTypesReducer,
  countries: countriesReducer,
  gateways: gatewaysReducer,
  references: referencesReducer,
  lastRecords: lastRecordsReducer,
  stats: statsReducer,
  filters: filtersReducer,
  notificationChannels: notificationChanelsReducer,
  reports: reportsReducer,
  rulesList: rulesListReducer,
  prefixLists: prefixListsReducer,
  dashboard: dashboardReducer,
});
