import s from "./index.module.scss";

type TableHeader = {
  columns: string[];
};

const HeaderTable = ({ columns }: TableHeader) => {
  return (
    <thead className={s.tableH}>
      <tr className={s.tableR}>
        {columns &&
          columns.map((x, _i) => {
            if (
              x === "network" ||
              x === "next hop carrier" ||
              x === "country"
            ) {
              return (
                <th key={_i} className={s.maxCell}>
                  {x}
                </th>
              );
            } else {
              return (
                <th key={x} className={s.cell}>
                  {x}
                </th>
              );
            }
          })}
      </tr>
    </thead>
  );
};

export default HeaderTable;
