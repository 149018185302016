import { GATEWAYS_FETCH_SUCCESS, GATEWAYS_FETCH_ERROR } from "../actions";

export const gatewaysReducer = (state = [], action) => {
  switch (action.type) {
    case GATEWAYS_FETCH_SUCCESS:
      return action.items;
    case GATEWAYS_FETCH_ERROR:
      return state;
    default:
      return state;
  }
};
