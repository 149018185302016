import { Button } from "antd";

import TableRow from "./TableRow";
import HeaderTable from "./TableHeader";
import {
  EGroupBy,
  TFraudType,
  TGateway,
  TNetwork,
  TTrafficList,
} from "../../../store/mobx/types";
import { dateCustom } from "utils/dates";

import s from "./index.module.scss";

type Props = {
  data: TTrafficList[];
  fraudType: string;
  idFraud: number;
  callsGroupBy: string;
  loadMore: () => void;
  hasMore: boolean;
  networks: TNetwork[];
  gateways: TGateway[];
  fraudTypes: TFraudType[];
  from: number;
  to: number | undefined;
};

export const Table = ({
  data,
  fraudType,
  idFraud,
  callsGroupBy,
  loadMore,
  hasMore,
  gateways,
  networks,
  fraudTypes,
  from,
  to,
}: Props) => {
  const columns = [
    "severity",
    "fraud volume",
    `${callsGroupBy === EGroupBy.gateway ? "next hop carrier" : callsGroupBy}`,
    "Total calls",
    "Validated calls",
    "fraud calls",
    "fraud duration",
  ];

  if (!data) return null;

  const from_ts = dateCustom(new Date())
    .subtract(from, "millisecond")
    .valueOf();
  const till_ts = to
    ? dateCustom(new Date()).subtract(to, "millisecond").valueOf()
    : dateCustom(new Date()).valueOf();

  return (
    <table className={s.tableWrapper}>
      <HeaderTable columns={columns} />
      <tbody>
        {data.map((data, i: number) => (
          <TableRow
            key={i}
            data={data}
            idFraud={idFraud}
            from={from_ts}
            to={till_ts}
            gateways={gateways}
            networks={networks}
            fraudType={fraudType}
            fraudTypes={fraudTypes}
          />
        ))}
      </tbody>

      {hasMore && (
        <tfoot>
          <tr>
            <td>
              <div style={{ margin: "20px" }}>
                <Button onClick={() => loadMore()}>Load more</Button>
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};
