import { makeAutoObservable } from "mobx";
import { AlertProps } from "antd";

import type { RootStoreType } from "./rootStore";

type NotificationData = {
  message: string;
  title?: string;
  status?: number;
};

type Notification = {
  data: NotificationData;
  type?: AlertProps["type"];
  silent?: boolean;
};

export class NotificationsStore {
  rootStore: RootStoreType;

  notification: Notification | null = null;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  /**
   * Set the notification
   * @param {Notification} notification - notification data
   * @param {number} timeout - time in ms after which the notification will be removed
   */
  setNotification = (notification: Notification, timeout?: number) => {
    this.notification = notification;
    if (timeout) setTimeout(this.resetNotification, timeout);
  };

  resetNotification = () => {
    this.notification = null;
  };
}
