import { useEffect, useState } from "react";
import { Button, Spin, Checkbox } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import FlagIcon from "components/flag/FlagIcon";
import { useNotifications } from "ui/Notifications";
import { qs } from "utils/qs";
import { useStores } from "store/mobx/useStore";
import { dateCustom } from "utils/dates";

import { DeleteButton } from "./helpers";

import { ReactComponent as IconWarning } from "assets/svg/warning-triangle.svg";

import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";

import s from "./index.module.scss";

export const PrefixListViewTable = observer(function PrefixListViewTable() {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { search } = useLocation();

  const {
    page: _page = 1,
    pageSize: _pageSize = 20,
    prefix,
    // TODO add other filters
  } = qs.parse(search, { ignoreQueryPrefix: true }) as Record<
    string,
    string | undefined
  >;

  const page = Number(_page) || 1;
  const pageSize = Number(_pageSize);

  const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const { handleError } = useNotifications();

  const {
    firewallStore: { expandPrefixList, prefixListsMap, prefixListValuesDelete },
    referenceStore: { reference },
  } = useStores();

  const { networks } = reference;

  const prefixList = prefixListsMap[Number(id)] || {};
  const data = prefixListsMap[Number(id)]?.values || [];
  const isEmpty = !data.length;

  const filteredData = data.filter(({ value }) => {
    return prefix ? value.includes(prefix) : true;
  });

  const currentPage = filteredData.slice(0, page * pageSize);

  const isEmptyFilter = !filteredData.length;
  const hasNextPage = filteredData.length > page * pageSize;

  const [isLoading, setIsLoading] = useState(isEmpty);

  useEffect(() => {
    let isCanceled = false;

    if (isEmpty && id) {
      expandPrefixList(id)
        .catch(handleError)
        .finally(() => {
          !isCanceled && setIsLoading(false);
        });
    } else setIsLoading(false);

    return () => {
      isCanceled = true;
    };
  }, [id, isEmpty, expandPrefixList, handleError]);

  const handleCheckboxChange = (list: CheckboxValueType[]) => {
    setCheckedValues(list);
    setIndeterminate(Boolean(list.length) && list.length < currentPage.length);
    setCheckAll(list.length === currentPage.length);
  };

  const handleCheckAll = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    setCheckedValues(isChecked ? currentPage.map(({ value }) => value) : []);
    setIndeterminate(false);
    setCheckAll(isChecked);
  };

  const handleLoadMore = () => {
    history.push({
      search: qs.stringify({
        ...qs.parse(search, { ignoreQueryPrefix: true }),
        page: page + 1,
      }),
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsLoading(true);

    return new Promise((resolve, reject) =>
      prefixListValuesDelete(Number(id), checkedValues as string[])
        .then(resolve)
        .catch(reject)
    )
      .catch(handleError)
      .finally(() => {
        setIsDeleting(false);
        setIsLoading(false);
        setIndeterminate(false);
        setCheckedValues([]);
        setCheckAll(false);
      });
  };

  return (isEmpty || isEmptyFilter) && !isLoading ? (
    <div className={s.noData}>
      <h1 className={s.fontTitle}>No Values found</h1>
      <p className={s.fontBody}>There are no Prefix List Values yet.</p>
    </div>
  ) : (
    <Checkbox.Group
      className={s.column}
      value={checkedValues}
      onChange={handleCheckboxChange}
    >
      <table
        className={`${s.table} ${prefixList.expirable ? "" : s.nonExpirable}`}
      >
        {/* Table Actions  */}
        <thead>
          <tr>
            <td className={s.checkAllCell}>
              <Checkbox
                className={`${s.checkbox}`}
                onChange={handleCheckAll}
                checked={checkAll}
                indeterminate={indeterminate}
                skipGroup
                disabled={isLoading}
              />
            </td>

            <td className={s.controlsCell}>
              Selected {checkedValues.length}
              <div>
                {Boolean(checkedValues.length) && (
                  // TODO replace with generic button
                  <DeleteButton
                    loading={isDeleting}
                    count={checkedValues.length}
                    onOk={handleDelete}
                  />
                )}
              </div>
            </td>
          </tr>
        </thead>

        <thead>
          <tr>
            <th>{/* checkbox */}</th>
            <th>Value</th>
            <th>Network</th>
            <th>Description</th>
            {prefixList.expirable ? <th>Expires at</th> : null}
          </tr>
        </thead>

        {/* Content */}
        <tbody className={s.content}>
          {currentPage.map((item, index) => {
            const curentNetwork = networks.find(
              (network) => network.id === item.NetworkID
            );
            return (
              <tr key={item.value + index}>
                {/* Checkbox */}
                <td>
                  <Checkbox className={`${s.checkbox}`} value={item.value} />
                </td>

                {/* Value */}
                <td>
                  <FlagIcon country={item.CountryIso || "ZZ"} />
                  <div>{item.value}</div>
                  {prefixList.values_type === "prefixes" ||
                  item.IsValid ? null : (
                    <IconWarning
                      width="2rem"
                      style={{ color: "var(--danger-base)" }}
                    />
                  )}
                </td>

                {/* Network */}
                <td
                  title={
                    curentNetwork && curentNetwork.name.length > 25
                      ? item.description
                      : ""
                  }
                >
                  <div>{curentNetwork?.name}</div>
                </td>

                {/* Description */}
                <td
                  title={item.description.length > 25 ? item.description : ""}
                >
                  <div>{item.description}</div>
                </td>

                {/* Expires AT */}
                {prefixList.expirable ? (
                  <td
                    className={`${s.expirableCell} ${
                      item.expires_at ? "" : s.disabled
                    }`}
                  >
                    {item.expires_at
                      ? dateCustom(item.expires_at).format()
                      : "-"}
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>

        {/* Spinner */}
        {isLoading && (
          <tbody className={s.spin}>
            <tr>
              <td>
                <Spin spinning />
              </td>
            </tr>
          </tbody>
        )}

        {/* Load More */}
        {hasNextPage && (
          <tbody className={s.loadMore}>
            <tr>
              <td>
                <Button className={s.btnSecondary} onClick={handleLoadMore}>
                  Load More
                </Button>
              </td>
              <td>{`Display ${currentPage.length} of ${filteredData.length}`}</td>
            </tr>
          </tbody>
        )}
      </table>
    </Checkbox.Group>
  );
});
