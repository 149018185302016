import { observer } from "mobx-react";
import { useDispatch } from "react-redux";
import { DateRangePicker } from "@vas-common/ui-kit";

import { dateCustom } from 'utils/dates';
import { useStores } from "../../../store/mobx/useStore";
import {
  dayInMillisec,
  SAVED_INTERVAL_ABH_CR,
  SAVED_INTERVAL_TO_ABH_CR,
} from "../../../utils/constants";
import { updateFilter } from "../../../store/actions";

import type { SelectOptionId } from "@vas-common/ui-kit/dist/esm/types/components/DateRangePicker/types";

import s from "./SelectInterval.module.scss";

const OPTIONS: SelectOptionId[] = ["24h", "7d", "30d", "custom"];

const SelectInterval = observer(() => {
  const dispatch = useDispatch();

  const onIntervalChange = (interval: number) => {
    const filters = {
      interval: interval,
      window: interval <= dayInMillisec ? "hour" : "day",
    };
    dispatch(updateFilter({ ...filters }));
  };

  const {
    statisticsStore: { setFilters, filters },
  } = useStores();

  const now = dateCustom().startOf("minute").valueOf();
  const { from: filtersFrom, to: filtersTo } = filters;

  const valueFrom = now - filtersFrom;
  const valueTo = filtersTo === undefined ? now : now - filtersTo;

  return (
    <div className={s.container} data-test='selectInterval'>
      <span>Time interval</span>
      <DateRangePicker
        allowClear={false}
        setOptions={OPTIONS}
        value={`[${valueFrom}, ${valueTo}]`}
        disabledDate={(date) => date > dateCustom()}
        onChange={(value) => {
          const now = dateCustom().startOf("minute");

          if (value) {
            const [from, to] = Array.isArray(value)
              ? value
              : (JSON.parse(value) as [number, number]);
            const isToday = now.isSame(to, "day");

            let distanceFrom: number;
            let distanceTo: number;

            if (isToday) {
              distanceFrom = to - from;
              distanceTo = 0;
            } else {
              const startOfToday = now.startOf("day");

              const fromStartOfDay = dateCustom(from).startOf("day");
              const toStartOfDay = dateCustom(to).startOf("day");

              distanceFrom = startOfToday.diff(fromStartOfDay);
              distanceTo = startOfToday.diff(toStartOfDay);
            }

            setFilters({
              from: distanceFrom,
              to: distanceTo,
            });

            localStorage.setItem(SAVED_INTERVAL_ABH_CR, String(distanceFrom));
            localStorage.setItem(SAVED_INTERVAL_TO_ABH_CR, String(distanceTo));

            onIntervalChange(distanceFrom);
          } else {
            // clear button clicked
            const period24Hours = now.diff(now.add(-1, "day"));

            setFilters({
              from: period24Hours,
              to: undefined,
            });

            onIntervalChange(period24Hours);
            localStorage.removeItem(SAVED_INTERVAL_TO_ABH_CR);
          }
        }}
      />
    </div>
  );
});

export default SelectInterval;
