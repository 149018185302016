import {FRAUD_TYPES_FETCH_SUCCESS, GATEWAYS_FETCH_SUCCESS, NETWORKS_FETCH_SUCCESS} from "../actions";

export const referencesReducer = (state = {}, action) => {
  switch (action.type) {
    case NETWORKS_FETCH_SUCCESS:
      const networkNames = {};
      const networkCountries = {};
      const countryNames = {};
      action.items.forEach(item => {
        networkNames[item.id] = item.name;
        networkCountries[item.id] = item.country_iso;
        countryNames[item.country_iso] = item.country_iso;
      });
      return {...state, networks: action.items, networkNames, countryNames, networkCountries};

    case GATEWAYS_FETCH_SUCCESS:
      const gatewayNames = {};
      action.items.forEach(n => {
        gatewayNames[n.id] = n.name
      });
      return {...state, gatewayNames};

    case FRAUD_TYPES_FETCH_SUCCESS:
      const fraudTypeNames = {};
      action.items.forEach(type => {
        fraudTypeNames[type.value] = type.full_name
      });
      return {...state, fraudTypeNames};

    default:
      return state;
  }
}
