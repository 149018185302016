import { Button } from "antd";
import { observer } from "mobx-react";
import { hasPerm, Perms } from "constants/Perms";
import { useStores } from "store/mobx/useStore";
import { Link } from "react-router-dom";
import { ReactComponent as IconPlus } from "assets/svg/plus.svg";
import { URLS } from "constants/Urls";
import { NoPerms } from "../../users/NoPerms";
import NotificationTable from "./NotificationTable";

import s from "./Notifications.module.scss";

const NotificationList = observer(() => {
  const {
    userStore: { user },
  } = useStores();

  const permissionAddChanel = user && !hasPerm(user, Perms.CHANNELS_ANY);
  const permissionShowChannels =
    user && !hasPerm(user, Perms.CHANNELS_ANY, Perms.CHANNELS_READ);

  return (
    <div className={s.container}>
      <div className={s.headContainer}>
        <div className={s.head}>
          <h1 className={s.fontMainTitle}>Notification Channels</h1>
          {!permissionAddChanel && (
            <Link to={URLS.channelCreate}>
              <Button className={s.btnPrimary}>
                <IconPlus width="2rem" />
                Add New
              </Button>
            </Link>
          )}
        </div>
        <p className={s.fontBody}>
          Please set up your notification channels for alerting and reporting.
        </p>
      </div>
      <div className={s.tableContainer}>
        {!permissionShowChannels ? (
          <NotificationTable />
        ) : (
          <NoPerms style={{ paddingLeft: 0 }} />
        )}
      </div>
    </div>
  );
});

export default NotificationList;
