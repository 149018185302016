import { makeAutoObservable } from "mobx";

import type { ArgsProps } from "antd/es/message";
import type { RootStoreType } from "./rootStore";

export class MessagesStore {
  rootStore: RootStoreType;

  message: ArgsProps | null = null;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  showMessage = (message: ArgsProps) => {
    this.message = message;
  };
}
