import React, {FC, memo} from "react";

import TableRow from "./TableRow";
import {TStateFields} from "../types";

import s from '../Users.module.scss';

type Props = {
  users: Array<any>,
  roles: Array<any>,
  edit: (values: TStateFields) => void;
  showButton: boolean,
  showConfirm: (str: string, idx: string) => void;
}

const Table: FC<Props> = memo(({users, roles, showButton, showConfirm, edit}) => {
  return (
    <div className={s.tableWrapper}>
      <table className={s.table}>
        <thead>
        <tr>
          <th>NAME</th>
          <th>ROLE</th>
          <th>LOGIN</th>
          <th>CREATED</th>
          <th style={{width: '50px'}}/>
        </tr>
        </thead>
        <tbody>
        {users.map((el, idx) => (
          <TableRow
            key={idx}
            item={el}
            roles={roles}
            edit={edit}
            showButton={showButton}
            showConfirm={showConfirm}
          />
        ))}
        </tbody>
      </table>
    </div>
  )
})

export default Table;
