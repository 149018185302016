import { Tag } from "antd";

import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import s from "./index.module.scss";

export const SipCodeTag = (props: CustomTagProps) => {
  const { value, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag onMouseDown={onPreventMouseDown} className={`${s.selectTag} ${s.pl8}`}>
      {value}
      <IconCross
        width="2rem"
        onClick={() => onClose()}
        className={s.closeIcon}
      />
    </Tag>
  );
};
