import React from "react";
import {CustomTagProps} from "rc-select/lib/BaseSelect";
import {Tag} from "antd";
import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";
import s from "./index.module.scss";

export const FraudTypesTag = (props: CustomTagProps) => {
  const {label, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag onMouseDown={onPreventMouseDown} className={s.selectTag}>
      {label}
      <IconCross
        width="2rem"
        onClick={() => onClose()}
        className={s.closeIcon}
      />
    </Tag>
  )
}
