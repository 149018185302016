import {IFilters} from "../../interfaces/ICommon";
import {SET_LOG_FILTERS, SET_FRAUD_CALLS_FILTERS} from "../actions";

export const filtersReducer = (state: IFilters, action: {type: any; filters: {}}) => {
  switch (action.type) {
    case SET_LOG_FILTERS: {
      return {
        ...state,
        logFilters: action.filters,
      };
    }

    case SET_FRAUD_CALLS_FILTERS: {
      return {
        ...state,
        fraudCallsFilters: action.filters,
      }
    }
    default:
      return {...state};
  }
};