import {ROLES_FAILED, ROLES_SUCCESS} from "../actions";

export const rolesReducer = (state, action) => {
  switch (action.type) {
    case ROLES_SUCCESS:
      return {
        loading: false,
        list: action.payload
      }
    case ROLES_FAILED: {
      return {
        loading: false,
        list: []
      }
    }
    default:
      return {...state}
  }
}
