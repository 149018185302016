import { useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import { TimeSeriesChart } from "@vas-common/ui-kit";

import { dateCustom } from "utils/dates";
import { useStores } from "../../../../store/mobx/useStore";
import type { TGroupByDataTimeSeriesChart } from "@vas-common/ui-kit/dist/cjs/types/components/TimeSeriesChart";

const FraudSchedule = observer(() => {
  const {
    callsStore: { fraudSchedule, filters, counterUpdate },
  } = useStores();

  const { list } = fraudSchedule;
  const { from_ts, till_ts } = filters;

  const [state, setState] = useState<{
    from: number;
    to?: number;
    groupBy: TGroupByDataTimeSeriesChart;
  }>({
    from: from_ts ? from_ts : dateCustom().subtract(1, "months").valueOf(),
    to: till_ts ? till_ts : undefined,
    groupBy: "day",
  });

  useLayoutEffect(() => {
    if (from_ts && till_ts) {
      const from = dateCustom(Number(from_ts));
      const to = dateCustom(Number(till_ts));
      const diff = to.diff(from, "hours");

      setState({
        from: from.valueOf(),
        to: to.valueOf(),
        groupBy: diff <= 24 ? "hour" : "day",
      });
    } else {
      setState({
        from: from_ts
          ? dateCustom(Number(from_ts)).valueOf()
          : dateCustom().subtract(1, "months").valueOf(),
        to: till_ts ? dateCustom(Number(till_ts)).valueOf() : undefined,
        groupBy: "day",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterUpdate]);

  if (list.length === 0) {
    return <div />;
  }

  const items = list.map((item) => ({
    timestamp: item.timestamp,
    subject1: item.inboundFraud + item.outboundFraud,
  }));

  const { from, to, groupBy } = state;

  return (
    <TimeSeriesChart
      from={Number(from)}
      to={Number(to)}
      height={130}
      showY={false}
      loader={false}
      groupBy={groupBy}
      data={items}
      subjects={{
        count: { label: "Count", color: "#a369e8" },
      }}
    />
  );
});

export default FraudSchedule;
