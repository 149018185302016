import React from "react";
import {CustomTagProps} from "rc-select/lib/BaseSelect";
import {Tag} from "antd";

import FlagIcon from "components/flag/FlagIcon";
import {useStores} from "store/mobx/useStore";
import { ReactComponent as IconCross } from "assets/svg/close-cross.svg";

import s from "./index.module.scss";

export const NetworkTag = (props: CustomTagProps) => {
  const {value, onClose } = props;

  const {referenceStore: {reference: {networks}}} = useStores();
  const network = networks.find((el) => el.id === value);

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag onMouseDown={onPreventMouseDown} className={s.selectTag}>
      <FlagIcon country={network?.country_iso || 'ZZ'}/>
      {network?.name}
      <IconCross
        width="2rem"
        onClick={() => onClose()}
        className={s.closeIcon}
      />
    </Tag>
  )
}
