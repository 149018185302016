import { FC } from "react";
import { TableRow } from "./TableRow";

import s from "./FraudCallsTable.module.scss";
import {
  TFraudCall,
  TFraudType,
  TGateway,
  TNetwork,
} from "../../../../store/mobx/types";

type Props = {
  data: TFraudCall[];
  fraudTypes: TFraudType[];
  networks: TNetwork[];
  gateways: TGateway[];
  direction: string[];
  setPosition?: (value: number | null) => void;
};

const columns = [
  "",
  "Fraud type",
  "Next hop Carrier",
  "A-NUMBER",
  "",
  "B-NUMBER",
  "DURATION",
  "Time",
  "Rule action",
];

export const Table: FC<Props> = ({
  data,
  fraudTypes,
  networks,
  gateways,
  direction,
  setPosition,
}) => {
  return (
    <table className={s.table}>
      <thead>
        <tr>
          {columns.map((x, i) => (
            <th key={i}>{x}</th>
          ))}
        </tr>
      </thead>

      {data.length ? (
        <tbody className={s.content}>
          {data.map((item, index) => (
            <TableRow
              item={item}
              key={`${item.id}${index}`}
              fraudTypes={fraudTypes}
              networks={networks}
              gateways={gateways}
              direction={direction}
              setPosition={setPosition}
            />
          ))}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td className={s.noData}>No items found</td>
          </tr>
        </tbody>
      )}
    </table>
  );
};
