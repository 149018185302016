import React, { useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { URLS } from "constants/Urls";
import { SUCCESS } from "store/actions";
import { useStores } from "store/mobx/useStore";

type Props = {
  shouldAccess?: () => boolean;
} & React.ComponentProps<typeof Route>;

const ProtectedRoute = observer(
  ({ component, shouldAccess, ...rest }: Props) => {
    const history = useHistory();

    const {
      callsStore: { setHideFilters },
      userStore: { status },
    } = useStores();

    // TODO move this effect to the component where it actually matters
    useEffect(() => {
      return history.listen((location: any) => {
        if (!location?.pathname.includes("fraudcalls")) {
          setHideFilters({ page: 1, page_size: 20 });
        }
      });
    }, [setHideFilters, history]);

    return (
      <Route
        {...rest}
        render={(props) =>
          status === SUCCESS && component ? (
            shouldAccess ? (
              shouldAccess() ? (
                React.createElement(component, props)
              ) : (
                <Redirect to={{ pathname: `${URLS.login}` }} />
              )
            ) : (
              React.createElement(component, props)
            )
          ) : (
            <Redirect to={{ pathname: `${URLS.login}` }} />
          )
        }
      />
    );
  }
);

export default ProtectedRoute;
