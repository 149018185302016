import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store/store";

import "antd/dist/reset.css";
// TODO remove this global re-assignment
import "./assets/styles/antd.scss";
import "@vas-common/ui-kit/dist/scss-library/theme.scss";

// This is the project-level theme.
// Here you can override UI-Kit theme variables
// and work with :root level variables and styles
import "./assets/styles/theme.scss";

ReactDOM.render(
  <>
    <Router>
      <Provider store={store}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID || ""}>
          <App />
        </IntercomProvider>
      </Provider>
    </Router>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
