import { useEffect, useState } from "react";
import {
  Button,
  Spin,
  Input,
  InputNumber,
  Form,
  Checkbox,
  Tooltip,
} from "antd";
import { useHistory, Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { Select } from "@vas-common/ui-kit";

import { DeleteButton } from "ui/FormControls";

import {
  arrayToRuleActionString,
  ruleActionStringToArray,
} from "utils/ruleActions";
import { useOnLeavePage } from "utils/useOnLeavePage";

import { useNotifications } from "ui/Notifications";
import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";
import { getPrefixListRuleById } from "utils/api";
import { ResetButton } from "ui/FormControls";
import {
  NotificationChannelTag,
  PrefixListTag,
} from "ui/FormControls/Select/tags";
import { RULE_ACTION_TAGS_MAP } from "ui/maps";
import dictionary from "assets/dictionary.json";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";
import { ReactComponent as IconBidirectional } from "assets/svg/arrow-transit.svg";
import { ReactComponent as IconRight } from "assets/svg/arrow-forward.svg";

import type { PrefixListRule, PrefixListRuleListType } from "store/mobx/types";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import s from "./index.module.scss";

type PrefixListRuleForm = Omit<PrefixListRule, "action"> & {
  action: ("block" | "alert")[];
};

const PrefixListRulesCreateUpdate = observer(() => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const isEditRuleMode = Boolean(id && id !== "new");

  const {
    firewallStore: {
      updatePrefixListRule,
      createPrefixListRule,
      deletePrefixListRule,
      getPrefixLists,
      prefixLists,
      prefixListRules,
    },
    referenceStore: { notificationChannels },
  } = useStores();

  const currentRuleData: PrefixListRule | null =
    prefixListRules.data.find((rule) => rule.id === Number(id)) || null;

  const [isThresholdDisabled, setIsThresholdDisabled] = useState(
    !currentRuleData?.threshold_enabled
  );
  const [isLeftPrefixListShown, setIsLeftPrefixListShown] = useState(
    currentRuleData?.list_type_left === "prefix_list"
  );
  const [isRightPrefixListShown, setIsRightPrefixListShown] = useState(
    currentRuleData?.list_type_right === "prefix_list"
  );

  const [isNotificationChannelsDisabled, setIsNotificationChannelsDisabled] =
    useState(!currentRuleData || currentRuleData?.action === "block");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [initialValues, setInitialValues] = useState<
    Partial<PrefixListRuleForm>
  >({
    action: ruleActionStringToArray(currentRuleData?.action),
    name: currentRuleData?.name || "",
    enabled: currentRuleData?.enabled || false,

    // scope
    match_direction: currentRuleData?.match_direction || "left_to_right",
    list_type_left: currentRuleData?.list_type_left || "any",
    list_type_right: currentRuleData?.list_type_right || "any",
    ...(currentRuleData?.list_ids_left && {
      list_ids_left: currentRuleData.list_ids_left,
    }),
    ...(currentRuleData?.list_ids_right && {
      list_ids_right: currentRuleData.list_ids_right,
    }),

    // threshold
    threshold_enabled: currentRuleData?.threshold_enabled || false,
    threshold_numeric: currentRuleData?.threshold_numeric || undefined,
    threshold_period: currentRuleData?.threshold_period || undefined,
    alert_on_threshold_once: currentRuleData?.alert_on_threshold_once || false,

    notification_channel_ids:
      currentRuleData?.notification_channel_ids || undefined,
  });

  // const [statistic, setStatistic] = useState<
  //   Pick<
  //     PrefixListRule,
  //     "last_triggered_at" | "matched_today" | "matched_yesterday"
  //   >
  // >({
  //   last_triggered_at: currentRuleData?.last_triggered_at || 0,
  //   matched_today: currentRuleData?.matched_today || 0,
  //   matched_yesterday: currentRuleData?.matched_yesterday || 0,
  // });

  const { error, handleError, resetNotification, setNotification } =
    useNotifications(() =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    );

  const [isLoading, setIsloading] = useState(true);

  const [form] = Form.useForm<
    Omit<PrefixListRule, "action"> & { action: ("alert" | "block")[] }
  >();

  useEffect(() => {
    let isCanceled = false;

    if (isEditRuleMode) {
      const fetchRule = async () => {
        try {
          const response = await getPrefixListRuleById(Number(id));

          if (!isCanceled) {
            setInitialValues({
              // TODO i do it only for comparison on leave the page
              name: response.name,
              enabled: response.enabled,

              // scope
              match_direction: response.match_direction,
              list_type_left: response.list_type_left,
              list_type_right: response.list_type_right,
              ...(response.list_ids_left && {
                list_ids_left: response.list_ids_left,
              }),
              ...(response.list_ids_right && {
                list_ids_right: response.list_ids_right,
              }),

              // threshold
              threshold_enabled: response.threshold_enabled,
              threshold_numeric: response.threshold_numeric,
              threshold_period: response.threshold_period,
              alert_on_threshold_once: response.alert_on_threshold_once,

              action: ruleActionStringToArray(response.action),
              notification_channel_ids: response.notification_channel_ids,
            });
            setIsThresholdDisabled(!response.threshold_enabled);
            setIsLeftPrefixListShown(response.list_type_left === "prefix_list");
            setIsRightPrefixListShown(
              response.list_type_right === "prefix_list"
            );

            setIsNotificationChannelsDisabled(response.action === "block");
            // setStatistic({
            //   last_triggered_at: response.last_triggered_at,
            //   matched_today: response.matched_today,
            //   matched_yesterday: response.matched_yesterday,
            // });
            setIsloading(false);
          }
        } catch (err) {
          if (!isCanceled) {
            handleError(err, true);
            setIsloading(false);
          }
        }
      };

      fetchRule();
    } else {
      setIsloading(false);
    }

    return () => {
      isCanceled = true;
    };
  }, [id, isEditRuleMode, handleError]);

  useOnLeavePage({
    onLeave: () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
      form.submit();
    },
    shouldDisplayMessage: () => {
      const currentValues = form.getFieldsValue();
      const isChanged = !isEqual(currentValues, initialValues);
      return isChanged && !isSubmitting && !isEmpty(currentValues);
    },
  });

  useEffect(() => {
    getPrefixLists(handleError, { page_size: 1000 });
  }, [getPrefixLists, handleError]);

  const handleThresholdEnabled = ({ target }: CheckboxChangeEvent) => {
    setIsThresholdDisabled(!target.checked);
  };

  const handleIsNotificationChannelsDisabled = (e: CheckboxChangeEvent) => {
    setIsNotificationChannelsDisabled(!e.target.checked);
  };

  const handleLeftPrefixList = (value: PrefixListRuleListType) => {
    setIsLeftPrefixListShown(value === "prefix_list");
  };

  const handleRightPrefixList = (value: PrefixListRuleListType) => {
    setIsRightPrefixListShown(value === "prefix_list");
  };

  const handleDeleteRule = async () =>
    deletePrefixListRule(Number(id))
      .then(() => history.push(URLS.prefixListRules))
      .catch(handleError);

  const isEmptyNotificationChannels = notificationChannels.data.length === 0;

  return isLoading ? (
    <Spin className={s.spin} spinning />
  ) : error?.status === 404 ? (
    <div className={s.container}>
      <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
        Not found
        <Link to={URLS.unallocatedPrsRules} className={s.mb8}>
          <Button className={s.btnFlat}>
            <IconArrowBack width="2rem" />
          </Button>
        </Link>
      </h1>
    </div>
  ) : (
    <Form
      form={form}
      className={`${s.container} ${s.form}`}
      initialValues={initialValues}
      layout="vertical"
      onFinish={(values) => {
        setIsSubmitting(true);
        resetNotification();

        const action = arrayToRuleActionString(values.action);

        const data = {
          ...values,
          action: action,
        };

        return (
          isEditRuleMode
            ? updatePrefixListRule(Number(id), data)
            : createPrefixListRule(data)
        )
          .then(() => {
            history.push(URLS.prefixListRules);
          })
          .then(() => {
            setNotification(
              {
                type: "success",
                data: {
                  message: isEditRuleMode
                    ? "Successfully Updated"
                    : "Successfully Created",
                },
              },
              1500
            );
          })
          .catch((err) => {
            setIsSubmitting(false);
            handleError(err);
          });
      }}
    >
      <section className={s.section}>
        <h1 className={`${s.fontMainTitle} ${s.mb24} ${s.titleBackBtn}`}>
          {isEditRuleMode ? "Edit Rule" : "New Rule"}

          <Link to={URLS.prefixListRules}>
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>

        {/* TODO do we need a statistic here?*/}
        {/* {isEditRuleMode && initialValues.enabled ? (
          <Statistic ruleType={'prefixlist'} statistic={statistic} />
        ) : null} */}

        {/* Name Active */}
        <div className={s.row}>
          <Form.Item
            label="Rule Name"
            name="name"
            required={false}
            rules={[{ required: true, message: "Rule Name is required" }]}
          >
            <Input className={s.input} />
          </Form.Item>

          <Form.Item
            className={s.activeCheckbox}
            name="enabled"
            valuePropName="checked"
          >
            <Checkbox className={s.checkbox}>
              <div className={s.fontBody}>Enabled</div>
            </Checkbox>
          </Form.Item>
        </div>
      </section>

      {/* Scope */}
      <section className={`${s.section} ${s.gap24}`}>
        <div>
          <h2 className={`${s.fontTitle} ${s.mb8}`}>Scope</h2>

          <p className={s.fontBody}>
            {dictionary.descriptions.rules.prefixListRule.scope}
          </p>
        </div>

        <div className={s.column}>
          <div className={s.row}>
            <Form.Item
              label="Matching Direction"
              name="match_direction"
              required={false}
              rules={[
                { required: true, message: "Matching Direction is required" },
              ]}
              tooltip={{
                title: dictionary.tooltips.matchingDirection,
                icon: <IconHelp width="1.6rem" />,
              }}
            >
              <Select
                options={[
                  {
                    value: "left_to_right",
                    label: (
                      <div className={s.directionSelectItem}>
                        <IconRight width="2rem" />
                        Left to right
                      </div>
                    ),
                  },
                  {
                    value: "bidirectional",
                    label: (
                      <div className={s.directionSelectItem}>
                        <IconBidirectional width="2rem" />
                        Bidirectional
                      </div>
                    ),
                  },
                ]}
              />
            </Form.Item>
            <div />
          </div>

          <div className={s.row}>
            <Form.Item
              label="Left"
              name="list_type_left"
              required={false}
              dependencies={["list_type_right"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value: PrefixListRuleListType) {
                    const isCurrentPreffixList = value === "prefix_list";

                    const isOtherSidePrefixList =
                      getFieldValue("list_type_right") === "prefix_list";

                    if (isCurrentPreffixList || isOtherSidePrefixList) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Require 'PrefixList' type on one side"
                      );
                    }
                  },
                }),
              ]}
            >
              <Select
                options={[
                  { value: "any", label: "Any" },
                  { value: "own_range", label: "Own range" },
                  { value: "prefix_list", label: "Prefix list" },
                ]}
                onChange={handleLeftPrefixList}
              />
            </Form.Item>

            <Form.Item
              label="Right"
              name="list_type_right"
              required={false}
              dependencies={["list_type_left"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value: PrefixListRuleListType) {
                    const isCurrentPreffixList = value === "prefix_list";

                    const isOtherSidePrefixList =
                      getFieldValue("list_type_left") === "prefix_list";

                    if (isCurrentPreffixList || isOtherSidePrefixList) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Require 'PrefixList' type on one side"
                      );
                    }
                  },
                }),
              ]}
            >
              <Select
                options={[
                  { value: "any", label: "Any" },
                  { value: "own_range", label: "Own range" },
                  { value: "prefix_list", label: "Prefix list" },
                ]}
                onChange={handleRightPrefixList}
              />
            </Form.Item>
          </div>

          {!(isLeftPrefixListShown || isRightPrefixListShown) ? null : (
            <div className={s.row}>
              <div>
                {isLeftPrefixListShown && (
                  <Form.Item
                    label="Left Prefix List"
                    name="list_ids_left"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: "Prefix List must not be empty",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      tagRender={PrefixListTag}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={prefixLists.data.map((item) => ({
                        value: item.id,
                        label: `${item.name}`,
                      }))}
                    />
                  </Form.Item>
                )}
              </div>

              <div>
                {isRightPrefixListShown && (
                  <Form.Item
                    label="Right Prefix List"
                    name="list_ids_right"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: "Prefix List must not be empty",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      tagRender={PrefixListTag}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={prefixLists.data.map((item) => ({
                        value: item.id,
                        label: `${item.name}`,
                      }))}
                    />
                  </Form.Item>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Threshold */}
      <section className={s.section}>
        <h2 className={`${s.fontTitle} ${s.mb16}`}>Threshold</h2>

        <div className={`${s.rowCheckbox} ${s.mb16}`}>
          <Form.Item name="threshold_enabled" valuePropName="checked">
            <Checkbox className={s.checkbox} onChange={handleThresholdEnabled}>
              <div className={s.fontBody}>Apply threshold</div>
            </Checkbox>
          </Form.Item>
        </div>

        <div className={s.rowCheckbox}>
          <Form.Item
            label="Calls"
            name="threshold_numeric"
            style={{ width: "6.4rem" }}
          >
            <InputNumber className={s.input} disabled={isThresholdDisabled} />
          </Form.Item>

          <Form.Item
            label="Period"
            name="threshold_period"
            style={{ width: "15.2rem" }}
          >
            <Select
              allowClear
              disabled={isThresholdDisabled}
              options={[
                { value: "hour", label: "Hour" },
                { value: "day", label: "Day" },
              ]}
            />
          </Form.Item>

          <Form.Item
            className={s.alertOnceWrapper}
            name="alert_on_threshold_once"
            valuePropName="checked"
          >
            <Checkbox className={s.checkbox} disabled={isThresholdDisabled}>
              <div className={`${s.fontBody} ${s.alertOnce}`}>
                Alert once
                <Tooltip
                  placement="right"
                  title={dictionary.tooltips.alertOnce}
                >
                  <IconHelp width="2rem" />
                </Tooltip>
              </div>
            </Checkbox>
          </Form.Item>
        </div>
      </section>

      {/* Rule Action */}
      <section className={s.section}>
        <h2 className={`${s.fontTitle} ${s.mb16}`}>Actions</h2>

        <div className={s.column}>
          <Form.Item
            name="action"
            className={s.checkboxGroup}
            required={false}
            rules={[{ required: true, message: "Rule Action is required" }]}
            normalize={(value) => {
              value.sort();
              return value;
            }}
          >
            <Checkbox.Group>
              <Checkbox
                className={s.checkbox}
                value="alert"
                onChange={handleIsNotificationChannelsDisabled}
                disabled={isEmptyNotificationChannels}
              >
                {RULE_ACTION_TAGS_MAP["alert"]}
              </Checkbox>
              <Checkbox className={s.checkbox} value="block">
                {RULE_ACTION_TAGS_MAP["block"]}
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            label="Notification Channels"
            name="notification_channel_ids"
            dependencies={["action"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const isAlertOn = Boolean(
                    getFieldValue("action")?.includes("alert")
                  );
                  const isEmptyChannels = !value || value.length === 0;

                  if (isAlertOn && isEmptyChannels) {
                    return Promise.reject(
                      new Error(
                        "At least one notification channel should be selected"
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              mode="multiple"
              tagRender={NotificationChannelTag}
              options={notificationChannels.data.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              disabled={isNotificationChannelsDisabled}
            />
          </Form.Item>
        </div>
      </section>

      {/* Buttons */}
      <section className={s.section}>
        <div className={s.buttonsRow}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsError, getFieldsValue, resetFields }) => {
              const isValid = getFieldsError().every(
                (field) => field.errors.length === 0
              );
              const currentValues = getFieldsValue();
              const isChanged = !isEqual(currentValues, initialValues);
              return (
                <>
                  <Button
                    className={s.btnPrimary}
                    htmlType="submit"
                    disabled={!isChanged || !isValid}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>

                  {isEditRuleMode ? null : (
                    <ResetButton onOk={resetFields} disabled={!isChanged} />
                  )}
                </>
              );
            }}
          </Form.Item>

          {isEditRuleMode && initialValues.name && (
            <DeleteButton
              ruleName={initialValues.name}
              onDelete={handleDeleteRule}
            />
          )}
        </div>
      </section>
    </Form>
  );
});

export default PrefixListRulesCreateUpdate;
