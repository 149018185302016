import { Button } from "antd";
import { showConfirm } from "ui/Modals";

import s from "./index.module.scss";

type ResetButtonProps = {
  disabled: boolean;
  onOk: () => void;
};

export const ResetButton = ({ disabled, onOk }: ResetButtonProps) => {
  const showResetConfirm = () =>
    showConfirm({
      title: "Reset Form",
      content: "Are you sure you want to reset current form state?",
      okText: "Reset",
      onOk: () => onOk(),
    });

  return (
    <Button
      className={s.btnSecondary}
      htmlType="button"
      onClick={showResetConfirm}
      disabled={disabled}
    >
      Reset
    </Button>
  );
};
