import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Spin, Form, Input } from "antd";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";
import { Select } from "@vas-common/ui-kit";

import { URLS } from "constants/Urls";
import { useStores } from "store/mobx/useStore";
import { getDescription } from "../NotificationTable";
import { useNotifications } from "ui/Notifications";
import { showConfirm } from "ui/Modals";
import { ResetButton } from "ui/FormControls/Buttons";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";
import { ReactComponent as EmailIcon } from "assets/svg/mail.svg";
import { ReactComponent as SlackIcon } from "assets/svg/slack.svg";
import { ReactComponent as HttpIcon } from "assets/svg/webhook.svg";
import { ReactComponent as IconHelp } from "assets/svg/question-mark-circle.svg";

import { NotificationChannel, NotificationTypes } from "store/mobx/types";

import s from "./NotificationChannelForm.module.scss";

type ChannelForm = {
  name: string;
  type: NotificationTypes;
  entries: string;
};

const selectItems = [
  {
    value: "email",
    label: (
      <div className={s.selectItem}>
        <EmailIcon />
        Email
      </div>
    ),
  },
  {
    value: "slack",
    label: (
      <div className={s.selectItem}>
        <SlackIcon />
        Slack
      </div>
    ),
  },
  {
    value: "http",
    label: (
      <span className={s.selectItem}>
        <HttpIcon />
        HTTP
      </span>
    ),
  },
];

const NotificationChannelDetail = observer(() => {
  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const isEditChannelMode = Boolean(id && id !== "new");

  const {
    referenceStore: {
      notificationChannels,
      updateNotificationChannel,
      createNotificationChannel,
      deleteNotificationChannel,
    },
  } = useStores();

  const { data: channels, isLoading } = notificationChannels;

  const currentChannelData = channels.find((item) => item.id === Number(id));

  const initialValues = {
    entries: currentChannelData?.entries,
    name: currentChannelData?.name,
    type: currentChannelData?.type,
  };
  const [form] = Form.useForm<ChannelForm>();

  const { handleError, setNotification, resetNotification } = useNotifications(
    () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [urlLabel, setUrlLabel] = useState<string>("Address");

  const setLabel = (value: string) => {
    switch (value) {
      case "slack":
        setUrlLabel("Webhook URL");
        break;
      case "email":
        setUrlLabel("Address");
        break;
      case "http":
        setUrlLabel("URL");
        break;
      default:
        setUrlLabel("Address");
    }
  };

  const showDeleteConfirm = () =>
    showConfirm({
      title: "Delete Chanel",
      content: (
        <>
          Are you sure you want to delete "
          <span className={s.fontBodyBold}>{currentChannelData?.name}</span>"?
        </>
      ),
      okText: "Delete",
      okButtonProps: {
        className: s.btnDanger,
      },
      onOk() {
        deleteNotificationChannel(Number(id))
          .then(() => {
            history.push(URLS.channels);
          })
          .then(() => {
            setNotification(
              { type: "success", data: { message: "Successfully Deleted" } },
              1500
            );
          })
          .catch(handleError);
      },
    });

  if (isLoading) {
    return (
      <div className={s.container}>
        <Spin className={s.spin} spinning />
      </div>
    );
  }

  return (
    <div className={s.container}>
      {!currentChannelData && isEditChannelMode ? (
        <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
          Not found
          <Link to={URLS.channels}>
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>
      ) : (
        <>
          <section className={s.grid}>
            <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
              {isEditChannelMode ? "Edit Channel" : "New Channel"}
              <Link to={URLS.channels} className={s.mb8}>
                <Button className={s.btnFlat}>
                  <IconArrowBack width="2rem" />
                </Button>
              </Link>
            </h1>
          </section>

          <Form
            form={form}
            className={`${s.form}`}
            initialValues={initialValues}
            layout="vertical"
            onFinish={(values) => {
              setIsSubmitting(true);
              resetNotification();
              let entries: string[] = [];
              if (typeof values.entries === "string") {
                entries = values.entries.split(";");
              }
              const body: Partial<NotificationChannel> = {
                name: values.name,
                type: values.type,
                entries: entries,
              };

              return (
                isEditChannelMode
                  ? updateNotificationChannel(Number(id), body)
                  : createNotificationChannel(body)
              )
                .then(() => {
                  history.push(URLS.channels);
                })
                .then(() => {
                  setNotification(
                    {
                      type: "success",
                      data: {
                        message: isEditChannelMode
                          ? "Successfully Updated"
                          : "Successfully Created",
                      },
                    },
                    1500
                  );
                })
                .catch((err) => {
                  setIsSubmitting(false);
                  handleError(err);
                });
            }}
            autoComplete="off"
            onValuesChange={(changedValues) => {
              if (changedValues?.type) {
                setLabel(changedValues.type);
              }
            }}
          >
            {currentChannelData && (
              <div className={s.info}>
                {`${
                  currentChannelData.usage?.fraud_rule_ids.length ||
                  currentChannelData.usage?.report_task_ids.length
                    ? "Used"
                    : ""
                } ${getDescription(currentChannelData)}`}
              </div>
            )}

            <section className={`${s.grid} ${s.gap24}`}>
              <Form.Item
                label="Channel name"
                name="name"
                required={false}
                rules={[
                  { required: true, message: "Channel name is required" },
                ]}
              >
                <Input className={s.input} placeholder="Enter name" />
              </Form.Item>

              <Form.Item
                label="Channel type"
                name="type"
                required={false}
                rules={[
                  { required: true, message: "Channel type is required" },
                ]}
              >
                <Select
                  placeholder="Select channel type"
                  options={selectItems.map((el) => ({
                    value: el.value,
                    label: el.label,
                  }))}
                />
              </Form.Item>

              <Form.Item
                label={urlLabel}
                name="entries"
                required={false}
                rules={[{ required: true, message: `${urlLabel} is required` }]}
                tooltip={
                  urlLabel === "Address"
                    ? {
                        title:
                          'You can enter multiple email addresses using a ";" separator',
                        icon: <IconHelp width="1.6rem" />,
                      }
                    : undefined
                }
              >
                <Input className={s.input} placeholder={`Enter ${urlLabel}`} />
              </Form.Item>
            </section>

            <section className={s.grid}>
              <div className={s.buttonsRow}>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldsError, getFieldsValue, resetFields }) => {
                    const isValid = getFieldsError().every(
                      (field) => field.errors.length === 0
                    );
                    const currentValues = getFieldsValue();
                    const isChanged = !isEqual(currentValues, initialValues);
                    return (
                      <>
                        <Button
                          className={s.btnPrimary}
                          htmlType="submit"
                          disabled={!isChanged || !isValid}
                          loading={isSubmitting}
                        >
                          Save
                        </Button>
                        {isEditChannelMode ? (
                          <Button
                            className={s.btnSecondary}
                            htmlType="button"
                            onClick={showDeleteConfirm}
                          >
                            Delete
                          </Button>
                        ) : (
                          <ResetButton
                            onOk={resetFields}
                            disabled={!isChanged}
                          />
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              </div>
            </section>
          </Form>
        </>
      )}
    </div>
  );
});

export default NotificationChannelDetail;
