import {Button} from 'antd';
import {Link} from 'react-router-dom';
import { UIHelpers } from '@vas-common/ui-kit';

import FlagIcon from '../../../flag/FlagIcon';
import {URLS} from '../../../../constants/Urls';
import {TListParticipantsFormat} from "../../../../store/mobx/types";

import s from './tableColumns.module.scss';

const columns = [
  {
    title: 'Country/Network',
    key: 'country',
    width: '25%',
    render: (text: string, record: TListParticipantsFormat) => renderCountry(record),
  },
  {
    title: 'Participant status',
    key: 'participantsActive',
    width: '15%',
    render: (text: string, record: TListParticipantsFormat) => renderParticipation(record)
  },
  {
    title: 'Node status',
    key: 'nodes',
    width: '15%',
    render: (text: boolean, record: TListParticipantsFormat) => renderStatus(record)
  },
  {
    title: 'Inbound calls',
    key: 'inbound',
    width: '10%',
    render: (text: number, record: TListParticipantsFormat) => renderCallsCount(record, 'inbound')
  },
  {
    title: 'Outbound calls',
    key: 'outbound',
    width: '10%',
    render: (text: number, record: TListParticipantsFormat) => renderCallsCount(record, 'outbound')
  },
  {
    title: 'Validated calls',
    key: 'validate',
    width: '10%',
    render: (text: number, record: TListParticipantsFormat) => renderCallsCount(record, 'validate')
  },
  {
    title: 'Prefixes',
    key: 'prefixes',
    width: '10%',
    render: (text: number, record: TListParticipantsFormat) => renderCallsCount(record, 'prefixes')
  },
  {
    title: '',
    key: 'btn',
    width: '5%',
    render: (val: any, record: TListParticipantsFormat) => addRuleCell(record)
  },
];

const addRuleCell = (record: TListParticipantsFormat) => (
  <Link className={s.addRuleCell} to={{pathname: URLS.createFraudRule, state: {idCountry: record.country}}}>
    <Button className={s.btnAddRule}>Add rule</Button>
  </Link>
)

const renderCountry = (record: TListParticipantsFormat) => (
  <div className={s.countryColumn}>
    <FlagIcon country={record.country} className=""/>
    <div>
      {UIHelpers.countries.getCountryName(record.country.toUpperCase())}
      {(record.country === 'ZZ' || record.country === 'ZY') ? '' : <>&nbsp;({record.country.toUpperCase()})</>}</div>
  </div>
)

const renderStatus = (record: TListParticipantsFormat) => (
  <div className={record.nodes ? s.active : s.inactive}/>
)


const renderCallsCount = (record: TListParticipantsFormat, type: "inbound" | "outbound" | "validate" | "prefixes") => {
  const value = record[type] > 10000 ? `${(record[type] / 1000).toFixed(2)}K` : record[type].toLocaleString();
  const name = type.charAt(0).toUpperCase() + type.slice(1)
  const styleCss = record[type] > 0 ? s.blackFont : s.lightGrayFont;
  const stylePadding = type === 'validate' ? s.paddingS : ''
  if (record[type] > 0) {
    <div className={`${styleCss} ${stylePadding}`}>
      {`${value} (${name} calls)`}
    </div>
  }
  return (
    <div className={`${styleCss} ${stylePadding}`}>
      {`${value}`}
    </div>
  )
}

const renderParticipation = (record: TListParticipantsFormat) => (
  <div
    className={`${(record.participantsActive > 0 || record.participantsTotal > 0) ? s.blackFont : s.lightGrayFont}`}>
    {getNetTypeCount(record)}
  </div>
)

const getNetTypeCount = (record: TListParticipantsFormat) => {
  const arrayActive = record.networks.filter((el) => el.type.toLowerCase() === 'mobile' || el.type.toLowerCase() === 'landline' || el.type === "Application")
  if (record.participantsActive > 0) {
    return `${record.participantsActive} / ${arrayActive.length} participants`
  } else {
    return `0 / ${record.participantsTotal} participants`
  }
}

export default columns;
