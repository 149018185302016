import { useEffect } from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { Link } from "react-router-dom";

import { useStores } from "store/mobx/useStore";
import { ReactComponent as IconPlus } from "assets/svg/plus.svg";
import { URLS } from "constants/Urls";
import { hasPerm, Perms } from "constants/Perms";
import { NoPerms } from "../../users/NoPerms";
import ReportsTable from "./ReportsTable";
import { IReport } from "interfaces/IReports";
import { EPeriodicity } from "./helper";
import dictionary from "assets/dictionary.json";

import s from "./Reports.module.scss";

const ReportsComponent = observer(() => {
  const {
    userStore: { user },
    referenceStore: { reference, notificationChannels },
    settingsStore: {
      getReportsAsync,
      updateReportAsync,
      sendReportAsync,
      setCurrentReportStatus,
      updatePredefinedReportAsync,
      reports,
      loadingReports,
    },
  } = useStores();

  useEffect(() => {
    getReportsAsync();
  }, [getReportsAsync]);

  const predefinedReportsArray = reports?.filter((el) => el.predefined);

  const dailyReports = predefinedReportsArray?.find(
    (el) => el.periodicity === EPeriodicity.Daily
  );
  const weeklyReports = predefinedReportsArray?.find(
    (el) => el.periodicity === EPeriodicity.Weekly
  );
  const monthlyReports = predefinedReportsArray?.find(
    (el) => el.periodicity === EPeriodicity.Monthly
  );

  const predefinedReports: IReport[] = [
    dailyReports,
    weeklyReports,
    monthlyReports,
  ]
    .filter((el) => el !== undefined)
    .map((el) => el as IReport);

  const customReports = reports.filter((el) => !el.predefined);

  const { networks, fraudTypes } = reference;

  const { data, isLoading } = notificationChannels;

  const updateReport = (id: string | number, report: IReport) => {
    if (report.predefined) {
      return updatePredefinedReportAsync(id, {
        enabled: report.enabled,
        notification_channel_ids: report.notification_channel_ids,
      });
    } else {
      return updateReportAsync(id, { ...report });
    }
  };

  return (
    <div className={s.container}>
      <div className={s.headContainer}>
        <div className={s.head}>
          <h1 className={s.fontMainTitle}>Regular Reports</h1>
        </div>
        <p className={s.fontBody}>
          {dictionary.descriptions.reports.regularReports}
        </p>
      </div>

      {user && !hasPerm(user, Perms.REPORTS_ANY, Perms.REPORTS_READ) ? (
        <NoPerms style={{ paddingLeft: 0 }} />
      ) : (
        <>
          <div className={s.tableContainer}>
            <div className={s.head}>
              <h1 className={s.fontTitle}>Predefined Reports</h1>
            </div>
            <ReportsTable
              networks={networks}
              fraudTypes={fraudTypes}
              isLoading={loadingReports}
              notificationChannels={data}
              isLoadingChannel={isLoading}
              sendReport={sendReportAsync}
              updateReport={updateReport}
              setCurrentReportStatus={setCurrentReportStatus}
              reports={predefinedReports.filter((el) => el !== undefined)}
            />
          </div>

          <div className={s.tableContainer}>
            <div className={s.head}>
              <h1 className={s.fontTitle}>Custom Reports</h1>

              <div className={s.buttonBar}>
                <Link to={URLS.reportGenerate}>
                  <Button className={s.btnSecondary}>Generate now</Button>
                </Link>

                <Link to={URLS.reportCreate}>
                  <Button className={s.btnPrimary}>
                    <IconPlus width="2rem" />
                    Add New
                  </Button>
                </Link>
              </div>
            </div>

            <ReportsTable
              custom
              networks={networks}
              fraudTypes={fraudTypes}
              reports={customReports}
              isLoading={loadingReports}
              notificationChannels={data}
              isLoadingChannel={isLoading}
              sendReport={sendReportAsync}
              updateReport={updateReport}
              setCurrentReportStatus={setCurrentReportStatus}
            />
          </div>
        </>
      )}
    </div>
  );
});

export default ReportsComponent;
