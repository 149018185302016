import { TCountries } from "store/mobx/types";
import FlagIcon from "components/flag/FlagIcon";

import s from "./index.module.scss";

export const CountryLabel = ({ country }: { country: TCountries }) => {
  return (
    <span className={s.label}>
      <FlagIcon country={country?.code || "ZZ"} />
      {country.value}
    </span>
  );
};
