import { makeAutoObservable, runInAction } from "mobx";
import sortBy from "lodash/sortBy";
import { UIHelpers } from "@vas-common/ui-kit";

import api, { getCallControlRules } from "utils/api";
import {
  createNotificationChannel,
  deleteNotificationChannel,
  getNotificationChannels,
  updateNotificationChannel,
} from "utils/api/abh-call-registry";
import { defaultDirection } from "./default";
import {
  TGateway,
  TNetwork,
  TFraudType,
  TConnectors,
  TReferenceState,
  TAvailableConnectorsState,
  CallControleRule,
  NotificationChannel,
  NotificationChannelsState,
  CallControleRuleGourp,
} from "./types";
import { TConnectorsResponse } from "./types/response";

import type { RootStoreType } from "./rootStore";

const arrayCountries = Object.entries(UIHelpers.countries.codes).map(
  ([key, value]) => {
    return { code: key, value: value };
  }
);

export class ReferenceStore {
  rootStore: RootStoreType;

  reference: TReferenceState = {
    loading: true,
    networks: [],
    gateways: [],
    fraudTypes: [],
    direction: defaultDirection,
    countries: arrayCountries,
  };

  /**
   * callControllRules - is the collection of all the rules of all types to show in a rules selector
   */
  callControlRules: CallControleRule[] = [];

  availableConnectors: TAvailableConnectorsState = {
    loading: true,
    connectors: [],
  };

  notificationChannels: NotificationChannelsState = {
    isLoading: true,
    data: [],
  };

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  // Group Call control rules by types to show in Filter's selectors
  get callControlRulesByTypes() {
    return this.callControlRules.reduce<CallControleRuleGourp>(
      (acc, { id, name, type }) => {
        if (acc[type]) {
          acc = {
            ...acc,
            [type]: [...acc[type], { label: name, value: id }],
          };
        } else {
          acc[type] = [{ label: name, value: id }];
        }

        return acc;
      },
      {} as CallControleRuleGourp
    );
  }

  setCallControlRules = (data: CallControleRule[]) => {
    this.callControlRules = data;
  };

  setReference = (
    networks: TNetwork[],
    gateways: TGateway[],
    fraudTypes: TFraudType[]
  ) => {
    const sortGateway = sortBy(gateways, "id");

    this.reference = {
      ...this.reference,
      loading: false,
      networks,
      gateways: sortGateway,
      fraudTypes: fraudTypes,
    };
  };

  setConnectors = (connectors: TConnectors[]) => {
    this.availableConnectors = {
      loading: false,
      connectors: connectors,
    };
  };

  setNotificationChannels = (channels: NotificationChannel[]) => {
    this.notificationChannels.data = channels;
    this.notificationChannels.isLoading = false;
  };

  getReferenceAsync = () => {
    Promise.all([
      api<TFraudType[]>({ method: "GET", url: "/api/v1/fraud_types" }),
      api<TGateway[]>({ method: "GET", url: "/api/v1/gateways" }),
      api<TNetwork[]>({ method: "GET", url: "/api/v1/networks" }),
    ])
      .then(([fraudTypes, gateways, networks]) => {
        this.setReference(networks, gateways, fraudTypes);
      })
      .catch(() => {
        this.setReference([], [], []);
      });
  };

  // Get all call controll rules at once to use in Filter's selectors
  getCallControlRules = async () => {
    try {
      const result = await getCallControlRules();
      this.setCallControlRules(result);
    } catch (err) {
      console.warn("[Error] getCallControlRules: ", err);
    }
  };

  getConnectorsAsync = () => {
    api<TConnectorsResponse>({
      method: "GET",
      url: "/api/v1/signaling_hub_status",
    })
      .then((res) => {
        runInAction(() => {
          this.setConnectors(res.connectors);
        });
      })
      .catch(() => {
        this.setConnectors([]);
      });
  };

  getNotificationChannels = async () => {
    try {
      this.notificationChannels.isLoading = true;
      const data = await getNotificationChannels();
      this.setNotificationChannels(data);
    } catch (err) {
      console.warn("[Error] getNotificationChannels: ", err);
    }
  };

  updateNotificationChannel = async (
    id: number,
    data: Partial<NotificationChannel>
  ) => {
    const updatedChannel = await updateNotificationChannel(id, data);

    runInAction(() => {
      this.notificationChannels.data = this.notificationChannels.data.map(
        (channel) => (channel.id === id ? updatedChannel : channel)
      );
    });
  };

  createNotificationChannel = async (data: Partial<NotificationChannel>) => {
    const newChannel = await createNotificationChannel(data);

    runInAction(() => {
      this.notificationChannels.data = [
        ...this.notificationChannels.data,
        newChannel,
      ];
    });
  };

  deleteNotificationChannel = async (id: number) => {
    await deleteNotificationChannel(id);

    runInAction(() => {
      this.notificationChannels.data = this.notificationChannels.data.filter(
        (channel) => channel.id !== id
      );
    });
  };
}
