import "./flag-icon.css";

type Props = {
  country: string;
  className?: string;
  title?: string;
};

const flag = ({ country, className = "", title }: Props) => (
  <span
    title={title}
    className={`flag-icon flag-icon-${country.toLowerCase()} ${className}`}
  />
);

export default flag;
