import { makeAutoObservable, runInAction } from "mobx";
import  isEqual  from "lodash/isEqual";
import  pickBy  from "lodash/pickBy";

import api from "utils/api";
import { getTerminatedCalls, createTerminatedCallsReport, getTerminatedCallsReport } from "utils/api/abh-call-registry";
import {types, windowInterval} from "utils/constants";
import { dateCustom } from 'utils/dates';

import {
  defaultFiltersCalls,
  defaultFraudCallsState,
  defaultFraudState,
  defaultHideFiltersCalls,
  defaultTerminatedCallsParams,
} from "./default";
import {getParamsFromFilters, getParamsFromFiltersStatistic} from "./utils";

import {
  TFiltersCalls,
  TFraudCall,
  TFraudCalls,
  TFraudDetail,
  TFraudDetailState,
  TFraudSchedule,
  THideFiltersCalls,
  TListCalls,
  TStatsCombinedDataTimeResponse,
  TerminatedCalls,
  TerminatedCallsParams
} from "./types";

import type { RootStoreType } from "./rootStore";
export class CallsStore {
  rootStore: RootStoreType;

  fraudStateDashboard: TFraudCalls = defaultFraudCallsState;
  fraudState: TFraudCalls = defaultFraudCallsState;
  filters: TFiltersCalls = defaultFiltersCalls;
  counterUpdate = 0;
  hideFilters: THideFiltersCalls = defaultHideFiltersCalls;
  fraudSchedule: TFraudSchedule = {
    ...defaultFraudState,
    loading: true
  }
  savePosition: number | null = null;
  fraudDetail: TFraudDetailState = {
    loading: true,
    call: null
  };

  terminatedCalls: TerminatedCalls = {
    isLoading: true,
    hasNextPage: true,
    params: defaultTerminatedCallsParams,
    data: [],
  };

  isTerminatedCallsReportLoading = false;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false })
  }

  setPosition = (value: number | null) => {
    this.savePosition = value;
  }

  setFiltersArray = (filter: any): Promise<TFiltersCalls> => {
    const objectFilters: TFiltersCalls = filter;
    // TODO WTF? refactor it ASAP because we work wit [from, to] as a numbers!!!
    // but sometime it happends to be strings (see src/components/monitoring/dashboard/fraudCallsTable/FraudSchedule.tsx)
    return new Promise((resolve) => {
      Object.entries(filter).forEach(([key, value]) => {
        // @ts-ignore
        objectFilters[key] = value;
      })
      this.filters = objectFilters;
      resolve(objectFilters)
    })
  }

  setHideFilters = (hideFilters: THideFiltersCalls) => {
    this.hideFilters = hideFilters;
  }

  setFraudSchedule = (res: TStatsCombinedDataTimeResponse[]) => {
    if (res.length) {
      const resSort = res.filter((el) => el.direction)
        .sort((x, y) => x.datetime - y.datetime);
      let arrayTotalCalls: TListCalls[] = [];
      let arrayValidCalls: TListCalls[] = [];
      const arrayAllCalls = resSort.map((el) => {
        const item = resSort.filter((x) => x.datetime === el.datetime);
        const inboundTotal = item.find((x) => x.direction === types.inbound)?.total || 0;
        const outboundTotal = item.find((x) => x.direction === types.outbound)?.total || 0;
        const inboundValid = item.find((x) => x.direction === types.inbound)?.fraudulent || 0;
        const outboundValid = item.find((x) => x.direction === types.outbound)?.fraudulent || 0;
        const fraudulentInbound = item.find((x) => x.direction === types.inbound)?.fraudulent || 0;
        const fraudulentOutbound = item.find((x) => x.direction === types.outbound)?.fraudulent || 0;
        const totalItem = {
          timestamp: el.datetime,
          inboundFraud: inboundTotal,
          outboundFraud: outboundTotal
        }
        const validItem = {
          timestamp: el.datetime,
          inboundFraud: inboundValid,
          outboundFraud: outboundValid
        };
        arrayTotalCalls.push(totalItem);
        arrayValidCalls.push(validItem)
        return {
          timestamp: el.datetime,
          inboundFraud: fraudulentInbound,
          outboundFraud: fraudulentOutbound,
        }
      })

      // @ts-ignore
      const uniqueArrayAllCalls = [...new Map(arrayAllCalls.map((item) => [item["timestamp"], item])).values()];
      // @ts-ignore
      const uniqueArrayTotalCalls = [...new Map(arrayTotalCalls.map((item) => [item["timestamp"], item])).values()];
      // @ts-ignore
      const uniqueArrayValidCalls = [...new Map(arrayValidCalls.map((item) => [item["timestamp"], item])).values()];
      this.fraudSchedule = {
        loading: false,
        list: uniqueArrayAllCalls,
        totalList: uniqueArrayTotalCalls,
        validList: uniqueArrayValidCalls
      };
    } else {
      this.fraudSchedule = {
        loading: false,
        list: [],
        totalList: [],
        validList: []
      };
    }
    this.counterUpdate = this.counterUpdate + 1;
  }

  setFraudStateDashboard = (data: TFraudCall[]) => {
    this.fraudStateDashboard = {
      loading: false,
      data: data
    }
  }

  setFraudState = (data: TFraudCall[], concat?: boolean) => {
    if (concat) {
      this.fraudState = {
        loading: false,
        data: this.fraudState.data.concat(data)
      }
    } else {
      this.fraudState = {
        loading: false,
        data: data
      }
    }
  }

  setFraudDetail = (call: TFraudDetail | null) => {
    this.fraudDetail = {
      loading: false,
      call: call
    };
  }

  setTerminatedCallsData = (data: TerminatedCalls["data"] ) => {
    this.terminatedCalls.data = data;
  }

  setTerminatedCallsLoading = (value: boolean) => {
    this.terminatedCalls.isLoading = value;
  }

  setTerminatedCallsDataHasNextPage = (value: boolean) => {
    this.terminatedCalls.hasNextPage = value;
  }

  resetTerminatedCallsParams = () => {
    this.terminatedCalls.params = defaultTerminatedCallsParams;
  }

  getCallsAsyncDashboard = () => {
    this.fraudStateDashboard = defaultFraudCallsState;
    api<TFraudCall[]>({
      method: "GET",
      url: '/api/v1/fraud_records/search?page=1&page_size=10'
    }).then((res) => {
      runInAction(() => {
        this.setFraudStateDashboard(res);
      })
    }).catch(() => {
      runInAction(() => {
        this.setFraudStateDashboard([]);
      })
    })
  }

  getCallsAsync = (filters: TFiltersCalls, concat?: boolean, customParams?: boolean) => {
    let page_size: number = this.hideFilters.page_size;
    let page: number = this.hideFilters.page;
    if (customParams) {
      if (page !== 1) {
        page_size = page + 20;
        page = 1;
      }
    }
    const params = getParamsFromFilters(filters);
    const dataParams: {
      window: null | string,
      from_ts: null | number,
      till_ts: null | number
    } = {
      window: null,
      from_ts: null,
      till_ts: null
    }
    let strDataParams: string = '';
    if (filters.from_ts && filters.till_ts) {
      const {from_ts, till_ts} = filters;
      const from = dateCustom(Number(from_ts));
      const to = dateCustom(Number(till_ts));
      const window = to.diff(from, 'hours');

      dataParams.window = window <= 24 ? windowInterval.hour : windowInterval.day;
      dataParams.from_ts = from_ts;
      dataParams.till_ts = till_ts;

      strDataParams = `&window=${dataParams.window}&from_ts=${dataParams.from_ts}`;
      strDataParams = strDataParams + `&till_ts=${dataParams.till_ts}`
    }
    if (!concat) {
      this.fraudState = defaultFraudCallsState;
    }
    api<TFraudCall[]>({
      method: "GET",
      url: `/api/v1/fraud_records/search?page=${page}&page_size=${page_size}${strDataParams}${params}`
    }).then((res) => {
      runInAction(() => {
        this.setFraudState(res, concat);
      })
    }).catch(() => {
      runInAction(() => {
        this.setFraudState([]);
      })
    })
  }

  setFraudScheduleAsync = (filters: TFiltersCalls) => {
    this.fraudSchedule = {
      ...this.fraudSchedule,
      loading: true,
    }
    const params = getParamsFromFiltersStatistic(filters);
    const dataParams: {
      window: null | string,
      from: null | number,
      to: null | number
    } = {
      window: windowInterval.day,
      from: new Date().setMonth(new Date().getMonth() - 1),
      to: new Date().setMonth(new Date().getMonth()),
    }
    let strDataParams: string = '';
    if (filters.from_ts && filters.till_ts) {
      const {from_ts, till_ts} = filters;
      const from = dateCustom(Number(from_ts));
      const to = dateCustom(Number(till_ts));

      const window = to.diff(from, 'hours');

      dataParams.window = window <= 24 ? windowInterval.hour : windowInterval.day;
      dataParams.from = from.valueOf();
      dataParams.to = to.valueOf();
      strDataParams = `&window=${dataParams.window}&from=${dataParams.from}&to=${dataParams.to}`;
    } else {
      strDataParams = `&window=${dataParams.window}&from=${dataParams.from}&to=${dataParams.to}`;
    }
    api<TStatsCombinedDataTimeResponse[]>({
      method: "GET",
      url: `/api/v1/stats/combined?group_by_direction=1&group_by_datetime=1${strDataParams}${params}`
    }).then((res) => {
      runInAction(() => {
        this.setFraudSchedule(res)
      })
    }).catch(() => {
      runInAction(() => {
        this.setFraudSchedule([])
      })
    })
  }

  getCurrentCallAsync = (id: string) => {
    this.fraudDetail = {
      loading: true,
      call: null
    };
    api<TFraudDetail>({
      method: "GET",
      url: `/api/v1/fraud_records/${id}`
    }).then((res) => {
      runInAction(() => {
        this.setFraudDetail(res);
      })
    }).catch(() => {
      runInAction(() => {
        this.setFraudDetail(null);
      })

    })
  }

  // TODO remove debounced version as we use "search" button to perform search
  // getTerminatedCalls = debounce(
  //   action(async (errorHandler: (err:unknown) => void, params?: Partial<TerminatedCallsParams>) => {
  //     // omit page from comparison
  //     const { page: nextPage = 1, ...rest } = params || {};

  //     const currentParams = this.terminatedCalls.params;
  //     const newParams = {
  //       ...this.terminatedCalls.params,
  //       ...rest,
  //     };

  //     const isEqualQueries = isEqual(currentParams, newParams);

  //     if (isEqualQueries) {
  //       // Queries are equal => do not reset data

  //       // Check if it's a page bump up
  //       const isNextPageRequest = nextPage > currentParams.page;

  //       let resultRequestParams: TerminatedCallsParams;

  //       if (isNextPageRequest) {
  //         this.terminatedCalls.params.page = nextPage;
  //         resultRequestParams = this.terminatedCalls.params;
  //       } else {
  //         const isEmptyStore = this.terminatedCalls.data.length === 0;
  //         // fetch silently
  //         if (!isEmptyStore) {
  //           this.terminatedCalls.isLoading = false;
  //         }

  //         const { page: currentPage, page_size: currentPageSize } =
  //           this.terminatedCalls.params;
  //         const newPageSize = currentPage * currentPageSize;

  //         resultRequestParams = {
  //           ...this.terminatedCalls.params,
  //           page: 1,
  //           // re-fetch all the data at once
  //           page_size: newPageSize,
  //         };
  //       }

  //       try {
  //         const result = await getTerminatedCalls(resultRequestParams);

  //         const hasNextPage = resultRequestParams.page_size <= result.length;

  //         this.setTerminatedCallsData([
  //           ...(isNextPageRequest ? this.terminatedCalls.data : []),
  //           ...result,
  //         ]);
  //         this.setTerminatedCallsDataHasNextPage(hasNextPage);
  //         this.setTerminatedCallsLoading(false);
  //       } catch (err) {
  //         console.warn("[ERROR] getTerminatedCalls: ", err);
  //         errorHandler(err);
  //       }
  //     } else {
  //       // Queries are NOT equal => do override data

  //       // store new params before fetch
  //       this.terminatedCalls.params = {
  //         ...this.terminatedCalls.params,
  //         ...params,
  //       };

  //       try {
  //         const result = await getTerminatedCalls(this.terminatedCalls.params);

  //         const hasNextPage =
  //           this.terminatedCalls.params.page_size <= result.length;

  //         this.setTerminatedCallsData(result);
  //         this.setTerminatedCallsDataHasNextPage(hasNextPage);
  //         this.setTerminatedCallsLoading(false);
  //       } catch (err) {
  //         console.warn("[ERROR] getTerminatedCalls: ", err);
  //         errorHandler(err);
  //       }
  //     }
  //   }),
  //   700
  // );

  getTerminatedCalls = async (params?: Partial<TerminatedCallsParams>) => {
    // omit "page" from comparison
    const { page: nextPage = 1, ...rest } = params || {};

    const currentParams = this.terminatedCalls.params;
    const newParams = {
      ...this.terminatedCalls.params,
      ...rest,
    };

    const isEqualQueries = isEqual(currentParams, newParams);

    if (isEqualQueries) {
      // Queries are equal => do not reset data

      // Check if it's a page bump up
      const isNextPageRequest = nextPage > currentParams.page;

      let resultRequestParams: TerminatedCallsParams;

      if (isNextPageRequest) {
        this.terminatedCalls.params.page = nextPage;
        resultRequestParams = this.terminatedCalls.params;
      } else {
        const isEmptyStore = this.terminatedCalls.data.length === 0;
        // fetch silently
        if (!isEmptyStore) {
          this.terminatedCalls.isLoading = false;
        }

        const { page: currentPage, page_size: currentPageSize } =
          this.terminatedCalls.params;
        const newPageSize = currentPage * currentPageSize;

        resultRequestParams = {
          ...this.terminatedCalls.params,
          page: 1,
          // re-fetch all the data at once
          page_size: newPageSize,
        };
      }

      const result = await getTerminatedCalls(resultRequestParams);

      const hasNextPage = resultRequestParams.page_size <= result.length;

      this.setTerminatedCallsData([
        ...(isNextPageRequest ? this.terminatedCalls.data : []),
        ...result,
      ]);
      this.setTerminatedCallsDataHasNextPage(hasNextPage);
      this.setTerminatedCallsLoading(false);

    } else {
      // Queries are NOT equal => do override data

      // store new params before fetch
      this.terminatedCalls.params = {
        ...this.terminatedCalls.params,
        ...params,
      };

      const result = await getTerminatedCalls(this.terminatedCalls.params);

      const hasNextPage =
        this.terminatedCalls.params.page_size <= result.length;

      this.setTerminatedCallsData(result);
      this.setTerminatedCallsDataHasNextPage(hasNextPage);
      this.setTerminatedCallsLoading(false);
    }
  }

  setTerminatedCallsReportIsLoading = (value: boolean) => {
    this.isTerminatedCallsReportLoading = value;
  }

  // TODO remove all comments and console.log() after job is done
  downloadTerminatedCallsReport = async () => {
    const { page, page_size, ...params } = this.terminatedCalls.params;

    // https://demo.dev.abhandshake.com/opt/cr/terminated_call_log/Terminated_call_log_1698838405_349.csv

    // https://demo.dev.abhandshake.com/console/static/terminated_call_logs/Terminated_call_log_1698838405_349.csv
    // https://demo.dev.abhandshake.com/api/static/terminated_call_logs/Terminated_call_log_1698838405_349.csv
    this.isTerminatedCallsReportLoading = true;

    const { id } = await createTerminatedCallsReport(
      pickBy(params, (item) => item)
    );

    console.log("Crete report started. Report id: ", id);
    let numberOfAttempts = 0;

    while (true) {
      numberOfAttempts += 1;
      
      console.log("Check status. Attempt #: ", numberOfAttempts);

      const { status, uri, ...rest } = await getTerminatedCallsReport(id);

      console.log("Check status response: ", { status, uri, ...rest });

      if (status === "done") {
        console.log("status === done. Report is ready");
        const reportUri =
          window.location.origin + uri.replace("/console", "/api");

        // TODO open in the same window
        // window.location.href = reportUri;
        window.open(reportUri, "_blank");
        break;
      }

      if (status === "failed") {
        this.rootStore.messagesStore.showMessage({
          type: "error",
          duration: 5,
          content: "Something wrong, please try again...",
        });
        break;
      }

      if(numberOfAttempts === 3) {
        this.rootStore.messagesStore.showMessage({
          type: "info",
          duration: 5,
          content:
            "Report generation takes a long time. We'll notify you when it's done!",
        });
      }

      // sleep for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }

    this.setTerminatedCallsReportIsLoading(false);
  }
}
