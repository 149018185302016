import { useEffect, useState } from "react";
import { Button, Spin, Input, Form, Radio } from "antd";
import { useHistory, Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";

import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";
import { getPrefixListById } from "utils/api";
import { useNotifications } from "ui/Notifications";
import { ResetButton, DeleteButton } from "ui/FormControls";

import dictionary from "assets/dictionary.json";

import { ReactComponent as IconArrowBack } from "assets/svg/arrow-back.svg";

import type { PrefixList } from "store/mobx/types";

import s from "./index.module.scss";

const PrefixListsCreateUpdate = observer(() => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const isEditRuleMode = Boolean(id && id !== "new");

  const {
    firewallStore: {
      prefixListsMap,
      updatePrefixList,
      createPrefixList,
      deletePrefixList,
    },
  } = useStores();

  const currentListData = prefixListsMap[Number(id)];
  const [isLoading, setIsloading] = useState(!currentListData);

  const [initialValues, setInitialValues] = useState<Partial<PrefixList>>({
    name: currentListData?.name,
    description: currentListData?.description,
    ...(!isEditRuleMode && { expirable: currentListData?.expirable || false }),
    ...(!isEditRuleMode && {
      values_type: currentListData?.values_type || "prefixes",
    }),
  });

  const [form] = Form.useForm<PrefixList>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { error, handleError, resetNotification, setNotification } =
    useNotifications(() =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    );

  useEffect(() => {
    let isCanceled = false;

    if (isEditRuleMode && !currentListData && id) {
      const fetchList = async () => {
        try {
          const response = await getPrefixListById(id);

          if (!isCanceled) {
            setInitialValues({
              name: response.name,
              description: response.description,
            });
            setIsloading(false);
          }
        } catch (err) {
          if (!isCanceled) {
            handleError(err, true);
            setIsloading(false);
          }
        }
      };

      fetchList();
    } else {
      setIsloading(false);
    }

    return () => {
      isCanceled = true;
    };
  }, [id, currentListData, isEditRuleMode, handleError]);

  const handleDeletePrefixList = async () =>
    deletePrefixList(Number(id))
      .then(() => history.push(URLS.prefixLists))
      .catch(handleError);

  return isLoading ? (
    <Spin className={s.spin} spinning />
  ) : error?.status === 404 ? (
    <div className={s.notFoundContainer}>
      <h1 className={`${s.fontTitle} ${s.titleBackBtn}`}>
        {"Not found"}

        <Link to={URLS.prefixLists} className={s.mb8}>
          <Button className={s.btnFlat}>
            <IconArrowBack width="2rem" />
            Back
          </Button>
        </Link>
      </h1>
    </div>
  ) : (
    <Form
      form={form}
      className={`${s.container} ${s.form}`}
      initialValues={initialValues}
      layout="vertical"
      onFinish={(values) => {
        setIsSubmitting(true);
        resetNotification();

        return (
          isEditRuleMode
            ? updatePrefixList(Number(id), values)
            : createPrefixList(values)
        )
          .then(() => {
            history.push(
              isEditRuleMode
                ? `${URLS.prefixLists}/${id}/view`
                : URLS.prefixLists
            );
          })
          .then(() => {
            setNotification(
              {
                type: "success",
                data: {
                  message: isEditRuleMode
                    ? "Successfully Updated"
                    : "Successfully Created",
                },
              },
              1500
            );
          })
          .catch((err) => {
            setIsSubmitting(false);
            handleError(err);
          });
      }}
    >
      <section className={s.grid}>
        <h1 className={`${s.fontMainTitle} ${s.titleBackBtn}`}>
          {isEditRuleMode ? "Edit Prefix List" : "New Prefix List"}

          <Link
            to={
              isEditRuleMode
                ? `${URLS.prefixLists}/${id}/view`
                : `${URLS.prefixLists}`
            }
          >
            <Button className={s.btnFlat}>
              <IconArrowBack width="2rem" />
            </Button>
          </Link>
        </h1>

        {isEditRuleMode ? null : (
          <p className={s.mt8}>
            {dictionary.descriptions.rules.prefixList.newPrefixList}
          </p>
        )}
      </section>

      <section className={`${s.grid} ${s.gap24}`}>
        {/* List Name */}
        <div className={s.row}>
          <Form.Item
            label="List Name"
            name="name"
            required={false}
            rules={[{ required: true, message: "List Name is required" }]}
          >
            <Input className={s.input} />
          </Form.Item>
        </div>

        {/* Description */}
        <div className={s.row}>
          <Form.Item label="Description" name="description">
            <Input className={s.input} />
          </Form.Item>
        </div>

        {/* ValueType Expiration QTY */}
        {isEditRuleMode && (
          <div className={s.row}>
            <div className={`${s.grid} ${s.gap4}`}>
              <div className={s.itemHead}>Value type</div>
              <div className={`${s.fontBody} ${s.capitalize}`}>
                {currentListData?.values_type}
              </div>
            </div>

            <div className={`${s.grid} ${s.gap4}`}>
              <div className={s.itemHead}>Expiration</div>
              <div className={s.fontBody}>
                {currentListData?.expirable ? "Expirable" : "Not Expirable"}
              </div>
            </div>

            <div className={`${s.grid} ${s.gap4}`}>
              <div className={s.itemHead}>Quantity</div>
              <div className={s.fontBody}>{currentListData?.values_count}</div>
            </div>
          </div>
        )}
      </section>

      {isEditRuleMode ? null : (
        <>
          <section className={`${s.grid} ${s.gap8}`}>
            <strong className={s.fontSubtitle}>Value type</strong>

            <Form.Item label="Value type" name="values_type" noStyle>
              <Radio.Group className={s.radioGroup}>
                <Radio value="prefixes">Prefixes</Radio>
                <Radio value="numbers">Numbers</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const currentValueType = getFieldValue("values_type");
                return currentValueType === "prefixes"
                  ? dictionary.descriptions.rules.prefixList.valuesPrefixes
                  : dictionary.descriptions.rules.prefixList.valuesNumbers;
              }}
            </Form.Item>
          </section>

          <section className={`${s.grid} ${s.gap8}`}>
            <strong className={s.fontSubtitle}>Expirable</strong>

            <Form.Item label="Expirable" name="expirable" noStyle>
              <Radio.Group className={s.radioGroup}>
                <Radio value={false}> Non-expirable </Radio>
                <Radio value={true}> Expirable </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isExpirable = getFieldValue("expirable");
                return isExpirable
                  ? dictionary.descriptions.rules.prefixList.expirable
                  : dictionary.descriptions.rules.prefixList.nonExpirable;
              }}
            </Form.Item>
          </section>
        </>
      )}

      {/* Buttons */}
      <section className={s.grid}>
        <div className={s.buttonsRow}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsError, getFieldsValue, resetFields }) => {
              const isValid = getFieldsError().every(
                (field) => field.errors.length === 0
              );
              const currentValues = getFieldsValue();
              const isChanged = !isEqual(currentValues, initialValues);
              return (
                <>
                  <Button
                    className={s.btnPrimary}
                    htmlType="submit"
                    disabled={!isChanged || !isValid}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>

                  {isEditRuleMode ? null : (
                    <ResetButton onOk={resetFields} disabled={!isChanged} />
                  )}
                </>
              );
            }}
          </Form.Item>

          {isEditRuleMode && (
            <DeleteButton
              ruleName={initialValues?.name || ""}
              onDelete={handleDeletePrefixList}
            />
          )}
        </div>
      </section>
    </Form>
  );
});

export default PrefixListsCreateUpdate;
