import type { FilterField } from "./index";

type ReducedControls = {
  alwaysOnControls: FilterField[];
  dynamicControls: FilterField[];
};

export const splitToAlwaysOnAndDynamic = (fields: FilterField[]) =>
  fields.reduce<ReducedControls>(
    (acc, item) => {
      return item.alwaysOn
        ? { ...acc, alwaysOnControls: [...acc.alwaysOnControls, item] }
        : { ...acc, dynamicControls: [...acc.dynamicControls, item] };
    },
    { alwaysOnControls: [], dynamicControls: [] }
  );
