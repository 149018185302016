import type { RuleActions } from "store/mobx/types";

export const ruleActionToArray = (
  value?: RuleActions
): ("block" | "alert")[] => {
  switch (value) {
    case "alert":
      return ["alert"];
    case "block":
      return ["block"];
    case "block_and_alert":
      return ["alert", "block"];
    default:
      return [];
  }
};

export const arrayToRuleAction = (
  value: ("alert" | "block")[]
): RuleActions => {
  const isAlert = value.includes("alert");
  const isBlock = value.includes("block");

  return isAlert && isBlock ? "block_and_alert" : isAlert ? "alert" : "block";
};
