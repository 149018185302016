import {FRAUD_TYPES_FETCH_SUCCESS, FRAUD_TYPES_FETCH_ERROR} from "../actions";

const initalState = [{value: 0, name: "All_traffic", full_name: 'All traffic', show_for_inbound: true, show_for_outbound: true}];
export const fraudTypesReducer = (state = initalState, action) => {
  switch (action.type) {
    case FRAUD_TYPES_FETCH_SUCCESS:
      return initalState.concat(action.items)
        .map(item => ({
          ...item,
          full_name: item.full_name,
          description: item.description,
          path: item.name.toLowerCase(),
          inbound: item.show_for_inbound,
          outbound: item.show_for_outbound
        }));
    case FRAUD_TYPES_FETCH_ERROR:
      return state;
    default:
      return state;
  }
};

