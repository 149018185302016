import {useEffect, useCallback} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {Spin} from "antd";

import {URLS} from '../../../../constants/Urls';
import { dateCustom, dateUtc} from 'utils/dates';

import {ReactComponent as BackArrow} from "../../../../assets/svg/backArrow.svg";
import css from './DetailFraudCard.module.scss';
import FlagIcon from "../../../flag/FlagIcon";
import {FraudCallDescription} from "./FraudCallDescription";
import {FRAUD_TYPES} from "../../../../utils/constants";
import {useStores} from "../../../../store/mobx/useStore";
import {observer} from "mobx-react";
import {TFraudDetailCall, TNetwork} from "../../../../store/mobx/types";

const DetailFraudCardComponent = observer(() => {
  const params = useParams<{id: string}>();

  const id = String(params.id);

  const history = useHistory();

  const {
    referenceStore: {
      reference
    },
    fraudRulesStore: {fraudRules},
    callsStore: {
      getCurrentCallAsync,
      fraudDetail
    }
  } = useStores();

  const {loading, fraudTypes, networks} = reference;

  const {call, loading: loadingCall} = fraudDetail;

  useEffect(() => {
    getCurrentCallAsync(id);
  }, [getCurrentCallAsync, id])


  const getLoad = useCallback(() => {
    if (!loadingCall) {
      return loading
    }
    if (!loading) {
      return loadingCall
    }
  }, [loadingCall, loading])

  const isLoaded = getLoad() === undefined ? true : getLoad();

  const renderRuleAction = () => {
    const action = fraudRules.find(item => item.id === call?.applied_rule_id)?.action;
    const text = action === 'alert' ? "Alert only" : action === 'block' ? "Reject call" : "Reject+Alert";

    return (
      <div className={action ? (action === 'alert' ? css.alertCell : css.rejectCell) : ""}>
        <span>{action ? text : "-"}</span>
      </div>
    )
  }

  const getNetworkName = (netId?: number) => {
    const findElement = networks.find((el) => el.id === netId);
    return findElement ? `${findElement.name} (${findElement.country_iso})` : '-';
  }

  const getParamsNetwork = (type: 'borrowed' | 'roaming' | 'ported', network: string) => {
    const ampersand = type === 'roaming' ? 'for' : 'from';
    return (
      <div className={css.paramsNetwork}>
        <div className={css.type} data-test='fraudCallDetails-NetworkParams'>{`${type} ${ampersand}:`}</div>
        <div>{network}</div>
      </div>
    )
  }

  const getOriginNetwork = (callData: TFraudDetailCall | null) => {
    if (!callData) {
      return '-'
    } else {
      if (callData.a_borrowed) {
        return (
          <div>
            {getNetworkName(callData.origin_network_id)}
            {getParamsNetwork('borrowed', getNetworkName(callData.initial_origin_network_id))}
          </div>
        )
      } else if (callData.a_in_roaming) {
        return (
          <div >
            {getNetworkName(callData.origin_network_id)}
            {getParamsNetwork('roaming', getNetworkName(callData.initial_origin_network_id))}
          </div>
        )
      } else if (callData.a_ported) {
        return (
          <div>
            {getNetworkName(callData.origin_network_id)}
            {getParamsNetwork('ported', getNetworkName(callData.initial_origin_network_id))}
          </div>
        )
      } else {
        return getNetworkName(callData.origin_network_id);
      }
    }
  }

  const getDestNetwork = (callData: TFraudDetailCall | null) => {
    if (!callData) {
      return '-'
    } else {
      if (callData.b_in_roaming) {
        return (
          <div>
            {getNetworkName(callData.destination_network_id)}
            {getParamsNetwork('roaming', getNetworkName(callData.initial_destination_network_id))}
          </div>
        )
      } else if (callData.b_ported) {
        return (
          <div>
            {getNetworkName(callData.destination_network_id)}
            {getParamsNetwork('ported', getNetworkName(callData.initial_destination_network_id))}
          </div>
        )
      } else {
        return getNetworkName(callData.destination_network_id);
      }
    }
  }

  const renderRule = () => {
    const rule = fraudRules.find(item => item.id === call?.applied_rule_id);
    if (rule) {
      return (
        <Link to={URLS.editFraudRule({ id: rule.id })}>
          <u>
            <span className={css.valueCol}>{rule.name}</span>
          </u>
        </Link>
      )
    } else {
      return "-"
    }
  }

  const handleRouteBack = () => {
    history.goBack()
  }

  return (
    <div className={css.container}>
      {isLoaded ?
        <div className={css.wrapperLoader}>
          <Spin/>
        </div>
        :
        <div className={css.content}>
          <div className={css.nav}>
            <BackArrow onClick={() => handleRouteBack()}/>
          </div>

          <div className={css.detailsContent}>
            <div className={css.title}>
              <span>{`Fraud call #${id.substring(id.length - 12)}`}</span>
            </div>

            <div className={css.flexContent}>
              <div className={css.cardContentFull}>
                <div className={css.cardTitle}>Call info</div>
                <div className={css.twoColumnContent}>
                  <div className={css.cardInfo}>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>Direction</span>
                      <span className={css.valueCol}>
                      {call?.outbound ? "OUTBOUND" : "INBOUND"}</span>
                    </div>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>Current NETWORK</span>
                      <span
                        className={css.valueCol} data-test='fraudCallDetails-CurrentNetwork'>{getNetworkName(call?.my_network_id)}</span>
                    </div>
                  </div>
                  <div className={css.cardInfo}>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>UTC Time</span>
                      <span
                        className={css.valueCol}>{dateCustom(call?.timestamp).format("YYYY-MM-DD HH:mm:ss")}</span>
                    </div>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>Partner NETWORK</span>
                      <span className={css.valueCol} data-test="fraudCallDetails-PartnerNetwork">{getNetworkName(call?.partner_network_id)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.flexContent}>
              <div className={css.cardContentFull} data-test='fraudCallDetails-DescriptionBlock'>
                <div className={css.cardTitle}>Fraud info</div>
                <div className={css.twoColumnContent}>
                  <div className={css.cardInfo}>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>Fraud Type</span>
                      <span className={css.valueCol}>
                      <strong>{call && fraudTypes &&
                        (fraudTypes.find((item) => item.value === call?.fraud_type)?.full_name || 'Provisional')}</strong>
                    </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={css.cardInfo}>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>Description</span>
                      <span className={css.valueCol} style={{lineHeight: '24px'}}>
                      {call && <FraudCallDescription data={call} fraudTypes={fraudTypes} networks={networks}/>}
                    </span>
                    </div>
                  </div>
                </div>
                {call?.applied_rule_id && call?.applied_rule_id > 0 ?
                  <div>
                    <div className={css.cardInfo}>
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>APPLIED RULE</span>
                        <span className={css.valueCol}>{call?.applied_rule_id ? renderRule() : '-'}</span>
                      </div>
                    </div>
                    <div className={css.cardInfo}>
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>RULE action</span>
                        <span className={css.valueCol}>{call && renderRuleAction()}</span>
                      </div>
                    </div>
                  </div> : ''}
              </div>
            </div>

            <div className={css.flexContent}>

              <div className={css.cardContent}>
                {call?.carried_call ?
                  <div className={css.cardTitle}>Carried call</div> :
                  <div className={css.noCallTitle} data-test='fraudCallDetails-NoCarrierCall'>No carried call</div>
                }
                {call?.carried_call ?
                  <div className={css.cardInfo}>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>A NUMBER</span>
                      <span className={`${css.valueCol} ${call?.outbound ? css.bold : ''}`} data-test="fraudCallDetails-CarriedCall-ANumber">
                       <CountryFlag networks={networks} netId={call?.carried_call.origin_network_id}/>
                        {`${call?.carried_call?.a_number || "-"} ${call?.carried_call?.a_ported ? "" : ""}`}
                    </span>
                    </div>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>B NUMBER</span>
                      <span className={`${css.valueCol} ${!call?.outbound ? css.bold : ''}`}>
                       <CountryFlag networks={networks} netId={call?.carried_call.destination_network_id}/>
                        {`${call?.carried_call?.b_number || "-"} ${call?.carried_call?.b_ported ? "" : ""}`}
                    </span>
                    </div>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>ORIGIN NETWORK</span>
                      <span className={css.valueCol} data-test="fraudCallDetails-CarriedCall-OriginNetwork">
                      {getOriginNetwork(call.carried_call)}
                    </span>
                    </div>
                    <div className={css.cardRow}>
                      <span className={css.descriptionCol}>DEST NETWORK</span>
                      <span className={css.valueCol} style={{width: '100%'}}>
                        {getDestNetwork(call.carried_call)}
                    </span>
                    </div>
                    {call?.carried_call.duration > 0 &&
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>DURATION</span>
                        {call?.carried_call &&
                          <span
                            className={css.valueCol} data-test="fraudCallDetails-CarriedCall-Duration">{call?.carried_call.duration > 0 ? `${call?.carried_call?.duration} sec` : "-"}</span>
                        }
                      </div>}
                    {call?.carried_call?.started_at > 0 &&
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>Started At</span>
                        <span
                          className={css.valueCol}>{call?.carried_call.started_at ?
                          <span title={dateUtc(call?.carried_call?.started_at).format("YYYY-MM-DD HH:mm:ss")} data-test='fraudCallDetails-CarriedCall-StartedAt'>
                            {dateCustom(call?.carried_call?.started_at).format("YYYY-MM-DD HH:mm:ss")}
                            </span> :
                          <span>-</span>}
                        </span>
                      </div>}
                    {call?.carried_call?.connected_at > 0 &&
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>Connected At</span>
                        <span
                          className={css.valueCol}>{call?.carried_call.connected_at ?
                          <span title={dateUtc(call?.carried_call?.connected_at).format("YYYY-MM-DD HH:mm:ss")}>
                          {dateCustom(call?.carried_call?.connected_at).format("YYYY-MM-DD HH:mm:ss")}
                        </span> : "-"}</span>
                      </div>}
                    {call?.carried_call?.ended_at > 0 &&
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>ended At</span>
                        <span
                          className={css.valueCol}>{call?.carried_call ?
                          <span title={dateUtc(call?.carried_call?.ended_at).format("YYYY-MM-DD HH:mm:ss")}>
                            {dateCustom(call?.carried_call?.ended_at).format("YYYY-MM-DD HH:mm:ss")}
                          </span>
                          : "-"}</span>
                      </div>}
                  </div> :
                  <div className={css.noCallLabel} data-test="fraudCallDetails-CarriedCall-NoAffect">This fraud record does not affect fraud statistics</div>
                }
              </div>

              {(call?.fraud_type === FRAUD_TYPES.PBX || call?.fraud_type === FRAUD_TYPES.STRETCH) && call?.outbound ? '' :
                <div className={css.cardContent} data-test='fraudCallDetails-RemoteCallBlock'>
                  {call?.remote_call ?
                    <div className={css.cardTitle}>Remote call</div> :
                    <div className={css.noCallTitle} data-test='fraudCallDetails-RemoteCallBlock-NoMAtch'>No remote call</div>
                  }
                  {call?.remote_call ?
                    <div className={css.cardInfo}>
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>A NUMBER</span>
                        <span className={css.valueCol}>
                       <CountryFlag networks={networks} netId={call?.remote_call.origin_network_id}/>
                          <span data-test="fraudCallDetails-A-Number">{`${call?.remote_call?.a_number || "-"}`}</span>
                    </span>
                      </div>
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>B NUMBER</span>
                        <span className={css.valueCol}>
                         <CountryFlag networks={networks} netId={call?.remote_call.destination_network_id}/>
                          <span>{`${call?.remote_call?.b_number || "-"}`}</span>
                    </span>
                      </div>
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>ORIGIN NETWORK</span>
                        <span className={css.valueCol} data-test="fraudCallDetails-RemoteCall-OriginNetwork">
                       {getOriginNetwork(call.remote_call)}
                    </span>
                      </div>
                      <div className={css.cardRow}>
                        <span className={css.descriptionCol}>DEST NETWORK</span>
                        <span className={css.valueCol} data-test="fraudCallDetails-CarriedCall-DestinationNetwork">
                          {getDestNetwork(call.remote_call)}
                        </span>
                      </div>
                      {call?.remote_call && call?.remote_call?.remote_duration > 0 &&
                        <div className={css.cardRow}>
                          <span className={css.descriptionCol}>DURATION</span>
                          <span
                            className={css.valueCol}>{call?.remote_call?.remote_duration > 0 ? `${call?.remote_call?.remote_duration} sec` : '-'}</span>
                        </div>}
                      {call?.remote_call?.remote_started_at > 0 &&
                        <div className={css.cardRow}>
                          <span className={css.descriptionCol}>Started At</span>
                          <span
                            className={css.valueCol}>{call?.remote_call?.remote_started_at ?
                            <span
                              title={dateUtc(call?.remote_call?.remote_started_at).format("YYYY-MM-DD HH:mm:ss")}>
                            {dateCustom(call?.remote_call?.remote_started_at).format("YYYY-MM-DD HH:mm:ss")}
                            </span>
                            : "-"}</span>
                        </div>}
                      {
                        call?.remote_call?.remote_connected_at > 0 &&
                        <div className={css.cardRow}>
                          <span className={css.descriptionCol}>Connected At</span>
                          <span
                            className={css.valueCol}>{call?.remote_call?.remote_connected_at ?
                            <span
                              title={dateUtc(call?.remote_call?.remote_connected_at).format("YYYY-MM-DD HH:mm:ss")}>
                            {dateCustom(call?.remote_call?.remote_connected_at).format("YYYY-MM-DD HH:mm:ss")}
                            </span>
                            : "-"}</span>
                        </div>}
                      {call?.remote_call?.remote_ended_at > 0 &&
                        <div className={css.cardRow}>
                          <span className={css.descriptionCol}>ended At</span>
                          <span
                            className={css.valueCol}>{call?.remote_call?.remote_ended_at ?
                            <span
                              title={dateUtc(call?.remote_call?.remote_ended_at).format("YYYY-MM-DD HH:mm:ss")}>
                            {dateCustom(call?.remote_call?.remote_ended_at).format("YYYY-MM-DD HH:mm:ss")}
                            </span>
                            : "-"}</span>
                        </div>}
                    </div> :
                    <div className={css.noCallLabel} data-test="fraudCallDetails-RemoteCall-NoMatch">No match in remote network</div>
                  }
                </div>}
            </div>
          </div>
        </div>
      }
    </div>
  )
})

const CountryFlag = ({networks, netId}: { networks: TNetwork[], netId: string | number }) => {
  const findCountry = networks.find((el) => el.id === Number(netId))?.country_iso;
  return (<FlagIcon className={css.countryIcon}
                    country={findCountry ? findCountry : 'ZZ'}/>)
}

export const DetailFraudCard = DetailFraudCardComponent;
