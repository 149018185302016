import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Button } from "antd";

import { Table } from "./fraudCallsTable/Table";
import { URLS } from "../../../constants/Urls";
import FraudCallsChart from "./FraudCallsChart";
import CallsChart from "./CallsChart";
import Widget from "./widgets/Widget";
import FraudWidget from "./widgets/FraudWidget";
import SelectInterval from "../filter/SelectInterval";
import { millsecToDay, millsecToHour } from "../../../utils";
import FilterCallsChart from "./FilterCallsChart";
import { useStores } from "../../../store/mobx/useStore";

import s from "./Dashboard.module.scss";

const DashboardComponent = observer((props: any) => {
  const [updateInterval, setUpdateInterval] = useState<number>(0);

  const {
    callsStore: { getCallsAsyncDashboard, fraudStateDashboard },
    referenceStore: { reference },
    statisticsStore: {
      getFraudCallsScheduleAsync,
      getFraudCallsTypesAsync,
      getCountersAsync,
      filters,
    },
    statusInfoStore: { statusInfo },
  } = useStores();

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateInterval((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (updateInterval >= 60) {
      getCallsAsyncDashboard();
      getFraudCallsScheduleAsync();
      getFraudCallsTypesAsync();
      getCountersAsync();
      setUpdateInterval(0);
    }
  }, [
    getCallsAsyncDashboard,
    getCountersAsync,
    getFraudCallsScheduleAsync,
    getFraudCallsTypesAsync,
    updateInterval,
  ]);

  useLayoutEffect(() => {
    getCallsAsyncDashboard();
    getFraudCallsScheduleAsync();
    getFraudCallsTypesAsync();
    getCountersAsync();
    setUpdateInterval(0);
  }, [
    getFraudCallsScheduleAsync,
    getFraudCallsTypesAsync,
    getCallsAsyncDashboard,
    getCountersAsync,
    filters,
  ]);

  const { loading: loadingFraudStateDashboard, data } = fraudStateDashboard;
  const {
    loading: loadingReference,
    fraudTypes,
    networks,
    gateways,
    direction,
  } = reference;

  const getLoad = useCallback(() => {
    if (!loadingFraudStateDashboard) {
      return loadingReference;
    }
    if (!loadingReference) {
      return loadingFraudStateDashboard;
    }
  }, [loadingFraudStateDashboard, loadingReference]);

  const isLoaded = getLoad() === undefined ? true : getLoad();

  const { filter } = props;

  const widgetData = {
    intervalType: filter.window,
    interval: filter.interval,
    ...statusInfo,
  };

  return (
    <div className={s.container}>
      <div className={s.titleDashboardContainer}>
        <div>
          <div className={s.titleDashboard}>
            {`Dashboard (${statusInfo?.selectedNode || ""})`}
          </div>
          <div className={s.timerUpdate}>
            Data updated {updateInterval} secs ago
          </div>
        </div>

        <SelectInterval />
      </div>

      <br />

      <Widget data={widgetData} />

      <br />

      <div className={s.paper}>
        <div className={s.head}>
          <div className={s.title}>Fraud calls</div>
          <span className={s.subtitle}>
            {`${
              filter.window === "day"
                ? `Last ${millsecToDay(filter.interval)} days`
                : `Last ${
                    millsecToHour(filter.interval) < 12
                      ? 12
                      : millsecToHour(filter.interval)
                  } hours`
            }`}
          </span>
        </div>
        <>
          <FraudCallsChart intervalType={filter.window} />
          <FraudWidget fraudTypes={fraudTypes} />
        </>
      </div>

      <br />

      <div className={s.paper}>
        <div className={s.head}>
          <div className={s.title}>Total calls</div>
          <FilterCallsChart />
          <span className={s.subtitle}>
            {`${
              filter.window === "day"
                ? `Last ${millsecToDay(filter.interval)} days`
                : `Last ${
                    millsecToHour(filter.interval) < 12
                      ? 12
                      : millsecToHour(filter.interval)
                  } hours`
            }`}
          </span>
        </div>
        <CallsChart intervalType={filter.window} />
      </div>

      <br />

      <div className={s.paper}>
        <div className={s.wrapperSection}>
          <div>
            <div className={s.titleSection}>Last fraud calls</div>
            <div className={s.timerUpdate}>
              Data updated {updateInterval} secs ago
            </div>
          </div>

          <div className={s.buttons}>
            <Link className={s.showAll} to={URLS.fraudcalls}>
              <Button type="primary">Show all</Button>
            </Link>
          </div>
        </div>

        {!isLoaded && (
          <Table
            data={data}
            fraudTypes={fraudTypes}
            networks={networks}
            gateways={gateways}
            direction={direction}
          />
        )}
      </div>
    </div>
  );
});

const mapStateToProps = (state: any) => {
  return {
    filter: state.stats.filter,
  };
};

export default connect(mapStateToProps)(DashboardComponent);
