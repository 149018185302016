import {USERS_FAILED, USERS_SUCCESS} from "../actions";

export const usersReducer = (state, action) => {
  switch (action.type) {
    case USERS_SUCCESS: {
      return {
        loading: false,
        list: action.payload
      }
    }
    case USERS_FAILED: {
      return {
        loading: false,
        list: []
      }
    }
    default:
      return {...state}
  }
}
