import { intervalParams, intervalParamsTo } from "../../../utils/savedParams";
import { TerminatedCallsParams } from "../types";

export const defaultCounters = {
  loading: false,
  inbound: 0,
  percentInbound: 0,
  outbound: 0,
  percentOutbound: 0,
  valid: 0,
  total: 0,
};

export const defaultTraffic = {
  loading: true,
  list: [],
  counter: 0,
  percent: 0,
};

export const defaultFilters = {
  from: intervalParams,
  to: intervalParamsTo,
};

export const defaultTotalCallsState = {
  loading: false,
  list: [],
  internationalList: [],
  validList: [],
  domesticList: [],
};

export const defaultFraudState = {
  loading: false,
  list: [],
  totalList: [],
  validList: [],
};

export const defaultParticipantsState = {
  loading: false,
  list: [],
  inboundTotal: 0,
  outboundTotal: 0,
};

export const defaultFraudCurrentState = {
  loading: false,
  data: [],
};

export const defaultFraudCallsState = {
  loading: true,
  data: [],
};

export const defaultFiltersCalls = {
  direction: undefined,
  fraud_types: undefined,
  countries: undefined,
  gateway_ids: undefined,
  from_ts: undefined,
  till_ts: undefined,
  network_ids: undefined,
};

export const defaultHideFiltersCalls = {
  page: 1,
  page_size: 20,
};

export const defaultDirection = ["outbound", "inbound"];

export const defaultTerminatedCallsParams: TerminatedCallsParams = {
  page: 1,
  page_size: 20,
  rule_type: null,
  rule_id: null,
  from: null,
  to: null,
  direction: null,
  termination_result: null,
  a_number: null,
  b_number: null,
};
