import { ReactNode, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation, useHistory } from "react-router-dom";
import { Spin, Button } from "antd";

import FlagIcon from "components/flag/FlagIcon";
import { useNotifications } from "ui/Notifications/index";

import { dateCustom, getDurationString } from "utils/dates";
import { qs } from "utils/qs";
import { useStores } from "store/mobx/useStore";
import { URLS } from "constants/Urls";

import { ReactComponent as IconArrowOutbound } from "assets/svg/arrow-outbound.svg";
import { ReactComponent as IconArrowInbound } from "assets/svg/arrow-inbound.svg";
import { ReactComponent as IconArrowForward } from "assets/svg/arrow-forward.svg";
import { ReactComponent as IconArrowTransit } from "assets/svg/arrow-transit.svg";
import { ReactComponent as IconWarning } from "assets/svg/warning-triangle.svg";

import type {
  CallDirection,
  CallControlRuleType,
  TerminatedCallsParams,
  TerminatedCall,
  TerminationResult,
  TNetwork,
} from "store/mobx/types";

import s from "./index.module.scss";

const DIRECTION_MAP: Record<TerminatedCall["direction"], ReactNode> = {
  inbound: <IconArrowInbound width="2rem" />,
  outbound: <IconArrowOutbound width="2rem" />,
  transit: <IconArrowTransit width="2rem" />,
};

export const RejectedCallsTable = observer(function RejectedCallsTable() {
  const { search } = useLocation();
  const history = useHistory();

  const { handleError, resetNotification } = useNotifications();

  const {
    callsStore: {
      getTerminatedCalls,
      terminatedCalls,
      setTerminatedCallsData,
      setTerminatedCallsLoading,
      resetTerminatedCallsParams,
    },
    referenceStore: { reference, callControlRules },
  } = useStores();

  const { data, isLoading, hasNextPage } = terminatedCalls;

  const { networks } = reference;

  useEffect(() => {
    setTerminatedCallsLoading(true);

    const {
      from,
      to,
      direction,
      rule_type,
      rule_id,
      termination_result,
      a_number,
      b_number,
    } = qs.parse(search, { ignoreQueryPrefix: true }) as Record<
      string,
      string | undefined
    >;

    let resultQuery: Partial<TerminatedCallsParams> = {
      page: 1,
      page_size: 20,
      from: from ? Number(from) : null,
      to: to ? Number(to) : null,
      direction: (direction as CallDirection) || null,
      rule_type: (rule_type as CallControlRuleType) || null,
      rule_id: rule_type && rule_id ? Number(rule_id) : null,
      termination_result: (termination_result as TerminationResult) || null,
      a_number: a_number || null,
      b_number: b_number || null,
    };

    resetNotification();
    getTerminatedCalls(resultQuery)
      .catch(handleError)
      .then(() => setTerminatedCallsLoading(false));
  }, [search, getTerminatedCalls, setTerminatedCallsLoading, setTerminatedCallsData, resetTerminatedCallsParams, handleError, resetNotification]);

  const handleRowClick = (call: TerminatedCall) => {
    history.push(`${URLS.rejectedCalls}/${call.call_label}`, {
      call: { ...call },
      search,
    });
  };

  const handleLoadMore = () => {
    setTerminatedCallsLoading(true);
    const currentPage = terminatedCalls.params.page;

    resetNotification();
    getTerminatedCalls({ page: currentPage + 1 }).catch(handleError);
  };

  return !data.length && !isLoading ? (
    <div className={s.noData}>There are no records.</div>
  ) : (
    <>
      <table className={s.table}>
        <thead>
          <tr>
            <th>rule</th>
            <th>a-number</th>
            <th className={s.cellForward}></th>
            <th>b-number</th>
            <th>started at</th>
            <th>terminated after</th>
            <th>ended after</th>
            <th>reject code</th>
          </tr>
        </thead>

        {/* Spinner */}
        {isLoading && (
          <tbody className={s.spin}>
            <tr>
              <td>
                <Spin spinning />
              </td>
            </tr>
          </tbody>
        )}

        {/* Content */}
        <tbody className={s.content}>
          {data.map((call) => {
            const { aNetwork, bNetwork } = networks.reduce<{
              aNetwork?: TNetwork;
              bNetwork?: TNetwork;
            }>((acc, network) => {
              if (network.id === call.a_network_id) {
                acc.aNetwork = network;
              }

              if (network.id === call.b_network_id) {
                acc.bNetwork = network;
              }

              return acc;
            }, {});

            const rule = callControlRules.find(
              (rule) => rule.type === call.rule_type && rule.id === call.rule_id
            );

            const terminatedAfter = getDurationString(
              call.started_at,
              call.terminated_at
            );

            const endedAfter = getDurationString(
              call.started_at,
              call.ended_at
            );

            return (
              <tr key={call.call_label} onClick={() => handleRowClick(call)}>
                {/* Rule */}
                <td className={s.cellRule}>
                  {DIRECTION_MAP[call.direction] || null}
                  <div
                    className={s.ellipsis}
                    title={rule && rule.name.length > 9 ? rule.name : ""}
                  >
                    {rule?.name || "N/A"}
                  </div>
                </td>

                {/* A-number */}
                <td className={s.cellNumber}>
                  <FlagIcon country={aNetwork?.country_iso || "ZZ"} />
                  <div className={s.fontBodyBold} title={aNetwork?.name}>
                    {call.a_number}
                  </div>
                  <div className={s.ellipsis} title={aNetwork?.name}>
                    {aNetwork?.name || "N/A"}
                  </div>
                </td>

                {/* Forward */}
                <td className={s.cellForward}>
                  <IconArrowForward width="2rem" />
                </td>

                {/* B-number */}
                <td className={s.cellNumber}>
                  <FlagIcon country={bNetwork?.country_iso || "ZZ"} />
                  <div className={s.fontBodyBold} title={bNetwork?.name}>
                    {call.b_number}
                  </div>
                  <div className={s.ellipsis} title={bNetwork?.name}>
                    {bNetwork?.name || "N/A"}
                  </div>
                </td>

                {/* Started at */}
                <td>{dateCustom(call.started_at).format()}</td>

                {/* Terminated after */}
                <td>{terminatedAfter}</td>

                {/* Ended after */}
                <td className={endedAfter === "N/A" ? s.disabled : ""}>
                  {!call.is_terminated && (
                    <IconWarning width="2rem" className={s.iconWarning} />
                  )}

                  {endedAfter}
                </td>

                {/* Reject Code */}
                <td className={s.cellRejectCode}>{call.reject_code || ""}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Load more */}
      {!data.length || !hasNextPage ? null : (
        <Button
          onClick={handleLoadMore}
          className={s.loadMoreBtn}
          disabled={isLoading}
        >
          Load more
        </Button>
      )}
    </>
  );
});
