import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "@vas-common/ui-kit";
import { Modal } from "antd";

import { ReactComponent as AccountIcon } from "assets/svg/profile-2.svg";
import { ReactComponent as NetworksIcon } from "assets/svg/accounts-2.svg";
import { ReactComponent as IconABHLogo } from "assets/svg/logo-new-2.svg";

import { URLS } from "constants/Urls";
import { PROJECT_ID_KEY } from "store/actions";
import { hasPerm, Perms } from "constants/Perms";
import { site } from "utils/site";

import { topMenuItems } from "./helpers";

import type { ItemType } from "antd/es/menu/hooks/useItems";
import { useStores } from "store/mobx/useStore";

const { confirm } = Modal;

export const Navigation = () => {
  const [footerMenu, setFooterMenu] = useState<ItemType[]>([]);

  const {
    userStore: { logOut, user },
    statusInfoStore: { statusInfo },
  } = useStores();

  const showConfirmLogout = useCallback(
    () =>
      confirm({
        title: "Log out",
        onOk() {
          logOut();
        },
      }),
    [logOut]
  );

  useEffect(() => {
    if (statusInfo?.node && statusInfo.node.networks.length > 1) {
      const projectId = Number(localStorage.getItem(PROJECT_ID_KEY));

      const currentNetwork = statusInfo.node.networks.find(
        (network: any) => network.id === projectId
      );

      if (currentNetwork) {
        const networksMenu: ItemType & { "data-test": string } = {
          label: currentNetwork.name,
          key: "Networks",
          icon: <NetworksIcon width="2rem" />,
          children: statusInfo.node.networks.map(({ name, id }: any) => ({
            label: (
              <Link
                to="#"
                onClick={() => {
                  localStorage.setItem(PROJECT_ID_KEY, `${id}`);
                  window.location.reload();
                }}
              >
                {name}
              </Link>
            ),
            key: id,
          })),
          "data-test": "navigation-networks",
        };

        setFooterMenu((footerMenu) => {
          const filteredMenu = footerMenu.filter(
            (item) => item?.key !== "Networks"
          );
          return [...filteredMenu, networksMenu];
        });
      }
    }
  }, [statusInfo]);

  useEffect(() => {
    if (user?.auth_enabled) {
      const items: ItemType[] = [
        {
          label: (
            <Link to="#" onClick={showConfirmLogout}>
              Logout
            </Link>
          ),
          key: "logout",
        },
      ];

      if (hasPerm(user, Perms.USERS_ANY, Perms.USERS_READ)) {
        items.unshift({
          label: <Link to={URLS.users}>User Management</Link>,
          key: URLS.users,
        });
      }

      const accountMenu: ItemType = {
        label: "Account Settings",
        key: "Account",
        icon: <AccountIcon width="2rem" />,
        children: items,
      };

      setFooterMenu((footerMenu) => {
        const filteredMenu = footerMenu.filter(
          (item) => item?.key !== "Account"
        );
        return [accountMenu, ...filteredMenu];
      });
    }
  }, [user, showConfirmLogout]);

  return (
    <Sidebar
      menu={topMenuItems}
      footerMenu={footerMenu}
      footerCaption={`${site.title} ${statusInfo?.deployment?.version}`}
      logo={<IconABHLogo width="16.9rem" />}
    />
  );
};
