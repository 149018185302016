import { Button } from "antd";
import { Link } from "react-router-dom";

import { URLS } from "constants/Urls";
import { TParticipantsFormatNetwork } from "store/mobx/types";
import { tickFormatter } from "utils/formatNumber";

import s from "./tableColumns.module.scss";

const columns = [
  {
    title: "Country/Network",
    key: "country",
    width: "26%",
    render: (text: string, record: TParticipantsFormatNetwork) => (
      <div className={`${s.boldFont} ${s.node}`}>{renderName(record)}</div>
    ),
  },
  {
    title: "",
    key: "type",
    width: "15%",
    render: (text: string, record: TParticipantsFormatNetwork) =>
      renderParticipant(record),
  },
  {
    title: "",
    key: "nodes",
    width: "15%",
    render: (text: string, record: TParticipantsFormatNetwork) =>
      renderStatus(record),
  },
  {
    title: "Inbound calls",
    key: "inbound",
    width: "10%",
    render: (text: number, record: TParticipantsFormatNetwork) =>
      renderCallsCount(record, "inbound"),
  },
  {
    title: "Outbound calls",
    key: "outbound",
    width: "10%",
    render: (text: number, record: TParticipantsFormatNetwork) =>
      renderCallsCount(record, "outbound"),
  },
  {
    title: "Validate calls",
    key: "validate",
    width: "10%",
    render: (text: number, record: TParticipantsFormatNetwork) =>
      renderCallsCount(record, "validate"),
  },
  {
    title: "Prefixes",
    key: "prefixes",
    width: "10%",
    render: (text: number, record: TParticipantsFormatNetwork) =>
      renderCallsCount(record, "prefixes"),
  },
  {
    title: "",
    key: "btn",
    width: "5%",
    render: (val: string, record: TParticipantsFormatNetwork) =>
      addRuleCell(record),
  },
];

const renderName = (record: TParticipantsFormatNetwork) => {
  switch (record.type) {
    case "Country":
      return "Other";
    case "Premium":
      return "Premium";
    case "Unallocated":
      return "Unallocated";
    case "Application":
      return `${record.country}`;
    default:
      return `${record.country} (${record.type})`;
  }
};

export const fixedAntdTable = columns.map((el) => {
  if (el.key === "country") {
    return {
      ...el,
      width: "26.5%",
    };
  } else {
    return {
      ...el,
    };
  }
});

const renderParticipant = (record: TParticipantsFormatNetwork) => {
  const getText = (type: string) => {
    switch (type) {
      case "None":
        return "None";
      case "Full":
        return "Participant";
      case "In progress":
        return "In progress";
      default:
        return type;
    }
  };

  const text = getText(record.participantType);

  const getBackgroundColor = (val: string | any) => {
    switch (val) {
      case "Participant":
        return s.greenBackground;
      case "Partial":
        return s.greenBackground;
      case "In progress":
        return s.blueBackground;
      case "None":
        return s.grayBackground;
      default:
        return s.grayBackground;
    }
  };

  if (
    record.type === "Unallocated" ||
    record.type === "Premium" ||
    record.type === "Country"
  )
    return <div className={s.ParticipantColumn}></div>;

  return (
    <div className={s.ParticipantColumn}>
      <span className={getBackgroundColor(text)}>{text}</span>
    </div>
  );
};

const renderStatus = (record: TParticipantsFormatNetwork) => {
  if (
    record.type !== "Mobile" &&
    record.type !== "Landline" &&
    record.type !== "Application" &&
    record.type !== "Satellite"
  )
    return "";
  return <div className={record.nodes ? s.active : s.inactive} />;
};

const renderCallsCount = (
  record: TParticipantsFormatNetwork,
  type: "inbound" | "outbound" | "validate" | "prefixes"
) => {
  const value = tickFormatter(record[type]);
  const styleCss = record[type] > 0 ? s.blackFont : s.lightGrayFont;

  if (
    type === "validate" &&
    record.type !== "Mobile" &&
    record.type !== "Landline" &&
    record.type !== "Application" &&
    record.type !== "Satellite"
  )
    return "";
  return <div className={styleCss}>{`${value}`}</div>;
};

const addRuleCell = (record: TParticipantsFormatNetwork) => (
  <div style={{ display: "flex", justifyContent: "end" }}>
    <Link
      to={{
        pathname: URLS.createFraudRule,
        state: {
          idCountry: record.iso,
          idNetwork: record.id,
        },
      }}
    >
      <Button className={s.btnAddRule}>Add rule</Button>
    </Link>
  </div>
);

export default columns;
