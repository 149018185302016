export type TCounter = {
  loading: boolean,
  inbound: number,
  percentInbound: number,
  outbound: number,
  percentOutbound: number,
  valid: number,
  total: number
}

export type TFilter = {
  from: number,
  to: number | undefined
}

export type TType = 'all' | 'current';

export enum TypeEnum {
  all = 'all',
  current = 'current'
}

export type TListCalls = {
  timestamp: number,
  inboundFraud: number,
  outboundFraud: number,
}

export type TTotalCallsSchedule = {
  loading: boolean,
  list: TListCalls[],
  internationalList: TListCalls[],
  validList: TListCalls[],
  domesticList: TListCalls[]
}

export type TFraudSchedule = {
  loading: boolean,
  list: TListCalls[],
  totalList: TListCalls[],
  validList: TListCalls[]
}

export type TParticipantsState = {
  loading: boolean,
  list: TListParticipants[],
  inboundTotal: number,
  outboundTotal: number
}

export type TParticipantsFormatNetwork = {
  id: number,
  country: string,
  nodes: boolean,
  inbound: number,
  outbound: number,
  validate: number,
  prefixes: number,
  iso: string,
  type: string,
  participantType: string
}

export type TListParticipantsFormat = {
  id: number,
  country: string,
  participantsActive: number
  participantsTotal: number
  nodes: boolean,
  inbound: number,
  outbound: number,
  validate: number,
  prefixes: number,
  networks: TParticipantsFormatNetwork[]
}

export type TParticipantsFormat = {
  loading: boolean,
  list: TListParticipantsFormat[]
}

export type TListParticipants = {
  id: number,
  inbound: number,
  outbound: number,
  validate: number
}

export type TFraudScheduleCurrent = {
  loading: boolean,
  data: {
    id: number,
    list: TListCalls[],
    count: number,
    percent: number,
    validatedInbound: number,
    percentInbound: number,
    validatedOutbound: number,
    percentOutbound: number
  }[]
}

export type TStatsCombinedResponse = {
  alerted: number,
  blocked: number,
  direction: "inbound" | "outbound"
  fraudulent: number
  fraudulent_connected: number
  fraudulent_duration: number
  rejected: number
  total: number
  total_connected: number
  total_duration: number
  validated_default: number
  validated_dry_run: number
  validation_errors: number
}

export type TStatsCombinedDataTimeResponse = {
  alerted: number,
  blocked: number,
  datetime: number,
  direction: "inbound" | "outbound",
  domestic_total: number,
  fraudulent: number,
  fraudulent_connected: number,
  fraudulent_duration: number,
  rejected: number,
  total: number,
  total_connected: number,
  total_duration: number,
  validated_default: number,
  validated_dry_run: number,
  validation_errors: number,
}

export type TStatsCombinedFraudTypeResponse = {
  alerted: number,
  blocked: number,
  datetime: number,
  direction: "inbound" | "outbound"
  fraud_type: number,
  fraudulent: number,
  fraudulent_connected: number,
  fraudulent_duration: number,
  rejected: number,
  total: number,
  total_connected: number,
  total_duration: number,
  validated_default: number,
  validated_dry_run: number,
  validation_errors: number,
}

export type TCarriedCall = {
  a_borrowed: boolean
  a_in_roaming: boolean
  a_number: string
  a_ported: boolean
  b_in_roaming: boolean
  b_number: string
  b_ported: boolean
  connected_at: number
  destination_network_id: number
  duration: number
  ended_at: number
  gateway_id: number
  initial_destination_network_id: number
  initial_origin_network_id: number
  is_domestic: boolean
  is_intranet: boolean
  origin_network_id: number
  remote_connected_at: number
  remote_duration: number
  remote_ended_at: number
  remote_started_at: number
  session_id: string
  started_at: number
}

export type TFraudCall = {
  alerted: boolean
  applied_rule_id: number
  blocked: boolean
  carried_call: TCarriedCall | null
  fraud_type: number
  id: string
  my_network_id: number
  outbound: boolean
  partner_network_id: number
  remote_call: TCarriedCall | null
  timestamp: number
}

export type TFraudCalls = {
  loading: boolean,
  data: TFraudCall[]
}

export type TFraudDetailCall = {
  a_number: string,
  b_number: string,
  gateway_id: number,
  session_id: string,
  duration: number,
  remote_duration: number,
  origin_network_id: number,
  initial_origin_network_id: number,
  destination_network_id: number,
  initial_destination_network_id: number,
  a_ported: boolean,
  a_borrowed: boolean,
  a_in_roaming: boolean,
  b_ported: boolean,
  b_in_roaming: boolean,
  is_domestic: boolean,
  is_intranet: boolean,
  started_at: number,
  connected_at: number,
  ended_at: number,
  remote_started_at: number,
  remote_connected_at: number,
  remote_ended_at: number
}

export type TFraudDetail = {
  id: string,
  outbound: boolean,
  fraud_type: number,
  my_network_id: number,
  partner_network_id: number,
  carried_call: TFraudDetailCall | null,
  remote_call: TFraudDetailCall | null,
  applied_rule_id: number,
  alerted: boolean,
  blocked: boolean,
  timestamp: number
}

export type TFraudDetailState = {
  loading: boolean,
  call: TFraudDetail | null
}
