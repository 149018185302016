export enum Perms {
  USERS_ANY = 'users/any',
  USERS_READ = 'users/read',
  RULES_ANY = 'rules/any',
  RULES_READ = 'rules/read',
  REPORTS_READ = 'reports/read',
  REPORTS_ANY = 'reports/any',
  CHANNELS_READ = 'channels/read',
  CHANNELS_ANY = 'channels/any',
  FRAUD_RECORDS_ANY = 'fraud_records/any',
  FRAUD_RECORDS_READ = 'fraud_records/read',
  FRAUD_LOGS_ANY = 'fraud_logs/any'
}

export const hasPerm = (user: any, permission: string, orPermission?: string): boolean => {
  if (!user.auth_enabled) return true;
  return user.role && user.role.permissions &&
    (user.role.permissions.includes(permission) || user.role.permissions.includes(orPermission));
}