import { NotificationChannel } from "store/mobx/types";
import { NOTIFICATION_TYPE_ICONS_MAP } from "../../../maps";

import s from "./index.module.scss";

type Props = {
  channel: NotificationChannel;
};
export const NotificationChannelLabel = ({ channel }: Props) => {
  return (
    <span className={s.label}>
      {NOTIFICATION_TYPE_ICONS_MAP[channel.type]}
      {channel?.name}
    </span>
  );
};
